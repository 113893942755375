import React, { Component } from "react";
import { Row, Col, Menu, Icon, Tabs, Button, Card } from "antd";

import { Link } from "react-router-dom";

class Footer extends Component {
  getCurrentYear() {
    return new Date().getFullYear();
  }
  render() {
    return (
      <Row
        type="flex"
        justify="center"
        style={{ textAlign: "center", color: "#fff" }}
      >
        <Col span={24}>
          <div
            style={{
              backgroundColor: "#1E1D1D",
              minHeight: "100px",
              paddingTop: "20px",
              display: "flex-column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>(c)-{this.getCurrentYear()}- All Rights Reserved</p>
            {/* <p><Link to ="/termsofuse">Terms Of Use</Link> | <Link to ="/privacypolicy">Privacy Policy</Link></p> */}
          </div>
        </Col>
      </Row>
    );
  }
}

export default Footer;
