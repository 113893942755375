import {
    AUTH_ERROR_SET,
    AUTH_ERROR_RESET

} from '../actions/types'


export const setAuthErrors = (errorData) => {
    return {
        type: AUTH_ERROR_SET,
        payload : errorData
    }
}

export const resetAuthErrors = () => {
    return {
        type: AUTH_ERROR_RESET,
    }
}
