import React, { Component } from "react";
import isEmpty from '../../../validation/is-empty'

import { Drawer, Button, Form, Alert, Spin, Input, Card, Select } from "antd";

import {} from "../../../helpers/Requesthelper/RequestStatistics";
import { r_GetUserDetails } from "../../../helpers/Requesthelper/RequestFormatter";
import { r_updateUserDetails, r_updateUserPassword } from "../../../helpers/Requesthelper/RequestAdminUserManagement";

const {Option} = Select

class UpdateUser extends Component {
  componentDidMount() {
  }
  render() {
    return (
      <Drawer
        width={"40%"}
        title="Update User Details"
        placement="right"
        destroyOnClose="true"
        closable={true}
        onClose={this.props.resetupdateUserDrawerVisible}
        visible={this.props.updateUserDrawerVisible}
      >
        <UpdateUserComponent 
        {...this.props}
        
        />

      </Drawer>
    );
  }
}

export default UpdateUser;


class UpdateUserComponent extends Component {
    //reference to the form
    formRef = React.createRef();

    state = {
      name:"",
      email:"",
      password:"",
      loadingUserDetails: true,
      errors:{},
      formBusy:false,
      userUpdateSuccess:false,
      changePassword: false,
      userDetails : {}
    }

    componentDidMount(){
      
      this.generateUserDetails()

    }
    
    generateUserDetails = () => {

      this.setState({loadingUserDetails: true})
      
      r_GetUserDetails(this.props.slectedUserRowDetails.username,this.props.auth.userToken, this.props.endpoints).then(res => {
        //console.log(res)
        this.setState({
          loadingUserDetails: false
        })
        this.setState({userDetails: res.data})
        
        this.formRef.current.setFieldsValue({

          name: this.state.userDetails.name? this.state.userDetails.name : "NA",
          email: this.state.userDetails.email ? this.state.userDetails.email: "NA",
          userName: this.state.userDetails.userName ? this.state.userDetails.userName: "NA",
          givenName: this.state.userDetails.givenName ? this.state.userDetails.givenName: "NA",
          familyName: this.state.userDetails.familyName ? this.state.userDetails.familyName: "NA",
          address: this.state.userDetails.address ? this.state.userDetails.address: "NA",
          gender: this.state.userDetails.gender ? this.state.userDetails.gender: "NA",
          telephone: this.state.userDetails.telephone ? this.state.userDetails.telephone: "NA",

        });
          
      })
    }
    onChange = (e) => {
      this.setState({[e.target.name] : e.target.value})

    }
    onFinish = (e) => {

     // console.log(e)

      this.setState({
        formBusy: true,
        errors:{},
        userUpdateSuccess: false
      })
      
      const userData = {

        name: e.name ? e.name : "",
        familyName: e.familyName ? e.familyName : "",
        givenName: e.givenName ? e.givenName : "",
        email: e.email ? e.email : "",
        userName: e.userName ? e.userName : "",

        familyName: e.familyName ? e.familyName: "",
        address: e.address ? e.address: "",
        gender: e.gender ? e.gender: "",
        telephone: e.telephone ? e.telephone: "",

        identifier: this.state.userDetails.identifier? this.state.userDetails.identifier : "",
      }

      //console.log(userData)

      r_updateUserDetails (userData, this.props.auth.userToken, this.props.endpoints).then(res=>{
        //console.log(res)
        if(res.data.status === "success"){
          //somehow reload the user details 
          //console.log("data updated successfully")
          this.setState({
            formBusy: false,
            errors:{},
            userUpdateSuccess: true
          })

          this.generateUserDetails()

        }else if (res.data.status === "fail"){
          const {message, messageId} = res.data
          const errorData = {message, messageId}
          this.setState({errors : errorData, formBusy: false})
        }
        
      })

    }
    onFinishFailed = (e) => {
      //console.log(e)
    }

    handleChangePassword = () => {
      this.setState({
        changePassword: true,
      })
    }
    cancelChangePassword = () => {
      this.setState({
        changePassword: false,
      })
    }
    render(){
      
      const {name, email, password} = this.state


      const layout = {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 18,
        },
      };
      const tailLayout = {
        wrapperCol: {
            offset: 6,
            span: 6,
        },
      };
      const tailLayoutMessage = {
        wrapperCol: {
            offset: 4,
            span: 20,
        },
      }
      return(
        <div>
        {this.state.loadingUserDetails ? (
        <Spin />
        ) :
        <Card className="card-box-shadow-default" >
        <Form
            ref={this.formRef}
            {...layout}
            name="basic"
            initialValues={{
                remember: true,
            }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            >
            <Form.Item
                label="Name"
                name="name"
                rules={[
                {
                    required: false,
                    message: 'Please input name!',
                },
                ]}
            >
                <Input
                  
                  defaultValue = {name}

                  onChange={this.onChange}
                />
            </Form.Item>

            <Form.Item
                label="Given Name"
                name="givenName"
                rules={[
                {
                    required: false,
                    message: 'Please input given Name!',
                },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Family Name"
                name="familyName"
                rules={[
                {
                    required: false,
                    message: 'Please input family Name!',
                },
                ]}
            >
                <Input />
            </Form.Item>


            <Form.Item
                label="Email"
                name="email"
                rules={[
                {
                    required: false,
                    message: 'Please input email!',
                },
                ]}
            >
                <Input 
                
                disabled
                
                />
            </Form.Item>

            <Form.Item
                label="User Name"
                name="userName"
                rules={[
                {
                    required: false,
                    message: 'Please input userName',
                },
                ]}
            >
                <Input 
                disabled
                />
            </Form.Item>
            
            <Form.Item
                label="Address"
                name="address"
                rules={[
                {
                    required: false,
                    message: 'Please input address!',
                },
                ]}
            >
                <Input />
            </Form.Item>


            <Form.Item
                label="Gender"
                name="gender"
                rules={[
                {
                    required: false,
                    message: 'Please input gender!',
                },
                ]}
            >
              <Select
                placeholder="Select gender"
                //onChange={onGenderChange}
                allowClear
              >
                <Option value="male">male</Option>
                <Option value="female">female</Option>
                <Option value="other">other</Option>
              </Select>
            </Form.Item>

            <Form.Item
                label="Telephone"
                name="telephone"
                rules={[
                {
                    required: false,
                    message: 'Please input telephone!',
                },
                ]}
            >
                <Input />
            </Form.Item>


            <Form.Item
                label="Password"
                name="userPassword"
                rules={[
                {
                    required: false,
                    message: 'Please input password!',
                },
                ]}
            >
                <Button onClick={this.handleChangePassword} >Change Password</Button>
                <Button onClick={this.cancelChangePassword} >Cancel</Button>
            </Form.Item>

            {
              this.state.changePassword ?
                <UpdateUserPassword 
                  {...this.props}
                  userDetails = {this.state.userDetails}

                />

              : null
              }

            {!isEmpty(this.state.errors) ?
                <Form.Item {...tailLayoutMessage}><Alert message={this.state.errors.message} type="error" showIcon style={{widt:"100%"}}/></Form.Item> 
                :null
            }
            {this.state.formBusy ?
                <Form.Item {...tailLayoutMessage}><Spin /></Form.Item>
                :null
            }
            {this.state.userUpdateSuccess ?
                <Form.Item {...tailLayoutMessage}>
                    <Alert message= "User updated Successfully" />
                </Form.Item> 
                :null
            }
            <Form.Item {...tailLayout} >
                <Button type="primary" htmlType="submit">
                Update User
                </Button>
            </Form.Item>
            </Form>
            </Card> 
          }
          </div>
      )
    }
}

class UpdateUserPassword extends Component {

  formRef = React.createRef();

  state = {
    errors:{},
    formBusy:false,
    userPasswordUpdateSuccess:false,
  }

  onFinish = (e) => {
    //console.log(e)
    this.setState({
      formBusy:true,
      errors: {},
      userPasswordUpdateSuccess: false,
    })

    const userData = {
      identifier: this.props.userDetails.identifier,
      userPassword: e.userPassword
    }

    //console.log(this.props)
    r_updateUserPassword (userData, this.props.auth.userToken, this.props.endpoints).then(res=>{
      //console.log(res)
      if(res.data.status === "success"){
        this.setState({
          formBusy: false,
          errors: {},
          userPasswordUpdateSuccess: true
        })
        this.formRef.current.resetFields();

      } else if (res.data.status === "fail"){
          const {message, messageId} = res.data
          const errorData = {message, messageId}
          this.setState({errors : errorData, formBusy: false})
      }
    })
  }
  onFinishFailed = (e)=> {
    //console.log(e)
  }


  render(){
    const layout = {
      labelCol: {
        span: 10,
      },
      wrapperCol: {
        span: 14,
      },
    };
    const tailLayoutMessage = {
      wrapperCol: {
          offset: 10,
          span: 14,
      },
    }
    const tailLayout= {
      wrapperCol: {
          offset: 10,
          span: 14,
      },
    }


    return(
      <Card>
      <Form
        ref={this.formRef}
        {...layout}
        name="basic"
        initialValues={{
            remember: true,
        }}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        >
 
      <Form.Item
          label="New Password"
          name="userPassword"
          rules={[
          {
              required: true,
              message: 'Please input password!',
          },
          ]}
      >
          <Input.Password />
      </Form.Item>
      <Form.Item
          label="New Password Again"
          name="userPassword2"
          rules={[
          {
              required: true,
              message: 'Please input password to confirm !',
          },
          ]}
      >
          <Input.Password />
      </Form.Item>

        {!isEmpty(this.state.errors) ?
            <Form.Item {...tailLayoutMessage}><Alert message={this.state.errors.message} type="error" showIcon style={{widt:"100%"}}/></Form.Item> 
            :null
        }
        {this.state.formBusy ?
            <Form.Item {...tailLayoutMessage}><Spin /></Form.Item>
            :null
        }
        {this.state.userPasswordUpdateSuccess ?
            <Form.Item {...tailLayoutMessage}>
                <Alert message= "Password updated Successfully" />
            </Form.Item> 
            :null
        }
      <Form.Item {...tailLayout} >
        <Button htmlType="submit" >
        Update Password
        </Button>
      </Form.Item>
      
      </Form >
      </Card >
    )
  }
}