import React from "react";
import { Button, message, Modal, notification } from "antd";
import axios from "axios";
import {} from "antd";
import { url } from "../../utils/url";

export function r_WordInDictionary(request, config) {
  return new Promise((resolve, reject) => {
    const { QUERY_END_POINT } = config;
    const formdata = new FormData();
    formdata.append("data", JSON.stringify(request));
    axios
      .post(QUERY_END_POINT, formdata)
      .then((response) => {
        const { data } = response;
        //console.log(data)
        if (response.status === 200 && data) {
          const { itemListElement } = data;
          if (itemListElement.length) {
            resolve(itemListElement);
          } else {
            reject(
              "तपाईँले खोज्नुभएको शब्द भेटिएन। कृपया अरू कुनै शब्द खोज्नुहोस्।"
            );
          }
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(
          "तपाईँले खोज्नुभएको शब्द भेटिएन। कृपया अरू कुनै शब्द खोज्नुहोस्।"
        );
      });
  });
}

// export const r_publicDictionarySearch = (word, endpoints) => {
//   const { QUERY_END_POINT } = endpoints;
//   return new Promise((resolve, reject) => {
//     word = word.trim();
//     //console.log("the requested word is ", word)

//     //create the request here
//     const requestFormat = {
//       "@context": "http://semantro.com/",
//       "@type": "SayakSearch",
//       actionName: "listMatchingWordsOfGivenLanguage",
//       pageLimit: {
//         "@context": "http://semantro.com/",
//         "@type": "PageProperty",
//         start: 0,
//         end: 10,
//       },
//       data: {
//         "@context": "http://semantro.com/",
//         "@type": "Word",
//         wordEntry: "अँगा",
//         language: "नेपाली",
//       },
//     };
//     const { data } = requestFormat;

//     const requestFormatWithUserData = {
//       ...requestFormat,
//       data: {
//         ...data,
//         wordEntry: word,
//       },
//     };

//     //console.log(requestFormatWithUserData)
//     const reqD = new FormData();
//     reqD.append("data", JSON.stringify(requestFormatWithUserData));

//     axios({
//       method: "POST",
//       url: QUERY_END_POINT,
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//       data: reqD,
//     })
//       .then((res) => {
//         const { data } = res;
//         if (res.status === 200 && data) {
//           //console.log(res)
//           resolve(res);
//         }
//       })
//       .catch((error) => {
//         //console.log(error);
//         reject(error);
//       });
//   }); //end of promise return
// };

export const r_publicDictionarySearch = (word, endpoints) => {
  const { QUERY_END_POINT } = endpoints;
  return new Promise((resolve, reject) => {
    const trimWord = word.trim();
    //console.log("the requested word is ", word)

    //create the request here
    const requestFormat = {
      word: "कमल",
      offset: 0,
      limit: 10,
    };

    const requestFormatWithUserData = {
      ...requestFormat,
      word: trimWord,
    };

    console.log(requestFormatWithUserData);
    // const reqD = new FormData();
    // reqD.append("data", JSON.stringify(requestFormatWithUserData));
    const reqD = JSON.stringify(requestFormatWithUserData);
    let authToken = localStorage.getItem("sayakusertoken");
    axios({
      method: "POST",
      url: `${url.BASE_END_POINT}/dictionary/search`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: reqD,
    })
      .then((res) => {
        const { data } = res;
        if (res.status === 200 && data) {
          //console.log(res)
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error);
      });
  }); //end of promise return
};
