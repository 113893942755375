import React, { Component } from "react";

import { Card, Row, Col, Button } from "antd";

import UserProductStatistics from "./StatisticsComponents/UserProductStatistics";

import { Link } from "react-router-dom";

import { r_GetUserDetails } from "../../helpers/Requesthelper/RequestFormatter";
import { configConsumerProps } from "antd/lib/config-provider";
import { compose } from "redux";
import { MUTATION_END_POINT } from "../../utils/Constants";
import { getValueWithToken } from "../../utils/axiosUtils/AxiosUtils";

export class UserProfile extends Component {
  state = {
    loadingUserDetails: true,
    userDetails: {},
  };

  componentDidMount() {
    console.log("user profile console props location", this.props);

    this.generateUserDetails();
  }

  // generateUserDetails = () => {
  //   this.setState({ loadingUserDetails: true });
  //   r_GetUserDetails(
  //     this.props.userDetails.userName,
  //     this.props.auth.userToken,
  //     this.props.endpoints
  //   ).then((res) => {
  //     console.log(res);
  // this.setState({ loadingUserDetails: false });
  // this.setState({ userDetails: res.data });
  //   });
  // };

  generateUserDetails = async (e) => {
    const endPoint = MUTATION_END_POINT.GET_USER_PROFILE;
    // const appStateData = { message: "Logging Out Please Wait" };
    // props.appBusySet(appStateData);

    try {
      this.setState({ loadingUserDetails: true });
      const response = await getValueWithToken(endPoint);
      // console.log(response);

      this.setState({ loadingUserDetails: false });
      this.setState({ userDetails: response });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <div className="dashboard-user-profile">
        <div style={{ marginTop: "20px" }}>
          {/* <h1 style={{ textAlign: "center" }}>Profile</h1> */}
        </div>
        <Card
          loading={this.state.loadingUserDetails}
          className="card-box-shadow-default"
          title=""
        >
          <Row gutter={16}>
            <Col span={24}>
              <b>Name:</b>
              <br />
              <p>
                {this.state.userDetails.fullName
                  ? this.state.userDetails.fullName
                  : "NA"}
              </p>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <b>Email:</b>
              <br />
              <p>
                {this.state.userDetails.email
                  ? this.state.userDetails.email
                  : "NA"}
              </p>
            </Col>
            <Col span={12}>
              <b>UserName:</b>
              <br />
              <p>
                {this.state.userDetails.username
                  ? this.state.userDetails.username
                  : "NA"}
              </p>
            </Col>
          </Row>

          {/* <Row gutter={16}>
            <Col span={12}>
              <b>Given Name:</b>
              <br />
              <p>
                {this.state.userDetails.givenName
                  ? this.state.userDetails.givenName
                  : "NA"}
              </p>
            </Col>
            <Col span={12}>
              <b>Family Name:</b>
              <br />
              <p>
                {this.state.userDetails.familyName
                  ? this.state.userDetails.familyName
                  : "NA"}
              </p>
            </Col>
          </Row> */}

          <Row gutter={16}>
            <Col span={12}>
              <b>Telephone:</b>
              <br />
              <p>
                {this.state.userDetails.provider === "mobile"
                  ? this.state.userDetails.username
                    ? this.state.userDetails.username
                    : "NA"
                  : "NA"}
              </p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <b>Login From:</b>
              <br />
              <p>
                {this.state.userDetails.provider
                  ? this.state.userDetails.provider
                  : "NA"}
              </p>
            </Col>
          </Row>
        </Card>

        {/* <div style={{ marginTop: "20px" }}>
          <h1 style={{ textAlign: "center" }}>Service Usage</h1>
          <UserProductStatistics />
        </div> */}
      </div>
    );
  }
}
