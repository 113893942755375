import React, { Component, createRef } from "react";

import { Alert, Spin, Input, Button, Row } from "antd";
import {
  CheckCircleTwoTone,
  LoadingOutlined,
  CopyOutlined,
} from "@ant-design/icons";

import copy from "copy-to-clipboard";
import NepaliTypingDrawer from "./SpellingCheckComponents/NepaliTypingDrawer";

import { r_addServiceUserLog } from "../../helpers/Requesthelper/RequestStatistics";
// import "./spellingcheck.css"

export class SpellingCheck extends Component {
  state = {
    serviceName: "SPELLSUGGESTION",
    loadingContainer: true,
    spellingTextAreaTextCopied: false,

    nepaliTypingDrawerVisible: false,
  };

  // inputRef = createRef();

  componentDidMount() {
    this.spinnerShow();
    // window.CKEDITOR.replace('editor');
    // window.npspell.ckeditor('editor', {
    // dictionary: window.NP_UTILS.DICTIONARY_LANGUAGE,
    // server: window.NP_UTILS.SERVER_NAME
    // });

    this.beforeLoadingSpelling();
    // r_addServiceUserLog(
    //   this.props.userDetails.userName,
    //   this.state.serviceName,
    //   this.props.endpoints,
    //   this.props.auth.userToken
    // ).then((res) => {
    //   // console.log("l");
    // });
  }

  spinnerShow = () => {
    setTimeout(() => {
      this.setState({ loadingContainer: false });
      if (document.querySelector("#spelling-check-bottom-instruction")) {
        document.querySelector(
          "#spelling-check-bottom-instruction"
        ).style.marginTop = "5px";
      }
    }, 1500);
  };

  beforeLoadingSpelling = () => {
    setTimeout(() => {
      // this.setState({ loadingContainer: false }, () => {
      //   console.log("spelling container loaded ");
      // });
      this.loadSpellingEditor();
    }, 500);
  };

  loadSpellingEditor = () => {
    if (document.querySelector("#editor")) {
      window.CKEDITOR.replace("editor");

      window.npspell.ckeditor("editor", {
        dictionary: window.NP_UTILS.DICTIONARY_LANGUAGE,
        server: window.NP_UTILS.SERVER_NAME,
      });
      // resolve("instance ready");
    }
  };

  showSuccessFullyCopied = () => {
    setTimeout(() => {
      this.setState({ spellingTextAreaTextCopied: false });
    }, 500);

    return (
      // <span style={{ marginRight:"5px"}}><CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize:"1.5em"}} /> Copied </span>
      <CheckCircleTwoTone twoToneColor="rgb(243, 227, 2)" />
    );
  };

  handleCopyText = () => {
    let a = window.NP_UTILS.editorSentenceText.trim();
    //console.log(a)
    copy(a, {
      options: {
        format: "text/html",
      },
    });
    this.setState({ spellingTextAreaTextCopied: true });
  };

  // openNepaliTypingDrawer = () => {this.setState({nepaliTypingDrawerVisible: true})}
  // closeNepaliTypingDrawer = () => {this.setState({nepaliTypingDrawerVisible: false})}

  render() {
    return (
      <Row className="container">
        <div className="spelling-check-container">
          {/* <h1 style={{ textAlign: "center" }}>नेपाली हिज्जे परीक्षण</h1> */}
          {/* <span>{this.state.setPasteData}</span> */}

          <div className="flex-row-justify-space-between">
            <Alert
              message="हिज्जे परीक्षणको लागि नेपाली युनिकोडमा टाइप गर्नुहोस्।"
              type="info"
              showIcon
            />

            <div
              id="spelling-check-checking-spelling-loading"
              style={{ display: "none" }}
            >
              <span>
                <LoadingOutlined style={{ fontSize: 24 }} spin /> Checking
                Spelling{" "}
              </span>
            </div>

            <div>
              {/* {
              this.state.spellingTextAreaTextCopied ? this.showSuccessFullyCopied() : null 
            } */}
              <Button
                type="primary"
                size="large"
                style={{ marginBottom: "10px" }}
                onClick={this.handleCopyText}
                icon={
                  this.state.spellingTextAreaTextCopied ? (
                    this.showSuccessFullyCopied()
                  ) : (
                    <CopyOutlined />
                  )
                }
              >
                Copy All Text To Clipboard
              </Button>
            </div>
          </div>

          {/* <div>
          <span>Problem in typing Nepali? </span>
          <Button onClick={this.openNepaliTypingDrawer} >Open Nepali Typing</Button>

          <NepaliTypingDrawer 
            onClose = {this.closeNepaliTypingDrawer}
            drawerVisible={this.state.nepaliTypingDrawerVisible}
            {...this.props}
          />
        </div> */}

          <Alert
            className="dumpUserSuggestedWord-success"
            message="शब्द सुझाव गर्नुभएकोमा धन्यवाद।"
            description=""
            type="success"
            showIcon
            style={{ marginBottom: "10px", display: "none" }}
          />

          <Alert
            className="dumpUserSuggestedWord-fail"
            message="यो शब्द पहिलेनै सुझाव भएको छ। धन्यवाद।"
            description=""
            type="error"
            showIcon
            style={{ marginBottom: "10px", display: "none" }}
          />

          {this.state.loadingContainer ? (
            <Spin
              tip="Please wait . It normally takes 3-5 seconds."
              style={{
                height: "45vh",
                backgroundColor: "rgba(227, 226, 210, 0.28)",
              }}
            >
              <div
                className="please-wait-tip-spellingcheck"
                style={{
                  zIndex: "999",
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
              ></div>
            </Spin>
          ) : null}

          <div id="serverStatus" style={{ display: "none" }}></div>
          <div id="statusMessage" style={{ display: "none" }}></div>
          <textarea id="editor" style={{ visibility: "hidden" }}></textarea>
          <div id="word-count" style={{ display: "none" }}></div>

          <div
            style={{ marginTop: "45vh" }}
            id="spelling-check-bottom-instruction"
          >
            <Alert
              message="बाकसमा टाइप गर्नुहोस् वा अन्य ठाउँबाट कपी गरेर त्यस बाकसमा पेस्ट गर्नुहोस् । गलत हिज्जे भएका शब्द रातो धर्को लगाउने छ ।"
              description="रातो धर्को लगाएको शब्दलाई राइट क्लिक गर्नुहोस् । उक्त गलत हिज्जे भएको शब्दसँग नजिक हिज्जे मिल्ने शब्दहरू देखिनेछन् । ति शब्दहरूबाट उपयुक्त शब्द छान्नुहोस् ।"
              type="info"
              showIcon
              style={{ marginBottom: "10px", marginTop: "20px" }}
            />
          </div>
        </div>
      </Row>
    );
  }
}
