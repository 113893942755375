import React, { Component } from "react";

import Aux from "../../components/Hoc/WrapperComponent";
import { updatePageHead } from "../../components/common/UpdateMetaAndTitle";

import isEmpty from "../../validation/is-empty";
import NavBar from "./NavBar";
import Footer from "./Footer";

import { Form, Input, Button, Row, Col, Alert } from "antd";

import { withRouter, Route, Link, Redirect } from "react-router-dom";

import PropTypes from "prop-types";
import Loading from "../layoutUtils/Loading";

import { connect } from "react-redux";

import {
  forgotPasswordChangeCodeRequest,
  forgotPasswordChangeCodeVerify,
  forgotPasswordSetNewPassword,
  resetFrogotPasswordChangeErrors,
} from "../../store/actions/authAction";

import { appBusySet, appBusyReset } from "../../store/actions/appStateAction";

import { resetAuthErrors } from "../../store/actions/authErrorAction";
import LoginWithGoogleAndFacebook from "../layoutUtils/LoginWithGoogleAndFacebook";

//import ResetPasswordEnterPin from 'resetPassword'

class ResetPassword extends Component {
  state = {
    errors: {},
  };

  componentDidMount() {

    updatePageHead("Reset Password", "", "");

    this.props.resetFrogotPasswordChangeErrors();
    this.props.appBusyReset();
  }

  render() {
    return (
      <div className="main-wrapper">
        <NavBar />
        {this.props.appState.busy ? (
          <Loading messageSpin="Logging In Please Wait" />
        ) : null}

        <Row
          type="flex"
          justify="space-around"
          align="middle"
          className="bg-blue-yellow-gradient"
        >
          {/* <Col
          xs={{ span: 22}} 
          sm={{ span: 22}}
          md={{ span: 22}} 
          lg={{ span: 8}}
          xl={{ span: 8}}
          xxl={{ span: 8}}
          > */}
            {/* <div
              className="container-layout-left-one"
            >
              <p>.</p>
            </div> */}
          {/* </Col> */}
          <Col 
          xs={{ span: 22}} 
          sm={{ span: 22}}
          md={{ span: 22}} 
          lg={{ span: 22}}
          xl={{ span: 22}}
          xxl={{ span: 22}}
          >
            <div
              className = "container-layout-right-one"
            >
            <div
              style={{
                padding: "40px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                  alignItems: "center",
                  margin: "70px 0px"
              }}
              className="form-container"
            >
              <Route
                exact
                path="/resetPassword"
                component={() => <ResetPasswordEnterEmail {...this.props} />}
              />
              <Route
                exact
                path="/resetPassword/pin"
                component={() => <ResetPasswordEnterPin {...this.props} />}
              />
              <Route
                exact
                path="/resetPassword/newPassword"
                component={() => (
                  <ResetPasswordEnterNewPassword {...this.props} />
                )}
              />
            </div>
            
            </div>

          </Col>
        </Row>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userForgotPassword: state.auth.forgotPassword,
  autherrors: state.authErrors,
  appState: state.appState,
  endpoints: state.apiConstants,
});

export default connect(mapStateToProps, {
  forgotPasswordChangeCodeRequest,
  forgotPasswordChangeCodeVerify,
  forgotPasswordSetNewPassword,

  resetFrogotPasswordChangeErrors,
  appBusySet,
  appBusyReset,
})(withRouter(ResetPassword));

export class ResetPasswordEnterEmail extends Component {
  state = {
    email: "",
    errors: {},
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    //e.preventDefault()

    const UserData = {
      email: this.state.email,
    };

    const appStateData = { message: "Requesting Password Reset" };
    this.props.appBusySet(appStateData);

    this.props.forgotPasswordChangeCodeRequest(
      UserData,
      this.props.history,
      this.props.endpoints
    );
  };
  render() {
    return (
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={this.onSubmit}
        style={{ width: "100%" }}
      >
        <h1 style={{ textAlign: "center", marginBottom: "10px", color: "#f3722c" , fontSize:"2.4em" }}>पासवर्ड परिवर्तन </h1>
        <div>
          <p style={{ fontSize: "1.1em" }}>
            कृपया, सायक खातामा प्रयोग भएको तपाईंको इमेल ठेगाना तलको बाकसमा
            राख्नुहोस्
          </p>
        </div>

        <Form.Item
          name="email"
          label="इमेल :"
          rules={[{ required: true, message: "Please input your Email!" }]}
        >
          <Input name="email" placeholder="इमेल:" onChange={this.onChange} />
        </Form.Item>

        {!isEmpty(this.props.userForgotPassword.errors) ? (
          <Form.Item>
            <Alert
              message={this.props.userForgotPassword.errors.message}
              type="error"
              showIcon
            />
          </Form.Item>
        ) : null}

        <Form.Item>
          <Button
            // type="primary"
            htmlType="submit"
            className="btn-orange"
            style={{ marginLeft: "10px", fontSize: "1.2em", marginTop: "10px", padding: "20px", display: "flex", alignItems: "center" }}
          >
            पासवर्ड परिवर्तन 
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
export class ResetPasswordEnterPin extends Component {
  state = {
    email: "",
    passwordrestpin: "",
    errors: {},
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    //e.preventDefault()

    const UserData = {
      email: this.props.userForgotPassword.email
        ? this.props.userForgotPassword.email
        : this.state.email,
      passwordrestpin: this.state.passwordrestpin,
    };

    const appStateData = { message: "Validating Password Reset Pin" };
    this.props.appBusySet(appStateData);

    //console.log(UserData);
    this.props.forgotPasswordChangeCodeVerify(
      UserData,
      this.props.history,
      this.props.endpoints
    );

    // this.props.resetUserPassword(userData)
  };

  render() {
    return (
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={this.onSubmit}
        style={{ width: "100%" }}
      >
        <h1 style={{ textAlign: "center", marginBottom: "10px", color: "#f3722c", fontSize: "2.4em" }}>पासवार्ड परिवर्तन </h1>
        <h2>पिन राख्नुहोस्। </h2>
        <div>
          <p style={{fontSize:"1.1em"}}>
            कृपया तपाईंको इमेल खाता {this.props.userForgotPassword.email} मा
            प्राप्त पिन तलको बाकसमा राख्नुहोस्।
          </p>
        </div>

        {/* <Form.Item
          name="email"
          label="Your Email"
          rules={[{ required: false, message: "Please input your Email!" }]}
        >
          <Input
            name="email"
            placeholder={
              this.props.userForgotPassword.email
                ? this.props.userForgotPassword.email
                : "Your Email"
            }
            onChange={this.onChange}
            defaultValue={this.props.userForgotPassword.email}
            value={this.props.userForgotPassword.email}
          />
        </Form.Item> */}
        <Form.Item
          name="passwordrestpin"
          label="पिन"
          rules={[{ required: true, message: "Please input your pin!" }]}
        >
          <Input
            name="passwordrestpin"
            placeholder="पिन"
            onChange={this.onChange}
          />
        </Form.Item>

        {!isEmpty(this.props.userForgotPassword.errors) ? (
          <Form.Item>
            <Alert
              message={this.props.userForgotPassword.errors.message}
              type="error"
              showIcon
            />
          </Form.Item>
        ) : null}

        <Form.Item>
          <Button
            // type="primary"
            htmlType="submit"
            className="btn-orange"
            style={{ marginLeft: "10px", fontSize: "1.2em", marginTop: "10px", padding: "20px", display: "flex", alignItems: "center" }}
          >
            पठाउनुहोस्।
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export class ResetPasswordEnterNewPassword extends Component {
  state = {
    email: "",
    newpassword: "",
    newpassword2: "",
    resetPasswordSuccess: false,
    errors: {},
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    //e.preventDefault()
    //console.log("yaaaaaa");
    //console.log(this.state)
    //console.log(this.props.userForgotPassword)
    const userData = {
      email: this.props.userForgotPassword.email,
      newPassword:
        this.state.newpassword === this.state.newpassword2
          ? this.state.newpassword
          : "",
      passwordrestpin: this.props.userForgotPassword.resetPasswordPin,
    };

    //console.log(userData);

    const appStateData = { message: "Setting New Password" };
    this.props.appBusySet(appStateData);

    this.props.forgotPasswordSetNewPassword(
      userData,
      this.props.history,
      this.props.endpoints
    );

    // this.props.resetUserPassword(userData)
  };

  render() {
    //console.log(this.props);
    return (
      <Aux>
        {this.props.userForgotPassword.changedPasswordSuccess ? (
          <div style={{ textAlign:"center" }}>
            <h1>पासवर्ड परिवर्तन भएको छ।  कृपया फेरी लगिन  गर्नुहोस्। </h1>
            <div style={{margin:"10px 0px 10px 0px"}}>
              <Link to ="/login">
                <Button
                  size="large"
                  className="btn-orange"
                  type="primary"
                    
                >
                  लगिन गर्नुहोस् ।
                </Button>
              </Link>
              
              <LoginWithGoogleAndFacebook />
            </div>
            
          </div>
        ) : (
          <Form
            name="normal_login"
            className="login-form"
            onFinish={this.onSubmit}
            style={{ width: "100%" }}
          >
              <h1 style={{ textAlign: "center", marginBottom: "10px", color: "#f3722c", fontSize: "2.4em" }}>पासवार्ड परिवर्तन</h1>
            <h2 style={{ marginBottom: "10px" }}>कृपया पासवर्ड राख्नुहोस्।</h2>
            <div>
              {this.props.userForgotPassword.resetPasswordPin ? (
                  <p style={{ fontSize: "1.1em" }}>
                  तपाईको इमेल {this.props.userForgotPassword.email} का लागि
                    कृपया नयाँ पासवर्ड राख्नुहोस् ।
                </p>
              ) : null}
            </div>

            {/* <Form.Item
              name="email"
              label="Your Email"
              rules={[{ required: false, message: "Please input your Email!" }]}
            >
              <Input
                name="email"
                placeholder= { this.props.userForgotPassword.email ? this.props.userForgotPassword.email : "Your Email" }
                onChange={this.onChange}
                defaultValue = {this.props.userForgotPassword.email}
                value = {this.props.userForgotPassword.email}
              />
            </Form.Item> */}

            <Form.Item
              name="newpassword"
              label="नयाँ पासवर्ड:"
              rules={[
                { required: true, message: "Please input new Password!" },
              ]}
            >
              <Input
                  name="newpassword"
                  type="password"
                placeholder="नयाँ पासवर्ड"
                onChange={this.onChange}
              />
            </Form.Item>
            <Form.Item
              name="newpassword2"
              label="पुन: नयाँ पासवर्ड"
              rules={[
                { required: true, message: "Please input new Password again!" },
              ]}
            >
              <Input
                  name="newpassword2"
                  type="password"
                placeholder="पुन: नयाँ पासवर्ड"
                onChange={this.onChange}
              />
            </Form.Item>

            {!isEmpty(this.props.autherrors) ? (
              <Form.Item>
                <Alert
                  message={this.props.autherrors.message}
                  type="error"
                  showIcon
                />
              </Form.Item>
            ) : null}

            <Form.Item>
              <Button
                // type="primary"
                  className="btn-orange"
                htmlType="submit"
               
                style={{ marginLeft: "10px", fontSize:"1.2em" , marginTop:"10px" , padding:"20px" , display:"flex" , alignItems:"center"}}
              >
                पासवर्ड परिवर्तन गर्नुहोस्। 
              </Button>
            </Form.Item>
          </Form>
        )}
      </Aux>
    );
  }
}
