import React, { Component } from 'react'
import LoadAllStatistics from './AdminWelcomPageComponents/LoadAllStatistics'


class AdminWelcomePage extends Component {

    render(){
        return(
        <div>

        <div>
            <h2>Hello , {this.props.userDetails.name} !</h2>
            <p>Welcome</p>
        </div>
        <div>
            <LoadAllStatistics {...this.props}/>
        </div>

        </div>
        )
    }
}

export default AdminWelcomePage