
import { SET_API_CALL_CONSTANTS } from "../actions/types";

import isEmpty from "../../validation/is-empty";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_API_CALL_CONSTANTS:
      return action.payload
    default:
      return state;
  }
}