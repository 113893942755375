import {store} from "../store";

import {
  setCurrentUserToken,
  setCurrentUserRegister,
} from "../actions/authAction";

export const prepReduxWithLocalStorage = () => {
  //the user authentication part

  if (localStorage.sayakusertoken) {
    //setauth token
    //setCurrentUserToken(localStorage.sayakusertoken);
    //decode the token
    //set user and is authenticated
    store.dispatch(setCurrentUserToken(localStorage.sayakusertoken));
  }

  // if (localStorage.sayakuserregisterdetails) {
  //   const userData = JSON.parse(localStorage.sayakuserregisterdetails);
  //   store.dispatch(setCurrentUserRegister(userData));
  // }
};
