import axios from "axios";


export const r_serviceUsedCountDateWise = (userData, endpoints, accessToken) => {
  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakSearch",
      "actionName": "getUserServiceUsedCountDatewise",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "userName": "sachingiri619@gmail.com",
        "description": "WEEKLY"
      },
      "dateFilter": {
        "@context": "http://semantro.com/",
        "@type": "DateRange",
        "startDate": "2020-06-30T00:00:000.00Z",
        "endDate": "2020-07-14T00:00:00.000Z"
      }
    }

    const {data} = requestFormat
    const {dateFilter} = requestFormat

    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            userName: userData.userName,
            description: userData.description
        },
        dateFilter: {
          ...dateFilter,
          startDate: '',
          endDate: userData.endDate
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })  
}

export const r_listServiceUsedHistory = (userName, serviceName,  endpoints, accessToken) => {
  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakSearch",
      "actionName": "listServiceUsedHistory",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "userName": "sachingiri619@gmail.com",
        "description": "SPELLSUGGESTION",
        "alternateName": "DESCENDING"
      },
      "pageLimit": {
        "@context": "http://semantro.com/",
        "@type": "PageProperty",
        "start": 0,
        "end": 1
      }
    }

    const {data} = requestFormat
    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            userName: userName,
            description: serviceName
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })  
}


export const r_countGivenServiceUsedGlobal = (serviceName,  endpoints, accessToken) => {
  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakSearch",
      "actionName": "countGivenServiceUsed",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "description": "SPELLSUGGESTION"
      }
    }

    const {data} = requestFormat
    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            description: serviceName
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })  
}

export const r_countGivenServiceUsedByUser = (userName, serviceName,  endpoints, accessToken) => {
  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakSearch",
      "actionName": "countGivenServiceUsed",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "userName": "",
        "description": ""
      }
    }

    const {data} = requestFormat
    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            userName: userName,
            description: serviceName
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })  
}

export const r_addServiceUserLog = (userName, usedService, endpoints, accessToken) => {
  const {MUTATION_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
        "@context": "http://semantro.com/",
        "@type": "SayakMutation",
        "actionName": "addServiceUsedLog",
        "data": {
          "@context": "http://semantro.com/",
          "@type": "User",
          "userName": "sachingiri619@gmail.com",
          "hasUsed": {
            "@context": "http://semantro.com/",
            "@type": "SayakServiceLog",
            "usedService": "SPELLSUGGESTION"
          }
        }
      }

    const {data} = requestFormat
    const {hasUsed} = data
    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            userName: userName,
            hasUsed :{
                ...hasUsed,
                usedService: usedService
            }
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })  
}