import React, { Component } from "react";

import { Layout, Menu, Row, Col } from "antd";

import Footer from './Footer'

import NavBarUser from "./NavBarAdmin";

import { SpellingCheck } from "../../components/userComponents/SpellingCheck";
import  FontConversion  from "../../components/userComponents/FontConversion";
import { DictSearch } from "../../components/userComponents/DictSearch/DictSearch";

import AdminWelcomePage from '../../components/adminComponents/AdminWelcomePage'
import AddAdminUser from '../../components/adminComponents/AddAdminUser'


import ListAllUsers from '../../components/adminComponents/listAllUsers'
import ListAllAdminUsers from '../../components/adminComponents/listAllAdminUsers'

import { Route, Link, withRouter } from "react-router-dom";


import { connect } from 'react-redux'
import {appBusyReset, appBusySet} from '../../store/actions/appStateAction'
import { resetUserAuth , logOutSayakUser} from '../../store/actions/authAction'
import Loading from '../layoutUtils/Loading'


const { SubMenu } = Menu;
const { Content, Sider } = Layout;



export class LayoutAdminDashboard extends Component {

  state = {

  }
  componentDidMount() {

    // console.log(this.props)
    // //check if logged in 
    // //if not then redirect to login 

    // if (!localStorage.getItem('sayakusertoken')){
    //     console.log("not logged in")
    //     this.props.history.push("/user")
    //     //this.props.resetUserAuth()
    // }else if (localStorage.getItem('sayakusertoken')){
    //   //this is good 
    //   //user logged in cha 
    // }

  }


  render() {
    //console.log(this.props)

    return (
      <div className="user-main-wrapper">
        <NavBarUser {...this.props} />

        {this.props.appState.busy ? (
          <Loading messageSpin="Logging In Please Wait" />
        ) : null}

        <Row type="flex" justify="space-around" style={{ minHeight: "90vh" }}>
          <Col
            className="user-dashboard-layout-left"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 4 }}
            xl={{ span: 4 }}
            xxl={{ span: 4 }}
          >
            <Menu
              className="dashboard-main-menu"
              theme=""
              mode="inline"
              //defaultSelectedKeys={['1']}
              defaultOpenKeys={["sub1"]}
              // style={{ height: "100%", borderRight: 0}}
              style={{ background: "none" }}
            >
                
                <Menu.Item key="1">
                  <span>Dash Board</span>
                  <Link to="/admin" />
                </Menu.Item>

                <SubMenu key="sub1" title={<span>Products</span>}>
                  <Menu.Item key="2">
                    <span>नेपाली हिज्जे परिक्षण</span>
                    <Link to="/admin/spelling" />
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span>फन्ट रूपान्तरण</span>
                    <Link to="/admin/fontConversion" />
                  </Menu.Item>
                  <Menu.Item key="4">
                    <span>नेपाली शब्दकोश</span>
                    <Link to="/admin/dictSearch" />
                  </Menu.Item>
                </SubMenu>


                <SubMenu key="sub3" title={<span>User Management</span>}>
                  <Menu.Item key="5">
                    List Users
                    <Link to="/admin/listUsers" />
                  </Menu.Item>
                  
                </SubMenu>

                <SubMenu key="sub4" title={<span>Admin Management</span>}>
                <Menu.Item key="6">
                    Add Admin
                    <Link to="/admin/addAdmin" />
                  </Menu.Item>
                  <Menu.Item key="7">
                    Admin Users
                    <Link to="/admin/listAdminUsers" />
                  </Menu.Item>
                </SubMenu>

              </Menu>
              </Col>

<Col
  xs={{ span: 24 }}
  sm={{ span: 24 }}
  md={{ span: 24 }}
  lg={{ span: 20 }}
  xl={{ span: 20 }}
  xxl={{ span: 20 }}
>
  <div className="user-dashboard-layout-right">
                  
                <Route 
                  exact 
                  path="/admin" 
                  component={() => <AdminWelcomePage {...this.props} />} 
                  />
                <Route 
                  exact 
                  path="/admin/spelling" 
                  component={() => <SpellingCheck {...this.props} />} 
                />
                <Route
                  exact
                  path="/admin/fontConversion"
                  component={() => <FontConversion {...this.props} />}
                />
                <Route 
                  exact 
                  path="/admin/dictSearch" 
                  component={() => <DictSearch {...this.props} />} 
                />

                <Route
                  exact
                  path="/admin/listUsers"
                  component={() => <ListAllUsers {...this.props} />}
                />

                <Route
                  exact
                  path="/admin/addAdmin"
                  component={() => <AddAdminUser {...this.props} />}
                />

                <Route
                  exact
                  path="/admin/listAdminUsers"
                  component={() => <ListAllAdminUsers {...this.props} />}
                />

</div>
          </Col>
        </Row>

        <Footer />
      </div>


    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  endpoints: state.apiConstants,
  userDetails: state.userDetails,
  appState: state.appState,

})

export default connect(mapStateToProps,{
  logOutSayakUser,

  appBusySet, 
  appBusyReset
})(withRouter(LayoutAdminDashboard))