import React, { Component } from "react";

import UserProductStatistics from "./StatisticsComponents/UserProductStatistics";

import { Card, Row, Col, Button } from "antd";

import { Link } from "react-router-dom";

export class UserStatisticsMain extends Component {
  render() {
    return (
      <div>
        <h1 style={{ textAlign: "center" }}>Your Statistics</h1>
        <UserProductStatistics {...this.props} />
      </div>
    );
  }
}
