import React, { Component } from "react";
import isEmpty from '../../../validation/is-empty'

import { Drawer, Button, Form, Alert, Spin, Input, Card, Select , Row, Col } from "antd";

import { r_GetUserDetails } from "../../../helpers/Requesthelper/RequestFormatter";

const {Option} = Select

class ShowUserProfile extends Component {
  componentDidMount() {
  }
  render() {
    return (
      <Drawer
        width={"40%"}
        title="User Profile"
        placement="right"
        destroyOnClose="true"
        closable={true}
        onClose={this.props.resetViewUserProfileDrawerVisible}
        visible={this.props.viewUserProfileDrawerVisible}
      >
        <DisplayUserProfile 
        {...this.props}
        
        />

      </Drawer>
    );
  }
}

export default ShowUserProfile;


class DisplayUserProfile extends Component {

    state = {
      loadingUserDetails: true,
      errors:{},
      userDetails : {}
    }

    componentDidMount(){
      
      this.generateUserDetails()

    }
    
    generateUserDetails = () => {

      this.setState({loadingUserDetails: true})
      r_GetUserDetails(this.props.slectedUserRowDetails.username,this.props.auth.userToken, this.props.endpoints).then(res => {
        console.log(res)
        this.setState({loadingUserDetails: false})
        this.setState({userDetails: res.data})
      })
    }

    render(){
      
      return(
        <div>
        {this.state.loadingUserDetails ? (
        <Spin />
        ) :
        <Card className="card-box-shadow-default" >

            <Row gutter={16}>
              <Col span={24}>
                <b>Name:</b>
                <br />
                <p>{this.state.userDetails.name? this.state.userDetails.name : "NA"}</p>
              </Col>

            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <b>Email:</b>
                    <br />
                    <p>{this.state.userDetails.email ? this.state.userDetails.email: "NA"}</p>
                </Col>
              <Col span={12}>
                <b>UserName:</b>
                <br />
                <p>{this.state.userDetails.userName ? this.state.userDetails.userName: "NA"}</p>
              </Col>
              
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                <b>Given Name:</b>
                <br />
                <p>{this.state.userDetails.givenName ? this.state.userDetails.givenName: "NA"}</p>
              </Col>
              <Col span={12}>
                <b>Family Name:</b>
                <br />
                <p>{this.state.userDetails.familyName ? this.state.userDetails.familyName: "NA"}</p>
              </Col>
              
            </Row>            
            <Row gutter={16}>
                <Col span={24}>
                <b>Address:</b>
                <br />
                <p>{this.state.userDetails.address ? this.state.userDetails.address: "NA"}</p>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <b>Gender:</b>
                <br />
                <p>{this.state.userDetails.gender ? this.state.userDetails.gender: "NA"}</p>
              </Col>
              <Col span={12}>
                <b>Telephone:</b>
                <br />
                <p>{this.state.userDetails.telephone ? this.state.userDetails.telephone: "NA"}</p>
              </Col>
            </Row>

        </Card> 
        }
        </div>
      )
    }
}
