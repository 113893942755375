import { AutoComplete } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";

const URL = "https://inputtools.google.com/request";

export function requestTransliterationOptions(textInput) {
  return new Promise((resolve, reject) => {
    axios
      .get(URL, {
        params: {
          text: textInput,
          itc: "ne-t-i0-und",
          num: "5",
          cp: "0",
          cs: "1",
          ie: "utf-8",
          oe: "utf-8",
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const RomanInput = (props) => {
  const [options, setOptions] = useState([]);
  const [romanInput, setRomanInput] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const { value, onChange, isRomanizeTyping } = props;

  useEffect(() => {
    console.log("isRomanizeTyping", isRomanizeTyping);
  }, [isRomanizeTyping]);

  useEffect(() => {
    // Set default value passed from schema "data" field
    if (value) setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    if (!romanInput.length) return;
    requestTransliterationOptions(romanInput)
      .then((response) => {
        setOptions(
          response[1][0][1].map((word) => {
            return {
              value: word,
              key: word,
              roman: romanInput,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [romanInput]);

  const onTextChange = (text) => {
    // if (text === "") {

    // } else {
    const lastChar = text[text.length - 1];
    setSelectedValue(text);
    if (lastChar === " ") {
      setRomanInput("");
    } else {
      if (text.includes(" ")) {
        const split = text.split(" ");
        setRomanInput(split[split.length - 1]);
      } else {
        setRomanInput(text);
      }
    }

    if (onChange) {
      onChange(text);
    }
    // }
  };

  const selectWord = (value) => {
    const newValue = selectedValue.replace(romanInput, value);
    setSelectedValue(newValue);
    setRomanInput("");

    if (onChange) {
      onChange(newValue.trim());
    }
    setOptions([]);
  };

  const clearInput = () => {
    setSelectedValue("");
    setRomanInput("");
    if (onChange) {
      onChange("");
    }
  };

  return (
    <div>
      {isRomanizeTyping ? (
        <AutoComplete
          size="large"
          className="dictionarySearchArea"
          style={{ width: window.innerWidth <= 768 ? "300px" : "600px" }}
          value={selectedValue}
          dropdownClassName="custom-dropdown"
          options={options}
          onSelect={selectWord}
          defaultActiveFirstOption={true}
          onChange={onTextChange}
          // allowClear
          // onClear={clearInput}
        />
      ) : (
        <AutoComplete
          size="large"
          className="dictionarySearchArea"
          style={{ width: window.innerWidth <= 768 ? "300px" : "600px" }}
          // value={selectedValue}
          // options={options}
          // onSelect={selectWord}
          defaultActiveFirstOption={true}
          onChange={onTextChange}
          // allowClear
          // onClear={clearInput}
        />
      )}
    </div>
  );
};
