
export const mapDisplayErrorMessage = (messageId) => {

    //console.log(messageId)
    return new Promise((resolve,reject)=>{
        fetch("messagesJSON/errorMessages.json")
            .then(data => data.json())
            .catch(error => {
                //console.log(error)
                reject(error)
            })
            .then(messages => {
                //console.log(messages[messageId])
                const returnMessage = messages[messageId].message
                //console.log(returnMessage)
                resolve(returnMessage)
            })
    })
  
}

const findMessageForMessageId = (messageId) => {
    return new Promise((resolve,reject)=>{
        fetch("messagesJSON/errorMessages.json")
            .then(data => data.json())
            .catch(error => {
                //console.log(error)
                reject(error)
            })
            .then(messages => {
                //console.log(messages[messageId])
                const returnMessage = messages[messageId].message
                //console.log(returnMessage)
                resolve(returnMessage)
            })
    })
}