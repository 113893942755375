import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import OtpInput from "react-otp-input";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { MUTATION_END_POINT } from "../../utils/Constants";
import {
  checkMobileNumber,
  resetMpin,
  signIn,
} from "../../utils/axiosUtils/MutationHelper";
import {
  mutateValueWithoutFile,
  signinRequest,
} from "../../utils/axiosUtils/AxiosUtils";
import { setCurrentUserToken } from "../../store/actions/authAction";
import { useDispatch } from "react-redux";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../utils/Notification";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { IoArrowBack } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";

const MobileLoginComponent = (props) => {
  const {
    otpCallBack,
    mobileNumberNotRegisterCallBack,
    mobileNumberFormSignup,
  } = props;
  let history = useHistory();

  const dispatch = useDispatch();

  const phoneValidator = /^[9][6-9]\d{8}$/;

  const [otp, setOtp] = useState("");
  const [buttonDisable, setButtonDisable] = useState(true);
  const [mobileNumber, setMobileNumber] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [isMobileNumberComplete, setIsMobileNumberComplete] = useState(false);

  // const validatePhoneNumber = (_, value) => {
  //   if (value && !phoneValidator.test(value)) {
  //     return Promise.reject(new Error("Please enter valid Mobile Number."));
  //   }
  //   return Promise.resolve();
  // };
  useEffect(() => {
    if (mobileNumberFormSignup) {
      otpCallBack(true);
      setIsMobileNumberComplete(true);
      setMobileNumber(mobileNumberFormSignup);
    }
    // console.log(mobileNumberFormSignup);
  }, [mobileNumberFormSignup]);

  const validatePhoneNumber = (_, value) => {
    if (value && value.length === 10) {
      if (!phoneValidator.test(value)) {
        return Promise.reject(new Error("Please enter a valid Mobile Number."));
      }
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (isMobileNumberComplete === true) {
      otpCallBack(true);
    }
  }, [isMobileNumberComplete]);

  useEffect(() => {
    if (inputValue) {
      if (inputValue.length === 10) {
        setButtonDisable(false);
        setInputValue("");
      } else {
        setButtonDisable(true);
      }
    }
  }, [inputValue]);

  useEffect(() => {
    if (otp.length === 6) {
      // console.log(otp);
      //   setFinalOtp(otp);

      // if (mobileNumber && finalOtp) {
      const mutationData = signIn(mobileNumber, otp);
      const endPoint = MUTATION_END_POINT.SIGN_IN;

      // setLoading
      signinRequest(mutationData, endPoint)
        .then((response) => {
          // console.log("Response", response);
          const { accessToken } = response;
          localStorage.setItem("sayakusertoken", accessToken);
          dispatch(setCurrentUserToken(accessToken));
          history.push("/user");
        })
        .catch((error) => {
          console.log(error);
          //   showErrorNotification("Error", error);
          toast(
            "तपाइले राख्नुभएको पिन कोड मिलेन फेरी, कृपया सहि पिन कोड राख्नुहोला",
            {
              theme: "light",
              className: "custom-toast",
            }
          );
          // showErrorNotification(
          //   "Error",
          //   "तपाइले राख्नुभएको पिन कोड मिलेन फेरी, कृपया सहि पिन कोड राख्नुहोला"
          // );
        });

      // console.log("Mutation Data", mutationData);
      // }
    }
  }, [otp]);

  const handleResendAction = (mobileNumber) => {
    if (mobileNumber) {
      const mutationData = resetMpin(mobileNumber);
      const endPoint = MUTATION_END_POINT.RESET_MPIN;

      mutateValueWithoutFile(mutationData, endPoint)
        .then((response) => {
          const { status, message } = response;
          if (status === "success") {
            showSuccessNotification("", message);
            toast("पिन कोड तपाइको मोबाइल नम्बरमा पठाइएको छ ", {
              theme: "light",
              className: "custom-toast",
            });
          }
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
          // showErrorNotification("Error", error);
          toast(error, {
            theme: "light",
            className: "custom-toast",
          });
        });
    }
  };

  // const mobileNumberCheck = (mobileNumber) => {
  //   if (mobileNumber) {
  //     const mutationData = checkMobileNumber(mobileNumber);
  //     const endPoint = MUTATION_END_POINT.CHECK_MOBILE_NUMBER;

  //     mutateValueWithoutFile(mutationData, endPoint)
  //       .then((response) => {
  //         console.log("response");

  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         showErrorNotification("Error", error);
  //       });
  //   }
  // };

  const handleFinish = (value) => {
    const { mobileNumber } = value;

    // Ensure mobileNumber is defined before proceeding
    if (mobileNumber) {
      const mutationData = checkMobileNumber(mobileNumber);
      const endPoint = MUTATION_END_POINT.CHECK_MOBILE_NUMBER;

      mutateValueWithoutFile(mutationData, endPoint)
        .then((response) => {
          const { exist } = response;
          // console.log("response", exist);
          if (exist === true) {
            setMobileNumber(mobileNumber);
            setIsMobileNumberComplete(true);
          } else {
            // showErrorNotification(
            //   "",
            //   `यस् प्रणालीमा यो (${mobileNumber}) फोन नम्बर दर्ता भएको छैन`
            // );
            mobileNumberNotRegisterCallBack(
              mobileNumber,
              // `यस् प्रणालीमा यो (${mobileNumber}) फोन नम्बर दर्ता भएको छैन`
              `तपाईँको मोबाइल नं. हिज्जे प्रणालीमा भेटिएन । कृपया Google वा मोबाइल नं. मार्फत हिज्जे प्रणालीमा दर्ता गर्नुहोस् ।`
            );
            history.push("/signup");
          }
        })
        .catch((error) => {
          console.log(error);
          showErrorNotification("Error", error);
        });
      // mobileNumberCheck(mobileNumber);
      // otpCallBack();
    } else {
      console.error("Mobile number is not provided.");
    }
  };

  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  return (
    <>
      <TransitionGroup component={null}>
        {!isMobileNumberComplete ? (
          <CSSTransition key="input-form" timeout={300} classNames="fade">
            <div
              style={{
                display: "flex",
                marginTop: "20px",
              }}
            >
              <Form
                layout="horizontal"
                onValuesChange={(value) => {
                  setInputValue(value.mobileNumber);
                }}
                onFinish={handleFinish}
                // disabled={componentDisabled}
              >
                <Form.Item
                  label="मोबाइल नम्बर"
                  name="mobileNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Mobile Number!",
                    },
                    {
                      validator: validatePhoneNumber, // Custom validation function
                    },
                  ]}
                >
                  <Input
                    maxLength={10}
                    placeholder="मोबाइल नम्बर"
                    allowClear
                    style={{
                      width: "350px",
                    }}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="login-button"
                    style={{
                      width: "350px",
                      marginTop: "20px",
                    }}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={buttonDisable}
                  >
                    लगिन
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </CSSTransition>
        ) : (
          <CSSTransition key="otp-form" timeout={300} classNames="fade">
            <div>
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    display: "inline",
                    justifyContent: "center",

                    marginTop: "20px",
                  }}
                >
                  <div>
                    <h1>प्राप्त पिन कोड राख्नुहोस्</h1>
                  </div>
                  <div>{mobileNumber}</div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <div className="otp-field">
                    <div className="number">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        renderInput={(props) => (
                          <input {...props} onKeyPress={handleKeyPress} />
                        )}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        // renderInput={(props) => <input {...props} />}
                        inputStyle={{
                          fontSize: "40px",
                          color: "#000000",
                          // padding: "5px ",
                          height: "40px",
                          border: "1px solid #c3d9f7",
                        }}
                        containerStyle={{ gap: 8 }}
                        shouldAutoFocus
                      />
                    </div>
                    <p
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      तपाईँले  पिन कोड प्राप्त गर्नु भएको छैन ?
                      <span
                        className="resend-pin"
                        onClick={() => {
                          handleResendAction(mobileNumber);
                        }}
                      >
                        कोड पुनः पठाउनुहोस्
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  type="text"
                  icon={<IoArrowBack />}
                  onClick={() => {
                    setIsMobileNumberComplete(false);
                    otpCallBack(false);
                  }}
                >
                  Back
                </Button>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      <ToastContainer style={{ width: "700px" }} />
    </>
  );
};

export default MobileLoginComponent;
