import {
  GET_ERRORS,
  RESET_USER_AUTH,
  RESET_USER_DETAILS,
  RESET_AUTH_ERRORS,
  RESET_APP_STATE,
  RESET_ADMIN,
  SET_CURRENT_USER_TOKEN,
  SET_CURRENT_USER_DETAILS,
  SET_CURRENT_USER_REGISTER,
  ACTIVATE_USER_ACCOUNT,
  SET_RESEND_ACTIVATION_CODE,
  SET_PASSWORD_RESET_EMAIL,
  SET_PASSWORD_CHANGED_SUCCESS,
  SET_PASSWORD_RESET_PIN,
  SET_PASSWORD_CHANGE_ERRORS,
  RESET_PASSWORD_CHANGE_ERRORS,
  RESET_RESEND_ACTIVATION_CODE,
  SET_ADD_PASSWORD_EMAIL,
  SET_ADD_PASSWORD_ERRORS,
  RESET_ADD_PASSWORD_ERRORS,
  RESET_ADD_PASSWORD,
  SET_ADD_PASSWORD_PIN,
  SET_ADD_PASSWORD_SUCCESS,
} from "./types";

import { mapDisplayErrorMessage } from "../../mapDisplayMessage/mapDisplayMessage";

//import setAuthToken from "../../utils/setAuthToken";
import { setAuthErrors, resetAuthErrors } from "./authErrorAction";
import { appBusyReset, appBusySet } from "./appStateAction";
import { setLoggedInUserDetails } from "./userDetailsAction";
import { setLoggedInAsAdmin } from "./adminActions";
import { NEW_LAYOUT_PATH } from "../../helpers/Constants/path";

//api call request methods
import {
  r_LoginUser,
  r_RegisterUser,
  r_AccountActivationCode,
  r_AccountActivation,
  r_ForgotPasswordChangeCode,
  r_ForgotPasswordChangeCodeVerify,
  r_ForgotPasswordSetNewPassword,
  r_storeUserFromGoogle,
  r_storeUserFromFacebook,
  r_AddPasswordCodeSendToEmail,
  r_addUserPassword,
  r_VerifyAddPasswordCode,
  r_GetUserDetails,
  r_LogOutUser,
  r_getUserType,
} from "../../helpers/Requesthelper/RequestFormatter";

import { r_addServiceUserLog } from "../../helpers/Requesthelper/RequestStatistics";
import isEmpty from "../../validation/is-empty";
import { MUTATION_END_POINT } from "../../utils/Constants";
import { signOutRequest } from "../../utils/axiosUtils/AxiosUtils";

export const logOutNonSayakUser = (history) => (dispatch) => {
  localStorage.removeItem("sayakusertoken");
  localStorage.removeItem("persist:root");

  dispatch(resetUserAuth());
  dispatch(resetUserDetails());

  //history.push('/')

  dispatch(appBusyReset());
};

export const logOutSayakUser =
  (userData, accessToken, history, endpoints) => (dispatch) => {
    const endPoint = MUTATION_END_POINT.SIGN_OUT;
    const appStateData = { message: "Logging Out Please Wait" };
    this.props.appBusySet(appStateData);
    signOutRequest(endPoint)
      .then((response) => {
        localStorage.removeItem("sayakusertoken");
        localStorage.removeItem("persist:root");

        dispatch(resetUserAuth());
        dispatch(resetUserDetails());

        history.push("/");

        dispatch(appBusyReset());
      })
      .catch((error) => {
        console.log(error);
      });

    // r_LogOutUser(userData, accessToken, endpoints).then(res => {
    //   //console.log(res)
    //   if (res.data.status === "success") {
    //     localStorage.removeItem("sayakusertoken")
    //     localStorage.removeItem("persist:root")

    //     dispatch(resetUserAuth())
    //     dispatch(resetUserDetails())

    //     history.push('/')

    //     dispatch(appBusyReset())
    //   }
    //   else if (res.data.logoutStatus === "fail") {

    //   }
    // })
  };

export const addPasswordSetNewPassword =
  (userData, history, endpoints) => (dispatch) => {
    //console.log(userData)

    r_addUserPassword(userData, endpoints).then((res) => {
      //console.log(res)
      if (res.data.status === "success") {
        dispatch(setAddPasswordSuccess());
        //history.push('/addPassword/newPassword')
        dispatch(appBusyReset());
        dispatch(resetaddPasswordErrors());
      } else if (res.data.status === "fail") {
        const { messageId, message } = res.data;
        const errorData = { messageId, message };
        dispatch(setaddPasswordErrors(errorData));
        dispatch(appBusyReset());
      }
    });
  };

export const addPasswordCodeVerify =
  (userData, history, endpoints) => (dispatch) => {
    //console.log(userData)

    r_VerifyAddPasswordCode(userData, endpoints).then((res) => {
      //console.log(res)
      if (res.data.status === "success") {
        dispatch(setAddPasswordPin(userData));
        history.push("/addPassword/newPassword");
        dispatch(appBusyReset());
        dispatch(resetaddPasswordErrors());
      } else if (res.data.status === "fail") {
        const { messageId, message } = res.data;
        const errorData = { messageId, message };
        dispatch(setaddPasswordErrors(errorData));
        dispatch(appBusyReset());
      }
    });
  };
export const addPasswordCodeRequest =
  (userData, history, endpoints) => (dispatch) => {
    //console.log(userData)

    r_AddPasswordCodeSendToEmail(userData, endpoints).then((res) => {
      //console.log(res)
      if (res.data.status === "success") {
        dispatch(setAddPasswordEmail(userData));
        history.push("/addPassword/pin");
        dispatch(appBusyReset());
        dispatch(resetaddPasswordErrors());
      } else if (res.data.status === "fail") {
        const { messageId, message } = res.data;
        const errorData = { messageId, message };
        dispatch(setaddPasswordErrors(errorData));
        dispatch(appBusyReset());
      }
    });
  };

export const setAddPasswordSuccess = () => {
  return {
    type: SET_ADD_PASSWORD_SUCCESS,
  };
};
export const resetAddPassswordState = () => {
  return {
    type: RESET_ADD_PASSWORD,
  };
};
export const resetaddPasswordErrors = () => {
  return {
    type: RESET_ADD_PASSWORD_ERRORS,
  };
};
export const setaddPasswordErrors = (errorData) => {
  return {
    type: SET_ADD_PASSWORD_ERRORS,
    payload: errorData,
  };
};
export const setAddPasswordEmail = (userData) => {
  return {
    type: SET_ADD_PASSWORD_EMAIL,
    payload: userData,
  };
};

export const setAddPasswordPin = (userData) => {
  return {
    type: SET_ADD_PASSWORD_PIN,
    payload: userData.addpasswordpin,
  };
};
export const forgotPasswordSetNewPassword =
  (userData, history, endpoints) => (dispatch) => {
    //console.log(userData);

    r_ForgotPasswordSetNewPassword(userData, endpoints).then(
      (res) => {
        if (res.data.status === "success") {
          dispatch(setForgotPasswordChangeSuccess(userData));
          dispatch(appBusyReset());
        } else if (res.data.status === "fail") {
          const { messageId, message } = res.data;
          const errorData = { messageId, message };
          dispatch(setForgotPasswordChangeErrors(errorData));
          dispatch(appBusyReset());
        }
      }
      //success bhaye k garne ?
      //first ma email ma pin jaancha
      //redirect user to the pin entering page
    );
  };

export const forgotPasswordChangeCodeRequest =
  (userData, history, endpoints) => (dispatch) => {
    //console.log(userData);
    dispatch(setForgotPasswordChangeEmail(userData));

    r_ForgotPasswordChangeCode(userData, endpoints).then(
      (res) => {
        if (res.data.status === "success") {
          history.push("/resetPassword/pin");
          dispatch(appBusyReset());
        } else if (res.data.status === "fail") {
          const { messageId, message } = res.data;
          const errorData = { messageId, message };
          dispatch(setForgotPasswordChangeErrors(errorData));
          dispatch(appBusyReset());
        }
      }
      //success bhaye k garne ?
      //first ma email ma pin jaancha
      //redirect user to the pin entering page
    );
  };

export const forgotPasswordChangeCodeVerify =
  (userData, history, endpoints) => (dispatch) => {
    //console.log(userData);
    dispatch(setForgotPasswordChangePin(userData));

    dispatch(resetAuthErrors());

    r_ForgotPasswordChangeCodeVerify(userData, endpoints).then(
      (res) => {
        if (res.data.status === "success") {
          //localStorage.setItem("forgotPasswordResetPin", userData.passwordrestpin);
          history.push("/resetPassword/newPassword");
          dispatch(appBusyReset());
        } else if (res.data.status === "fail") {
          const { messageId, message } = res.data;
          const errorData = { messageId, message };
          dispatch(setForgotPasswordChangeErrors(errorData));
          dispatch(appBusyReset());
        }
      }
      //success bhaye k garne ?
      //first ma email ma pin jaancha
      //redirect user to the pin entering page
    );
  };

export const requestActivationCode =
  (userData, history, endpoints) => (dispatch) => {
    //console.log(userData);
    if (userData) {
      r_AccountActivationCode(userData.email, endpoints).then((res) => {
        if (res.data.status === "success") {
          //history.push("/login")
          //console.log(res.data);

          dispatch(setAccountActivationCodeSent(userData));
          dispatch(appBusyReset());
        } else if (res.data.status === "fail") {
          const { messageId, message } = res.data;
          const errorData = { messageId, message };
          dispatch(setAuthErrors(errorData));
          dispatch(resetAccountActivationPinSent());
          dispatch(appBusyReset());
        }
      });
    }
  };

export const activateUser = (userData, history, endpoints) => (dispatch) => {
  if (userData) {
    //console.log(userData);
    r_AccountActivation(userData, endpoints).then((res) => {
      //console.log(res);

      if (res.data.status === "success") {
        //history.push("/login")
        dispatch(setUserActivated(res.data));

        // localStorage.removeItem("sayakuserregisterdetails")
        dispatch(appBusyReset());
      } else if (res.data.status === "fail") {
        const { messageId, message } = res.data;
        const errorData = { messageId, message };
        dispatch(setAuthErrors(errorData));
        dispatch(resetAccountActivationPinSent());
        dispatch(appBusyReset());
      }
    });
    //yedi userdata cha bhane
    //aaba call
  }
};

export const registerUser = (userData, history, endpoints) => (dispatch) => {
  // const email = userData.email;
  // const name = userData.name;
  // const accountStatus = "notActivated";
  // const userDataForLocalStorage = JSON.stringify({
  //   email: email,
  //   name: name,
  //   accountStatus: accountStatus,
  // });
  // localStorage.setItem("sayakuserregisterdetails", userDataForLocalStorage);

  // const newUserData = { email: email, name: name };
  //aaba yo data lai api call garne

  r_RegisterUser(userData, endpoints).then((res) => {
    if (res.data.status === "success") {
      dispatch(setCurrentUserRegister(userData));

      //console.log("the user created successfully");
      r_AccountActivationCode(userData.email, endpoints);

      history.push("/activateAccount");

      dispatch(appBusyReset());
    } else if (res.data.status === "fail") {
      const { messageId, message } = res.data;
      if (messageId === "addPassword") {
        //this is where you setup password if the user doesnt have password already set
        //dispatch action to add email to state

        history.push("/addPassword");
      }
      dispatch(setAuthErrors({ messageId, message }));
      dispatch(appBusyReset());
    }
  });
  //response aaucha ani tei response anusar redirect garne ani herne
};

//login action
export const loginUserWithGoogle =
  (userData, history, endpoints) => (dispatch) => {
    let { tokenId } = userData;
    const { profileObj } = userData;
    tokenId = tokenId + "?2";
    //console.log(profileObj);

    r_storeUserFromGoogle(profileObj, endpoints).then((res) => {
      //console.log(res);

      //set token to localStorage
      localStorage.setItem("sayakusertoken", tokenId);
      //set token to Auth Header
      dispatch(setUserDetails(profileObj));
      //console.log(profileObj.email)

      r_GetUserDetails(userData.userName, tokenId, endpoints).then((res) => {
        //console.log(res)

        const { email, name, userName } = res.data;
        const x = {
          email: profileObj.email,
          name: profileObj.name,
          userName: profileObj.email,
          loginPlatform: "GOOGLE",
          userType: "USER",
        };
        dispatch(setLoggedInUserDetails(x));
      });

      r_addServiceUserLog(userData.userName, "LOGIN", endpoints, tokenId).then(
        (res) => console.log("log successful")
      );

      //console.log(userData.userName)

      //set User
      //auth state ko user object set garne
      //decode token to get user data
      //now set this data to state  . set current user
      dispatch(setCurrentUserToken(tokenId));
      // history.push("/user/spelling");
      history.push({ NEW_LAYOUT_PATH });
    });
  };

export const loginUserWithFacebook =
  (userData, history, endpoints) => (dispatch) => {
    const { accessToken } = userData;

    let tokenId = accessToken + "?1";

    r_storeUserFromFacebook(userData, endpoints).then((res) => {
      //console.log(res);

      //set token to localStorage
      localStorage.setItem("sayakusertoken", tokenId);
      //set token to Auth Header
      //setAuthToken(accessToken);
      //set User
      //auth state ko user object set garne
      //decode token to get user data
      //now set this data to state  . set current user
      dispatch(setCurrentUserToken(tokenId));
      r_GetUserDetails(userData.email, tokenId, endpoints).then((res) => {
        //console.log(res)

        const { email, name, userName } = res.data;
        const x = {
          email: email,
          name: name,
          userName: userName,
          loginPlatform: "FACEBOOK",
          userType: "USER",
        };
        dispatch(setLoggedInUserDetails(x));
      });

      r_addServiceUserLog(userData.userName, "LOGIN", endpoints, tokenId).then(
        (res) => {
          //console.log("log")
        }
      );
      //console.log(userData.userName)
      //history.push("/user/spelling");
      history.push({ NEW_LAYOUT_PATH });
    });
  };

export const loginUser = (userData, history, endpoints) => (dispatch) => {
  r_LoginUser(userData, endpoints).then((res) => {
    //console.log(res);
    //console.log(res.data);

    if (res.data.status === "fail") {
      //prepare the error state of redux
      if (res.data.messageId === "verifyEmail") {
        const email = userData.userName;
        const accountStatus = "notActivated";
        const userDataForLocalStorage = JSON.stringify({
          email: email,
          accountStatus: accountStatus,
        });
        localStorage.setItem(
          "sayakuserregisterdetails",
          userDataForLocalStorage
        );

        const userDataForReduxStore = { email: email, name: "" };
        dispatch(setCurrentUserRegister(userDataForReduxStore));
        dispatch(resetAuthErrors());

        history.push("/activateAccount");

        dispatch(requestActivationCode());

        dispatch(appBusyReset());
      } else {
        const { messageId, message } = res.data;
        //const errorData = { messageId, message };

        //set a new field named ( displayMessage )
        mapDisplayErrorMessage(messageId, message).then((displayMessage) => {
          const errorData = { messageId, message, displayMessage };
          dispatch(setAuthErrors(errorData));
          dispatch(appBusyReset());
        });

        // dispatch(setAuthErrors(errorData));
        // dispatch(appBusyReset());
      }
    }

    if (res.data.status === "success") {
      const { accessToken } = res.data;
      localStorage.setItem("sayakusertoken", accessToken);
      dispatch(setCurrentUserToken(accessToken));

      //console.log(userData.userName)

      r_addServiceUserLog(
        userData.userName,
        "LOGIN",
        endpoints,
        accessToken
      ).then((res) => {
        //console.log("log successful")
      });

      r_getUserType(userData.userName, accessToken, endpoints).then((res) => {
        const { roleName } = res.data;
        //console.log(roleName)

        if (roleName === "ADMIN") {
          //dispatch(setLoggedInAsAdmin())
          r_GetUserDetails(userData.userName, accessToken, endpoints).then(
            (res) => {
              //console.log(res)

              const { email, name, userName } = res.data;
              const x = {
                email: email,
                name: name,
                userName: userName,
                loginPlatform: "SAAYAK",
                userType: "ADMIN",
              };
              dispatch(setLoggedInUserDetails(x));
              dispatch(appBusyReset());
              history.push("/admin");
            }
          );
          // dispatch(appBusyReset())
          // history.push("/admin");
        } else {
          r_GetUserDetails(userData.userName, accessToken, endpoints).then(
            (res) => {
              //console.log(res)

              const { email, name, userName } = res.data;
              const x = {
                email: email,
                name: name,
                userName: userName,
                loginPlatform: "SAAYAK",
                userType: "USER",
              };
              dispatch(setLoggedInUserDetails(x));
              dispatch(appBusyReset());
              //history.push("/user/spelling");
              history.push({ NEW_LAYOUT_PATH });
            }
          );
          // dispatch(appBusyReset())
          // history.push("/user/spelling");
        }
      });

      // r_GetUserDetails (userData.userName, accessToken, endpoints).then(res => {
      //   console.log(res)

      //   const {email, name, userName } = res.data
      //     const x = {
      //       "email":email,
      //       "name" :name,
      //       "userName": userName,
      //       "loginPlatform": "SAAYAK"

      //     }
      //     dispatch(setLoggedInUserDetails(x))
      // })
    }
  });
};

export const setCurrentUserToken = (userToken) => {
  return {
    type: SET_CURRENT_USER_TOKEN,
    payload: userToken,
  };
};

export const resetUserDetails = () => {
  return {
    type: RESET_USER_DETAILS,
  };
};

export const setUserDetails = (userData) => {
  return {
    type: SET_CURRENT_USER_DETAILS,
    payload: userData,
  };
};
export const setCurrentUserRegister = (userData) => {
  return {
    type: SET_CURRENT_USER_REGISTER,
    payload: userData,
  };
};

export const resetAccountActivationPinSent = () => {
  return {
    type: RESET_RESEND_ACTIVATION_CODE,
  };
};
export const setAccountActivationCodeSent = (userData) => {
  return {
    type: SET_RESEND_ACTIVATION_CODE,
    payload: userData,
  };
};
export const setUserActivated = (userData) => {
  return {
    type: ACTIVATE_USER_ACCOUNT,
    payload: userData,
  };
};

export const setForgotPasswordChangeEmail = (userData) => {
  return {
    type: SET_PASSWORD_RESET_EMAIL,
    payload: userData.email,
  };
};

export const setForgotPasswordChangePin = (userData) => {
  return {
    type: SET_PASSWORD_RESET_PIN,
    payload: userData,
  };
};

export const setForgotPasswordChangeSuccess = (userData) => {
  return {
    type: SET_PASSWORD_CHANGED_SUCCESS,
    payload: userData,
  };
};

export const setForgotPasswordChangeErrors = (userData) => {
  return {
    type: SET_PASSWORD_CHANGE_ERRORS,
    payload: userData,
  };
};

export const resetFrogotPasswordChangeErrors = () => {
  return {
    type: RESET_PASSWORD_CHANGE_ERRORS,
    payload: true,
  };
};

export const resetUserAuth = () => {
  return {
    type: RESET_USER_AUTH,
    payload: true,
  };
};
