
import { AUTH_ERROR_SET, AUTH_ERROR_RESET } from "../actions/types";

import isEmpty from "../../validation/is-empty";

const initialState = {
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTH_ERROR_SET:
      return action.payload
    case AUTH_ERROR_RESET:
      return {}
    default:
      return state;
  }
}
