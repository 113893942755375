import React, { Component } from "react";
import { Row, Col, Menu, Tabs, Button, Card, Collapse } from "antd";
const { Panel } = Collapse;
const { SubMenu } = Menu;
const { TabPane } = Tabs;
export default class FAQpublic extends Component {
  render() {
    return (
      <div className="container">
        <h1
          style={{
            marginBottom: "20px",
            textAlign: "center",
            fontSize: "3em",
            color: "#f3722c",
          }}
        >
          सहयोगी प्रश्नोतर
        </h1>

        <Collapse onChange={this.callback}>
          <Panel header="सायक के हो ?" key="1" style={{ fontSize: "1.3em" }}>
            <p>
              सायक एक एकीकृत सफ्टवेयर प्रणाली हो । यसले नेपाली भाषामा लेखिएका
              पाठ्यसामग्रीमा स्वचालित तवरले हिज्जे परिक्षण गर्ने, फन्ट परिवर्तन
              गर्ने, फोटो फर्म्याटमा लेखिएको पाठ्यसामग्री सम्पादन गर्न मिल्ने
              गरी छुट्याउने (ओसीआर ) आदि कार्यमा सहयोग गर्दछ ।
            </p>
          </Panel>

          <Panel
            header="सायक  खाता कसरी बनाउने ?"
            key="2"
            style={{ fontSize: "1.3em" }}
          >
            <p>
              <li>१. http://hijje.com मा जानुहोस् ।</li>
              <li>२. निशुल्क खाता बनाउनुहोस् लेखेको बटनमा थिच्नुहोस्।</li>
              <li>३. अब सायकमा उपलब्ध विभिन्न सेवाहरू सुचारु गर्नुहोस्। </li>
            </p>
          </Panel>

          <Panel
            header="सायकमा के के सुविधा छन् ?"
            key="3"
            style={{ fontSize: "1.3em" }}
          >
            <p>
              हाल सायकमा नेपाली भाषामा लेखिएको पाठ्यसामग्रीमा हिज्जे परिक्षण
              गर्ने (Spell Checking and Auto Suggestion System) तथा नेपालीका
              विविध TTF फन्टहरूबाट युनिकोडमा रूपान्तरण गर्ने त्यस्तै युनिकोड बाट
              विविध TTF (Preeti, Kantipur, PCS nepali, Himalb, etc) फन्टहरूमा
              पनि रूपान्तरण गर्ने प्रविधि उपलब्ध छ । यसमा छिट्टै इमेजबाट
              पाठ्यसामग्री झिक्ने सफ्टवेयर पनि उपलब्ध हुनेछ ।
            </p>
          </Panel>

          <Panel
            header="के यसले युनिकोडमा लेखेको पाठ्यसामग्रीलाई मात्र परिक्षण गर्ने हो ?"
            key="4"
            style={{ fontSize: "1.3em" }}
          >
            <p>
              निशुल्क उपलब्ध प्रणालीमा युनिकोडमा लेखेको पाठ्यसामग्रीलाई परिक्षण
              गर्ने सुविधा छ। अन्य फन्टहरूमा यो सुविधा लिनका लागि सम्पर्क
              गर्नुहोस्।{" "}
            </p>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
