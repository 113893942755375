import React, { Component } from 'react'

import { Affix, Button, Card } from 'antd';


export default class DownloadMSWordPluginAffix extends Component {
    render() {
        return (
            <div style={{
                position: "absolute",
                zIndex: "9999",
                // top:"150px",

            }}>
                <Affix
                >
                    <Card>
                        Download spelling Check
                        <br />
                        For MS word.
                    </Card>
                </Affix>
            </div>
        )
    }
}
