import {store} from "../store";

import {
  setApiCallConstants,test
} from "../actions/apiCallConstantsAction";

export const prepReduxWithApiCallConstants = () => {
  //fetch api call constants
  //send them to be set to store
  //console.log("api call wala function samm apugyo")
  fetch('apiConstants.json')
        .then(data => data.json())
        .catch(error => console.log(error))
        .then(config => {
            const endpoints = {...config}
            //console.log(endpoints)

            store.dispatch(setApiCallConstants(endpoints))
            
            
        })
  


};
