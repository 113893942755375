import React, { useState, useEffect } from "react";
import { Card, Input, Alert, Button, Checkbox } from "antd";
import "./searchdictionary.css";
import ContainerLoading from "../../../../layout/layoutUtils/ContainerLoading";
import { r_publicDictionarySearch } from "../../../../helpers/Requesthelper/RequestDictionarySearch";
import { r_addServiceUserLog } from "../../../../helpers/Requesthelper/RequestStatistics";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updatePageHead } from "../../../common/UpdateMetaAndTitle";
import { toast } from "react-toastify";
import {
  resetUserAuth,
  resetUserDetails,
} from "../../../../store/actions/authAction";
import { signOutRequest } from "../../../../utils/axiosUtils/AxiosUtils";
import { MUTATION_END_POINT } from "../../../../utils/Constants";
import { SearchOutlined } from "@ant-design/icons";
import { RomanInput } from "./RomanInput";

const { Search } = Input;

const SearchDictionary = (props) => {
  const [serviceName] = useState("DICTIONARY");
  const [wordList, setWordList] = useState([]);
  const [errorResponse, setErrorResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [wordNotFound, setWordNotFound] = useState(false);
  const [buttonText, setButtonText] = useState("खोज्नुहोस्"); // default button text
  const [searchText, setSearchText] = useState("");
  const [isRomanizeTyping, setIsRomanizeTyping] = useState(false);
  const [isEmptyError, setIsEmptyError] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    updatePageHead(
      "Nepali Dictionary",
      "Nepali dictionary, Nepali shabdakosh, Nepali sabdakosh, Nepali word meaning, Nepali meaning, Nepali words, नेपाली शब्दकोश, प्रज्ञा शब्दकोश",
      "Nepali dictionary, Nepali shabdakosh, Nepali sabdakosh, Nepali word meaning, Nepali meaning, Nepali words, नेपाली शब्दकोश, प्रज्ञा शब्दकोश"
    );
    window.addEventListener("resize", updateButtonText);
    updateButtonText();

    return () => {
      window.removeEventListener("resize", updateButtonText);
    };
  }, []);

  const updateButtonText = () => {
    if (window.innerWidth <= 600) {
      setButtonText(<SearchOutlined />);
    } else {
      setButtonText("खोज्नुहोस्");
    }
  };

  const handleLogout = async () => {
    const endPoint = MUTATION_END_POINT.SIGN_OUT;
    const appStateData = { message: "Logging Out Please Wait" };
    props.appBusySet(appStateData);

    try {
      const response = await signOutRequest(endPoint);
      localStorage.clear();
      dispatch(resetUserAuth());
      dispatch(resetUserDetails());
      history.push("/");
    } catch (error) {
      console.log(error);
    }
  };

  const searchInDictionary = (word) => {
    setLoading(true);
    setWordList([]);
    setWordNotFound(false);
    if (word) {
      setIsEmptyError(false);
      r_publicDictionarySearch(word, props.endpoints)
        .then((response) => {
          const { itemListElement } = response.data;
          // console.log(response);
          if (response.data.length > 0) {
            setWordList(response.data);
            setErrorResponse("");
            // setLoading(false);
          } else {
            setWordNotFound(true);
            // setLoading(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.status === 401 &&
              error.response.data.message === "invalid token"
            ) {
              handleLogout();
            } else {
              toast(
                "तपाईंको नेटवर्क मा समस्या देखिएकोले यो अनुरोध पुरा हुन सकेन । इन्टरनेट कनेक्सन जाँच गरेर पुन प्रयास गर्नुहोला । "
              );
              console.log("error Response", error.response);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setIsEmptyError(true);
      setLoading(false);
    }
  };

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>
        कृपया तपाईंले अर्थ खोजेको शब्द तलको बाकसमा राख्नुहोस्।
      </h3>
      <div style={{ textAlign: "center", paddingTop: "15px" }}>
        <Checkbox
          onChange={() => {
            setIsRomanizeTyping(!isRomanizeTyping);
          }}
          style={{ fontWeight: "bold", color: "#0d6efd", fontSize: "17px" }}
        >
          रोमनाइज टाइपिङ गर्न यहाँ टिक लगाउनुहोस्।
        </Checkbox>
      </div>
      {/* <br /> */}
      <div className="search-container">
        {/* {isRomanizeTyping ? ( */}
        <div className="responsive-search">
          <RomanInput
            onChange={(value) => {
              setSearchText(value);
            }}
            isRomanizeTyping={isRomanizeTyping}
            // allowClear
            // onClear={() => {
            //   console.log("hello");
            // }}
          />
          <Button
            // style={{ marginTop: "7px" }}
            size="large"
            type="primary"
            // disabled={!searchText}
            onClick={() => searchInDictionary(searchText)}
          >
            खोज्नुहोस्
          </Button>
        </div>
        {/* ) : (
          <Search
            id="dictionarySearchTextArea"
            placeholder="Please type in a word"
            className="dictionarySearchArea"
            enterButton={buttonText}
            size="large"
            allowClear
            defaultValue="कमल"
            onSearch={(e) => searchInDictionary(e)}
          />
        )} */}
      </div>
      {loading ? <ContainerLoading /> : null}
      {isEmptyError ? (
        <Alert
          type="error"
          message=" कृपया शब्द राखेर मात्र अर्थ खोज्नुहोस् ।"
          banner
        />
      ) : null}
      {!wordNotFound ? (
        <div
          style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
        >
          {wordList.length
            ? wordList.map((word) => (
                <WordCard word={word} key={word.wordEntry} />
              ))
            : null}
        </div>
      ) : (
        <Alert
          message="शब्द भेटिएन"
          type="error"
          showIcon
          style={{ marginTop: "30px" }}
        />
      )}
    </div>
  );
};

const WordCard = ({ word }) => {
  const { ipa, definition, pos } = word || {};

  return (
    <Card
      hoverable
      title={
        <div>
          <b style={{ fontSize: "20px" }}>{word.word}</b>
          <br />[{ipa}]
        </div>
      }
      className="dictionary-result-card"
    >
      <p className="dictionary-inner-text">
        <b>पदवर्ग : </b>
        <span>{pos}</span>
      </p>
      <p className="dictionary-inner-text">
        <b>परिभाषा : </b>
        <span>{definition}</span>
      </p>
    </Card>
  );
};

export default SearchDictionary;
