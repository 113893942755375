import React, { Component } from "react";

import "./style.css";

import LoginWithGoogleAndFacebook from "../../layout/layoutUtils/LoginWithGoogleAndFacebook";

import {
  Input,
  Row,
  Col,
  Menu,
  Dropdown,
  Button,
  Select,
  Alert,
  Spin,
  Modal,
  Card,
} from "antd";

import { connect } from "react-redux";
import { withRouter, Route, Link, Redirect } from "react-router-dom";

import { r_publicFontConversion } from "../../helpers/Requesthelper/RequestFontConversion";

const { TextArea } = Input;
const { Option } = Select;

class FontConversion extends Component {
  state = {
    initialFont: "unicode",
    finalFont: "preeti",
    callWord: "",
    conversionResult: "",
    wordCount: 0,
    wordCountLimit: 100,
    errors: "",
    loading: false,
    wordLimitExceeded: false,
    fontUnavailableModalDisplay: false,
  };

  componentDidMount() {
    //console.log("this is from the font conversion component", this.props);
  }

  onChangeFrom(value) {
    //console.log(`selected from font ${value}`);
  }

  onChangeInput({ target: { value } }) {
    console.log(value);
    let length = value.trim().split(" ").length;

    if (length <= this.state.wordCountLimit) {
      this.setState({ callWord: value, wordCount: length });
    } else {
      this.setState({ wordLimitExceeded: true });
    }
  }

  handleConversion(e) {
    this.setState({
      errors: "",
      loading: true,
      conversionResult: "",
      wordLimitExceeded: false,
    });

    //if(!this.state.callWord) return
    //console.log("returning the state", this.state);
    //console.log("returning the props", this.props);

    //code test for font conversion
    if (this.state.initialFont && this.state.finalFont && this.state.callWord) {
      const requestData = {
        fromFont: `${this.state.initialFont}`,
        toFont: `${this.state.finalFont}`,
        sourceWord: `${this.state.callWord}`,
      };

      //call function from here and get the result
      r_publicFontConversion(requestData, this.props.endpoints).then((resp) => {
        console.log(resp);
        if (resp.status === 200) {
          this.setState({ conversionResult: resp.data.text, loading: false });
        } else if (resp.data.status === "fail") {
          const { messageId } = resp.data;
          if (
            messageId ===
            "सिमा भन्दा बढी अक्षर राख्नुभयो। थोरै अक्षर राखेर फेरी प्रयास गर्नुहोस्।"
          ) {
            this.setState({
              conversionResult: "",
              loading: false,
              wordLimitExceeded: true,
            });
          }
        }
      });
    } else {
      this.setState({
        errors: "फन्ट छानेर माथिको बाकसमा केहि लेख्नुहोस्।",
        conversionResult: "",
        loading: false,
      });
    }
  }

  openPopUpForUnavailableFont = () => {
    //console.log("font unavailable");
    this.setState({ fontUnavailableModalDisplay: true });
  };

  handleOk = (e) => {
    //console.log(e);
    this.setState({
      fontUnavailableModalDisplay: false,
    });
  };

  handleCancel = (e) => {
    //console.log(e);
    this.setState({
      fontUnavailableModalDisplay: false,
    });
  };

  render() {
    const showModalForUnavailableFont = () => {
      return (
        <Modal
          title="यो फन्ट उपलब्ध छैन। यो फन्ट प्रयोग गर्न कृपया खाता खोल्नुहोस। "
          visible={this.state.fontUnavailableModalDisplay}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <LoginWithGoogleAndFacebook />
        </Modal>
      );
    };
    const { loading } = this.state;

    const fontList = {
      preeti: "प्रीति",
      unicode: "युनिकोड",
    };

    const fonts = ["preeti", "unicode"];

    return (
      <div>
        {showModalForUnavailableFont()}

        <h2 style={{ textAlign: "center" }}>फन्ट रूपान्तरण</h2>
        <Alert
          message="तल बाकसमा टाइप गर्नुहोस् वा अन्य ठाउँबाट कपी गरेर त्यस बाकसमा पेस्ट गर्नुहोस् ।"
          description="अन्य फन्टहरू प्रयोग गर्न नि:शुल्क लगिन गर्नुहोस्। "
          type="info"
          showIcon
          style={{ marginBottom: "10px" }}
        />
        <br />
        <Row style={{ marginBottom: "10px" }}>
          <Col
            className="font-selection-dropdown"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              defaultValue={this.state.initialFont}
              value={this.state.initialFont}
              placeholder="फन्ट छानुहोश "
              optionFilterProp="children"
              onChange={(e) => {
                //this.setState({initialFont: e, conversionResult: ""})
                if (e === "unicode") {
                  this.setState({
                    initialFont: e,
                    conversionResult: "",
                    finalFont: "preeti",
                  });
                }
                if (e === "preeti") {
                  this.setState({
                    initialFont: e,
                    conversionResult: "",
                    finalFont: "unicode",
                  });
                }
                if (e === "kantipur" || e === "PCSnepali") {
                  this.openPopUpForUnavailableFont();
                }
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {/* {this.state.finalFont === "unicode"? null : <Option value="unicode">युनिकोड</Option>}
                        {this.state.finalFont === "preeti"? null : <Option value="preeti">प्रीति</Option> } */}

              <Option value="preeti">प्रीति </Option>
              <Option value="unicode">युनिकोड</Option>
              <Option value="kantipur">Kantipur</Option>
              <Option value="PCSnepali">PCS Nepali</Option>
            </Select>
            <span style={{ marginLeft: "10px", fontSize: "1.2em" }}>बाट</span>
          </Col>
          <Col
            className="font-selection-dropdown"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="फन्ट छानुहोश "
              optionFilterProp="children"
              defaultValue={this.state.finalFont}
              value={this.state.finalFont}
              defaultActiveFirstOption
              onChange={(e) => {
                if (e === "unicode") {
                  this.setState({
                    finalFont: e,
                    conversionResult: "",
                    initialFont: "preeti",
                  });
                }
                if (e === "preeti") {
                  this.setState({
                    inalFont: e,
                    conversionResult: "",
                    initialFont: "unicode",
                  });
                }

                if (e === "kantipur" || e === "PCSnepali") {
                  this.openPopUpForUnavailableFont();
                }
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {/* {this.displayFinalFonts(fonts)} */}
              {/* {this.state.initialFont === "unicode"? null : <Option value="unicode">युनिकोड</Option>}
                        {this.state.initialFont === "preeti"? null : <Option value="preeti">प्रीति</Option> } */}

              <Option value="preeti">प्रीति </Option>
              <Option value="unicode">युनिकोड</Option>
              <Option value="kantipur">Kantipur</Option>
              <Option value="PCSnepali">PCS Nepali</Option>
            </Select>
            <span style={{ marginLeft: "10px", fontSize: "1.2em" }}>
              मा लानुहोस्।
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input.TextArea
              value={this.state.callWord}
              placeholder="टाइप गर्नुहोस् वा अन्य ठाउँबाट कपी गरेर यस बाकसमा पेस्ट गर्नुहोस् "
              className="font-conversion-textarea"
              autoSize={{ minRows: 4 }}
              allowClear
              style={{
                marginTop: "10px",
                fontFamily: `"${this.state.initialFont}"`,
              }}
              onChange={(e) => {
                this.onChangeInput(e);
              }}
              // onChange={({ target: { value } }) =>
              //   this.setState({ callWord: value })
              // }
            />
            <p style={{ textAlign: "right" }}>
              {this.state.wordCount} / १०० शब्द
            </p>
            {/* <p style={{ textAlign: "right" }}>
              यो फन्ट रुपान्तरण १०० शब्दहरूको लागि मात्र हो । असीमित शब्दहरू रूपान्तरणको लागि कृपया 
              <Link to="/signup"> खाता खोल्नुहोस् ।</Link>
            </p> */}

            <Button
              className="btn-orange"
              type="primary"
              size="large"
              style={{ marginTop: "10px" }}
              onClick={() => this.handleConversion()}
            >
              रूपान्तरण
            </Button>
          </Col>

          {this.state.errors ? (
            <Col span={24} style={{ marginTop: "20px" }}>
              <Alert
                message={this.state.errors}
                //description={this.state.errors}
                type="error"
                showIcon
              />
            </Col>
          ) : null}
          {this.state.wordLimitExceeded ||
          this.state.wordCount > this.state.wordCountLimit ? (
            <Col span={24} style={{ marginTop: "20px" }}>
              <Card className="card-with-shadow" style={{ marginTop: "10px" }}>
                {/* <p style={{ fontSize: "1.2em" }}>
                  यो फन्ट रुपान्तरण १०० शब्दहरूको लागि मात्र हो । असीमित शब्दहरू रूपान्तरणको लागि कृपया
                  <Link to="/signup"> खाता खोल्नुहोस् ।</Link>
                </p> */}
                {/* <Alert
                message={
                  "यो फन्ट रुपान्तरण १०० शब्दहरूको लागि मात्र हो । असीमित शब्दहरू रूपान्तरणको लागि कृपया खाता खोल्नुहोस् ।"
                }
                //description={this.state.errors}
                type="error"
                showIcon
              /> */}
                <LoginWithGoogleAndFacebook />
              </Card>
            </Col>
          ) : null}
        </Row>

        {this.state.conversionResult != "" ? (
          <Row>
            <Col span={24} style={{ marginTop: "20px" }}>
              <h3>
                <span className="color-orange">
                  {fontList[this.state.initialFont]}
                </span>{" "}
                बाट{" "}
                <span className="color-orange">
                  {fontList[this.state.finalFont]}
                </span>{" "}
                रुपान्तरण सफल भएको छ
              </h3>

              <TextArea
                autoSize={{ minRows: 4 }}
                style={{
                  marginTop: "10px",
                  fontFamily: `"${this.state.finalFont}"`,
                  fontSize: "24px",
                }}
                value={this.state.conversionResult}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col></Col>
          </Row>
        )}

        {loading ? <Spin size="large" style={{ marginTop: "30px" }} /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  endpoints: state.apiConstants,
});

export default connect(mapStateToProps, {})(withRouter(FontConversion));
