export const RESET_USER_AUTH = "RESET_USER_AUTH";

export const SET_CURRENT_USER_REGISTER = "SET_CURRENT_USER_REGISTER";
export const SET_CURRENT_USER_LOGIN = "SET_CURRENT_USER_LOGIN";
export const SET_CURRENT_USER_TOKEN = "SET_CURRENT_USER_TOKEN";
export const SET_CURRENT_USER_DETAILS = "SET_CURRENT_USER_DETAILS"
export const ACTIVATE_USER_ACCOUNT = "ACTIVATE_USER_ACCOUNT";
export const SET_RESEND_ACTIVATION_CODE = "SET_RESEND_ACTIVATION_CODE";
export const RESET_RESEND_ACTIVATION_CODE = "RESET_RESEND_ACTIVATION_CODE"

export const APP_BUSY_SET = "APP_BUSY_SET";
export const APP_BUSY_RESET = "APP_BUSY_RESET";

export const AUTH_ERROR_SET = "AUTH_ERROR_SET";
export const AUTH_ERROR_RESET = "AUTH_ERROR_RESET";

export const SET_PASSWORD_CHANGED_SUCCESS = "SET_PASSWORD_CHANGED_SUCCESS";
export const SET_PASSWORD_RESET_EMAIL = "SET_PASSWORD_RESET_EMAIL";
export const SET_PASSWORD_RESET_PIN = "SET_PASSWORD_RESET_PIN";
export const SET_PASSWORD_CHANGE_ERRORS = "SET_PASSWORD_CHANGE_ERRORS";
export const RESET_PASSWORD_CHANGE_ERRORS = "RESET_PASSWORD_CHANGE_ERRORS";

export const SET_API_CALL_CONSTANTS = "SET_API_CALL_CONSTANTS";


export const RESET_ADD_PASSWORD = "RESET_ADD_PASSWORD"
export const SET_ADD_PASSWORD_EMAIL = "SET_ADD_PASSWORD_EMAIL"
export const SET_ADD_PASSWORD_ERRORS = "SET_ADD_PASSWORD_ERRORS"
export const RESET_ADD_PASSWORD_ERRORS = "RESET_ADD_PASSWORD_ERRORS"
export const SET_ADD_PASSWORD_PIN = "SET_ADD_PASSWORD_PIN"
export const SET_ADD_PASSWORD_SUCCESS = "SET_ADD_PASSWORD_SUCCESS"

export const SET_LOGGED_IN_USER_DETAILS = "SET_LOGGED_IN_USER_DETAILS"

export const SET_LOGGED_IN_AS_ADMIN = "SET_LOGGED_IN_AS_ADMIN"
export const RESET_USER_DETAILS = "RESET_USER_DETAILS"
export const RESET_AUTH_ERRORS = "RESET_AUTH_ERRORS"
export const RESET_APP_STATE = "RESET_APP_STATE"
export const RESET_ADMIN ="RESET_ADMIN"