const HOST = window.location.origin;
// const HOST = "http://192.168.1.66:8000";
// 103.198.9.212
const baseUrl = `${HOST}/v1`;
const fontConvertUrl = `${HOST}/v1/font/convert`;

// const baseUrl = "/v1";
// const fontConvertUrl = "/v1/font/convert";

export const url = {
  //   MUTATION_END_POINT1: baseUrl + "/mutation",
  //   QUERY_END_POINT1: baseUrl + "/dictionary/list",
  //   // QUERY_END_POINT1: baseUrl + "/v1/dictionary/list",
  //   LOGIN_END_POINT1: baseUrl + "/login",
  //   DOWNLOAD_END_POINT1: baseUrl + "/download",
  //   DOWNLOAD_URL1: downloadUrl,
  //   VIEW_URL1: viewUrl,
  //   OCR_URL1: baseUrl + "/ocr",

  BASE_END_POINT: baseUrl,
  FONT_CONVERT_END_POINT: fontConvertUrl,
};
