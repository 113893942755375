import React from "react";

import { Route, Redirect } from "react-router-dom";

import { connect } from "react-redux";

const LoggedInRoute = ({ component: Component, auth, userType, ...rest }) => (
  <Route
    {...rest}
    // render = {props =>
    //     auth.isAuthenticated === true ? (
    //         userType === "USER" ? <Component {...props} /> : <Redirect to="/admin" />
    //     ) : (
    //         <Redirect to="/login" />
    //     )
    // }
    render={(props) => {
      // console.log("Logged in route", props)
      return auth.isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
    }
    }
  />
);

const mapStateToProps = (state) => ({
  auth: state.auth,
  userType: state.userDetails.userType,
});

export default connect(mapStateToProps)(LoggedInRoute);
