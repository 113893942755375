import { Card, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import Login from "./login/Login";
import SignUp from "./signup/SignUp";
import { useHistory, useLocation } from "react-router";
// import "./LoginComponent.css"; // Uncomment the custom CSS import

const LoginComponent = () => {
  let history = useHistory();
  const location = useLocation();

  const [activeKey, setActiveKey] = useState(
    location.pathname.split("/")[1] || "login"
  );
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberFromSignUp, setMobileNumberSignUp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const pathname = location.pathname.split("/")[1];
    if (pathname && pathname !== activeKey) {
      setActiveKey(pathname);
    }
  }, [location, activeKey]);

  const signUpCallBack = (mobileNumber) => {
    history.push("/login");
    setMobileNumberSignUp(mobileNumber);
  };

  const onTabChange = (key) => {
    history.push(`/${key}`);
  };

  const loginCallBack = (mobileNumber, errorMessage) => {
    if (mobileNumber) {
      setMobileNumber(mobileNumber);
    }
    if (errorMessage) {
      setErrorMessage(errorMessage);
    }
  };

  return (
    <Card className="custom-card">
      <Tabs
        activeKey={activeKey}
        size="large"
        className="custom-tabs"
        onChange={onTabChange}
        destroyInactiveTabPane
      >
        <Tabs.TabPane tab="Login" key="login" className="custom-tab-pane">
          <Login
            loginCallBack={loginCallBack}
            mobileNumber={mobileNumber}
            mobileNumberFromSignUp={mobileNumberFromSignUp}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Sign up" key="signup" className="custom-tab-pane">
          <SignUp
            signUpCallBack={signUpCallBack}
            mobileNumber={mobileNumber}
            errorMessage={errorMessage}
          />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default LoginComponent;
