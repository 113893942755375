
import { SET_LOGGED_IN_USER_DETAILS, RESET_USER_DETAILS } from "../actions/types";

import isEmpty from "../../validation/is-empty";

const initialState = {
    userName:"",
    email:"",
    name: "",
    loginPlatform:"",
    userType:"",
    productsSubscribed:{},
    otherInfo:{}
};

export default function (state = initialState, action) {
  switch (action.type) { 

    case RESET_USER_DETAILS :
      return {
        userName:"",
        email:"",
        name: "",
        loginPlatform:"",
        userType:"",
        productsSubscribed:{},
        otherInfo:{}
      }
    
    case SET_LOGGED_IN_USER_DETAILS:
      return {
        ...state,
        userName: action.payload.userName,
        email: action.payload.email,
        name: action.payload.name,
        loginPlatform: action.payload.loginPlatform,
        userType: action.payload.userType
      }
    default:
      return state;
  }
}

