import React, { Component } from "react";

import ContainerLoading from "../../layout/layoutUtils/ContainerLoading";

import {
  Table,
  Tag,
  Space,
  Spin,
  Modal,
  Button,
  Card,
  Row,
  Col,
  Input,
  Select,
  Form,
} from "antd";
import {
  ExclamationCircleOutlined,
  SearchOutlined
} from "@ant-design/icons";
import UserStatistics from "./listAllUsersComponents/userStatistics";
import UserFilter from "./listAllUsersComponents/userFilter";
import ShowUserProfile from './listAllUsersComponents/ShowUserProfile'
import ComposeEmail from './listAllUsersComponents/ComposeEmail'

import UpdateUserDetails from "./listAllUsersComponents/UpdateUser";

import {
  r_listAllUsers,
  r_deleteUser,
  r_countTotalUsers,
  r_countMatchingUsersByGivenName,
  r_listMatchingUsersByGivenName,
  r_countUsersByServiceUsed,
  r_listUsersByServiceUsedCount,
} from "../../helpers/Requesthelper/RequestAdminUserManagement";

const { Option } = Select;
const { confirm } = Modal;

class listAllUsers extends Component {
  state = {
    //pageLoading: true,
    tableLoading: true,
    tableFilterOn: false,
    userList: [],
    userListSortedByService: [],
    pageStart: 0,
    pageEnd: 50,
    pagination: {
      current: 1,
      pageSize: 50,
      total: 0,
    },
    selectedTableRow: {},
    userStatisticsDrawerVisible: false,
    updateUserDrawerVisible: false,
    viewUserProfileDrawerVisible: false,

    // filter states
    userNameToFilter: "",
    filterByName: false,
    serviceUsageFilter: "",
    filterByUsage: false,
  };

  componentDidMount() {
    //this.countTotalUsers()
    this.countTotalUsers().then((totalUserCount) => {
      this.updatePaginationTotal(totalUserCount);
      //console.log(this.state)
      this.renderUsers();
    });
    //this.renderUsers()
  }

  setTableFilterOn = () => {
    this.setState({ tableFilterOn: true });
    this.resetPagination();
  };

  resetTableFilterOn = () => {
    this.setState({ tableFilterOn: false });
    this.resetUserNameToFilter()
    this.resetServiceUsageFilter()
    this.resetUserListFilteredByService()
    this.resetPagination();
    this.countTotalUsers().then((totalUserCount) => {
      this.updatePaginationTotal(totalUserCount);
      //console.log(this.state)
      this.reRenderUsers();
    });
  };

  resetTableData = () => {
    this.setState({
      userList: [],
      userListSortedByService: []
    })
    this.resetUserNameToFilter()
    this.resetServiceUsageFilter()
    this.resetUserListFilteredByService()
    this.resetPagination();
  }

  handleShowUserStatistics = (rowData) => {
    const { userName } = rowData;
    //console.log(rowData)
    this.setState({ selectedTableRow: rowData }, () => {
      //console.log(this.state)
    });
    this.setuserStatisticsDrawerVisible();
  };

  handleUpdateUser = (rowData) => {
    const { userName } = rowData;
    //console.log(rowData)
    this.setState({ selectedTableRow: rowData }, () => {
      //console.log(this.state)
    });
    this.setupdateUserDrawerVisible();
  };

  handleUserProfileView = (rowData) => {
    this.setState({ selectedTableRow: rowData }, () => {
      //console.log(this.state)
    });
    this.setViewUserProfileDrawerVisible();
  }
  updatePaginationTotal = (total) => {
    const { pagination } = this.state;
    this.setState({ pagination: { ...pagination, total: total } });
  };

  resetPagination = () => {
    const { pagination } = this.state;
    this.setState({
      pageStart: 0,
      pageEnd: 50,
      pagination: { ...pagination, current: 1 },
    });
  };

  handlePagination = (tableStatus) => {
    //console.log(tableStatus)
    const { pagination } = this.state;

    const pageStartValue = tableStatus.current * 50 - 50;
    //const pageEndValue = (tableStatus.current * 10) >  tableStatus.total? tableStatus.total : (tableStatus.current * 10)
    const pageEndValue = tableStatus.current * 50;

    //console.log(pageStartValue)
    //console.log(pageEndValue)

    this.setState(
      {
        pageStart: pageStartValue,
        pageEnd: pageEndValue,
        pagination: {
          ...pagination,
          current: tableStatus.current,
        },
      },
      () => {
        this.renderTableBasedOnFilter();
      }
    );
  };

  renderTableBasedOnFilter = () => {
    if (this.state.tableFilterOn) {
      if (this.state.filterByName) {
        this.renderUsersFilteredByName();
      }
      if (this.state.filterByUsage) {
        this.renderUsersFilterdByServiceUsage();
      }
    } else {
      this.reRenderUsers();
    }
  };

  countTotalUsers = () => {
    return new Promise((resolve, reject) => {
      r_countTotalUsers(
        this.props.userDetails.userName,
        this.props.endpoints,
        this.props.auth.userToken
      ).then((res) => {
        //console.log(res.data.value)
        resolve(res.data.value);
      });
    });
  };

  reRenderUsers = () => {
    this.setTableLoading();
    this.renderUsers();
  };

  renderUsersFilteredByName = () => {
    console.log("being the calls to filter users by name");

    this.setTableLoading();

    r_countMatchingUsersByGivenName(
      this.state.userNameToFilter,
      this.props.auth.userToken,
      this.props.endpoints
    ).then((res) => {
      console.log(res);
      const totalCount = res.data.value;

      this.updatePaginationTotal(totalCount);

      r_listMatchingUsersByGivenName(
        this.state.userNameToFilter,
        this.props.auth.userToken,
        this.props.endpoints,
        this.state.pageStart,
        this.state.pageEnd
      ).then((res) => {
        console.log(res);
        const { itemListElement } = res.data;
        //console.log(itemListElement)
        this.setUserList(itemListElement);

        this.resetTableLoading();
      });
    });
  };

  renderUsersFilterdByServiceUsage = () => {
    console.log("being the calls to filter users by serviceName");

    this.setTableLoading();

    r_countUsersByServiceUsed(
      this.state.serviceUsageFilter,
      this.props.auth.userToken,
      this.props.endpoints
    ).then((res) => {
      console.log(res);
      const totalCount = res.data.value;

      this.updatePaginationTotal(totalCount);

      r_listUsersByServiceUsedCount(
        this.state.serviceUsageFilter,
        this.props.auth.userToken,
        this.props.endpoints,
        this.state.pageStart,
        this.state.pageEnd
      ).then((res) => {
        console.log(res);
        const { itemListElement } = res.data;
        //console.log(itemListElement)
        this.setUserListFilteredByService(itemListElement);

        this.resetTableLoading();
      });
    });
  };

  renderUsers = () => {
    r_listAllUsers(
      this.props.userDetails.userName,
      this.props.auth.userToken,
      this.props.endpoints,
      this.state.pageStart,
      this.state.pageEnd
    ).then((res) => {
      //console.log(res)
      const { itemListElement } = res.data;
      //console.log(itemListElement)
      this.setUserList(itemListElement);

      this.resetTableLoading();

      // if(res.status === "success"){
      //   //console.log(res)
      //   const {itemListElement} = res.data
      //   //console.log(itemListElement)
      //   this.setUserList(itemListElement)
      // }else if(res.status === "fail"){
      //   const {message,messageId} = res.data
      //   this.setState({errorMessage:message})
      // }
    });
  };

  setUserNameToFilter = (userName) => {
    this.setState({ filterByName: true, userNameToFilter: userName, userList: [] });
  };
  resetUserNameToFilter = () => {
    this.setState({ filterByName: false, userNameToFilter: "" });
  };

  setServiceUsageFilter = (serviceName) => {
    this.setState({ filterByUsage: true, userListSortedByService: [], serviceUsageFilter: serviceName });
  };
  resetServiceUsageFilter = () => {
    this.setState({ filterByUsage: false, userListSortedByService: [], serviceUsageFilter: "" });
  };
  setViewUserProfileDrawerVisible = () => {
    this.setState({ viewUserProfileDrawerVisible: true });
  };
  resetViewUserProfileDrawerVisible = () => {
    this.renderTableBasedOnFilter();
    this.setState({ viewUserProfileDrawerVisible: false });
  };
  setupdateUserDrawerVisible = () => {
    this.setState({ updateUserDrawerVisible: true });
  };
  resetupdateUserDrawerVisible = () => {
    this.renderTableBasedOnFilter();
    this.setState({ updateUserDrawerVisible: false });
  };
  setuserStatisticsDrawerVisible = () => {
    this.setState({ userStatisticsDrawerVisible: true });
  };
  resetuserStatisticsDrawerVisible = () => {
    this.setState({ userStatisticsDrawerVisible: false });
  };

  setTableLoading = () => {
    this.setState({ tableLoading: true });
  };
  resetTableLoading = () => {
    this.setState({ tableLoading: false });
  };
  setUserList = (users) => {
    console.log(users)
    let totalUserList = [];
    if (users) {
      users.map((item, index) => {
        const {
          approvedStatus,
          createdDate,
          description,
          email,
          name,
          userName,
        } = item;
        const descriptionList = description.split(",");
        const user = {
          key: index,
          name: name,
          email: email,
          username: userName,
          loggedinusing: descriptionList,
          activestatus: approvedStatus ? "VERIFIED" : "NOT VERIFIED",
        };

        //console.log(user.username)
        totalUserList.push(user);
      });
    }

    //console.log(totalUserList)
    this.setState({ userList: totalUserList, pageLoading: false });
  };

  resetUserListFilteredByService = () => {
    this.setState({ userListSortedByService: [] })
  }
  setUserListFilteredByService = (users) => {
    let totalUserList = [];
    if (users) {
      users.map((item, index) => {
        const {
          userName,
          count,
        } = item;
        const user = {
          key: index,
          username: userName,
          servicecount: count,
        };

        //console.log(user.username)
        totalUserList.push(user);
      });
    }

    //console.log(totalUserList)
    this.setState({ userListSortedByService: totalUserList, pageLoading: false });
  };

  render() {
    console.log(this.state);
    return (
      <div>
        {/* {
        this.state.tableLoading?
        <ContainerLoading message="Generating User Table" />: */}
        <RenderUserList
          userList={this.state.userList}
          userListSortedByService={this.state.userListSortedByService}
          {...this.props}
          renderUsers={this.renderUsers}
          setPageLoading={this.setPageLoading}
          resetPageLoading={this.resetPageLoading}
          handlePagination={this.handlePagination}
          pagination={this.state.pagination}
          tableLoading={this.state.tableLoading}
          setTableLoading={this.setTableLoading}
          resetTableLoading={this.resetTableLoading}
          handleShowUserStatistics={this.handleShowUserStatistics}
          handleUpdateUser={this.handleUpdateUser}
          handleUserProfileView={this.handleUserProfileView}
          setTableFilterOn={this.setTableFilterOn}
          resetTableFilterOn={this.resetTableFilterOn}
          resetTableData={this.resetTableData}

          renderUsersFilteredByName={this.renderUsersFilteredByName}
          renderUsersFilterdByServiceUsage={this.renderUsersFilterdByServiceUsage}
          setUserNameToFilter={this.setUserNameToFilter}
          setServiceUsageFilter={this.setServiceUsageFilter}

          filterByServiceUsage={this.state.filterByUsage}
        />


        <UserStatistics
          {...this.props}
          slectedUserRowDetails={this.state.selectedTableRow}
          userStatisticsDrawerVisible={this.state.userStatisticsDrawerVisible}
          setuserStatisticsDrawerVisible={this.setuserStatisticsDrawerVisible}
          resetuserStatisticsDrawerVisible={
            this.resetuserStatisticsDrawerVisible
          }
        />

        <ShowUserProfile
          {...this.props}
          slectedUserRowDetails={this.state.selectedTableRow}
          viewUserProfileDrawerVisible={this.state.viewUserProfileDrawerVisible}
          setViewUserProfileDrawerVisible={this.setViewUserProfileDrawerVisible}
          resetViewUserProfileDrawerVisible={this.resetViewUserProfileDrawerVisible}
        />

        <UpdateUserDetails
          {...this.props}
          slectedUserRowDetails={this.state.selectedTableRow}
          updateUserDrawerVisible={this.state.updateUserDrawerVisible}
          setupdateUserDrawerVisible={this.setupdateUserDrawerVisible}
          resetupdateUserDrawerVisible={this.resetupdateUserDrawerVisible}

        />

        <ComposeEmail
          {...this.props}
          selectedEails={this.state.selectedEmails}
        />



        {/* }  */}
      </div>
    );
  }
}

class RenderUserList extends Component {
  state = {
    selectedRows: [],
    composeEmailDrawerVisible: false,
    selectedEmails: []
  };

  composeEmailDrawerClose = () => { this.setState({ composeEmailDrawerVisible: false }) }
  composeEmailDrawerOpen = () => { this.setState({ composeEmailDrawerVisible: true }) }
  handleComposeEmail = (selectedRows) => {
    this.composeEmailDrawerOpen()

    let tempEmailList = [selectedRows.username]
    this.setState({ selectedEmails: tempEmailList })
    console.log(this.state)
  }
  handleComposeEmailInBulk = () => {
    if (this.state.selectedRows.length >= 1) {
      this.setState({ selectedEmails: this.state.selectedRows })
    }
    this.composeEmailDrawerOpen()

  }

  onSelectChange = (selectedRowKeys, rows) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    console.log('rows changed: ', rows);

    let tempList = []
    rows.map(item => {
      tempList.push(item.username)
    })
    this.setState({ selectedRows: tempList })
    this.setState({ selectedEmails: tempList })
  };

  handleTableChange = (e) => {
    this.props.handlePagination(e);
  };

  onDeleteUserConfirm = (rowData) => {
    //console.log("delete user initiated")

    //this.setState({tableLoading: true})
    this.props.setTableLoading();

    //console.log(rowData)
    //console.log("User Deletion ")
    const { username } = rowData;

    r_deleteUser(
      username,
      this.props.userDetails.userName,
      this.props.endpoints,
      this.props.auth.userToken
    ).then((resp) => {
      //console.log("userDeleted")
      this.props.renderUsers();
      //this.setState({tableLoading: false})
      this.props.resetTableLoading();
    });
  };

  handleUserDelete = (rowData) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Click on OK to delete the user.`,
      onOk: () => {
        this.onDeleteUserConfirm(rowData);
      },
      onCancel() {
        //console.log('Cancel');
        //return false
      },
    });

    // this.setState({tableLoading: true})

    // console.log(rowData)
    // console.log("User Deletion ")
    // const {username} = rowData

    // r_deleteUser (username, this.props.endpoints, this.props.auth.userToken).then(
    //   resp => {
    //     console.log("userDeleted")
    //     this.props.renderUsers()
    //     this.setState({tableLoading: false})
    //   }
    // )
  };

  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // later use this render to show a modal with user info on it
      //render: text => <a>{text}</a>,
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => this.props.handleUserProfileView(record)}>
            {text}
          </a>
        </Space>
      ),
      width: '7%',
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: '15%',
      ellipsis: true
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      width: '15%',
      ellipsis: true
    },
    {
      title: "Account Verified",
      dataIndex: "activestatus",
      key: "activestatus",
      width: '15%',
    },
    {
      title: "Logged In Using",
      key: "loggedinusing",
      dataIndex: "loggedinusing",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = "green";
            if (tag === "GOOGLE") {
              color = "blue";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
      width: '7%',
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => this.handleUserDelete(record)}>
            <Button type="dashed" size="small">
              Delete
            </Button>
          </a>
          <a onClick={() => this.props.handleShowUserStatistics(record)}>
            <Button type="dashed" size="small">
              Statistics
            </Button>
          </a>

          <a onClick={() => this.props.handleUpdateUser(record)}>
            <Button type="dashed" size="small">
              Edit
            </Button>
          </a>

          <a onClick={() => this.handleComposeEmail(record)}>
            <Button type="dashed" size="small">
              Email
            </Button>
          </a>
        </Space>
      ),
    },
  ];

  columnsOfServiceUsed = [
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => this.props.handleUserProfileView(record)}>
            {text}
          </a>
        </Space>
      ),
    },
    {
      title: "Service Count",
      dataIndex: "servicecount",
      key: "servicecount",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => this.handleUserDelete(record)}>
            <Button type="dashed" size="small">
              Delete
            </Button>
          </a>
          <a onClick={() => this.props.handleShowUserStatistics(record)}>
            <Button type="dashed" size="small">
              Statistics
            </Button>
          </a>
          <a onClick={() => this.props.handleUpdateUser(record)}>
            <Button type="dashed" size="small">
              Edit
            </Button>
          </a>

          <a onClick={() => this.handleComposeEmail(record)}>
            <Button type="dashed" size="small">
              Email
            </Button>
          </a>
        </Space>
      ),
    },
  ];


  render() {
    const { selectedRows } = this.state;
    const rowSelection = {
      selectedRows,
      onChange: this.onSelectChange,
    };
    console.log(this.state)



    return (
      <div>
        {/* compose email  */}
        {this.state.composeEmailDrawerVisible &&
          <ComposeEmail
            {...this.props}
            composeEmailDrawerVisible={this.state.composeEmailDrawerVisible}
            composeEmailDrawerClose={this.composeEmailDrawerClose}
            selectedEmails={this.state.selectedEmails}
          />
        }

        <Row>
          <Col span={12} style={{ textAlign: "" }}>
            <p style={{ fontSize: "1.5em" }}>Total Users : <b>{this.props.pagination.total}</b></p>
          </Col>
          <Col span={12}>
            {
              this.state.selectedRows.length >= 1 ? <Button onClick={this.handleComposeEmailInBulk}>Send Email In Bulk</Button> : null
            }
          </Col>
        </Row>
        {/* <Button dashed size="large">Total Users: {this.props.pagination.total}</Button> */}
        {/* <p style={{fontSize:"1.5em"}}>Total Users : <b>{this.props.pagination.total}</b></p> */}

        <UserFilter
          // send filter functions and stuff as props
          {...this.props}
        />

        {this.props.filterByServiceUsage ?
          <Table
            rowSelection={rowSelection}

            columns={this.columnsOfServiceUsed}
            dataSource={this.props.userListSortedByService}
            loading={this.props.tableLoading}
            pagination={this.props.pagination}
            onChange={this.handleTableChange}
          />
          :
          <Table
            rowSelection={rowSelection}

            columns={this.columns}
            dataSource={this.props.userList}
            loading={this.props.tableLoading}
            pagination={this.props.pagination}
            onChange={this.handleTableChange}
          />
        }
        {/* <Table
          columns={this.columns}
          dataSource={this.props.userList}
          loading={this.props.tableLoading}
          pagination={this.props.pagination}
          onChange={this.handleTableChange}
        /> */}
      </div>
    );
  }
}


export default listAllUsers;
