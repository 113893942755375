import React from 'react'

import { Route, Redirect } from 'react-router-dom'

import { connect } from 'react-redux'

const PublicRoute = ({ component: Component, auth, userType, ...rest }) => (
    <Route
        {...rest}

        render={props =>
            !(auth.isAuthenticated) === true ? (
                <Component {...props} />
            ) : (
                userType === "ADMIN" ? <Redirect to="/admin" /> : <Redirect to="/user" />
            )
        }

    />
)


const mapStateToProps = state => ({
    auth: state.auth,
    userType: state.userDetails.userType

})

export default connect(mapStateToProps)(PublicRoute)

