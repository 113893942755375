import React, { Component } from "react";
import { Drawer, Button, Statistic, Card, Row, Col, Spin } from "antd";

import moment from "moment";

import ContainerLoading from "../../../layout/layoutUtils/ContainerLoading";

import {
  r_countGivenServiceUsedByUser,
  r_listServiceUsedHistory,
} from "../../../helpers/Requesthelper/RequestStatistics";

class userStatistics extends Component {
  componentDidMount() {
    //console.log("ooooooooooooooooooooooooooooooooooooo");
  }
  render() {
    return (
      <Drawer
        width={"40%"}
        title="User Statistics"
        placement="right"
        destroyOnClose="true"
        closable={true}
        onClose={this.props.resetuserStatisticsDrawerVisible}
        visible={this.props.userStatisticsDrawerVisible}
      >
        <RenderSpellingStatistics {...this.props} />
        <RenderFontConversionStatistics {...this.props} />
        <RenderDictionarySearchStatistics {...this.props} />
      </Drawer>
    );
  }
}

export default userStatistics;

class RenderSpellingStatistics extends Component {
  state = {
    serviceName: "SPELLSUGGESTION",
    loading: true,
    totalServiceCount: null,
    lastServiceUsed: "NA",
  };

  componentDidMount() {
    this.generateServiceStatisticsForGivenUser(
      this.props.slectedUserRowDetails
    );
    this.lastServiceUsedHistoryOfGivenUser(this.props.slectedUserRowDetails);
  }

  lastServiceUsedHistoryOfGivenUser = (userRow) => {
    r_listServiceUsedHistory(
      userRow.username,
      this.state.serviceName,
      this.props.endpoints,
      this.props.auth.userToken
    ).then((res) => {
      //console.log(res);
      const { itemListElement } = res.data;
      if (itemListElement.length > 0) {
        this.setState({ lastServiceUsed: itemListElement[0].enteredAt });
      }
    });
  };
  generateServiceStatisticsForGivenUser = (userRow) => {
    r_countGivenServiceUsedByUser(
      userRow.username,
      this.state.serviceName,
      this.props.endpoints,
      this.props.auth.userToken
    ).then((res) => {
      //console.log(res);
      const { data } = res;
      this.setState({ totalServiceCount: data.value, loading: false });
    });
  };

  render() {
    const { totalServiceCount } = this.state;
    return (
      <div>
        <Card className="card-with-shadow" >
          <p>
            <b>Spelling Service</b>
          </p>
          {this.state.loading ? (
            <Spin />
          ) : (
            <Row gutter={16}>
              <Col span={24}>
                <Card>
                  <Statistic
                    title="Total Hits"
                    value={totalServiceCount}
                    valueStyle={{ color: "#3f8600" }}
                  />
                  <p>
                    <b>Last Used : </b>
                    {this.state.lastServiceUsed}
                  </p>
                </Card>
              </Col>
            </Row>
          )}
        </Card>
      </div>
    );
  }
}

class RenderFontConversionStatistics extends Component {
  state = {
    serviceName: "FONTCONVERSION",
    loading: true,
    totalServiceCount: null,
    lastServiceUsed: "NA",
  };
  componentDidMount() {
    this.generateServiceStatisticsForGivenUser(
      this.props.slectedUserRowDetails
    );
    this.lastServiceUsedHistoryOfGivenUser(this.props.slectedUserRowDetails);
  }

  lastServiceUsedHistoryOfGivenUser = (userRow) => {
    r_listServiceUsedHistory(
      userRow.username,
      this.state.serviceName,
      this.props.endpoints,
      this.props.auth.userToken
    ).then((res) => {
      //console.log(res);
      const { itemListElement } = res.data;
      if (itemListElement.length > 0) {
        this.setState({ lastServiceUsed: itemListElement[0].enteredAt });
      }
    });
  };
  generateServiceStatisticsForGivenUser = (userRow) => {
    //console.log(userRow);
    r_countGivenServiceUsedByUser(
      userRow.username,
      this.state.serviceName,
      this.props.endpoints,
      this.props.auth.userToken
    ).then((res) => {
      //console.log(res);
      const { data } = res;
      this.setState({ totalServiceCount: data.value, loading: false });
    });
  };

  render() {
    const { totalServiceCount } = this.state;
    return (
      <div>
        <Card className="card-with-shadow" >
          <p>
            <b>Font Conversion Service</b>
          </p>
          {this.state.loading ? (
            <Spin />
          ) : (
            <Row gutter={16}>
              <Col span={24}>
                <Card>
                  <Statistic
                    title="Total Hits"
                    value={totalServiceCount}
                    valueStyle={{ color: "#3f8600" }}
                  />
                  <p>
                    <b>Last Used : </b>
                    {this.state.lastServiceUsed}
                  </p>
                </Card>
              </Col>
            </Row>
          )}
        </Card>
      </div>
    );
  }
}

class RenderDictionarySearchStatistics extends Component {
  state = {
    serviceName: "DICTIONARY",
    loading: true,
    totalServiceCount: null,
    lastServiceUsed: "NA",
  };

  componentDidMount() {
    this.generateServiceStatisticsForGivenUser(
      this.props.slectedUserRowDetails
    );
    this.lastServiceUsedHistoryOfGivenUser(this.props.slectedUserRowDetails);
  }

  lastServiceUsedHistoryOfGivenUser = (userRow) => {
    r_listServiceUsedHistory(
      userRow.username,
      this.state.serviceName,
      this.props.endpoints,
      this.props.auth.userToken
    ).then((res) => {
      ///console.log(res);
      const { itemListElement } = res.data;
      if (itemListElement.length > 0) {
        this.setState({ lastServiceUsed: itemListElement[0].enteredAt });
      } else {
        //console.log("list sano cha");
        this.setState({ lastServiceUsed: "NA" });
      }
    });
  };

  generateServiceStatisticsForGivenUser = (userRow) => {
    r_countGivenServiceUsedByUser(
      userRow.username,
      this.state.serviceName,
      this.props.endpoints,
      this.props.auth.userToken
    ).then((res) => {
      //console.log(res);
      const { data } = res;
      this.setState({ totalServiceCount: data.value, loading: false });
    });
  };

  render() {
    const { totalServiceCount } = this.state;
    return (
      <div>
        <Card className="card-with-shadow" >
          <p>
            <b>Dictionary Search Service</b>
          </p>
          {this.state.loading ? (
            <Spin />
          ) : (
            <Row gutter={16}>
              <Col span={24}>
                <Card>
                  <Statistic
                    title="Total Hits"
                    value={totalServiceCount}
                    valueStyle={{ color: "#3f8600" }}
                  />
                  <p>
                    <b>Last Used : </b>
                    {this.state.lastServiceUsed}
                  </p>
                </Card>
              </Col>
            </Row>
          )}
        </Card>
      </div>
    );
  }
}
