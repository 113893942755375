import React, { Component } from "react";

import SayakLogo from "../../images/sayak_logo.png";

import { Row, Col, Menu, Button, Affix } from "antd";
import { Link, withRouter } from "react-router-dom";
import { NEW_LAYOUT_PATH } from "../../helpers/Constants/path";

const { SubMenu } = Menu;

class NavBarUser extends Component {
  state = {};

  handleLogout = (e) => {
    e.preventDefault();

    const userData = this.props.userDetails.userName;

    //console.log(this.props)

    const appStateData = { message: "Logging Out Please Wait" };
    this.props.appBusySet(appStateData);

    this.props.logOutSayakUser(
      userData,
      this.props.auth.userToken,
      this.props.history,
      this.props.endpoints
    );
  };

  render() {
    return (
      <Affix offsetTop={0}>
        <div id="mySidepanel" class="sidepanel">
          <a
            href="javascript:void(0)"
            class="closebtn"
            onClick={() => {
              window.closeNav();
            }}
          >
            &times;
          </a>
          <div className="logo-section">
            <Link to="/">
              <img src={SayakLogo} className="logo-desktop" />
            </Link>
          </div>

          <div>
            <Menu
              theme=""
              mode="inline"
              //defaultSelectedKeys={['1']}
              defaultOpenKeys={["sub1"]}
              className="sidepanel-antd-menu"
              style={{ borderRight: 0, background: "none" }}
            >
              <Menu.Item key="1">
                <span>Dash Board</span>
                <Link to="/admin" />
              </Menu.Item>

              <SubMenu key="sub1" title={<span>Products</span>}>
                <Menu.Item key="2">
                  <span>नेपाली हिज्जे परिक्षण</span>
                  <Link to="/admin/spelling" />
                </Menu.Item>
                <Menu.Item key="3">
                  <span>फन्ट रूपान्तरण</span>
                  <Link to="/admin/fontConversion" />
                </Menu.Item>
                <Menu.Item key="4">
                  <span>नेपाली शब्दकोश</span>
                  <Link to="/admin/dictSearch" />
                </Menu.Item>
              </SubMenu>

              <SubMenu key="sub3" title={<span>User Management</span>}>
                <Menu.Item key="5">
                  List Users
                  <Link to="/admin/listUsers" />
                </Menu.Item>
              </SubMenu>

              <SubMenu key="sub4" title={<span>Admin Management</span>}>
                <Menu.Item key="6">
                  Add Admin
                  <Link to="/admin/addAdmin" />
                </Menu.Item>
                <Menu.Item key="7">
                  Admin Users
                  <Link to="/admin/listAdminUsers" />
                </Menu.Item>
              </SubMenu>
            </Menu>
          </div>
          <div class="button btn-yellow" onClick={this.handleLogout}>
            <Link to="">Log Out</Link>
          </div>
        </div>
        <button
          class="openbtn"
          onClick={() => {
            window.openNav();
          }}
        >
          &#9776; Menu{" "}
        </button>

        <div className="nav-main">
          <Row
            type="flex"
            justify="space-between"
            align="bottom"
            style={{ marginBottom: "20px" }}
          >
            <Col span={6}>
              <div className="logo-section">
                <Link to={NEW_LAYOUT_PATH}>
                  <img src={SayakLogo} className="logo-desktop" />
                </Link>
              </div>
            </Col>

            <Col span={18} style={{ textAlign: "right" }}>
              <Menu
                mode="horizontal"
                style={{
                  height: "100%",
                  fontWeight: "500",
                  fontSize: "1.2em",
                  background: "transparent",
                  paddingRight: "20px",
                }}
              >
                {/* <Menu.Item>
                    DashBoard
                    <Link to ="/user" />
                </Menu.Item> */}
                <Button
                  type="primary"
                  style={{ marginLeft: "5px" }}
                  className="btn-orange"
                  onClick={this.handleLogout}
                >
                  LogOut
                </Button>
              </Menu>
            </Col>
          </Row>
        </div>
      </Affix>
    );
  }
}

export default NavBarUser;
