import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import OtpInput from "react-otp-input";
import { LeftOutlined } from "@ant-design/icons";

const MobileNumberLoginComponent = () => {
  const phoneValidator = /^[9][6-9]\d{8}$/;

  const [otp, setOtp] = useState("");
  const [buttonDisable, setButtonDisable] = useState(true);
  const [mobileNumber, setMobileNumber] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [isMobileNumberComplete, setIsMobileNumberComplete] = useState(false);

  // const validatePhoneNumber = (_, value) => {
  //   if (value && !phoneValidator.test(value)) {
  //     return Promise.reject(new Error("Please enter valid Mobile Number."));
  //   }
  //   return Promise.resolve();
  // };

  const validatePhoneNumber = (_, value) => {
    if (value && value.length === 10) {
      if (!phoneValidator.test(value)) {
        return Promise.reject(new Error("Please enter a valid Mobile Number."));
      }
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (inputValue) {
      if (inputValue.length === 10) {
        setButtonDisable(false);
        setInputValue("");
      } else {
        setButtonDisable(true);
      }
    }
  }, [inputValue]);

  const handleResendAction = () => {};

  const handleFinish = (value) => {
    const { mobileNumber } = value;
    setMobileNumber(mobileNumber);
    setIsMobileNumberComplete(true);
    console.log(value);
  };
  return (
    <>
      {!isMobileNumberComplete ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Form
            layout="horizontal"
            onValuesChange={(value) => {
              setInputValue(value.mobileNumber);
            }}
            onFinish={handleFinish}
            // disabled={componentDisabled}
          >
            <Form.Item
              label="मोबाइल नम्बर"
              name="mobileNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your Mobile Number!",
                },
                {
                  validator: validatePhoneNumber, // Custom validation function
                },
              ]}
            >
              <Input
                maxLength={10}
                placeholder="मोबाइल नम्बर"
                allowClear
                style={{
                  width: "400px",
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                className="login-button"
                // style={{
                //   width: "400px",
                //   marginTop: "20px",

                // }}
                type="primary"
                htmlType="submit"
                size="large"
                disabled={buttonDisable}
              >
                लगिन
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div>
          <div>
            <Button
              type="text"
              icon={<LeftOutlined />}
              onClick={() => {
                setIsMobileNumberComplete(false);
              }}
            >
              Back
            </Button>
          </div>
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                display: "inline",
                justifyContent: "center",

                marginTop: "20px",
              }}
            >
              <div>
                <h1>प्राप्त पिन कोड राख्नुहोस्</h1>
              </div>
              <div>{mobileNumber}</div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <div className="otp-field">
                <div className="number">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      fontSize: "50px",
                      color: "#000000",
                      // padding: "5px ",
                      height: "50px",
                    }}
                    containerStyle={{ gap: 8 }}
                    shouldAutoFocus
                  />
                </div>
                <p
                  style={{
                    marginTop: "20px",
                  }}
                >
                  तपाईँले  पिन कोड प्राप्त गर्नु भएको छैन ?
                  <NavLink to="" onClick={() => handleResendAction()}>
                    कोड पुनः पठाउनुहोस्
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileNumberLoginComponent;
