import React from "react";
import { Row, Col } from "antd";
import { CopyrightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Footer = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Row
      type="flex"
      justify="center"
      style={{ textAlign: "center", color: "#fff" }}
    >
      <Col span={24}>
        <div
          style={{
            backgroundColor: "#1E1D1D",
            minHeight: "100px",
            paddingTop: "20px",
            display: "flex-column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>
            <CopyrightOutlined />-{getCurrentYear()}|All Rights Reserved
          </p>

          <p>
            <Link to="/termsofuse">Terms Of Use</Link> |{" "}
            <Link to="/privacypolicy">Privacy Policy</Link>
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default Footer;
