import React, { Component } from 'react'

import './util_style.css'

import { message, Button, Spin, Alert } from 'antd';
import { connect } from 'react-redux';


class ContainerLoading extends Component {

    
    render() {
        //console.log(this.props)
        return (
            <div className="layout-util-spinner">   
               <Spin tip=''>
                    <Alert
                    message={this.props.message}
                    description=" "
                    type="info"
                    />
                </Spin>
            </div>
        )
    }
}


export default ContainerLoading