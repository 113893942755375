import React, { Component } from 'react'

import isEmpty from '../../validation/is-empty'
import { updatePageHead } from "../../components/common/UpdateMetaAndTitle";

import Sayakimage from './images/yoda.png'

import Aux from '../../components/Hoc/WrapperComponent'

import Loading from '../layoutUtils/Loading'
import NavBar from './NavBar'
import Footer from './Footer'

import LoginWithGoogleAndFacebook from '../layoutUtils/LoginWithGoogleAndFacebook'

import { Form, Input, Button, Row, Col, Alert, message } from 'antd';

import { withRouter, Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { activateUser, requestActivationCode } from '../../store/actions/authAction'
import { appBusyReset, appBusySet } from '../../store/actions/appStateAction'
import { resetAuthErrors } from '../../store/actions/authErrorAction'


class ActivateAfterSignUp extends Component {


  state = {
    email: "",
    activationCode: "",
    errors: {},
  }


  componentDidMount() {
    updatePageHead("Activate Account", "", "");


    this.props.resetAuthErrors()
    this.props.appBusyReset()

  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmit = (e) => {
    //e.preventDefault()

    const UserData = {
      email: this.props.userRegister.email ? this.props.userRegister.email : this.state.email,
      activationCode: this.state.activationCode
    }
    //console.log(verifyUserData, this.props.history)

    const appStateData = { message: "Activating Your Account Please Wait" }
    this.props.appBusySet(appStateData)

    this.props.activateUser(UserData, this.props.history, this.props.endpoints)


  }

  requestAccountActivationCode = (e) => {
    e.preventDefault()
    //reset user Register state of redux


    this.props.resetAuthErrors()

    const UserData = {
      email: this.props.userRegister.email ? this.props.userRegister.email : this.state.email
    }

    const { email } = UserData

    if (!isEmpty(email)) {
      //console.log(UserData)
      const appStateData = { message: "Requesting New Activation Pin" }
      this.props.appBusySet(appStateData)

      this.props.requestActivationCode(UserData, this.props.history, this.props.endpoints)
    }

  }


  render() {
    return (

      <div className="main-wrapper">
        <NavBar />
        {this.props.appState.busy ? <Loading messageSpin="Logging In Please Wait" /> : null}

        <Row type="flex" justify="space-around" align="middle" className="bg-blue-yellow-gradient">
          {/* <Col
      xs={{ span: 22}} 
      sm={{ span: 22}}
      md={{ span: 22}} 
      lg={{ span: 8}}
      xl={{ span: 8}}
      xxl={{ span: 8}}
      >
          
      </Col> */}
          <Col
          // xs={{ span: 22}} 
          // sm={{ span: 22}}
          // md={{ span: 22}} 
          // lg={{ span: 12}}
          // xl={{ span: 12}}
          // xxl={{ span: 12}}
          >

            <div className="container-layout-right-one">
              <div
                style={{
                  padding: "40px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                className="form-container"
              >

                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onFinish={this.onSubmit}
                  style={{ width: "100%" }}
                >

                  {
                    this.props.userRegister.isAccountActivated ?

                      <div style={{ textAlign: "center" }} >
                        <h1 >तपाईंको खाता सक्रिय भएको छ। </h1>
                        <h1>सञ्चालन जारी राख्नका लागि लगिन  गर्नुहोस्।</h1>
                        <div style={{ margin: "10px 0px 10px 0px" }}>
                          <Link to="/login">
                            <Button
                              size="large"
                              className="btn-orange"
                              type="primary"

                            >
                              लगिन गर्नुहोस् ।
                            </Button>
                          </Link>

                          <LoginWithGoogleAndFacebook />
                        </div>
                      </div>
                      :
                      <Aux>
                        <div>
                          <h1 style={{ color: "#fa8c16" }}>खाता सक्रिय सञ्चालनमा ल्याउनुहोस।</h1>
                          <br/>

                          <p style={{ fontSize: "1.1em" }}>तपाईंको पहिचान पुष्टि गरि खाता सक्रिय सञ्चालनमा ल्याउनको लागि एउटा पिनकोड तपाईंको इमेल खाता <strong>{this.props.userRegister.email}</strong> मा पठाइएको छ।</p>

                          <p style={{ fontSize: "1.1em" }}>आफ्नो इमेल खातामा प्राप्त ५ अङ्कको पिनकोड तलको बाकसमा राख्नुहोस्। </p>
                        </div>
                        {this.props.userRegister.resendActivationCode ?
                          <Alert message="पिनकोड तपाईंको इमेल खाता मा पठाइएको छ।" type="success" showIcon
                            style={{ marginBottom: "10px" }} />

                          :
                          null}

                        <Form.Item
                          name="activationCode"
                          label="पहिचान पुष्टि ( पिनकोड ):"
                          rules={[{ required: true, message: 'Please input your Activation code!' }]}
                        >
                          <Input name="activationCode" placeholder="पहिचान पुष्टि ( पिनकोड )" onChange={this.onChange} />
                        </Form.Item>

                        {!isEmpty(this.props.autherrors) ?
                          <Form.Item><Alert message={this.props.autherrors.message} type="error" showIcon /></Form.Item>
                          : null
                        }
                        <br/>


                        <Form.Item wrapperCol={{
                          sm: { span: 24, offset: 0 },
                          xl: { span: 24, offset: 0 },
                        }}>

                          <Button type="primary" htmlType="submit" style={{ marginLeft: "10px" }} className="login-form-button">
                            पहिचान पुष्टि गर्नुहोस्।
                          </Button>

                        </Form.Item>

                        <br />
                        <br />
                        <Form.Item wrapperCol={{
                          sm: { span: 24, offset: 0 },
                          xl: { span: 24, offset: 0 },
                        }}>

                          <Button type="primary" style={{ marginLeft: "10px" }} className="btn-orange-border" onClick={this.requestAccountActivationCode}>
                          पिनकोड फेरी पठाउनुहोस्।
                          </Button>
                        </Form.Item>

                      </Aux>
                  }

                </Form>

              </div>
            </div>

          </Col>
        </Row>
        <Footer />
      </div>

    )
  }
}



const mapStateToProps = (state) => ({
  userRegister: state.auth.userRegister,
  autherrors: state.authErrors,
  appState: state.appState,
  endpoints: state.apiConstants

})

export default connect(mapStateToProps,
  {
    activateUser,
    resetAuthErrors,
    appBusySet,
    appBusyReset,
    requestActivationCode

  })(withRouter(ActivateAfterSignUp))