import { Alert, Button, Card, Divider, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import LoginWithGoogleAndFacebook from "../../layoutUtils/LoginWithGoogleAndFacebook";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { signUp } from "../../../utils/axiosUtils/MutationHelper";
import { MUTATION_END_POINT } from "../../../utils/Constants";
import { signUpRequest } from "../../../utils/axiosUtils/AxiosUtils";
import { useHistory } from "react-router-dom";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../utils/Notification";
import { toast } from "react-toastify";

const SignUp = (props) => {
  const { signUpCallBack, mobileNumber, errorMessage } = props;

  const [form] = Form.useForm();

  const phoneValidator = /^[9][6-9]\d{8}$/;

  const [buttonDisable, setButtonDisable] = useState(true);
  const [isCloseErrorMessage, setIsCloseErrorMessage] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  // const validatePhoneNumber = (_, value) => {
  //   if (value && !phoneValidator.test(value)) {
  //     return Promise.reject(new Error("Please enter valid Mobile Number."));
  //   }
  //   return Promise.resolve();
  // };

  const validatePhoneNumber = (_, value) => {
    if (value && value.length === 10) {
      if (!phoneValidator.test(value)) {
        return Promise.reject(new Error("Please enter a valid Mobile Number."));
      }
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (inputValue) {
      if (inputValue.length === 10) {
        setButtonDisable(false);
        setInputValue("");
      } else {
        setButtonDisable(true);
      }
    }
  }, [inputValue]);

  useEffect(() => {
    if (mobileNumber && errorMessage) {
      form.setFieldsValue({
        mobileNumber: mobileNumber,
      });
      setInputValue(mobileNumber);
    }
  }, [mobileNumber, errorMessage]);

  const handleResendAction = () => {};

  const handleChange = (event) => {
    // console.log(event.target.value.length);
    if (event.target.value.length > 0) {
      setIsCloseErrorMessage(true);
    }
  };

  const handleFinish = (value) => {
    const { mobileNumber, name } = value;
    // setMobileNumber(mobileNumber);
    // setIsMobileNumberComplete(true);
    // window.location.reload();

    if (mobileNumber && name) {
      const mutationData = signUp(mobileNumber, name);
      const endPoint = MUTATION_END_POINT.SIGN_UP;
      setIsLoading(true);
      signUpRequest(mutationData, endPoint)
        .then((response) => {
          const { status, message } = response;
          // console.log(response);
          if (status === "success") {
            // showSuccessNotification("", message);
            toast(
              "हिज्जे प्रणालीमा दर्ता गर्नुभएकोमा धन्यवाद ! आफ्नो मोबाइलमा प्राप्त पिन कोड राखी लागिन भएर तपाईँ हिज्जे सेवाको लाभ उठाउन सक्नुहुनेछ । ",
              {
                theme: "light",
                className: "custom-toast",
              }
            );
            setIsLoading(false);
            // history.push("/login");
            // window.location.reload();
            signUpCallBack(mobileNumber);
          }
        })
        .catch((error) => {
          console.log(error);
          //   showErrorNotification("Error", error);
          if (error === "409: User exists with same username") {
            toast(
              "तपाईँको मोबाइल नम्बर हिज्जेमा पहिलेनै दर्ता भइसकेको छ । कृपया लागिन गरेर हिज्जेको सेवा सुचारू गर्नुहोस् ।",
              {
                theme: "black",
                className: "custom-toast",
              }
            );
          } else {
            toast(error, {
              theme: "light",
              className: "custom-toast",
            });
          }
          // showErrorNotification("Error", error);
          setIsLoading(false);
        });
    }

    // signUpCallBack(mobileNumber);

    // console.log(value);
  };
  return (
    <Card>
      <div style={{ minHeight: "30vh", margin: "auto" }}>
        {/* <div className="login-container"> */}
        <GoogleOAuthProvider clientId="93712308965-12kfjhu6h0hcelejdosqadsv2kfu1fik.apps.googleusercontent.com">
          <LoginWithGoogleAndFacebook />
        </GoogleOAuthProvider>{" "}
        <br />
        {/* <h2 style={{ textAlign: "center", color: "#fa8c16" }}>-अथवा-</h2> */}
        <Divider
          style={{
            padding: "0px",
            margin: "0px",
            color: "#fa8c16",
          }}
        >
          -अथवा-
        </Divider>
        {!isCloseErrorMessage ? (
          errorMessage ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ textAlign: "justify" }}
                closable
                message={errorMessage}
              />
            </div>
          ) : null
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Form
            form={form}
            layout="horizontal"
            onValuesChange={(value) => {
              setInputValue(value.mobileNumber);
            }}
            onFinish={handleFinish}
            // disabled={componentDisabled}
          >
            <Form.Item
              label="पुरा नाम"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your Full Name!",
                },
              ]}
            >
              <Input
                placeholder="पुरा नाम"
                allowClear
                style={{
                  width: "350px",
                }}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item
              label="मोबाइल नम्बर"
              name="mobileNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your Mobile Number!",
                },
                {
                  validator: validatePhoneNumber, // Custom validation function
                },
              ]}
            >
              <Input
                maxLength={10}
                placeholder="मोबाइल नम्बर"
                allowClear
                style={{
                  width: "350px",
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                className="login-button"
                style={{
                  width: "350px",
                  marginTop: "20px",
                }}
                loading={isLoading}
                type="primary"
                htmlType="submit"
                size="large"
                disabled={buttonDisable}
              >
                दर्ता गर्नुहोस्
              </Button>
            </Form.Item>
          </Form>
        </div>
        {/* </div> */}
      </div>
    </Card>
    // <div>
    //   <Card>
    //     <LoginWithGoogleAndFacebook />
    //     <br />
    //     <Divider
    //       style={{
    //         padding: "0px",
    //         margin: "0px",
    //         color: "#fa8c16",
    //       }}
    //     >
    //       -अथवा-
    //     </Divider>

    //   </Card>
    // </div>
  );
};

export default SignUp;
