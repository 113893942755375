
import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


import authReducer from './authReducer'
import errorReducer from './authErrorReducer'
import appStateReducer from './appStateReducer'
import apiCallConstantsReducer from './apiCallConstantsReducer'
import userDetailsReducer from './userDetailsReducer'
import adminReducer from './adminReducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'userDetails']
}

const rootReducer = combineReducers({
    auth: authReducer,
    authErrors: errorReducer,
    appState: appStateReducer,
    apiConstants: apiCallConstantsReducer,
    userDetails: userDetailsReducer,
    admin: adminReducer
})

export default persistReducer(persistConfig,rootReducer)

// export default combineReducers({
//     auth: authReducer,
//     authErrors: errorReducer,
//     appState: appStateReducer,
//     apiConstants: apiCallConstantsReducer
// })