import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./store/store";

import { HashRouter as Router } from "react-router-dom";
// import "./tailwind.css"; // Import Tailwind CSS
// import "./tailwind.css";
// import "./index.css";

// import "nepalispell-build/environmentSetup.js";
// import "nepalispell-build/4.5.10/full-all/ckeditor.js";
// import "nepalispell-build/npspell.ckeditor/jslib/np_plugin.js";

import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
