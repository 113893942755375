import React, { Component } from "react";
import { GoogleApiWrapper } from "google-maps-react";

class GoogleMapICT extends Component {
  render() {
    return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.0160916536047!2d85.31968927492223!3d27.685897526438204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19b798e074b1%3A0x4eaa9c1341f18c2a!2sIntegrated%20ICT%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1721891583706!5m2!1sen!2snp"
        width="500"
        height="200"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCORX-2mKFYEln9GxaSiikIt7VazEnsG3E",
})(GoogleMapICT);
