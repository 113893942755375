// import { QUERY_CONFIG } from "./QueryHelperConfig";

import { QUERY_CONFIG } from "./QueryHelperConfig";

export const CONTEXT = "http://semantro.com/";
export const SCHEMA_ORG_CONTEXT = "http://schema.org/";

export function signIn(phoneNumber, masterPin) {
  const { signIn } = QUERY_CONFIG;

  const data = Object.assign({}, signIn, {
    ...signIn.phoneNumber,
    phoneNumber,
    ...signIn.masterPin,
    masterPin,
  });

  return data;
}

export function signUp(phoneNumber, fullName) {
  const { signUp } = QUERY_CONFIG;

  const data = Object.assign({}, signUp, {
    ...signUp.phoneNumber,
    phoneNumber,
    ...signUp.fullName,
    fullName,
  });

  return data;
}

export function resetMpin(phoneNumber) {
  const { resetMpin } = QUERY_CONFIG;

  const data = Object.assign({}, resetMpin, {
    ...resetMpin.phoneNumber,
    phoneNumber,
  });

  return data;
}

export function checkMobileNumber(phoneNumber) {
  const { checkMobileNumber } = QUERY_CONFIG;

  const data = Object.assign({}, checkMobileNumber, {
    ...checkMobileNumber.phoneNumber,
    phoneNumber,
  });

  return data;
}

export function createContact({ name, objective, message, ...restdata }) {
  const { createContact } = QUERY_CONFIG;

  const data = Object.assign({}, createContact, {
    ...createContact.name,
    name,
    ...createContact.objective,
    objective,
    ...createContact.message,
    message,
    ...restdata,
  });

  return data;
}
