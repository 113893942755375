import React, { Component } from 'react'

import isEmpty from '../../validation/is-empty'

import {
    r_AddAdminUser
} from '../../helpers/Requesthelper/RequestAdminUserManagement'

import { Form, Input, Button, Checkbox, Row, Col, Alert, Spin, Card } from 'antd';


export default class AddAdminUser extends Component {
    state = {
        errors : {},
        adminAddedSuccess : false,
        formBusy: false,

    }

    formRef = React.createRef();
    
    formReset = () => {
        this.formRef.current.resetFields();
      };



    onFinish = (e) => {
        this.setState({formBusy:true, errors: {}, adminAddedSuccess: false})
        //console.log(e)

        const adminUser = this.props.userDetails.userName

        r_AddAdminUser(adminUser, e, this.props.endpoints, this.props.auth.userToken).then(res => {

            if(res.data.status === "success"){

                this.setState({adminAddedSuccess:true, formBusy: false, }) 
                this.formReset()

            }else if (res.data.status === "fail"){
                const {message, messageId} = res.data
                const errorData = {message, messageId}
                this.setState({errors : errorData, formBusy: false})
                
            }
        })
    }

    onFinishFailed = (e) => {
        //console.log(e)
    }

    render() {

        const layout = {
            labelCol: {
              span: 4,
            },
            wrapperCol: {
              span: 20,
            },
          };
        const tailLayout = {
        wrapperCol: {
            offset: 4,
            span: 4,
        },
        };
        const tailLayoutMessage = {
        wrapperCol: {
            offset: 4,
            span: 20,
        },
        }

        return (
            <div>
                <Row type="flex" justify="space-around" align="middle">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                >
                        <Card className="card-box-shadow-default">
                        <h2 style={{marginBottom:"10px", textAlign:"center"}}>Add Admin User</h2>
                        <Form
                        ref={this.formRef}
                        {...layout}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        >
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                            {
                                required: true,
                                message: 'Please input name!',
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                            {
                                required: true,
                                message: 'Please input email!',
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="userPassword"
                            rules={[
                            {
                                required: true,
                                message: 'Please input password!',
                            },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        {!isEmpty(this.state.errors) ?
                            <Form.Item {...tailLayoutMessage}><Alert message={this.state.errors.message} type="error" showIcon style={{widt:"100%"}}/></Form.Item> 
                            :null
                        }
                        {this.state.formBusy ?
                            <Form.Item {...tailLayoutMessage}><Spin /></Form.Item>
                            :null
                        }
                        {this.state.adminAddedSuccess ?
                            <Form.Item {...tailLayoutMessage}>
                               <Alert message= "User created Successfully" />
                            </Form.Item> 
                            :null
                        }
                        <Form.Item {...tailLayout} >
                            <Button type="primary" htmlType="submit">
                            Submit
                            </Button>
                        </Form.Item>
                        </Form> 
                        
                        </Card>

                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>

            </div>
        )
    }
}
