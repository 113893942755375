import {
  Button,
  Card,
  Carousel,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Upload,
} from "antd";
import React, { useState } from "react";
import { createContact } from "../../../utils/axiosUtils/MutationHelper";
import { MUTATION_END_POINT } from "../../../utils/Constants";
import { mutateValueWithoutFile } from "../../../utils/axiosUtils/AxiosUtils";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../utils/Notification";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { TextArea } = Input;
const { Option } = Select;

function ContactForm(props) {
  const [form] = Form.useForm();
  const [loadingIcon, setLoadingIcon] = useState(false);

  const options = [
    {
      value: "हिज्जे परिक्षण सम्बन्धी",
      label: "हिज्जे परिक्षण सम्बन्धी",
    },
    {
      value: "फन्ट रूपान्तरण सम्बन्धी",
      label: "फन्ट रूपान्तरण सम्बन्धी",
    },
    {
      value: "फन्ट टाइपिङ्ग सम्बन्धी",
      label: "फन्ट टाइपिङ्ग सम्बन्धी",
    },
    {
      value: "शब्दकोश सम्बन्धी",
      label: "शब्दकोश सम्बन्धी",
    },

    {
      value: "दर्ता वा लागिन सम्बन्धी",
      label: "दर्ता वा लागिन सम्बन्धी",
    },
    {
      value: "अन्य हिज्जेको सेवा सम्बन्धी",
      label: "अन्य हिज्जेको सेवा सम्बन्धी",
    },
    {
      value: "अन्य सुझाव वा प्रतिक्रिया",
      label: "अन्य सुझाव वा प्रतिक्रिया",
    },
  ];
  const onFinish = (values) => {
    const { name, email, objective, message } = values;

    // const userIdentifier = localStorage.getItem("userIdentifier");

    const mutationData = createContact({
      name,
      objective,
      message,
      email,
    });

    console.log(mutationData);
    setLoadingIcon(true);
    const endPoint = MUTATION_END_POINT.CREATE_CONTACT;

    mutateValueWithoutFile(mutationData, endPoint)
      .then((response) => {
        const { status, message } = response;
        if (status === "success") {
          form.resetFields();
          //   showSuccessNotification(
          //     "",
          //     "प्रतिक्रियाको लागि धन्यवाद ! तपाईंले दिनुभएको प्रतिक्रिया सफलतापूर्वक पेश भएको छ । "
          //   );
          //   toast.success(
          //     "प्रतिक्रियाको लागि धन्यवाद ! तपाईंले दिनुभएको प्रतिक्रिया सफलतापूर्वक पेश भएको छ ।",
          //     {
          //       style: {
          //         backgroundColor: "white", // Custom background color
          //         color: "black", // Text color
          //       },
          //       position: "top-right",
          //       autoClose: 5000,
          //       hideProgressBar: false,
          //       newestOnTop: false,
          //       closeOnClick: true,
          //       rtl: false,
          //       pauseOnFocusLoss: true,
          //       draggable: true,
          //       pauseOnHover: true,
          //       theme: "light",
          //       transition: Bounce,
          //     }
          //   );

          toast(
            "प्रतिक्रियाको लागि धन्यवाद ! तपाईंले दिनुभएको प्रतिक्रिया सफलतापूर्वक पेश भएको छ । ",
            {
              theme: "light",
              className: "custom-toast",
            }
          );
        }
        setLoadingIcon(false);
      })
      .catch((error) => {
        console.log(error);
        showErrorNotification("Error", error);
        setLoadingIcon(false);
      });
  };

  return (
    <>
      <h1
        style={{
          textAlign: "center",
          marginBottom: "10px",
          color: "#f3722c",
          fontSize: "2.5em",
        }}
      >
        हामीलाई सन्देश पठाउनुहोस्।
      </h1>
      <p style={{ textAlign: "center", color: "#000000", fontSize: "1.1em" }}>
        तलको फारम भरेर आफ्ना समस्या, सुझाब तथा जिज्ञासा पठाउनुहोस्।
      </p>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col span={12}>
          <Card>
            <Form layout="vertical" form={form} onFinish={onFinish}>
              {/* <Row gutter={35}>
                <Col xs={24} sm={24}> */}
              <Form.Item
                label="नाम :"
                name="name"
                rules={[
                  {
                    required: "true",
                    message: "Enter Full name.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {/* </Col>

                <Col xs={24} sm={12}> */}
              <Form.Item
                label="इमेल :"
                name="email"
                // rules={[
                //   {
                //     required: "true",
                //     message: "Enter Full name.",
                //   },
                // ]}
              >
                <Input type="email" />
              </Form.Item>

              <Form.Item
                label="विषय"
                name="objective"
                rules={[
                  {
                    required: "true",
                    message: "Enter Notice in Nepali.",
                  },
                  // {
                  //   validator: validateNepali,
                  // },
                ]}
              >
                <Select
                  // onClick={() => {
                  //   listAllNoticeCategory().then((response) => {
                  //     //   console.log("RESPONSE", response);
                  //     setNoticeCategoryOption(response.itemListElement);
                  //   });
                  // }}

                  // onSelect={(value) => {
                  //   // console.log(value);
                  //   setNoticeCategoryIdentifier(value);
                  // }}
                  options={options}
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="विषय छान्नुहोस् "
                />
              </Form.Item>
              {/* </Col>

                <Col span={24}> */}

              <Form.Item
                label="सन्देश :"
                name="message"
                rules={[
                  {
                    required: "true",
                    message: "Enter Message",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  span: 24,
                }}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  //   type="primary"
                  className="btn-orange"
                  style={{
                    fontSize: "1.2em",
                    marginTop: "10px",
                    padding: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  htmlType="submit"
                  loading={loadingIcon}
                >
                  सन्देश पठाउनुहोस्
                </Button>
              </Form.Item>
              {/* </Col> */}
              {/* </Row> */}
            </Form>
          </Card>
        </Col>
      </Row>

      <ToastContainer style={{ width: "700px" }} />
    </>
  );
}

export default ContactForm;
