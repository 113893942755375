function getMeta(metaName) {
  const metas = document.getElementsByTagName("meta");
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute("name") === metaName) {
      return metas[i];
    }
  }
  return "";
}

export function updatePageHead(pageTitle, pageDescription, pageKeyword) {
  const descriptionTag = getMeta("description");
  const keywordTag = getMeta("keywords");

  // if (!pageTitle) {
  //   setTitle(TITLE_SUFFIX);
  //   descriptionTag.content = DESCRIPTION_SUFFIX;
  //   keywordTag.content = KEYWORDS_SUFFIX;
  //   return;
  // }

  setTitle(pageTitle);

  descriptionTag.content = pageDescription + " | " + DESCRIPTION_SUFFIX;
  keywordTag.content = pageKeyword + ", " + KEYWORDS_SUFFIX;
}

export function setTitle(name) {
  window.document.title = name + " | " + TITLE_SUFFIX;
}

const TITLE_SUFFIX = "Sayak - Complete Nepali Language Portal";

const KEYWORDS_SUFFIX =
  "Nepali Spell Checking, Spelling Checking, Preeti to Unicode, Unicode To Preeti, Nepali Font, Nepali Hijje, Nepali Dictionary, Nepali Font Conversion, Nepali Font, Preeti, Type Correct Nepali, Nepali Grammar";

const DESCRIPTION_SUFFIX =
  "Sayak Is a Complete Nepali Language Portal Helping People with Nepali Font Conversion, Nepali Spelling Checker, Nepali Dictionary Search, Preeti To Unicode Conversion, Unicode To Preeti Conversion and Nepali Hijje ";
