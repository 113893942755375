import React, { Component } from "react";

import { Row, Col } from "antd";

class LayoutTermsOfUse extends Component {
    render() {
        return (
            <div className="main-wrapper">


                <Row
                    type="flex"
                    justify="space-around"
                    align="middle"
                    className="bg-blue-yellow-gradient"
                >
                    <Col span={20}>
                        <div
                            style={{
                                minHeight: "80vh",
                                display: "flex",
                                flexDirection: "column",
                                fontSize: "1.1em"
                            }}
                        >

                            <h2 style={{ marginBottom: "20px", textAlign: "center", fontSize: "2.2em", color: "#f3722c" }}>Terms of Service and License Agreement</h2>
                            <br />
                            <hr />
                            <br />
                            <p style={{ textAlign: "justify" }}>Integrated ICT Pvt. Ltd (together with its subsidiaries and other affiliates, “Integrated ICT”), owns and/or operates the web pages available at www.Integratedict.com.np and all sub-domains thereof (collectively, the “Site”). Access to and use of the software (“Software”) and services (“Services”) available on or through the Site (including, without limitation, Spell Checking and autosuggestion system in Nepali, TTF to Unicode font conversion and vice-versa, document content extraction form pdf and image format as well as any future Software or Services provided by Integrated ICT) are governed by this Terms of Service and License Agreement (this “Agreement”).</p>

                            <p style={{ textAlign: "justify" }}>THIS IS A LEGALLY BINDING AGREEMENT. IF YOU DO NOT UNDERSTAND THIS AGREEMENT, OR DO NOT AGREE TO BE BOUND BY IT OR THE PRIVACY POLICY REFERENCED HEREIN, YOU MUST IMMEDIATELY LEAVE THE SITE AND YOU ARE NOT AUTHORIZED TO ACCESS OR USE ANY OF THE SERVICES OR SOFTWARE. BY ACCESSING OR USING THE SITE, SERVICES AND/OR SOFTWARE, YOU CONFIRM THAT YOU ARE AT LEAST 18 YEARS OLD (OR IF YOU ARE BETWEEN 13 AND 17 YEARS OLD, INCLUSIVE, THAT YOU ARE USING THE SITE, SERVICES AND/OR SOFTWARE ONLY WITH THE APPROVAL OF YOUR PARENT OR GUARDIAN), THAT YOU ARE LEGALLY ABLE TO ENTER INTO THIS AGREEMENT, AND THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THIS AGREEMENT.</p>

                            <p style={{ textAlign: "justify" }}>If you are an individual paying subscriber, or are accessing the Site to use a Trial (as defined below) of the Services or Software, or are otherwise browsing the Site, this Agreement is between you, individually, and Integrated ICT. If you are an employee, faculty member or student of a university, school or other entity or organization that has subscribed to the Services and Software (an “Enterprise Subscriber”) and has authorized you to use the Site, Services and Software (an “Authorized User”), this Agreement is an agreement between you and the Enterprise Subscriber, on the one hand, and Integrated ICT, on the other hand, except that if the Enterprise Subscriber has entered into a separate written agreement for the Services and Software, then this Agreement is an agreement between you and Integrated ICT. Integrated ICT may seek recourse against you for any violation of the terms of this Agreement.</p>

                            <p style={{ textAlign: "justify" }}>If you are entering into this Agreement on behalf of an Enterprise Subscriber, you represent that you have the authority to bind the Enterprise Subscriber to this Agreement, in which case references to “you” in this Agreement shall mean the Enterprise Subscriber. If you do not have such authority, you must not accept this Agreement and may not use the Site, Software or Services.</p>

                            <p style={{ textAlign: "justify" }}>THIS AGREEMENT CONTAINS AN ARBITRATION AGREEMENT, WHICH WILL, WITH LIMITED EXCEPTION, REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION. UNDER THE ARBITRATION AGREEMENT, (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST INTEGRATED ICT ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU ARE WAIVING YOUR RIGHT TO A JURY TRIAL AND YOUR RIGHT TO HAVE YOUR CLAIMS DECIDED BY A JUDGE OR JURY.</p>

                            <h3>License</h3>

                            <p style={{ textAlign: "justify" }}>In consideration for your agreement to this Agreement and your payment of all applicable Fees (as defined below), Integrated ICT grants you a personal, limited, non-exclusive, non-sublicensable, non-transferable, revocable license to access and make use of the Site, the Services and the Software, subject to the terms and conditions set forth in this Agreement. If you are an Enterprise Subscriber, you may permit such number of Authorized Users as has been agreed between you and Integrated ICT to access and make use of the Site, Services and Software.</p>

                            <p style={{ textAlign: "justify" }}>You may access and use the Site, Services and Software solely for your own personal purposes and only in accordance with any instruction manuals, user guides and other documentation as made available by Integrated ICT from time to time (“Documentation”).</p>

                            <p style={{ textAlign: "justify" }}>YOU MAY NOT CHECK MORE THAN 300 DOCUMENTS OR 150,000 WORDS IN ANY 30-DAY PERIOD OR 100 DOCUMENTS OR 50,000 WORDS IN ANY 24-HOUR PERIOD are an Enterprise Subscriber, this restriction applies to each of your Authorized Users. In addition, you may not:</p>

                            <ul style={{ paddingLeft: "50px" }}>
                                <li>
                                    (a) copy, modify or create derivative works based on the Site, Services, Software or Documentation, or any portion(s) of any of the foregoing (individually and collectively, “Integrated ICT IP”);</li>
                                <li>(b) distribute, transmit, publish or otherwise disseminate any Integrated ICT IP;</li>
                                <li>(c) download or store any Integrated ICT IP except to the extent explicitly permitted on the Site;</li>
                                <li>(d) transfer to any third party any of your rights under this Agreement;</li>
                                <li>(e) access or use the Services, Software or Integrated ICT IP for the benefit of any third party;</li>
                                <li >
                                    (f) access content or data not intended for you, log onto a server or account that you are not authorized to access, or otherwise violate or attempt to violate any security or authentication feature or measures of the Site, Software or Services;
                                </li>
                                <li>(g) attempt to access or derive the source code or architecture of any Software;</li>
                                <li>(h) attempt to probe, scan or test the vulnerability of the Site, Services and/or Software, or any associated system or network, or to breach any security or authentication feature or measures of the Site, Software or Services, and if you are blocked by Integrated ICT from accessing the Site, Software or Services (including by blocking your IP address), you will not implement any measures to circumvent such blocking (e.g., by masking your IP address or using a proxy IP address);</li>
                                <li>(i) interfere or attempt to interfere with service to any user, host or network, including, without limitation, by means of submitting malicious software or computer code (“Malicious Code”) to the Site or Services, load testing, overloading, “flooding,” “spamming,” “mail bombing,” “crashing,”;</li>
                                <li>(j) email or otherwise transmit any content that (i) infringes any intellectual property or other proprietary rights of any party; (ii) you do not have a right to upload under any law or under contractual or fiduciary relationships; (iii) contains any Malicious Code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; (iv) poses or creates a privacy or security risk to any person or entity; (v) constitutes unsolicited or unauthorized materials; or (vi) is otherwise objectionable;</li>
                                <li>(k) automate access to the Site or the Services, including, without limitation, through the use of APIs, bots, scrapers or other similar devices;</li>
                                <li>(k) automate access to the Site or the Services, including, without limitation, through the use of APIs, bots, scrapers or other similar devices;</li>
                                <li>(l) export or re-export any Integrated ICT IP;</li>
                                <li>(m) use or access any Services, Software or Integrated ICT IP in order to build a competitive product, service or solution;</li>
                                <li>(n) violate any applicable law or regulations in connection with your use of the Site, Services or Software;</li>
                                <li>(o) impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity, including, without limitation, an Enterprise Subscriber; or</li>
                                <li>(p) permit any third party to do any of the foregoing.</li>
                            </ul>

                            <p style={{ textAlign: "justify" }}>Integrated ICT may offer certain Software, including certain interfaces, for download from the Site (“Ancillary Software”). Subject to the other terms and conditions of this Agreement, you may install and use Ancillary Software on computers owned, leased or otherwise controlled by you, solely for your personal use in conjunction with your authorized use of the Services. Upon expiration or termination of this Agreement for any reason, you shall cease any further use of the Ancillary Software and shall promptly destroy all copies thereof in your possession.</p>

                            <p style={{ textAlign: "justify" }}>Certain Services or Software (including Ancillary Software) may be subject to additional limitations, restrictions, terms and/or conditions specific to such Services or Software (“Specific Terms”). In such cases, the applicable Specific Terms will be made available to you and your access to and use of the relevant Services or Software will be contingent upon your acceptance of and compliance with such Specific Terms.</p>

                            <h3>Registration and Security</h3>

                            <p style={{ textAlign: "justify" }}>By completing the registration process for any given Services, you are agreeing to subscribe to the selected Services, subject to the terms and conditions of this Agreement. You agree to provide Integrated ICT with accurate and complete registration information, and to promptly notify Integrated ICT in the event of any changes to any such information. You shall be solely responsible for the security and proper use of all user IDs, passwords or other security devices used in connection with the Site and/or the Services, and shall take all reasonable steps to ensure that they are kept confidential and secure, are used properly and are not disclosed to or used by any other person or entity. You shall immediately inform Integrated ICT if there is any reason to believe that a user ID, password or any other security device issued by Integrated ICT has or is likely to become known to someone not authorized to use it, or is being or is likely to be used in an unauthorized way. Integrated ICT reserves the right (at its sole discretion) to request that you change your password(s) in connection with the Services, and you shall promptly comply with any such request.</p>

                            <p style={{ textAlign: "justify" }}>You are solely responsible for all activity in connection with access to the Site and/or Services through your account or using your password, and for the security of your computer systems, and in no event shall Integrated ICT be liable for any loss or damages relating to such activity.</p>

                            <h3>Enterprise Subscriber Responsibility for Authorized Users</h3>

                            <p style={{ textAlign: "justify" }}>Enterprise Subscribers shall ensure that the total number of Authorized Users does not exceed the maximum number of seats authorized by and purchased from Integrated ICT (each of which seats represents a unique (named) Authorized User). Authorized Users may not transfer (including by way of sublicense, lease, assignment or other transfer, including by operation of law) their seat, user name or right to use the Site, Services or Software to any third party. You, the Enterprise Subscriber, are solely responsible for the way your Authorized Users use the Site, Services and Software, and for ensuring that all of your Authorized Users comply with all of the terms and conditions of this Agreement. Any violation of the terms and/or conditions of this Agreement by an Authorized User shall be deemed to be a violation thereof by you.</p>

                            <h3>Fees, Payment and Trial Memberships</h3>

                            <p style={{ textAlign: "justify" }}>If you have registered to use certain Services and/or Software on a trial basis, then you may use such Services and Software only for noncommercial evaluation purposes during the applicable trial period. In all other cases, unless you are an Enterprise Subscriber or Authorized User thereof, your access to or use of the Services and/or Software shall be contingent upon your payment of all applicable fees as described on the Site from time to time (“Fees”). Enterprise Subscribers shall pay the fees set forth in the invoice(s) rendered by Integrated ICT in accordance with the payment terms set forth therein.</p>

                            <p style={{ textAlign: "justify" }}>Integrated ICT reserves the right to revise its Fees, including by increasing or adding new Fees, at any time on ten (10) days’ notice. Such notice may be sent to you by email to your most recently provided email address or posted on the Site or by any other manner chosen by Integrated ICT in its commercially reasonable discretion. You will be deemed to have received any such notice that is posted on the Site on the day it was posted. Your use of the Services after the ten (10) day notice period constitutes your acceptance of the new or revised Fees. If you do not agree to the revised Fees, you may cancel your subscription by following the “View cancellation instructions” link below.</p>

                            <h3>Continuous membership</h3>

                            <p style={{ textAlign: "justify" }}>To ensure uninterrupted service, all subscriptions to the Site, Software and Services you shall be solely responsible for the renewal of the services form your side.</p>

                            <h3>Free trial</h3>

                            <p style={{ textAlign: "justify" }}>Integrated ICT may offer a free trial membership from time to time with regard to certain Software or Services (a “Trial”). By accessing or using the Site, Services or Software, you agree to the terms of any such Trial and further agree to any changes Integrated ICT may make to such Trial as described in “Changes to Services or Terms” below. If you cancel the Services before the end of the trial period, all your rights to any remaining free trial period will be waived and you will not be eligible to participate in any further Trials, except as allowed by Integrated ICT in its sole discretion.</p>

                            <h3>Access to Services</h3>

                            <p style={{ textAlign: "justify" }}>You are responsible for obtaining and maintaining any equipment and ancillary services needed to connect to or access the Site or otherwise use the Services, including, without limitation, modems, hardware, software, internet service and telecommunications capacity. You shall be solely responsible for ensuring that such equipment and ancillary services are compatible with the Services and Software.</p>

                            <h3>User Content</h3>

                            <p style={{ textAlign: "justify" }}>You are solely responsible for all text, documents or other content or information uploaded, entered or otherwise transmitted by you in connection with your use of the Services and/or Software (“User Content”). User Content includes, among other things, any mistakes contained in the content or information transmitted by you. Integrated ICT has no obligation to monitor any User Content and shall have no liability to you or any other person or entity with respect thereto, including, without limitation, liability with respect to any information (including confidential information) contained in or apparent from any User Content. You warrant, represent and covenant that you own or have a valid and enforceable license to use all User Content, and that no User Content infringes, misappropriates or violates the rights (including, without limitation, any copyrights or other intellectual property rights) of any person or entity or any applicable law, rule or regulation of any government authority of competent jurisdiction. Integrated ICT is not responsible for the loss, corruption or other changes to User Content. Without limiting the foregoing, any feature(s) of the Services and/or Software that may permit you to temporarily save or otherwise store certain User Content is offered for your convenience only and does not guarantee that the User Content will be retrievable. You are solely responsible for saving, storing and otherwise maintaining User Content including by maintaining backup copies of your User Content on appropriate independent systems that do not rely on the Services and/or Software.</p>

                            <p style={{ textAlign: "justify" }}>By uploading or entering any User Content, you give Integrated ICT (and those it works with) a nonexclusive, worldwide, royalty-free and fully-paid, transferable and sublicensable, perpetual, and irrevocable license to copy, store and use your User Content in connection with the provision of the Software and the Services and to improve the algorithms underlying the Software and the Services.</p>

                            <h3>Changes to Services or Terms</h3>

                            <p style={{ textAlign: "justify" }}>Integrated ICT reserves the right at any time to (i) change any information, specifications, features or functions of the Site, Services or Software, including any Trial, (ii) suspend or discontinue, temporarily or permanently, any or all of the Services or any Trial, including the availability of any feature, database or content, or (iii) impose limits on certain features and Services or restrict access to parts or all of the Services, including any Trial, in each case with or without prior notice and without any liability to you or any third party. Integrated ICT will use its commercially reasonable efforts to notify you of changes to the Services and/or Software that, in Integrated ICT’s reasonable opinion, have the effect of materially and adversely diminishing the functionality of the Services to which you have subscribed.</p>

                            <p style={{ textAlign: "justify" }}>Integrated ICT may from time to time update or revise this Agreement. If Integrated ICT updates or revises this Agreement, Integrated ICT will notify you either by email to your most recently provided email address, by posting the updated or revised Terms of Service and End User License Agreement on the Site or by any other manner chosen by Integrated ICT in its commercially reasonable discretion. Your use of the Site, Services or Software following any such update or revision constitutes your agreement to be bound by and comply with this Agreement as updated or revised. You can view the most current Terms of Service and End User License Agreement at https://integratedict.com.np/terms . It is your responsibility to review the Terms of Service and End User License Agreement periodically.</p>

                            <p style={{ textAlign: "justify" }}>If you cancel your subscription within ten (10) days following such notice by Integrated ICT in accordance with the two preceding paragraphs, then Integrated ICT will refund you a pro-rated portion of any pre-paid Fees for the affected Services applicable to the cancelled portion of the pre-paid subscription term, if any.</p>

                            <h3>Links to Third Party Sites</h3>

                            <p style={{ textAlign: "justify" }}>Any links on the Site to third party websites are provided for your convenience only. If you choose to access third party websites or obtain products or services from third parties, you do so entirely at your own risk and such access is between you and such third party. Integrated ICT does not warrant or make any representation regarding the legality, accuracy or authenticity of content presented by such websites or any products or services offered by third parties and shall have no liability for any loss or damages arising from the access or use of such websites, products or services.</p>

                            <h3>Consent to Receive Email</h3>

                            <p style={{ textAlign: "justify" }}>Your registration to use the Site and/or Services constitutes your consent to receive email communications from Integrated ICT, including messages regarding customer service issues and other matters. You may opt not to receive email correspondence, other than technical notifications and email regarding issues related to your account and your use of the Site and Services, at any time by following the link included in the email messages.</p>

                            <h3>Data Collection and Privacy</h3>

                            <p style={{ textAlign: "justify" }}>Integrated ICT does not collect personally identifiable information from you except to the extent you have explicitly given such information to Integrated ICT. Integrated ICT’s information practices are further described in its privacy policy, which is available at: http://hijje.com/privacy-policy or http://integratedict.com.np (the “Privacy Policy”). The Privacy Policy is an integral part of this Agreement and is expressly incorporated by reference, and by entering into this Agreement you agree to (i) all of the terms of the Privacy Policy, and (ii) Integrated ICT’s utilization of data as described in the Privacy Policy is not an actionable breach of your privacy or publicity rights. Integrated ICT may from time to time update or revise the Privacy Policy. If Integrated ICT updates or revises the Privacy Policy, Integrated ICT will notify you either by email to your most recently provided email address, by posting the updated or revised Privacy Policy on the Site or by any other manner chosen by Integrated ICT in its commercially reasonable discretion. Your use of the Site, Services or Software following any such update or revision constitutes your agreement to be bound by and comply with the Privacy Policy as updated or revised.</p>

                            <p style={{ textAlign: "justify" }}>In addition, Integrated ICT may engage third parties to conduct risk control and fraud detection/prevention activities. As part of such engagements, if you initiate a transaction on the Site or through the Services, Integrated ICT may give such third parties access to your pertinent credit card and other personal information. Such third parties may only use such personal information for purposes of performing risk control and fraud detection/prevention activities for us. However, they may also convert such personal information into hashed or encoded representations of such information to be used for statistical and/or fraud prevention purposes. By initiating any such transaction, you hereby consent to the foregoing disclosure and use of your information.</p>

                            <h3>Ownership</h3>

                            <p style={{ textAlign: "justify" }}>All intellectual property rights in and to the User Content are and shall remain your property, and Integrated ICT shall acquire no right of ownership or use with respect to any User Content except in connection with its provision of the Services under this Agreement.</p>

                            <p style={{ textAlign: "justify" }}>All intellectual property rights in and to the Software, Site and Services and other Integrated ICT IP are and shall remain the sole property of Integrated ICT and its affiliates and licensors, as applicable, and you shall acquire no right of ownership or use with respect to any Software or other Integrated ICT IP except as specified in this Agreement. You may from time to time provide Integrated ICT with suggestions, comments, recommendations and/or feedback regarding the Services, the Software and/or Integrated ICT’s related technologies (“Feedback”). Any and all Feedback is and shall be given entirely voluntarily. As between the you, Integrated ICT and, if applicable, the Enterprise Subscriber, all Feedback shall be exclusively owned by Integrated ICT, and you hereby make all assignments necessary to accomplish the foregoing ownership, and as a result Integrated ICT shall be freely entitled to reproduce, prepare derivative works, disclose to third parties, display and perform (publicly or otherwise), sell, lease, license, distribute and otherwise use and exploit any and all such Feedback as it deems appropriate, at its sole discretion, without obligation or liability of any kind to you, the Enterprise Subscriber or any other person or entity.</p>

                            <h3>Indemnity</h3>

                            <p style={{ textAlign: "justify" }}>You shall indemnify, release and hold harmless Integrated ICT and its parents, subsidiaries and affiliates, and each of their respective officers, directors, employees and agents, from and against any loss, liability (including settlements, judgments, fines and penalties) and costs (including reasonable attorney fees, court costs and other litigation expenses) relating to any claim or demand made by any third party due to or arising out of your access to the Site, use of the Services or Software, violation of this Agreement, or infringement of any intellectual property or other right of any person or entity.</p>

                            <h3>Warranty Disclaimers</h3>

                            <p style={{ textAlign: "justify" }}>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, INTEGRATED ICT EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS, EXPRESS OR IMPLIED, REGARDING THE SITE, SERVICES AND SOFTWARE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, MERCHANTABLE QUALITY, FITNESS FOR A PARTICULAR PURPOSE OR NONINFRINGEMENT. YOU AGREE THAT YOUR USE OF THE SITE, SERVICES AND SOFTWARE ARE AT YOUR OWN SOLE RISK AND THAT THE SITE, SERVICES AND ANY SOFTWARE ARE PROVIDED ON AN “AS IS” AND “WITH ALL FAULTS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING, INTEGRATED ICT DOES NOT WARRANT THAT THE OPERATION OF THE SITE, SERVICES AND/OR SOFTWARE WILL MEET YOUR REQUIREMENTS OR WILL BE UNINTERRUPTED OR ERROR-FREE.</p>

                            <h3>Limitation of Liability</h3>

                            <p style={{ textAlign: "justify" }}>IN NO EVENT SHALL INTEGRATED ICT BE LIABLE WITH RESPECT TO THE SITE, SERVICES AND/ OR SOFTWARE FOR (I) ANY AMOUNT IN THE AGGREGATE IN EXCESS OF THE FEES YOU HAVE ACTUALLY PAID TO INTEGRATED ICT DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT(S) GIVING RISE TO SUCH LIABILITY; (II) ANY LOST PROFITS, LOST OR DAMAGED USER CONTENT OR OTHER DATA, OR FAILURE TO MEET ANY DUTY, INCLUDING WITHOUT LIMITATION GOOD FAITH AND REASONABLE CARE; OR (III) ANY INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER.</p>
                            <p style={{ textAlign: "justify" }}>YOU AGREE THAT THIS LIMITATION OF LIABILITY REPRESENTS A REASONABLE ALLOCATION OF RISK AND IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN INTEGRATED ICT AND YOU. YOU UNDERSTAND THAT THE SITE, SERVICES AND SOFTWARE WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS.</p>
                            <p style={{ textAlign: "justify" }}>CERTAIN STATES DO NOT ALLOW THE LIMITATION OF CERTAIN DAMAGES, SO SOME OR ALL OF THIS LIMITATION OF LIABILITY MAY NOT APPLY TO YOU AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>

                            <h3>General Provisions</h3>

                            <p style={{ textAlign: "justify" }}>This Agreement shall be governed by, and construed in accordance with, the laws of the federal democratic republic of Nepal, without regard to any choice of law, conflicts of law or other principles that would result in the applicable of the laws or regulations of any other jurisdiction. Subject to the section titled “Arbitration”, any legal action or proceeding relating to this Agreement shall be instituted in a high court in any federal state. You and Integrated ICT agree to submit to the jurisdiction of, and agree that venue is proper in, these courts in any such legal action or proceeding. This Agreement and the rights and obligations herein are personal to you, and you may not assign or otherwise transfer this Agreement or any of your rights or obligations hereunder, by operation of law or otherwise, without the prior written consent of Integrated ICT. Integrated ICT may freely assign this Agreement.</p>

                            <p style={{ textAlign: "justify" }}>If any one or more of the provisions of this Agreement are for any reason held to be invalid, illegal or unenforceable by a court of competent jurisdiction, the remaining provisions of this Agreement shall be unimpaired and shall remain in full force and effect, and the invalid, illegal or unenforceable provision(s) shall be replaced by a valid, legal and enforceable provision or provisions that comes closest to the intent of the parties underlying the invalid, illegal or unenforceable provision(s).</p>

                            <p style={{ textAlign: "justify" }}>The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.</p>

                            <p style={{ textAlign: "justify" }}>If Integrated ICT is unable to perform any obligation under this Agreement because of any matter beyond its reasonable control, such as lightning, flood, exceptionally severe weather, fire, explosion, war, civil disorder, industrial disputes (whether or not involving employees of Integrated ICT), acts of local or central government or other competent authorities, problems with telecommunications providers, hostile network attacks or other events beyond Integrated ICT’s reasonable control (each, a “Force Majeure Event”), Integrated ICT will have no liability to you for such failure to perform; provided, however, that Integrated ICT shall resume performance promptly upon removal of the circumstances constituting the Force Majeure Event. If any Force Majeure Event continues for more than sixty (60) days, either Integrated ICT or you may terminate this Agreement by delivery of written notice to the other party. You will remain responsible for all Fees incurred through the last day the Services were available.</p>

                            <p style={{ textAlign: "justify" }}>If you and Integrated ICT have executed a separate Subscription Agreement applicable to your access to and use of the Site, Services and/or Software, then the terms and conditions of such Subscription Agreement shall prevail to the extent of any conflict with the terms and conditions of this Agreement. In all other cases, this Agreement constitutes the entire agreement between Integrated ICT and you with respect to its subject matter, and supersedes all prior communications and proposals, whether electronic, oral or written, between Integrated ICT and you. No waiver or modification of any of the provisions of this Agreement shall be binding unless in writing and signed by a duly authorized representative of each party.</p>




                        </div>
                    </Col>
                </Row>


            </div>
        );
    }

};



export default LayoutTermsOfUse