import React, { Component } from "react";
import { Row, Col } from "antd";
import { CopyrightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

class Footer extends Component {
  getCurrentYear() {
    return new Date().getFullYear();
  }

  handleClick = () => {
    const footerSection = document.getElementById("footer-scroll");
    footerSection.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    return (
      <Row
        type="flex"
        justify="center"
        style={{ textAlign: "center", color: "#fff" }}
      >
        <Col span={24}>
          <div
            style={{
              backgroundColor: "#1E1D1D",
              minHeight: "100px",
              fontSize: "1.2em",
              paddingTop: "20px",
              display: "flex-column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="footer-container">
              <div className="footer-sub-container">
                <div className="footer-content">
                  <span
                    style={{ color: "#fff", lineHeight: "3", fontSize: "17px" }}
                  >
                    LEGAL
                  </span>
                  <p>
                    <Link to="/termsofuse" onClick={this.handleClick}>
                      Terms of Use
                    </Link>
                  </p>
                  <p>
                    <Link to="/privacypolicy" onClick={this.handleClick}>
                      Privacy Policy
                    </Link>
                  </p>
                </div>
                <div className="footer-content">
                  <span
                    style={{ color: "#fff", lineHeight: "3", fontSize: "17px" }}
                  >
                    CONTACT
                  </span>

                  <p>
                    <Link to="/faq" onClick={this.handleClick}>
                      सहयोगी प्रश्नोतर
                    </Link>
                  </p>
                  <p>
                    <Link to="/contact" onClick={this.handleClick}>
                      सम्पर्क
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                fontSize: "0.8em",
                paddingBottom: "10px",
                paddingTop: "20px",
              }}
            >
              <p>
                <CopyrightOutlined /> {this.getCurrentYear()}|All Rights
                Reserved
              </p>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default Footer;

// import React, { Component } from 'react'
// import { Row, Col , Menu, Icon, Tabs, Button, Card } from 'antd'

// import { Link } from "react-router-dom";

// class Footer extends Component {
//     render() {
//         return (
//             <Row type="flex" justify="center">
//                 <Col span={24}>
//                     <div
//                          style={{backgroundColor: "#1E1D1D", display:"flex-column", justifyContent:"center", alignItems:"center", color: "#fff"}}
//                     >
//                     <p>(c)-2020- All Rights Reserved</p>
//                     </div>
//                 </Col>
//             </Row>
//         )
//     }
// }

// export default Footer
