import React, { Component } from "react";

import SayakLogo from "../../images/sayak_logo.png";

import Aux from "../../components/Hoc/WrapperComponent";

import { BrowserRouter as Router, Link } from "react-router-dom";

import { Row, Col, Menu, Button, Affix } from "antd";
import { connect } from "react-redux";

class NavBar extends Component {
  render() {
    //console.log("props from navbar" , this.props)
    return (
      <Affix offsetTop={0}>
        <div id="mySidepanel" class="sidepanel">
          <a
            href="javascript:void(0)"
            class="closebtn"
            onClick={() => {
              window.closeNav();
            }}
          >
            &times;
          </a>
          <div className="logo-section">
            <Link to="/">
              <img src={SayakLogo} className="logo-desktop" />
            </Link>
          </div>
          <Menu
            theme=""
            mode="inline"
            // defaultSelectedKeys={['1']}

            defaultOpenKeys={["sub1"]}
            style={{ borderRight: 0, background: "none" }}
          >
            <Menu.Item key="1">
              <span>गृहपृष्ठ</span>
              <Link to="/" />
            </Menu.Item>

            <Menu.Item key="5">
              <span>हाम्रो बारेमा</span>
              <Link to="/about" />
            </Menu.Item>

            <Menu.Item key="6">
              <span>सम्पर्क</span>
              <Link to="/contact" />
            </Menu.Item>

            <Menu.Item key="6">
              <span>सहयोगी प्रश्नोतर</span>
              <Link to="/faq" />
            </Menu.Item>
          </Menu>

          <div class="button btn-yellow">
            <Link to="/login">लगिन</Link>
          </div>
          {/* <div class="button btn-orange"><Link to="/signup">खाता खोल्नुहोस्</Link></div> */}
        </div>
        <button
          class="openbtn"
          onClick={() => {
            window.openNav();
          }}
        >
          &#9776; Menu{" "}
        </button>

        <div className="nav-main">
          <div className="container">
            <Row type="flex" justify="space-between" align="bottom">
              <Col span={6}>
                <div className="logo-section">
                  <Link to="/">
                    <img src={SayakLogo} className="logo-desktop" />
                  </Link>
                </div>
              </Col>
              <Col span={18} style={{ textAlign: "right" }}>
                <Menu
                  mode="horizontal"
                  style={{
                    height: "100%",
                    fontWeight: "500",
                    fontSize: "1.2rem",
                    background: "transparent",
                    paddingRight: "20px",
                  }}
                >
                  {/* <Menu.Item>
                                    <Link to="/">गृहपृष्ठ</Link>
                                </Menu.Item> */}

                  <Menu.Item>
                    <Link to="/about" className="no-decoration">
                      हाम्रो बारेमा
                    </Link>
                  </Menu.Item>

                  <Menu.Item>
                    <Link to="/contact" className="no-decoration">
                      सम्पर्क
                    </Link>
                  </Menu.Item>

                  <Menu.Item>
                    <Link to="/faq" className="no-decoration">
                      सहयोगी प्रश्नोतर
                    </Link>
                  </Menu.Item>

                  {this.props.auth.isAuthenticated ? (
                    <Aux>
                      {this.props.userDetails.userType === "ADMIN" ? (
                        <Button
                          type="primary"
                          style={{ marginLeft: "5px" }}
                          className="btn-yellow"
                        >
                          <Link to="/admin" className="no-decoration">
                            Dash Board
                          </Link>
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          style={{ marginLeft: "5px" }}
                          className="btn-yellow"
                        >
                          <Link to="/user/spelling" className="no-decoration">
                            Dash Board
                          </Link>
                        </Button>
                      )}
                    </Aux>
                  ) : (
                    <Aux>
                      <Button
                        style={{ marginLeft: "5px", fontSize: "1rem" }}
                        className="layout-home-login-btn"
                      >
                        <Link to="/login" className="no-decoration">
                          लगिन
                        </Link>
                      </Button>
                      {/* <Button type="primary" style={{ marginLeft: "5px" }} className="layout-home-signup-btn"><Link to="/signup">खाता खोल्नुहोस्</Link></Button> */}
                    </Aux>
                  )}
                  {/* <Button><Link to="/login">Login</Link></Button>
                    <Button type="primary" style={{marginLeft: "5px"}}><Link to="/signup">Create Free Account</Link></Button> */}
                </Menu>
              </Col>
            </Row>
          </div>
        </div>
      </Affix>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userDetails: state.userDetails,
});

export default connect(mapStateToProps)(NavBar);
