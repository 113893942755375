import { ACTIONS, CONTEXT, TYPES } from "../Constants";

export const QUERY_CONFIG = {
  countUsers: {
    "@context": CONTEXT,
    "@type": TYPES.SAYAK_SEARCH,
    actionName: ACTIONS.COUNT_USERS,
    data: {
      "@context": CONTEXT,
      "@type": TYPES.USER,
      userName: "info@integratedict.com.np",
      alternateName: "USER",
    },
  },

  listUsers: {
    "@context": CONTEXT,
    "@type": TYPES.SAYAK_SEARCH,
    actionName: ACTIONS.LIST_USERS,
    data: {
      "@context": CONTEXT,
      "@type": TYPES.USER,
      userName: "info@integratedict.com.np",
    },
    pageLimit: {
      "@context": CONTEXT,
      "@type": TYPES.PAGE_PROPERTY,
      start: 0,
      end: 10,
    },
  },

  listDictionaryData: {
    offset: 0,
    limit: 10,
  },

  // for mutation starts from herhe
  signIn: {
    phoneNumber: "",
    masterPin: "",
  },
  signUp: {
    phoneNumber: "",
    fullName: "",
  },

  resetMpin: {
    phoneNumber: "",
  },

  checkMobileNumber: {
    phoneNumber: "",
  },

  createContact: {
    name: "",
    email: "",
    objective: "",
    message: "",
  },
};
