import React, {Component} from "react";

import { Row, Col ,Button,  Tabs, Divider, Checkbox  } from "antd"

import { AddUserLinks } from "./LinkArchiveComponents/AddUserLinks";
import { ListLinksOfUser } from "./LinkArchiveComponents/ListLinksOfUser";
import AddUserLinkModal  from "./LinkArchiveComponents/AddUserLinkModal"

import {ListPublicLinks} from './LinkArchiveComponents/ListPublicLinks'

const { TabPane } = Tabs;

export class LinkArchive extends Component {

  state={
    addLinkModalVisible: false,
    // personalArchive: true,
    // publicArchive: false,

  }

  addLinkModalShow=()=>{this.setState({addLinkModalVisible: true})}
  addLinkModalClose=()=>{this.setState({addLinkModalVisible: false})}

  showPersonalArchive = () => {this.setState({personalArchive: true, publicArchive: false})}
  showPublicArchive = () => {this.setState({publicArchive: true, personalArchive: false})}

  reloadArchive = () => {
    this.setState({
      refreshArchive: true
    })
    this.startLoading()
  }

  startLoading = () => {
    setTimeout(()=>{
      this.setState({
        refreshArchive: false
      })
    }, 500)
  }


  render(){

    const operations = <Button onClick={this.reloadArchive}>Refresh</Button>;
    

  return (
    <div>

      <div className="flex-row-justify-space-between" style={{marginBottom:"20px"}}>
        <div>
          <h1 style={{ textAlign: "center" }}> सङ्कलित सामग्रीहरू </h1>
        </div>
        <div>
          <Button onClick={this.addLinkModalShow} type='primary' >सामग्री थप्नुहोस् । </Button>
        </div>
      </div>


      {
        !(this.state.refreshArchive) && 
        <Tabs tabBarExtraContent={operations}>
          <TabPane tab="Personal Archive" key="1">
            <ListLinksOfUser {...this.props} />
          </TabPane>
          <TabPane tab="Public Archive" key="2">
            <ListPublicLinks {...this.props} />
          </TabPane>
       
        </Tabs>
      }
      


      {/* <div className="flex-row-justify-space-between" style={{marginBottom:"20px"}}>
        <div>
          <Button 
            type='primary' 
            disabled={this.state.personalArchive} 
            onClick={this.showPersonalArchive}>
          Personal Archive 
          </Button>

          <Button 
            type='primary' 
            style={{marginLeft: "10px"}} 
            disabled={this.state.publicArchive}  
            onClick={this.showPublicArchive}>
          Public Archive 
          </Button>
        </div>
      </div> */}
      
      <AddUserLinkModal
        {...this.props}
        modalVisible={this.state.addLinkModalVisible}
        onModalClose={this.addLinkModalClose}

        reloadArchive = {this.reloadArchive}
      />
      {/* 
      {
        this.state.personalArchive &&
        <ListLinksOfUser {...this.props} />
      }

      {
        this.state.publicArchive &&
        <ListPublicLinks {...this.props} />
      } */}

    </div>
  )
  };
};
