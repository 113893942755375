import React, { Component } from "react";

import { Drawer, Button, Select, Input, Spin, Alert, Tabs } from 'antd';
import copy from "copy-to-clipboard";
import { CheckCircleTwoTone, CopyOutlined, TranslationOutlined } from "@ant-design/icons";

import './style.css'

const { Option } = Select
const { TextArea } = Input
const { TabPane } = Tabs;

export class TypeInNepali extends Component {
  state = {

  };

  render() {

    return (
      <div>
        {/* <h1 style={{ textAlign: "center" }}>नेपालीमा टाइप गर्नुहोस्।</h1> */}

        <div className="type-in-nepali-container">
          <Tabs >
            <TabPane tab="युनिकोड टाइपिङ" key="1">
              <div style={{ border: "none" }}>
                <TypeInNepaliUnicode />
              </div>
            </TabPane>
            <TabPane tab="फन्ट टाइपिङ ( प्रीति, कान्तिपुर आदि )" key="2">
              <div>
                <TypeInNepaliTTF />
              </div>
            </TabPane>
          </Tabs>
        </div>

      </div>
    );
  }
}



class TypeInNepaliTTF extends Component {

  state = {
    textBoxContent: localStorage.getItem('typeInNepaliTTF'),
    copied: false,
    selectedFont: "preeti"
  }

  componentDidMount() {

  }
  onChangeInput = ({ target: { value } }) => {
    this.setState({ textBoxContent: value });

    localStorage.setItem("typeInNepaliTTF", value)
  }

  showSuccessFullyCopied = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 500);

    return (
      <CheckCircleTwoTone
        twoToneColor="rgb(243, 227, 2)"
      // style={{ marginLeft: "5px", fontSize: "1.4em" }}
      />
    );
  };

  handleCopyText = () => {
    let a = this.state.textBoxContent;
    copy(
      a,
      {
        options: {
          format: "text/plain",
        }
      }
    );
    this.setState({ copied: true });
  };

  handleClearTextArea = () => {
    this.setState({ textBoxContent: "" })

    localStorage.removeItem("typeInNepaliTTF")
  }

  render() {

    const fontList = {
      preeti: "प्रीति",
      aakriti: "आकृति",
      aalekh: "आलेख",
      ganess: "गणेश",
      himalb: "हिमालबी",
      kantipur: "कान्तिपुर",
      navjeevan: "नवजीवन",
      PCSnepali: "पिसिएस",
      shangrila: "साङ्ग्रिला ",
      shreenath: "श्रीनाथ",
      sumodacharya: "सुमुदाचार्य",
    };
    const fonts = [
      "preeti",
      "aakriti",
      "aalekh",
      "ganess",
      "himalb",
      "kantipur",
      "navjeevan",
      "PCSnepali",
      "shangrila",
      "shreenath",
      "sumodacharya",
    ];

    return (
      <div className="type-in-nepali-ttf-container">
        <div className="flex-row-justify-space-between" style={{ marginBottom: "10px" }}>
          <div>
            <Select
              value={this.state.selectedFont}
              showSearch
              size='large'
              style={{ width: 200 }}
              defaultValue="preeti"
              placeholder="फन्ट छन्नुहोस् ।"
              optionFilterProp="children"
              onChange={(e) => {
                this.setState({ selectedFont: e });
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {fonts.map((font, index) => {
                return (
                  <Option key={index} value={font} >
                    {fontList[font]}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div>
            <Button type="primary" shape="round" size="large" onClick={this.handleCopyText}
              icon={this.state.copied ? this.showSuccessFullyCopied() : <CopyOutlined />}
            >
              Copy to Clipboard
            </Button>
            {/* {this.state.copied
              ? this.showSuccessFullyCopied()
              : null} */}
          </div>
          <Button danger dashed onClick={this.handleClearTextArea}>Clear</Button>
        </div>

        <div>
          <Input.TextArea
            value={this.state.textBoxContent}
            placeholder="यस बाकसमा टाइप गर्नुहोस्।"
            className="font-conversion-textarea"
            allowClear
            style={{
              height: "500px",
              fontFamily: `"${this.state.selectedFont}"`,
            }}
            onChange={(e) => { this.onChangeInput(e) }}
          />
        </div>
      </div>
    );
  }
}


const ENG_NEP = {
  1: "१",
  2: "२",
  3: "३",
  4: "४",
  5: "५",
  6: "६",
  7: "७",
  8: "८",
  9: "९",
  0: "०",
}
class TypeInNepaliUnicode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textBoxContent: "",
      copied: false,
    }

  }

  componentDidMount() {
    let transliterationInput = document.getElementById("transliteration");
    const localValue = localStorage.getItem('typeInNepaliUnicode') || ""
    this.setState({
      textBoxContent: localValue
    }, () => {
      transliterationInput.value = this.state.textBoxContent
    })
    if (transliterationInput && window.enableTransliteration) {
      this.handleTransliteration(transliterationInput);
    }
  }

  handleTransliteration = (transliterationInput) => {
    window.enableTransliteration(transliterationInput, "ne");
    transliterationInput.addEventListener('keyup', e => {
      if (transliterationInput.value) {
        if (transliterationInput.value !== this.state.textBoxContent) {
          Object.keys(ENG_NEP).forEach(eng => {
            const index = transliterationInput.value.indexOf(eng)
            {/** CONSISTS OF english DIGIT then replace with nepali digit */ }
            const reg = new RegExp(eng, "g");
            this.setState({
              textBoxContent: index > -1 ? transliterationInput.value.replace(reg, ENG_NEP[eng]) : transliterationInput.value
            }, () => {
              localStorage.setItem("typeInNepaliUnicode", this.state.textBoxContent);
              document.getElementById("transliteration").value = this.state.textBoxContent;
              // CHANGE CURSER POSITION TO ONE CHARACTER AFTER DIGIT ENTERED
              if (index > -1) (e.target.selectionEnd = index + 1);
            })
          })
        } else {
          // console.log("repeated value");
        }
      } else {
        // console.log("no value");
        this.handleClearTextArea();
      }
    })
  }

  showSuccessFullyCopied = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 500);

    return (
      <CheckCircleTwoTone
        twoToneColor="rgb(243, 227, 2)"
      // style={{ marginLeft: "5px", fontSize: "1.4em" }}
      />
    );
  };

  handleCopyText = () => {
    // let a = this.state.textBoxContent;
    //console.log(a)
    let m = document.querySelector("#transliteration")
    let a = m.value
    this.setState({ textBoxContent: a })
    copy(
      a,
      {
        options: {
          format: "text/plain",
        }
      }
    );
    this.setState({ copied: true });
  };

  handleClearTextArea = () => {
    localStorage.setItem('typeInNepaliUnicode', "")
    this.setState({ textBoxContent: "" })
    document.getElementById("transliteration").value = ""
  }

  render() {
    // console.log("Text box content = ", this.state.textBoxContent, "LocalStorage = ", localStorage.getItem('typeInNepaliUnicode'))
    return (
      <div>
        <div className="flex-row-justify-space-between" style={{ marginBottom: "10px" }}>
          <div>
            <Button type="primary" onClick={this.handleCopyText}
              icon={this.state.copied ? this.showSuccessFullyCopied() : <CopyOutlined />}
            >
              Copy to Clipboard
            </Button>
            {/* {this.state.copied
                ? this.showSuccessFullyCopied()
                : null} */}
          </div>
          <Button danger dashed onClick={this.handleClearTextArea}>Clear</Button>
        </div>

        <textarea id="transliteration" placeholder="यस बाकसमा टाइप गर्नुहोस्।"></textarea>

      </div>
    );
  }
}
