import {SET_LOGGED_IN_AS_ADMIN} from "../actions/types";

const initialState = {
  isLoggedInAsAdmin:false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOGGED_IN_AS_ADMIN:
      return {
        ...state,
        isLoggedInAsAdmin: true
      };
    default:
      return state;
  }
}
