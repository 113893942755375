import React, { Component } from "react";

import { Button, Input, Spin, Alert } from "antd";
import copy from "copy-to-clipboard";
import { CheckCircleTwoTone, CopyOutlined } from "@ant-design/icons";

import "./style.css";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { updatePageHead } from "../common/UpdateMetaAndTitle";

// export class TypeInNepali extends Component {
//   state = {

//   };

//   render() {

//     return (
//       <div>
//         {/* <h1 style={{ textAlign: "center" }}>रोमन नेपाली टाइपिंग </h1> */}

//         <div className="type-in-nepali-container">
//           <TypeInNepaliUnicode pasteText={this.props.pasteText} />
//           {/* <Tabs >
//             <TabPane tab="युनिकोड टाइपिङ" key="1">
//               <div style={{border: "none"}}>
//                 <TypeInNepaliUnicode />
//               </div>
//             </TabPane>
//             <TabPane tab="फन्ट टाइपिङ ( प्रीति, कान्तिपुर आदि )" key="2">
//               <div>
//                 <TypeInNepaliTTF />
//               </div>
//             </TabPane>
//           </Tabs> */}
//         </div>

//       </div>
//     );
//   }
// }

// class TypeInNepaliTTF extends Component {

//   state = {
//     textBoxContent: localStorage.getItem('typeInNepaliTTF'),
//     copied: false,
//     selectedFont: "preeti"
//   }

//   componentDidMount() {

//   }
//   onChangeInput = ({ target: { value } }) => {
//     this.setState({ textBoxContent: value });

//     localStorage.setItem("typeInNepaliTTF", value)
//   }

//   showSuccessFullyCopied = () => {
//       setTimeout(() => {
//           this.setState({ copied: false });
//       }, 500);

//       return (
//           <CheckCircleTwoTone
//           twoToneColor="rgb(243, 227, 2)"
//           // style={{ marginLeft: "5px", fontSize: "1.4em" }}
//           />
//       );
//   };

//   handleCopyText = () => {
//       let a = this.state.textBoxContent;
//       copy(
//           a,
//           {options : {
//           format: "text/plain",
//           }}
//           );
//       this.setState({ copied: true });
//   };

//   handleClearTextArea = () => {
//     this.setState({textBoxContent: ""})

//     localStorage.removeItem("typeInNepaliTTF")
//   }

//   render(){

//     const fontList = {
//       preeti: "प्रीति",
//       aakriti: "आकृति",
//       aalekh: "आलेख",
//       ganess: "गणेस",
//       himalb: "हिमालबी",
//       kantipur: "कान्तिपुर",
//       navjeevan: "नवजीवन",
//       PCSnepali: "पिसियस",
//       shangrila: "सांग्रिला",
//       shreenath: "श्रीनाथ",
//       sumodacharya: "सुमुदाचार्य",
//     };
//     const fonts = [
//       "preeti",
//       "aakriti",
//       "aalekh",
//       "ganess",
//       "himalb",
//       "kantipur",
//       "navjeevan",
//       "PCSnepali",
//       "shangrila",
//       "shreenath",
//       "sumodacharya",
//     ];

//     return(
//       <div className="type-in-nepali-ttf-container">
//           <div className="flex-row-justify-space-between" style={{marginBottom: "10px"}}>
//             <div>
//               <Select
//                 value={this.state.selectedFont}
//                 showSearch
//                 size= 'large'
//                 style={{ width: 200 }}
//                 defaultValue = "preeti"
//                 placeholder="फन्ट छन्नुहोस् ।"
//                 optionFilterProp="children"
//                 onChange={(e) => {
//                     this.setState({ selectedFont: e});
//                 }}
//                 filterOption={(input, option) =>
//                     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//                 }
//               >
//                 {fonts.map((font, index) => {
//                 return (
//                     <Option key={index} value={font} >
//                         {fontList[font]}
//                     </Option>
//                 );
//                 })}
//               </Select>
//             </div>
//             <div>
//             <Button  type="primary" shape="round" size="large" onClick={this.handleCopyText}
//             icon= {this.state.copied ? this.showSuccessFullyCopied() : <CopyOutlined />}
//             >
//               Copy to Clipboard
//             </Button>
//             {/* {this.state.copied
//               ? this.showSuccessFullyCopied()
//               : null} */}
//             </div>
//             <Button danger dashed onClick={this.handleClearTextArea}>Clear</Button>
//           </div>

//           <div>
//             <Input.TextArea
//               value={this.state.textBoxContent}
//               placeholder="यस बाकसमा टाइप गर्नुहोस्।"
//               className="font-conversion-textarea"
//               allowClear
//               style={{
//                   height: "500px",
//                   fontFamily: `"${this.state.selectedFont}"`,
//               }}
//               onChange={(e) => {this.onChangeInput(e)}}
//             />
//           </div>
//       </div>
//     );
//   }
// }

const ENG_NEP = {
  1: "१",
  2: "२",
  3: "३",
  4: "४",
  5: "५",
  6: "६",
  7: "७",
  8: "८",
  9: "९",
  0: "०",
};

function getHtmlCOntent(html) {
  // Create a container to parse the HTML content
  const container = document.createElement("div");
  container.innerHTML = html;

  // Get all paragraph elements
  const paragraphs = container.getElementsByTagName("p");

  // Extract text content from each paragraph
  const textArray = Array.from(paragraphs).map((p) => p.textContent);

  // Join the text content with double newlines
  return textArray.join("\n\n");
}

export class ChangeTypeInNepali extends Component {
  state = {
    textBoxContent: "",
    copied: false,
    value: getHtmlCOntent(localStorage.getItem("textarea_value")) || "",
    message: "",
    redirect: false,

    // showPasteBtn: false,
    // pasteData: ''
  };

  componentDidMount() {
    updatePageHead(
      "Type In Nepali",
      "Nepali typing, Romanized Nepali, English to Nepali typing, Uncicode typing, Nepali transliteration, transliterate, Nepali Keyboard, नेपाली टाइप, नेपाली रोमनाइजड, युनिकोड टाइपिङ",
      "Nepali typing, Romanized Nepali, English to Nepali typing, Uncicode typing, Nepali transliteration, transliterate, Nepali Keyboard, नेपाली टाइप, नेपाली रोमनाइजड, युनिकोड टाइपिङ"
    );

    let transliterationInput = document.getElementById("transliteration");

    if (transliterationInput && window.enableTransliteration) {
      transliterationInput.addEventListener("keydown", function (e) {
        localStorage.setItem("textarea_value", transliterationInput.value);
        // console.log(transliterationInput.value)
        // console.log("event fired")
      });
      this.handleTransliteration(transliterationInput);
    }

    const persistedData = getHtmlCOntent(
      localStorage.getItem("textarea_value")
    );
    if (persistedData) {
      document.getElementById("transliteration").value = persistedData;
      // this.setState({ value: persistedData });
      // console.log("storage = ", persistedData);
    }

    setTimeout(() => {
      const textarea = document.getElementById("transliteration");
      if (textarea) {
        textarea.setSelectionRange(
          textarea.value.length,
          textarea.value.length
        );
      }
    }, 100);

    this.timer = null;

    if (localStorage.getItem("textarea_value")) {
      document.getElementById("transliteration").value = getHtmlCOntent(
        localStorage.getItem("textarea_value")
      );
    }
  }

  handleTransliteration = (transliterationInput) => {
    window.enableTransliteration(transliterationInput, "ne");
    transliterationInput.addEventListener("keyup", (e) => {
      if (transliterationInput.value) {
        if (transliterationInput.value !== this.state.textBoxContent) {
          Object.keys(ENG_NEP).forEach((eng) => {
            const index = transliterationInput.value.indexOf(eng);
            {
              /** CONSISTS OF english DIGIT then replace with nepali digit */
            }
            const reg = new RegExp(eng, "g");
            this.setState(
              {
                textBoxContent:
                  index > -1
                    ? transliterationInput.value.replace(reg, ENG_NEP[eng])
                    : transliterationInput.value,
              },
              () => {
                localStorage.setItem(
                  "textarea_value",
                  this.state.textBoxContent
                );
                document.getElementById("transliteration").value =
                  this.state.textBoxContent;
                // CHANGE CURSER POSITION TO ONE CHARACTER AFTER DIGIT ENTERED
                if (index > -1) e.target.selectionEnd = index + 1;
              }
            );
          });
        } else {
          // console.log("repeated value");
        }
      } else {
        // console.log("no value");
        this.handleClearTextArea();
      }
    });
  };

  // componentWillUnmount() {
  //     clearTimeout(this.timer);
  // }

  componentWillReceiveProps(nextProps) {
    // console.log("props changed ", nextProps)
  }

  showSuccessFullyCopied = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 500);

    return (
      <CheckCircleTwoTone
        twoToneColor="rgb(243, 227, 2)"
        // style={{ marginLeft: "5px", fontSize: "1.4em" }}
      />
    );
  };

  generateHtmlContent(text) {
    // Create a container for the content
    const container = document.createElement("div");

    // Split the text into paragraphs based on double newlines
    const paragraphs = text.split("\n\n");

    paragraphs.forEach((paragraph) => {
      // Create a paragraph element
      const p = document.createElement("p");
      p.textContent = paragraph;
      container.appendChild(p);
    });

    // Convert the container's HTML to a string
    return container.innerHTML;
  }

  handleCheckHijjeText = () => {
    let m = document.querySelector("#transliteration");
    let a = m.value;

    copy(a, {
      options: {
        format: "text/html",
      },
    });

    this.setState({ copied: true });
    this.setState({ textBoxContent: a });
    this.setState({ redirect: true });

    // console.log(this.generateHtmlContent(a));

    localStorage.setItem("textarea_value", this.generateHtmlContent(a));
    localStorage.setItem("isCheckHijje", true);

    setTimeout(() => {
      this.props.history.push("/user/spelling");
      this.props.updateStyle({ key: "2" });
      this.props.pasteText(a);
      this.setState({ redirect: false });
    }, 3000);

    // this.setState({ showPasteBtn: true })
  };
  handleCopyText = () => {
    // let a = this.state.textBoxContent;
    //console.log(a)
    let m = document.querySelector("#transliteration");
    let a = m.value;
    this.setState({ textBoxContent: a });
    copy(a, {
      options: {
        format: "text/html",
      },
    });
    this.setState({ copied: true });
  };

  handleChange = (event) => {
    // this.setState({ textBoxContent: event.target.value });
    localStorage.setItem("textarea_value", event.target.value);
    // console.log("localstorage", event.target.value);
    this.setState({ value: event.target.value });
  };

  handleClearTextArea = () => {
    // localStorage.removeItem('typeInNepaliUnicode')
    localStorage.setItem("textarea_value", "");
    this.setState({ textBoxContent: "" });
    // this.setState({ value: "" })
    document.getElementById("transliteration").value = "";
  };

  render() {
    return (
      <div>
        <div className="typeInNepali-heading-btn-style">
          {/* <h1 className="typeInNepali-heading-style">रोमन नेपाली टाइपिङ</h1> */}
          <Alert
            message="हिज्जे परीक्षणको लागि Check hijje मा click गर्नुहोस् ।"
            type="info"
            showIcon
          />
          <div className="typeInNepali-btn-style">
            <Button
              size="large"
              className="hijje-check-btn-style"
              onClick={this.handleCheckHijjeText}
            >
              Check hijje
            </Button>
            <Button
              type="primary"
              onClick={this.handleCopyText}
              size="large"
              icon={
                this.state.copied ? (
                  this.showSuccessFullyCopied()
                ) : (
                  <CopyOutlined />
                )
              }
            >
              Copy to Clipboard
            </Button>
            <Button
              danger
              dashed
              size="large"
              onClick={this.handleClearTextArea}
              style={{ marginLeft: "10px" }}
            >
              Clear
            </Button>
          </div>
        </div>

        {this.state.redirect ? (
          <Spin
            tip="Please wait , you're redirecting to check spelling page..."
            style={{
              height: "45vh",
              backgroundColor: "rgba(227, 226, 210, 0.28)",
            }}
          >
            <div
              className="please-wait-tip-spellingcheck"
              style={{
                zIndex: "999",
                position: "absolute",
                top: "0",
                left: "0",
              }}
            ></div>
          </Spin>
        ) : null}

        <textarea
          id="transliteration"
          placeholder="उदाहरण:- बाकसमा Nepal टाइप गर्नुहोस र परिणाममा नेपाल पाउनुहोस (Nepal => नेपाल )"
          className="typeinnepali-textarea-style"
          style={{
            fontFamily: "unicode",
            fontSize: "20px",
            height: "500px",
          }}

          // value={this.state.value}
          // onChange={this.handleChange}
        >
          {/* {localStorage.getItem('textarea_value')} */}
        </textarea>
      </div>
    );
  }
}
