import React, { Component } from "react";
import { Drawer, Button, Row, Col, Tag, Input } from "antd";

import { r_sendEmailToSelectedUsers } from "../../../helpers/Requesthelper/RequestAdminUserManagement";


import ContainerLoading from "../../../layout/layoutUtils/ContainerLoading";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


class ComposeEmail extends Component {
  
  state = {
    emailSubject: "",
    editorHtml : "",
    theme: "snow",
    emailMessageError : false,
    emailSubjectError: false,
    
    emailSending: false,
    emailSendingSuccess: false,
    emailSendingFailed : false,


  }
  
  componentDidMount() {
  }

  handleChange = (html) => {
    console.log(html)
  	this.setState({ emailMessageError: false, editorHtml: html });
  }
  handleSubjectChange = (e) =>{
    console.log(e.target.value)
    this.setState({emailSubjectError: false, emailSubject: e.target.value})
  }

  handleEmailSend = () => {
    this.setState({
      emailSendingFailed: false,
      emailSendingSuccess: false,
    })
    if(this.state.emailSubject === ""){
      this.setState({emailSubjectError: true})
      return
    }
    if(this.state.editorHtml){
      if(this.props.selectedEmails.length >= 1){

        const emailList = this.props.selectedEmails.toString()
        const subject = this.state.emailSubject
        const messageBody = this.state.editorHtml

        this.setState({emailSending: true})

        r_sendEmailToSelectedUsers(emailList, subject, messageBody, this.props.auth.userToken, this.props.endpoints).then(res=>{
          console.log(res)
          if(res.data.status === "success"){
            this.setState({
              emailSendingSuccess: true,
              emailSubject: "",
              editorHtml: "",
              emailMessageError: false,
              emailSubjectError: false,
              emailSending: false,
            })
          }else{

          }
        })
        
        // this.setState({editorHtml: "", emailSendingSuccess: true})
      } 
    }else {
        this.setState({emailMessageError: true})
    }
  }

  render() {
    let formats = [
      'header', 'font', 'size',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image', 'video'
    ]
    let modules = {
      toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, 
        {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
      ],
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      }
    }
        
    return (
      <Drawer
        width={"40%"}
        title="Write a Email"
        placement="right"
        destroyOnClose="true"
        closable={true}
        onClose={this.props.composeEmailDrawerClose}
        visible={this.props.composeEmailDrawerVisible}
      > 
        {
          this.state.emailSendingFailed ?
          <h1>Email Sending Failed</h1> : 
          null
        }
        {
          this.state.emailSendingSuccess ?
          <h1>Email Sent SuccessFully</h1>:
          <div>
            <Row>
              <Col span={24}>
                <h4>Email Participants : </h4>
                
                {this.props.selectedEmails && this.props.selectedEmails.map( email => 
                <Tag closable={false}>{email}</Tag>
                ) }
                <br />
              </Col>
              
            </Row>
            <br />
            <h4>Email Subject:</h4>
            {this.state.emailSubjectError ? <p style={{color: "red"}}>Please Write Email Subject </p> : null}

            <Input placeholder="Email Subject Here" onChange={ this.handleSubjectChange } /><br />
            <br />
            
            <h4>Message Body: </h4>
            {this.state.emailMessageError ? <p style={{color: "red"}}>Please Write Message </p> : null}
            <ReactQuill 
              theme={this.state.theme}
              onChange={this.handleChange}
              value={this.state.editorHtml}
              modules={modules}
              formats={formats}
              bounds={'.app'}
              placeholder={this.props.placeholder}

              style={{
                height : "250px"
              }}
            />
            <br />

            <div
            style={{height:"50px"}}
            >
              
            </div>
            <Button type="primary" size = "large" onClick={this.handleEmailSend} loading={this.state.emailSending}>Send Email</Button>
          </div>
        }


      </Drawer>
    );
  }
}

export default ComposeEmail;

