import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import RootReducer from "./reducers/RootReducer";
//redux persistor import
import { persistStore } from "redux-persist";
import jwt_decode from 'jwt-decode';

import {logOutNonSayakUser} from './actions/authAction'


const initialState = {};

const checkTokenExpirationMiddleware = ({
  dispatch,
  getState
}) => next => action => {
  const bearerToken = getState().auth.userToken;
  if (bearerToken) {
    const token = bearerToken
    console.log("jwt_decode",token)
    if (jwt_decode(token).exp < Date.now() / 1000) {
      next(action);
      console.log("this user is not valid")
      //dispatch(logOutNonSayakUser());
    }else{
      console.log("the user is validdd")
      //dispatch(logOutNonSayakUser());
    }
  }
  next(action);
};

const middleWare = [thunk];

export const store = createStore(
  RootReducer,
  initialState,
  compose(
    applyMiddleware(...middleWare),
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);
export const persistor = persistStore(store);

export default { store, persistor };
