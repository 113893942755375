import React, { Component } from 'react'

import { Modal, Button, Form, Input, Radio, Row, Col, message, Card, Select  } from 'antd'

import { r_addUserHtmlLink } from '../../../helpers/Requesthelper/RequestLinkArchive'

const {Option} = Select

export default class AddUserLinkModal extends Component {

    state = {
        addingLink: false,
        addLinkOn: true,
        linkAdded: false,
        linkAddFail: false,

        errorData : {}
    }

    handleAddLink=()=> {
        console.log("initiate add link")
    }

    handleAddLink = () => {
        this.setState({ addLinkOn: true, linkAdded: false, linkAddFail: false });
      };
    
    cancelAddLink = () => {
        this.setState({ addLinkOn: false, linkAdded: false, linkAddFail: false });
    };
    
    onFinish = (e) => {
        //console.log(e)
        this.setState({addingLink: true})
        let a = e.linkArchive.definedTags ? e.linkArchive.definedTags.join(' ') : ""
        let b = e.linkArchive.customTags ? e.linkArchive.customTags.split(",").join(' ') : ""

        let c = a + " " + b 
        //console.log(c)

        const linkData = {
            url: e.linkArchive.url,
            visibleTo: e.linkArchive.visibleTo,
            tags: c,
            documentType: "HTML"
        }
        const userData = {
            userName: this.props.userDetails.userName
        }
        const endpoints = this.props.endpoints
        const accessToken = this.props.auth.userToken

        //console.log(linkData)

        r_addUserHtmlLink( linkData, userData, endpoints, accessToken ).then(res=>{
            console.log(res)
            if(res.data.status === "success"){
                this.setState({linkAdded: true , addLinkOn: false, addingLink: false })
                message.success('Link Successfully Added');
                this.props.reloadArchive()
                
            }else{
            this.setState({
                linkAddFail: true,
                addLinkOn: false,
                addingLink: false,
                errorData: res.data 
            })
            message.error('Link Adding Failed');
            }
        })

    }

    addLinkAgain=()=> {
        this.setState({
            addingLink: false,
            addLinkOn: true,
            linkAdded: false,
            linkAddFail: false,
        })
    }

    render() {
        const {modalVisible, onModalClose} = this.props
        const layout={}

        const tagsList = [
            <Option key={1} value="news">news</Option>,
            <Option key={2} value="politics">politics</Option>,
            <Option key={3} value="editorial">editorial</Option>,
            <Option key={4} value="other">other</Option>,

        ];

        
        return (
            <div>
                <Modal
                visible={modalVisible}
                title="सङ्कलित सामग्रीहरू थप्नुहोस् ।"
                closable={false}
                onCancel={onModalClose}
                destroyOnClose={true}
                afterClose={this.handleAddLink}
                footer={[
                    <Button key="back" onClick={onModalClose}>
                        Close
                    </Button>
                ]}
                >
                    {
                    this.state.linkAdded ? 
                    <div>
                        <h1>सामग्री थप सफल भयो ।</h1>
                        <div>
                            <Button onClick={this.addLinkAgain} type='primary'>
                                सामग्री थप्नुहोस् ।
                            </Button>
                        </div>
                    </div>
                    : null 
                    }
                    {
                    this.state.linkAddFail ? 
                    <div>
                        <h1>सामग्री थप असफल भयो। </h1>
                        <p>{this.state.errorData.message}</p>
                        <div>
                            <Button onClick={this.addLinkAgain} type='primary'>
                                सामग्री थप्नुहोस् ।
                            </Button>
                        </div>
                    </div>
                    : null 
                    }

                {this.state.addLinkOn ? (
                    <div>
                        <Card>
                        <Row gutter={20}>
                            <Col span={24}>

                            <Form
                                {...layout}
                                name="nest-messages"
                                onFinish={this.onFinish}
                                layout="vertical"
                            >

                                <Form.Item
                                name={["linkArchive", "url"]}
                                label="URL"
                                rules={[{ 
                                    required: true,
                                    message: "Please Input a url",
                                    }]}
                                >
                                <Input />
                                </Form.Item>

                                <Form.Item
                                name={["linkArchive", "visibleTo"]}
                                label="Visible To"
                                rules={[{ 
                                    required: true,
                                    message: "Please select if you want this url for public or private",
                                    }]}
                                >
                                <Radio.Group
                                    // defaultValue = "PUBLIC"
                                >
                                    <Radio value="PUBLIC">Public</Radio>
                                    <Radio value="PRIVATE">Private</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                    name={["linkArchive", "definedTags"]}
                                    label="Selelct Tags"
                                    rules={[{ 
                                        required: false,
                                        message: "Please select one or more tags",
                                        }]}
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Please select"
                                        //defaultValue={}
                                        >
                                        {tagsList}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                name={["linkArchive", "customTags"]}
                                label="Custom Tags"
                                >
                                    <Input />
                                </Form.Item>



                                <Form.Item>
                                    <Button type="primary" htmlType="submit" disabled={this.state.addingLink}>
                                        Add Link
                                    </Button>
                                </Form.Item>
                            </Form>

                            </Col>
                        </Row>
                        </Card>
                    </div>
                    ) : null}
                </Modal>
            </div>
        )
    }
}
