import {
    SET_API_CALL_CONSTANTS
} from '../actions/types'


export const setApiCallConstants = (data) => {
    //console.log(data)
    return {
        type: SET_API_CALL_CONSTANTS,
        payload : data
    }
}

export const test = () => {
    return {
        type: "test"
    }
}
