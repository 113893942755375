import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {r_listPublicHtmlLink, r_countPublicHtmlLink, r_GetDataForDocumentPreview } from '../../helpers/Requesthelper/RequestLinkArchive'
import {Button, Col, Row, Tag, Card} from 'antd'

import InfiniteScroll from "react-infinite-scroll-component";

import './LinkArchiveStyles.css'

class LinkArchive extends Component {

    state = {
        loadingLinks: true,
        
        linkList: [],
        
        pageStart: 0,
        pageEnd: 10,
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
        scrollData: {
            dataLength: 0,
            hasMore: false,
        }
    }

    componentDidMount() {

        console.log("from link archive component")
        this.countPublicHtmlLinks().then((totalCount) => {
            console.log(totalCount)
            this.updatePaginationTotal(totalCount);
            this.listPublicHtmlLinks()
        });
    }

    updatePaginationTotal = (total) => {
        const { pagination } = this.state;
        const { scrollData } = this.state
        this.setState({ 
            pagination: { 
                ...pagination, 
                total: total 
            }, 
            scrollData: {
                ...scrollData,
                dataLength: Math.ceil(total / this.state.pagination.pageSize),
                hasMore: ((Math.ceil(total / this.state.pagination.pageSize)) > pagination.current),
            }
        });
    };

    countPublicHtmlLinks=()=>{
        const endpoints = this.props.endpoints
        return new Promise((resolve, reject) => {
        r_countPublicHtmlLink(endpoints).then((res) => {
            console.log(res)
            resolve(res.data.value);
          });
        });
    }

    listPublicHtmlLinks=()=>{
        const endpoints = this.props.endpoints
        const pagination = {
            start: this.state.pageStart,
            end: this.state.pageEnd
        }

        r_listPublicHtmlLink(pagination, endpoints).then(res=>{
            if(res.data){
                this.setState({
                    linkList: [...this.state.linkList, ...res.data.itemListElement]
                })
                
            }
        })
    }

    fetchMoreData = () => {
        console.log("lu fetch gar")

        let newPageStart = this.state.pageEnd
        let newPageEnd = this.state.pageEnd + this.state.pagination.pageSize

        const { pagination } = this.state;
        const { scrollData } = this.state

        this.setState({
            pageStart: newPageStart,
            pageEnd : newPageEnd,

            pagination:{
                ...pagination,
                current: pagination.current + 1,
            },
            scrollData:{
                ...scrollData,
                hasMore: (scrollData.dataLength > pagination.current + 1),

            }

        }, ()=> {
            this.listPublicHtmlLinks()
            console.log(this.state)
        })

    }

    render() {

        console.log(this.state)

        return (

        <div className="link-archive-articles-container" >
        <Row gutter={12}>
            
            <InfiniteScroll
            dataLength={100}
            next={this.fetchMoreData}
            hasMore={this.state.scrollData.hasMore}
            loader={<h4>Loading...</h4>}
            height={600}
            endMessage={
                <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
                </p>
            }
            >

                {
                this.state.linkList &&
                this.state.linkList.map(link=>(
                    <Col span={24}>
                        <LinkArchiveLinkPreview linkItem={link} {...this.props}/>
                    </Col>
                ))
                }
            </InfiniteScroll>
        </Row>
        </div>

            
        )
    }
}


export default LinkArchive;

class LinkArchiveLinkPreview extends Component {
    
    state={
        loadingLink: true,
        articleInfo : {},
    }

    componentDidMount(){

        r_GetDataForDocumentPreview(
            this.props.linkItem.url,
            this.props.endpoints,
            ).then(res => {
                console.log(res)
                const {data} = res
                console.log(data)
                const {description, image, topic, url} = data
                this.setState({
                    articleInfo: {
                        topic:topic,
                        image:image,
                        description: description,
                        url: url
                    },
                    loadingLink: false
                })
            })

    }

    render(){
        const {url, dateCreated, identifier, documentType, topic} = this.props.linkItem

        return(
            <div>
                <Card
                loading = {this.state.loadingLink}
                className="article-card-landing"
                hoverable
                // title={this.state.articleInfo.topic}
                // extra={
                //     // <Button type="dashed" danger className="article-info-footer__right__button-two">
                //     //     Edit
                //     // </Button>
                // }
                >
                    <a href={url} target="_blank" className="article-card-landing__article-link"><Row gutter={12}>
                        <Col span={2}>
                            <img src={this.state.articleInfo.image} className="article-card-landing__image" />
                        </Col>
                        <Col span={22} className="padding-10px article-card-landing__description">
                            <h2>{this.state.articleInfo.topic}</h2>
                            {this.state.articleInfo.description && 
                            this.state.articleInfo.description.slice(0,200)} 
                            <a href={url} target="_blank" style={{
                                                                marginLeft:"7px",
                                                                fontSize:".9em",
                                                                fontStyle:"italic"
                                                                }} 
                            >...Read more</a>
                        </Col>
                    </Row></a>
                    <Row>
                        <Col span={24} className="padding-10px" className="article-card-landing-footer" >
                            <div className="article-card-landing-footer__left">
                                Archived On: <span className="bold">{dateCreated}</span> ,
                                {/* Archived By: <span className="bold">"User name here"</span> */}
                                <div className="article-card-landing-footer__tags-container">
                                    {
                                        topic && topic.split(' ').map(tag=>(
                                            <Tag>
                                                {tag}
                                            </Tag>
                                        ))
                                    }
                                </div>
                                
                            </div>
                            <div className="article-card-landing-footer__right">
                                {/* <Button tyle="dashed" primary>
                                    <a href={url} target="_blank" >Read Complete Article</a>
                                </Button> */}
                                {/* <Button type="dashed" danger className="article-info-footer__right__button-two">
                                    Edit
                                </Button> */}
                            </div>
                        </Col>
                    </Row>

                </Card>
            </div>
        )
    }
}