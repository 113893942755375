import React from 'react'

import { Route, Redirect } from 'react-router-dom'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const PrivateRouteUserRegister = ({component: Component , userRegister, ...rest}) => (
        <Route 
        {...rest}
        render = {props => 
            userRegister.email ? (
                <Component {...props} />
            ) : (
                <Redirect to="/login" />
            )
        }
        />
)

PrivateRouteUserRegister.propTypes = {
    auth: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    userRegister: state.auth.userRegister
})

export default connect(mapStateToProps)(PrivateRouteUserRegister)
