import React, { Component } from "react";
import { Card, Table, Select, Input, Col, Row, Spin, Alert } from "antd";

import "../../style.css";

import { r_publicDictionarySearch } from "../../../../helpers/Requesthelper/RequestDictionarySearch";

import { connect } from "react-redux";
import { withRouter, Route, Link, Redirect } from "react-router-dom";

const { Option } = Select;
const Search = Input.Search;

class SearchDictionary extends Component {
  state = {
    wordList: [],
    errorResponse: "",
    loading: false,
    wordNotFound: false,
  };

  componentDidMount() {}

  setWordList = (list) => {
    //list.length > 0 ? console.log("thulo"): console.log("sano")
    if (list.length > 0) {
      this.setState(
        {
          wordList: list,
          errorResponse: "",
          loading: false,
        },
        () => {
          //console.log(this.state)
        }
      );
    } else {
      this.setState({ wordNotFound: true, loading: false });
    }
  };

  //how things should work now
  //first user enters the word and clicks the search button
  //once search button is clicked and state are set , take the necessary data and make the API call
  //once you do the api call take the result back
  //set the state again
  //and once the state is set show the results on the area where the results are to be showed

  //first of all style the area to show the results of the data that has been returned from the api call
  //and then work on it

  searchInDictionary(word) {
    this.setState({ loading: true, wordList: {}, wordNotFound: false });
    console.log("returning the props", this.props);
    //this function is fired whenever user submits a request in the text box of UI
    //prep for the dictionary search call call

    //console.log(this.state)

    //console.log("before calling values", listExactMatches, QUERY_END_POINT)
    r_publicDictionarySearch(word, this.props.endpoints).then((res) => {
      const { itemListElement } = res.data;
      //console.log(itemListElement)
      this.setWordList(itemListElement);
      // const resposeData = res.data
      //this.setWordList(resposeData.itemListElement)
      //this.setDefinitionList(itemListElement)
    });
  }

  render() {
    //initialize some state values so you can render them
    const { errorResponse, wordList, loading, wordNotFound } = this.state;

    return (
      <div>
        <h2 style={{ marginBottom: "20px" }}>नेपाली शब्दकोश</h2>
        <h3>कृपया तपाईंले अर्थ खोजेको शब्द तलको बाकसमा राख्नुहोस्।</h3>
        <br />

        <Search
          id="dictionarySearchTextArea"
          placeholder="Please type in a word "
          enterButton="खोज्नुहोस्।"
          size="large"
          allowClear
          defaultValue="कमल"
          onSearch={(e) => this.searchInDictionary(e)}
        />
        {loading ? <Spin size="large" style={{ marginTop: "30px" }} /> : null}
        {!wordNotFound ? (
          <div
            style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
          >
            {wordList.length
              ? wordList.map((word) => {
                  return <WordCard word={word} />;
                })
              : null}
          </div>
        ) : (
          <Alert
            message="शब्द भेटिएन"
            type="error"
            showIcon
            style={{ marginTop: "30px" }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  endpoints: state.apiConstants,
});

export default connect(mapStateToProps, {})(withRouter(SearchDictionary));

export class WordCard extends Component {
  state = {};

  render() {
    const { word } = this.props;

    const { wordEntry, language, definition, pos, disambiguatingDescription } =
      word || {};

    return (
      <Card
        hoverable
        title={
          <div>
            <b style={{ fontSize: "20px" }}>{wordEntry}</b>
            <br />[{disambiguatingDescription}]
          </div>
        }
        className="dictionary-result-card"
      >
        {pos && pos.itemListElement
          ? pos.itemListElement.map((item, index) => {
              return (
                <p>
                  <b>पदवर्ग : </b>
                  <br /> <span key={index}>{item}</span>
                </p>
              );
            })
          : null}
        {definition && definition.itemListElement
          ? definition.itemListElement.map((item, index) => {
              return (
                <p>
                  <b>परिभाषा : </b>
                  <br /> <span key={index}>{item}</span>
                </p>
              );
            })
          : null}
      </Card>
    );
  }
}

export class WordNotFound extends Component {
  render() {
    return <p>शब्द भेटिएन</p>;
  }
}
