import React, { Component } from 'react'

import Sayakimage from './images/yoda.png'

import { updatePageHead } from "../../components/common/UpdateMetaAndTitle";

import  NavBar from './NavBar'
import Footer from './Footer'

import { Row, Col , Menu,Tabs, Button, Card, Collapse } from 'antd'
import FAQpublic from './layoutComponents/FAQpublic'

const { Panel } = Collapse;
const { SubMenu } = Menu
const { TabPane } = Tabs

export class LayoutFAQ extends Component {
    
    
    componentDidMount(){
        updatePageHead("FAQ", "", "");
    } 

    callback(key) {
        //console.log(key);
    }
      
    text = `
    A dog is a type of domesticated animal.
    Known for its loyalty and faithfulness,
    it can be found as a welcome guest in many households across the world.
    `;

    render(){
    return (
        <div className="main-wrapper">
        <NavBar />

        <Row type="flex" justify="space-around" align="middle" className="bg-blue-yellow-gradient" >
            <Col 
                xs={{ span: 22}} 
                sm={{ span: 22}}
                md={{ span: 22}} 
                lg={{ span: 22}}
                xl={{ span: 22}}
                xxl={{ span: 22}}
                >
                <div style={{minHeight: "80vh", display:"flex", flexDirection:"column"}}>
                    
                    <FAQpublic />

                </div>
            </Col>
           
        </Row>

        <Footer />

        </div>
        

    )
    }
}


