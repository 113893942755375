import ReactGA from "react-ga4";

const initializeGA = () => {
  const measurementId = "G-JL6ZNKDHHJ";
  ReactGA.initialize(measurementId); // Replace with your GA4 Measurement ID
};

const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

const logEvent = (category = "", action = "", label = "") => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

export { initializeGA, logPageView, logEvent };
