import React, { Component, createRef } from "react";

import { Alert, Spin, Input, Button } from "antd";
import {
  CheckCircleTwoTone,
  LoadingOutlined,
  CopyOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import copy from "copy-to-clipboard";
import NepaliTypingDrawer from "./SpellingCheckComponents/NepaliTypingDrawer";

import { r_addServiceUserLog } from "../../helpers/Requesthelper/RequestStatistics";
import { NEW_LAYOUT_PATH } from "../../helpers/Constants/path";
import { updatePageHead } from "../common/UpdateMetaAndTitle";
// import "./spellingcheck.css"

export class ChangeSpellingCheck extends Component {
  state = {
    serviceName: "SPELLSUGGESTION",
    loadingContainer: true,
    spellingTextAreaTextCopied: false,
    nepaliTypingDrawerVisible: false,
    setPasteData: "",
    // setCopiedData: "",
    setSpellCheck: "",
    docxContent: null,
  };

  componentDidMount() {
    this.spinnerShow();

    this.beforeLoadingSpelling();
    updatePageHead(
      "Spelling Suggesion",
      "Nepali spell checking, Spelling checking, Nepali spelling correction, spelling correction, Nepali grammar correction, Nepali hijje correction, Nepali spelling tool, Nepali spelling software, Nepali Hijje, Type Correct Nepali, Nepali Grammar, Hijje check, नेपाली हिज्जे, सायक, नेपली भाषा परीक्षण, हिज्जे परिक्षण, नेपाली व्याकरण",
      "Nepali spell checking, Spelling checking, Nepali spelling correction, spelling correction, Nepali grammar correction, Nepali hijje correction, Nepali spelling tool, Nepali spelling software, Nepali Hijje, Type Correct Nepali, Nepali Grammar, Hijje check, नेपाली हिज्जे, सायक, नेपली भाषा परीक्षण, हिज्जे परिक्षण, नेपाली व्याकरण"
    );
    // this.setState({ isMounted: true });

    // r_addServiceUserLog(
    //     this.props.userDetails.userName,
    //     this.state.serviceName,
    //     this.props.endpoints,
    //     this.props.auth.userToken
    // ).then((res) => {
    //     // console.log("l");

    // });

    // console.log("Did mount");
    // this.setState({ setCopiedData: `<span class="Arial" style="font-size:18px">${this.props.data}</span>`});
    // console.log("mount huna sath data =", this.props.data);
  }

  // componentDidUpdate(prevProps) {
  //     if (this.state.isMounted && this.props.data !== prevProps.data) {
  //         this.setState({
  //             setCopiedData: `<span class="Arial" style="font-size:18px">${this.props.data}</span>`
  //         });
  //     }
  // }

  // componentWillUnmount() {
  //     // fix Warning: Can't perform a React state update on an unmounted component
  //     this.setState = (state, callback) => {
  //         return;
  //     };
  // }

  spinnerShow = () => {
    setTimeout(() => {
      this.setState({ loadingContainer: false });
      if (document.querySelector("#spelling-check-bottom-instruction")) {
        document.querySelector(
          "#spelling-check-bottom-instruction"
        ).style.marginTop = "5px";
      }
    }, 1500);
  };

  beforeLoadingSpelling = () => {
    setTimeout(() => {
      // this.setState({ loadingContainer: false }, () => {
      //   console.log("spelling container loaded ");
      // });
      this.loadSpellingEditor()
        .then((message) => {
          // this.setEditorData(this.state.setCopiedData);
          this.setEditorData(
            `<span class="Arial" style="font-size:18px">${this.props.data}</span>`
          );
          window.CKEDITOR.instances["editor"].fire("key", {
            keyCode: 32,
            domEvent: new window.CKEDITOR.dom.event("keydown", {
              keyCode: 32,
              which: 32,
            }),
          });
        })
        .catch((error) => {
          // console.log(error);
        });
    }, 500);
  };

  handleExport2Word = (element, filename = "") => {
    // console.log(localStorage.getItem("spellingCheckText"));
    // console.log(document.getElementById(element));
    // var data = localStorage.getItem("spellingCheckText");
    var preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    // var html = preHtml + (document.getElementById(element) ? document.getElementById(element).innerHTML : '') + postHtml;
    var html =
      preHtml + window.CKEDITOR.instances["editor"].getData() + postHtml;
    var blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });

    // Specify link url
    var url =
      "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

    // Specify file name
    filename = filename ? filename + ".doc" : "document.doc";

    // Create download link element
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  };

  // onEditorChange = (event) => {
  //     const newData = window.CKEDITOR.instances["editor"].getData();

  //     this.setState({
  //         setPasteData: newData
  //     })

  //     // this.setEditorData(newData)
  //     // console.log("editor instance = ", window.CKEDITOR.instances["editor"]);

  //     window.npspell.ckeditor("editor", {
  //         dictionary: window.NP_UTILS.DICTIONARY_LANGUAGE,
  //         server: window.NP_UTILS.SERVER_NAME,
  //     });
  //     console.log(this.state.setPasteData);
  // }

  setEditorData = (dataInput) => {
    window.CKEDITOR.instances["editor"].setData(dataInput);
    console.log(dataInput);
    // console.log("seteditordata value get = ", window.CKEDITOR.instances["editor"].getData());
  };

  setCursorToEnd = () => {
    // return new Promise((resolve) => {
    setTimeout(() => {
      var range = window.CKEDITOR.instances.editor.createRange();
      range.moveToElementEditEnd(window.CKEDITOR.instances.editor.editable());
      window.CKEDITOR.instances.editor.getSelection().selectRanges([range]);
    }, 600);
    // })
  };

  loadSpellingEditor = () => {
    return new Promise((resolve, reject) => {
      if (document.querySelector("#editor")) {
        window.CKEDITOR.replace("editor", {
          on: {
            // change: this.onEditorChange,
            // paste: e => {
            //     console.log("paste")
            // },
            instanceReady: (e) => {
              window.CKEDITOR.instances["editor"].focus();
              this.setCursorToEnd();
              resolve("INSTANCE READY");
            },
          },
        });

        window.npspell.ckeditor("editor", {
          dictionary: window.NP_UTILS.DICTIONARY_LANGUAGE,
          server: window.NP_UTILS.SERVER_NAME,
        });

        // resolve("instance ready");
      } else {
        reject("editor ID not found!");
      }
    });
  };

  showSuccessFullyCopied = () => {
    setTimeout(() => {
      this.setState({ spellingTextAreaTextCopied: false });
    }, 500);
    return (
      // <span style={{ marginRight:"5px"}}><CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize:"1.5em"}} /> Copied </span>
      <CheckCircleTwoTone twoToneColor="rgb(243, 227, 2)" />
    );
  };

  handleCopyText = () => {
    let a = window.NP_UTILS.editorSentenceText.trim();
    //console.log(a)
    copy(window.CKEDITOR.instances["editor"].document.getBody().getText(), {
      options: {
        format: "text/html",
      },
    });
    this.setState({ spellingTextAreaTextCopied: true });
  };

  handleContinueTyping = () => {
    // Get the HTML data from CKEditor instance
    const editorData = window.CKEDITOR.instances["editor"].getData();

    // Convert line breaks to <br> tags (if necessary)
    const formattedData = editorData.replace(/\r?\n|\r/g, "<br>");

    // Save the raw HTML content to localStorage
    localStorage.setItem("textarea_value", editorData);

    // Log the formatted data
    console.log(formattedData);

    // Navigate to the new path
    this.props.history.push(`${NEW_LAYOUT_PATH}/typeInNepali`);

    // Update the style or state
    this.props.updateStyle({ key: "1" });
  };

  // openNepaliTypingDrawer = () => {this.setState({nepaliTypingDrawerVisible: true})}
  // closeNepaliTypingDrawer = () => {this.setState({nepaliTypingDrawerVisible: false})}

  render() {
    return (
      <div>
        {/* <h1 style={{ textAlign: "center" }}>नेपाली हिज्जे परीक्षण</h1> */}
        {/* <span>{this.state.setPasteData}</span> */}

        <div className="spelling-main-container">
          <Alert
            message="हिज्जे परीक्षणको लागि नेपाली युनिकोडमा टाइप गर्नुहोस्।"
            type="info"
            showIcon
            className="spelling-alert"
          />

          <div
            id="spelling-check-checking-spelling-loading"
            style={{ display: "none" }}
          >
            <span>
              <LoadingOutlined style={{ fontSize: 24 }} spin /> Checking
              Spelling{" "}
            </span>
          </div>
          <div className="spellingCheck-btn-style">
            <div>
              {/* {this.state.docxContent ? (
                                <a href={URL.createObjectURL(new Blob([this.state.docxContent], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }))} download='converted.docx'>Download Converted File</a>
                            ) : (
                                <p>Converting...</p>
                            )} */}
              {/* <Button onClick={this.handleExport2Doc}>
                                <a
                                    href={URL.createObjectURL(new Blob([this.state.docxContent], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }))}
                                    download='converted.docx'
                                >
                                    Download Converted File
                                </a>

                            </Button> */}
            </div>

            <div>
              <Button
                className="continue-type-btn-style"
                size="large"
                onClick={this.handleContinueTyping}
              >
                Continue Typing?
              </Button>
            </div>
            <Button
              className="continue-type-btn-style"
              size="large"
              icon={<DownloadOutlined />}
              onClick={() => {
                this.handleExport2Word("htmltodoc", "sayak");
              }}
            >
              {" "}
              Download doc file
            </Button>

            <div>
              {/* {
              this.state.spellingTextAreaTextCopied ? this.showSuccessFullyCopied() : null 
            } */}
              <Button
                type="primary"
                size="large"
                style={{ display: "flex", alignItems: "center" }}
                onClick={this.handleCopyText}
                icon={
                  this.state.spellingTextAreaTextCopied ? (
                    this.showSuccessFullyCopied()
                  ) : (
                    <CopyOutlined />
                  )
                }
                className="spelling-check-copy-btn-style"
              >
                Copy To Clipboard
              </Button>
            </div>
          </div>
        </div>

        {/* <div>
          <span>Problem in typing Nepali? </span>
          <Button onClick={this.openNepaliTypingDrawer} >Open Nepali Typing</Button>

          <NepaliTypingDrawer 
            onClose = {this.closeNepaliTypingDrawer}
            drawerVisible={this.state.nepaliTypingDrawerVisible}
            {...this.props}
          />
        </div> */}

        <Alert
          className="dumpUserSuggestedWord-success"
          message="शब्द सुझाव गर्नुभएकोमा धन्यवाद।"
          description=""
          type="success"
          showIcon
          style={{ marginBottom: "10px", display: "none" }}
        />

        <Alert
          className="dumpUserSuggestedWord-fail"
          message="यो शब्द पहिलेनै सुझाव भएको छ। धन्यवाद।"
          description=""
          type="error"
          showIcon
          style={{ marginBottom: "10px", display: "none" }}
        />

        {this.state.loadingContainer ? (
          <Spin
            tip="Please wait . It normally takes 3-5 seconds."
            style={{
              height: "45vh",
              backgroundColor: "rgba(227, 226, 210, 0.28)",
            }}
          >
            <div
              className="please-wait-tip-spellingcheck"
              style={{
                zIndex: "999",
                position: "absolute",
                top: "0",
                left: "0",
              }}
            ></div>
          </Spin>
        ) : null}

        <div id="serverStatus" style={{ display: "none" }}></div>
        <div id="statusMessage" style={{ display: "none" }}></div>
        <textarea id="editor" style={{ visibility: "hidden" }}>
          Loading...
        </textarea>
        {/* <div id="htmltodoc" style={{ visibility: "hidden" }}>{localStorage.getItem("spellingCheckText")}</div> */}
        <div id="word-count" style={{ display: "none" }}></div>

        <div
          style={{ marginTop: "45vh" }}
          id="spelling-check-bottom-instruction"
        >
          <Alert
            message="बाकसमा टाइप गर्नुहोस् वा अन्य ठाउँबाट कपी गरेर त्यस बाकसमा पेस्ट गर्नुहोस् । गलत हिज्जे भएका शब्द रातो धर्को लगाउने छ ।"
            description="रातो धर्को लगाएको शब्दलाई राइट क्लिक गर्नुहोस् । उक्त गलत हिज्जे भएको शब्दसँग नजिक हिज्जे मिल्ने शब्दहरू देखिनेछन् । ति शब्दहरूबाट उपयुक्त शब्द छान्नुहोस् ।"
            type="info"
            showIcon
            style={{ marginBottom: "10px", marginTop: "20px" }}
          />
        </div>
      </div>
    );
  }
}
