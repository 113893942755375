import React, { Component } from "react";

import Sayakimage from "./images/yoda.png";

import { updatePageHead } from "../../components/common/UpdateMetaAndTitle";

import NavBar from "./NavBar";
import Footer from "./Footer";
import { Row, Col, Menu, Icon, Tabs, Button, Card } from "antd";

import { Link } from "react-router-dom";

const { SubMenu } = Menu;
const { TabPane } = Tabs;

export class LayoutAbout extends Component {
  componentDidMount() {
    updatePageHead("About Us", "", "");
  }
  render() {
    return (
      <div className="main-wrapper">
        <NavBar />

        <div className="container">
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            // className="bg-blue-yellow-gradient"
          >
            <Col
              xs={{ span: 22 }}
              sm={{ span: 22 }}
              md={{ span: 22 }}
              lg={{ span: 22 }}
              xl={{ span: 22 }}
              xxl={{ span: 22 }}
            >
              <div
                style={{
                  minHeight: "80vh",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1
                  style={{
                    marginBottom: "20px",
                    textAlign: "center",
                    fontSize: "3em",
                    color: "rgb(245, 141, 66)",
                  }}
                >
                  सायकको बारेमा
                </h1>
                <h3 className="justify-text" style={{ fontSize: "1.3em" }}>
                  शुद्ध नेपाली लेखन अहिले साझा चुनौतीको विषय बनेको छ । पेशागत
                  हिसाबले उच्च ओहदामा आसीन र दैनिक रूपमा नेपाली भाषामै कामकाज
                  गर्नुपर्ने अधिकारीहरूबाट समेत भाषागत त्रुटि भइरहनु क्षम्य वा
                  वेवास्ता गर्न मिल्ने विषय होइन । विश्वनै सामाजिक सञ्जालहरूमा
                  साँघुरिएर आएको वर्तमान अवस्थामा यसरी उच्च तहबाट हुने भाषागत
                  त्रुटिहरू तुरुन्तै सामाजिक सञ्जालमा फैलिन्छन् र मानिसले भाषागत
                  दक्षताका आधारमा समेत ती व्यक्तिहरूको व्यक्तित्वप्रति आफ्नो
                  दृष्टिकोण बनाउछन्, अझ कतिपय अवस्थामा त भाषिक कमजोरीकै कारण
                  त्यस्ता व्यक्तिहरू आलोचनाको शिकार बन्नुपरेको छ । नेपाली
                  भाषामाथिको कमजोर पकड भएकै कारण तपाईं आफ्नो व्यक्तित्व विकासमा
                  अवरोध हुनसक्ने चिन्तामा हुनुहुन्छ ? अब आत्तिनु पर्दैन सायक
                  तपाईं समक्ष आइसकेको छ ।
                </h3>
                <h2 style={{ textAlign: "center", marginTop: "30px" }}>
                  सायकमा निशुल्क खाता बनाई आजै सेवा सुचारु गर्नुहोस्।
                </h2>

                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    // className="btn-yellow"
                    className="btn-orange"
                  >
                    <Link to="/login">निशुल्क खाता बनाउनुहोस्</Link>
                  </Button>
                  <br />
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <Footer />
      </div>
    );
  }
}
