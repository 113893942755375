import React, { Component } from "react";

import Sayakimage from "./images/yoda.png";

import { updatePageHead } from "../../components/common/UpdateMetaAndTitle";

import NavBar from "./NavBar";
import Footer from "./Footer";
import GoogleMapICT from "./layoutComponents/GoogleMapICT";
import ContactForm from "./layoutComponents/ContactForm";
import Loading from "../layoutUtils/Loading";
import { connect } from "react-redux";
import { appBusyReset, appBusySet } from "../../store/actions/appStateAction";

import { Row, Col, Menu, Icon, Tabs, Button, Card } from "antd";

const { SubMenu } = Menu;
const { TabPane } = Tabs;

class LayoutContact extends Component {
  componentDidMount() {
    updatePageHead("Contact Us", "", "");
  }
  render() {
    return (
      <div className="main-wrapper">
        <NavBar />
        {this.props.appState.busy ? (
          <Loading messageSpin="Logging In Please Wait" />
        ) : null}

        <div className="bg-blue-yellow-gradient">
          <div className="container">
            <Row type="flex" justify="space-around" align="middle">
              <Col
                xs={{ span: 22 }}
                sm={{ span: 22 }}
                md={{ span: 22 }}
                lg={{ span: 10 }}
                xl={{ span: 10 }}
                xxl={{ span: 10 }}
                style={{
                  alignContent: "center",
                }}
              >
                <div>
                  <Row>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center", // Center horizontally
                        padding: "20px",
                        textAlign: "center", // Center text
                      }}
                      className="layoutcontact-p-style"
                    >
                      <h1
                        style={{
                          marginBottom: "10px",
                          textDecoration: "underline",
                          color: "#f3722c",
                        }}
                      >
                        हाम्रो सम्पर्क कार्यालय
                      </h1>
                      <p>Integrated ICT PVT.LTD./Semantro Pvt. Ltd.</p>
                      <p>Rio 6 Marga, Lalitpur</p>
                      <p>Lalitpur-10, Kupondole</p>
                      <p>Lalitpur, Nepal</p>
                      <p>info@integratedict.com.np</p>
                    </div>
                  </Row>
                  <Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center", // Center vertically
                        height: "400px",
                      }}
                    >
                      <GoogleMapICT />
                    </div>
                  </Row>
                </div>
              </Col>

              <Col
                xs={{ span: 22 }}
                sm={{ span: 22 }}
                md={{ span: 22 }}
                lg={{ span: 14 }}
                xl={{ span: 14 }}
                xxl={{ span: 14 }}
              >
                <ContactForm {...this.props} />

                {/* <div className="contact-form-container"></div> */}
              </Col>
            </Row>
          </div>

          {/* <Row style={{ margin: "0% 0%" }}>
            <Col span={24}>
              <div style={{ backgroundColor: "#ccc", height: "400px" }}>
                <GoogleMapICT />
              </div>
            </Col>
          </Row> */}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  endpoints: state.apiConstants,
  appState: state.appState,
});

export default connect(mapStateToProps, {
  appBusySet,
  appBusyReset,
})(LayoutContact);
