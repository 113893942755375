import axios from "axios";

export const r_sendEmailToSelectedUsers = (emailList, subject, messageBody, accessToken, endpoints) => {
  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakSearch",
      "actionName": "sendEmailToSelectedUsers",
       "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "email": "sachingiri619@gmail.com,076mscsk012.sachin@pcampus.edu.np",
        "description": "subject",
        "disambiguatingDescription":"message"
        }
    }

      const {data} = requestFormat
      
      const requestFormatWithUserData = {
          ...requestFormat,
          data : {
              ...data,
              email: emailList,
              description: messageBody,
              disambiguatingDescription: subject,
          }
  
      }
  
      //console.log(requestFormatWithUserData)
      const reqD = new FormData();
      reqD.append("data", JSON.stringify(requestFormatWithUserData));

    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })
}

export const r_listUsersByServiceUsedCount = (serviceNameToFilter, accessToken, endpoints, pageStart, pageEnd) => {
  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakSearch",
      "actionName": "listUsersByServiceUsedCount",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "SayakServiceLog",
        "usedService": "SPELLSUGGESTION",
        "description": "desc"
      },
      "pageLimit": {
        "@context": "http://semantro.com/",
        "@type": "PageProperty",
        "start": 0,
        "end": 10
      }
    }

      const {data} = requestFormat
      const {pageLimit} = requestFormat
    
      const requestFormatWithUserData = {
          ...requestFormat,
          data : {
              ...data,
              usedService: serviceNameToFilter
          },
          pageLimit: {
            ...pageLimit,
            start: pageStart,
            end: pageEnd
          }
  
      }
  
      //console.log(requestFormatWithUserData)
      const reqD = new FormData();
      reqD.append("data", JSON.stringify(requestFormatWithUserData));

    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })
}

export const r_countUsersByServiceUsed = (serviceNameToFilter, accessToken, endpoints) => {
  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakSearch",
      "actionName": "countUsersByServiceUsed",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "SayakServiceLog",
        "usedService": "SPELLSUGGESTION",
        "description": "desc"
      }
    }

    const {data} = requestFormat
    
    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            usedService: serviceNameToFilter,
            description: "desc"
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  }) 
}
export const r_listMatchingUsersByGivenName = (userNameToFilter, accessToken, endpoints, pageStart, pageEnd) => {
  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakSearch",
      "actionName": "listMatchingUsersByGivenName",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "givenName": "Sach"
      },
      "pageLimit": {
        "@context": "http://semantro.com/",
        "@type": "PageProperty",
        "start": 0,
        "end": 10
      }
    }

      const {data} = requestFormat
      const {pageLimit} = requestFormat
    
      const requestFormatWithUserData = {
          ...requestFormat,
          data : {
              ...data,
              givenName: userNameToFilter
          },
          pageLimit: {
            ...pageLimit,
            start: pageStart,
            end: pageEnd
          }
  
      }
  
      //console.log(requestFormatWithUserData)
      const reqD = new FormData();
      reqD.append("data", JSON.stringify(requestFormatWithUserData));

    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })
}

export const r_countMatchingUsersByGivenName = (userNameToFilter, accessToken, endpoints) =>{

  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakSearch",
      "actionName": "countMatchingUsersByGivenName",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "givenName": "Sac"
      }
    }

    const {data} = requestFormat
    
    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            givenName: userNameToFilter
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  }) 

}

export const r_updateUserPassword = (userData, accessToken, endpoints) => {

  const {MUTATION_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakMutation",
      "actionName": "changeUserPassword",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "identifier": "",
        "userPassword":""
    }
    }

    const {data} = requestFormat
    
    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            identifier: userData.identifier,
            userPassword: userData.userPassword
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })  
}

export const r_updateUserDetails = (userData, accessToken, endpoints) => {

  const {MUTATION_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakMutation",
      "actionName": "updateUser",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "identifier": "6782f5a0664b40f2-ac35b01479a4e",
        "address": "test1",
        "familyName": "",
        "givenName": "",
        "name": "",
        "gender": "",
        "telephone": ""
      }
    }

    const {data} = requestFormat
    
    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            name: userData.name,
            identifier: userData.identifier,
            givenName: userData.givenName,
            familyName: userData.familyName,
            address: userData.address,
            gender: userData.gender,
            telephone: userData.telephone
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })  
}

export const r_countTotalAdminUsers = (userName, endpoints, accessToken) => {
  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakSearch",
      "actionName": "countUsers",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "userName": "info@integratedict.com.np",
        "alternateName": "USER"
      }
    }

    const {data} = requestFormat
    
    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            userName: userName
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })  
}

export const r_listAllAdminUsers = (userName, accessToken, endpoints, pageStart, pageEnd) => {
  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakSearch",
      "actionName": "listAdminUsers",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "userName": "info@integratedict.com.np"
      },
      "pageLimit": {
        "@context": "http://semantro.com/",
        "@type": "PageProperty",
        "start": 0,
        "end": 10
      }
    }

      const {data} = requestFormat
      const {pageLimit} = requestFormat
    
      const requestFormatWithUserData = {
          ...requestFormat,
          data : {
              ...data,
              userName: userName
          },
          pageLimit: {
            ...pageLimit,
            start: pageStart,
            end: pageEnd
          }
  
      }
  
      //console.log(requestFormatWithUserData)
      const reqD = new FormData();
      reqD.append("data", JSON.stringify(requestFormatWithUserData));

    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })
}

export const r_AddAdminUser = (adminUser, userData, endpoints, accessToken) => {
  const {MUTATION_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakMutation",
      "actionName": "registerAdmin",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "name": "Integrated ICT",
        "email": "test@integratedict.com.np",
        "userName": "info@integratedict.com.np",
        "userPassword": "mypassword",
        "hasRole": {
          "@context": "http://semantro.com/",
          "@type": "UserRole",
          "roleName": "ADMIN"
        }
      }
    }

    const {data} = requestFormat
    
    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            name: userData.name,
            email:userData.email,
            userName:adminUser,
            userPassword: userData.userPassword
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })  
}

export const r_countTotalUsers = (userName, endpoints, accessToken) => {
  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakSearch",
      "actionName": "countUsers",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "userName": "info@integratedict.com.np",
        "alternateName": "USER"
      }
    }

    const {data} = requestFormat
    
    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            userName: userName
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })  
}

export const r_deleteUser = (userName, adminName, endpoints, accessToken) => {
  const {MUTATION_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakMutation",
      "actionName": "deleteUser",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "userName": "info@integratedict.com.np",
        "description": "sachingiri619@gmail.com"
      }
    }

    const {data} = requestFormat
    
    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            userName: adminName,
            description: userName
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })
}

export const r_listAllUsers = (userName, accessToken, endpoints, pageStart, pageEnd) => {
  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
        "@context": "http://semantro.com/",
        "@type": "SayakSearch",
        "actionName": "listUsers",
        "data": {
          "@context": "http://semantro.com/",
          "@type": "User",
          "userName": ""
        },
        "pageLimit": {
          "@context": "http://semantro.com/",
          "@type": "PageProperty",
          "start": 0,
          "end": 10
        }
      }

      const {data} = requestFormat
      const {pageLimit} = requestFormat
    
      const requestFormatWithUserData = {
          ...requestFormat,
          data : {
              ...data,
              userName: userName
          },
          pageLimit: {
            ...pageLimit,
            start: pageStart,
            end: pageEnd
          }
  
      }
  
      //console.log(requestFormatWithUserData)
      const reqD = new FormData();
      reqD.append("data", JSON.stringify(requestFormatWithUserData));

    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })
}