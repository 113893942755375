import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { useGoogleLogin } from "@react-oauth/google";
import { setCurrentUserToken } from "../../store/actions/authAction";
import { url } from "../../utils/url";

async function getUserInfo(codeResponse) {
  let authToken = localStorage.getItem("sayakusertoken");

  var response = await fetch(`${url.BASE_END_POINT}/auth/google/signin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ authCode: codeResponse.code }),
  });
  return await response.json();
}

const LoginWithGoogleAndFacebook = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const googleLogin = useGoogleLogin({
    // flow: "auth-code",
    // onSuccess: async (codeResponse) => {
    //   var loginDetails = await getUserInfo(codeResponse);
    //   //  setLoggedIn(true);
    //   localStorage.setItem("sayakusertoken", loginDetails.accessToken);

    //         dispatch(setCurrentUserToken(accessToken));

    //   //  setUser(loginDetails);
    // },
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      var loginDetails = await getUserInfo(codeResponse);
      // var code =
      //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.ewogICJpZGVudGlmaWVyIiA6ICIyYjM1Nzc2NGIyZDE0ZGYzLThmNmFkNzI5OWY4OTdmMmUiLAogICJyb2xlTmFtZSIgOiAiU3VwZXIgQWRtaW4iLAogICJkYXRlQ3JlYXRlZCIgOiAiMTcxODUzNTk4Nzc1NiIsCiAgImFjY2Vzc0NvZGUiIDogImUxYWMyOGQxY2RlZTQ1NDYtOTNhNmNiM2YxZmM3OTAxYyIsCiAgInVzZXJOYW1lIiA6ICJhZG1pbjMwOTA1Igp9.WhdkfU8YCJa4F8Sg150YKimtrsGlIq7UjcEW_TzBuVM";
      localStorage.setItem("sayakusertoken", loginDetails.accessToken);
      dispatch(setCurrentUserToken(loginDetails.accessToken));
      // localStorage.setItem("sayakusertoken", code);
      // dispatch(setCurrentUserToken(code));
    },
  });

  const handleClick = () => {
    var code =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.ewogICJpZGVudGlmaWVyIiA6ICIyYjM1Nzc2NGIyZDE0ZGYzLThmNmFkNzI5OWY4OTdmMmUiLAogICJyb2xlTmFtZSIgOiAiU3VwZXIgQWRtaW4iLAogICJkYXRlQ3JlYXRlZCIgOiAiMTcxODUzNTk4Nzc1NiIsCiAgImFjY2Vzc0NvZGUiIDogImUxYWMyOGQxY2RlZTQ1NDYtOTNhNmNiM2YxZmM3OTAxYyIsCiAgInVzZXJOYW1lIiA6ICJhZG1pbjMwOTA1Igp9.WhdkfU8YCJa4F8Sg150YKimtrsGlIq7UjcEW_TzBuVM";
    // localStorage.setItem("sayakusertoken", loginDetails.accessToken);
    // dispatch(setCurrentUserToken(loginDetails.accessToken));
    localStorage.setItem("sayakusertoken", code);
    dispatch(setCurrentUserToken(code));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        border: "1px solid #d9d9d9",
      }}
      onClick={() => googleLogin()}
    >
      <div className="googleLoginContainer">
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              marginRight: "20px",
              display: "flex",
              alignContent: "center",
            }}
          >
            <FcGoogle
              style={{
                color: "#ffffff",
                width: "30px",
                height: "27px",
              }}
            />
          </span>
          <span style={{ fontSize: "16px", fontWeight: "600", color: "#000" }}>
            Continue with Google
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoginWithGoogleAndFacebook;
