export const CONTEXT = "http://semantro.com/";
export const SCHEMA_ORG_CONTEXT = "http://schema.org/";

export const TYPES = {
  SAYAK_SEARCH: "SayakSearch",
  USER: "User",
  PAGE_PROPERTY: "PageProperty",
};

export const ACTIONS = {
  COUNT_USERS: "countUsers",
  LIST_USERS: "listUsers",
};

export const QUERY_END_POINTS = {
  DICTIONARY_COUNT: "dictionary/count",
  LIST_DICTIONARY: "dictionary/list",
};

export const MUTATION_END_POINT = {
  SIGN_IN: "auth/mobile/signin",
  SIGN_UP: "auth/mobile/signup",
  RESET_MPIN: "auth/reset/mpin",
  CHECK_MOBILE_NUMBER: "auth/user/check",
  SIGN_OUT: "auth/user/logout",
  GET_USER_PROFILE: "auth/user/me",
  CREATE_CONTACT: "contact/create",
};
