
import {
    RESET_USER_AUTH,

    SET_CURRENT_USER_TOKEN,
    SET_CURRENT_USER_DETAILS,
    SET_CURRENT_USER_REGISTER,
    SET_CURRENT_USER_LOGIN,
    ACTIVATE_USER_ACCOUNT,
    RESET_RESEND_ACTIVATION_CODE,
    SET_RESEND_ACTIVATION_CODE,

    SET_PASSWORD_CHANGED_SUCCESS,
    SET_PASSWORD_RESET_EMAIL,
    SET_PASSWORD_RESET_PIN,
    SET_PASSWORD_CHANGE_ERRORS,
    RESET_PASSWORD_CHANGE_ERRORS,

    SET_ADD_PASSWORD_EMAIL,
    SET_ADD_PASSWORD_ERRORS,
    RESET_ADD_PASSWORD_ERRORS,
    RESET_ADD_PASSWORD,
    SET_ADD_PASSWORD_PIN,
    SET_ADD_PASSWORD_SUCCESS

} from '../actions/types'

import isEmpty from '../../validation/is-empty'

const initialState = {
   isAuthenticated : false,
   user : {},
   userToken: "",
   userRegister: {
       isAccountActivated: false,
       resendActivationCode : false,
       name:"",
       email:""
   },
   forgotPassword: {
       email: "",
       resetPasswordPin: "",
       newPassword: "",
       changedPasswordSuccess: false,
       errors: {}
   },
   addPassword: {
       email:"",
       setUpPasswordPin:"",
       newPassword:"",
       setUpPasswordSuccess: false,
       errors: {}
   }
}



export default function (state = initialState, action) {
    const { userRegister } = state
    const { forgotPassword } = state
    const { addPassword } = state

   switch (action.type) {
        case RESET_USER_AUTH:
           return {
            isAuthenticated : false,
            user : {},
            userToken: "",
            userRegister: {
                isAccountActivated: false,
                resendActivationCode : false,
                name:"",
                email:""
            },
            forgotPassword: {
                email: "",
                resetPasswordPin: "",
                newPassword: "",
                changedPasswordSuccess: false,
                errors: {}
            },
            addPassword: {
                email:"",
                setUpPasswordPin:"",
                newPassword:"",
                setUpPasswordSuccess: false,
                errors: {}
            }
           }
        case RESET_ADD_PASSWORD : {
            return {
                ...state,
                addPassword: {
                    email:"",
                    setUpPasswordPin:"",
                    newPassword:"",
                    setUpPasswordSuccess: false,
                    errors: {}
                }
            }
        }
        case SET_ADD_PASSWORD_SUCCESS: 
            return {
                ...state,
                addPassword:{
                    ...addPassword,
                    setUpPasswordSuccess: true
                }
            }
        case SET_ADD_PASSWORD_PIN:
            return {
                ...state,
                addPassword: {
                    ...addPassword,
                    setUpPasswordPin: action.payload
                }
            }
        case SET_ADD_PASSWORD_EMAIL:
            return {
                ...state,
                addPassword: {
                    ...addPassword,
                    email: action.payload.email
                }
            }
        case SET_ADD_PASSWORD_ERRORS:
            return {
                ...state,
                addPassword: {
                    ...addPassword,
                    errors: action.payload
                }
            }
        case RESET_ADD_PASSWORD_ERRORS:
            return {
                ...state,
                addPassword: {
                    ...addPassword,
                    errors: {}
                }
            }
        case SET_CURRENT_USER_TOKEN:
           return {
               ...state,
               isAuthenticated: !isEmpty(action.payload),
               userToken: action.payload
           }
        case SET_CURRENT_USER_DETAILS:
            return {
                ...state,
                user : action.payload
            }
        case SET_CURRENT_USER_REGISTER:
           return {
               ...state,
               userRegister: {
                ...userRegister,
                isAccountActivated: false,
                email: action.payload.email,
                name: action.payload.name
            }
           }
        case SET_CURRENT_USER_LOGIN:
           return {
               ...state,
               user: action.payload
           }
       case ACTIVATE_USER_ACCOUNT:
           return {
               ...state,
               userRegister: {
                   ...userRegister,
                   isAccountActivated: !isEmpty(action.payload)
               }
           }
        case RESET_RESEND_ACTIVATION_CODE:
        return {
            ...state,
            userRegister: {
                ...userRegister,
                resendActivationCode: false
            }
        }  
        case SET_RESEND_ACTIVATION_CODE:
        return {
            ...state,
            userRegister: {
                ...userRegister,
                email: action.payload.email,
                resendActivationCode: !isEmpty(action.payload)
            }
        }
        case SET_PASSWORD_RESET_EMAIL:
            return {
                ...state,
                forgotPassword: {
                    ...forgotPassword,
                    email: action.payload,
                    errors: {}
                }
            }
        case SET_PASSWORD_RESET_PIN : 
            return{
                ...state,
                forgotPassword: {
                    ...forgotPassword,
                    email: action.payload.email,
                    resetPasswordPin: action.payload.passwordrestpin,
                    errors: {}
                }
            }
        case SET_PASSWORD_CHANGED_SUCCESS: 
            return {
                ...state,
                forgotPassword: {
                    email: "",
                    resetPasswordPin: "",
                    newPassword: "",
                    changedPasswordSuccess: true
                }
            }
        case SET_PASSWORD_CHANGE_ERRORS: 
        return {
            ...state,
            forgotPassword: {
                ...forgotPassword,
                errors: action.payload
            }
        }
        case RESET_PASSWORD_CHANGE_ERRORS:
            return {
                ...state,
                forgotPassword: {
                    ...forgotPassword,
                    errors: {}
                }
            }
        
       default:
           return state
   }
} 

