import React from "react";
import { Button, message, Modal, notification } from "antd";
import axios from "axios";
import {} from "antd";

export const r_getUserType = (userName, accessToken, endpoints) => {
  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakSearch",
      "actionName": "getUserType",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "userName": "info@integratedict.com.np"
      }
    }

    const {data} = requestFormat

    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            userName: userName,
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })
}

export const r_LogOutUser = (userName, accessToken, endpoints) => {

  const {LOGOUT_END_POINT} = endpoints

  return new Promise((resolve,reject) => {

    const requestFormat = {"userName" : userName }

    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormat));


    axios({
        method: "POST",
        url: LOGOUT_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })
}

export const r_GetUserDetails = (userName,accessToken, endpoints) => {
  const {QUERY_END_POINT} = endpoints
  return new Promise((resolve,reject) => {

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakSearch",
      "actionName": "getUserDetail",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "userName": "sachingiri619@gmail.com"
      }
    }

    const {data} = requestFormat

    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            userName: userName,
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          "accessToken" : accessToken
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })
}

export const r_addUserPassword = (userData, endpoints) => {
  const { MUTATION_END_POINT } = endpoints
  return new Promise((resolve,reject) => {
    //console.log(userData)

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakMutation",
      "actionName": "addUserPassword",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "userPassword": "sachin",
        "userName": "sachingiri619@gmail.com",
        "oldPassword": "528725"
      }
    }

    const {data} = requestFormat

    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            userPassword:userData.newPassword,
            oldPassword:userData.passwordrestpin,
            userName: userData.email,
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })
}

export const r_VerifyAddPasswordCode = (userData, endpoints) => {
  const { MUTATION_END_POINT } = endpoints
  return new Promise((resolve,reject) => {
    //console.log(userData)

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakMutation",
      "actionName": "verifyAddPasswordVerificationCode",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "oldPassword":"528725",
        "userName": "Sachingiri619@gmail.com"
      }
    }

    const {data} = requestFormat

    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            oldPassword:userData.addpasswordpin,
            userName: userData.email,
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
  })
}


export const r_AddPasswordCodeSendToEmail= (userData, endpoints) => {
  const { MUTATION_END_POINT } = endpoints
  return new Promise((resolve,reject) => {
    console.log(userData)

    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakMutation",
      "actionName": "sendAddPasswordVerificationCode",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "userName": "Sachingiri619@gmail.com"
      }
    }

    const {data} = requestFormat

    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            userName: userData.email,
        }

    }

    console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: reqD,
      })
      .then((res) => {
        console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error)
      });
  })
}


export const r_storeUserFromFacebook = (userData, endpoints) => {
  const { MUTATION_END_POINT } = endpoints
  return new Promise ((resolve, reject) => {
    //console.log(userData)
    // const {email} = userData
    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakMutation",
      "actionName": "storeUserFromFacebook",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "name": "Sachin Giri",
        "email": "Sachingiri619@gmail.com",
        "hasRole": {
          "@context": "http://semantro.com/",
          "@type": "UserRole",
          "roleName": "USER"
        }
      }
    }
    

    const {data} = requestFormat

    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            email: userData.email,
            name: userData.name,
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
})
}

export const r_storeUserFromGoogle = (userData, endpoints) => {
  const {MUTATION_END_POINT} = endpoints
  return new Promise ((resolve, reject) => {
    //console.log(userData)
    // const {email} = userData
    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakMutation",
      "actionName": "storeUserFromGoogle",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "name": "Sachin Giri",
        "email": "Sachingiri619@gmail.com",
        "hasRole": {
          "@context": "http://semantro.com/",
          "@type": "UserRole",
          "roleName": "USER"
        }
      }
    }
    

    const {data} = requestFormat

    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            email: userData.email,
            name: userData.name,
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && res.data) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
})
}


export const r_ForgotPasswordSetNewPassword = (userData, endpoints) => {
  const {MUTATION_END_POINT} = endpoints
  return new Promise ((resolve, reject) => {
    //console.log(userData)
    // const {email} = userData
    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakMutation",
      "actionName": "forgotUserPassword",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "userPassword": "sachin123",
        "userName": "sachingiri619@gmail.com",
        "oldPassword": "227157"
      }
    }
    

    const {data} = requestFormat

    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            userName : userData.email,
            oldPassword: userData.passwordrestpin,
            userPassword: userData.newPassword
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
})
}

export const r_ForgotPasswordChangeCodeVerify = (userData, endpoints) => {
  const {MUTATION_END_POINT} = endpoints

  return new Promise ((resolve, reject) => {
    //console.log(userData)
    // const {email} = userData
    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakMutation",
      "actionName": "verifyForgotPasswordVerificationCode",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "userName": "",
        "oldPassword":""
      }
    }
    

    const {data} = requestFormat

    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            userName : userData.email,
            oldPassword: userData.passwordrestpin
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
})
}

export const r_ForgotPasswordChangeCode = (userData, endpoints) => {
  const {MUTATION_END_POINT} = endpoints

  return new Promise ((resolve, reject) => {
    //console.log(userData)
    // const {email} = userData
    const requestFormat = {
      "@context": "http://semantro.com/",
      "@type": "SayakMutation",
      "actionName": "sendForgotPasswordVerificationCode",
      "data": {
        "@context": "http://semantro.com/",
        "@type": "User",
        "userName": "Sachingiri619@gmail.com"
      }
    }
    

    const {data} = requestFormat

    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            userName : userData.email,
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: reqD,
      })
      .then((res) => {
        //console.log(res)
        const {data} = res
        if (res.status === 200 && data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });
})

  
}
export const r_AccountActivation = (userData, endpoints) => {
  const {MUTATION_END_POINT} = endpoints

  return new Promise ((resolve, reject) => {
      //console.log(userData)
      // const {email} = userData
      const requestFormat = {
        "@context": "http://semantro.com/",
        "@type": "SayakMutation",
        "actionName": "verifyEmailVerificationCode",
        "data": {
          "@context": "http://semantro.com/",
          "@type": "User",
          "userName": "",
          "oldPassword":""
        }
      }

      const {data} = requestFormat

      const requestFormatWithUserData = {
          ...requestFormat,
          data : {
              ...data,
              userName : userData.email,
              oldPassword: userData.activationCode
          }
  
      }
  
      //console.log(requestFormatWithUserData)
      const reqD = new FormData();
      reqD.append("data", JSON.stringify(requestFormatWithUserData));
  
  
      axios({
          method: "POST",
          url: MUTATION_END_POINT,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: reqD,
        })
        .then((res) => {
          //console.log(res)
          resolve(res)
        })
        .catch((error) => {
          //console.log(error);
          reject(error)
        });
  })
}

export const r_AccountActivationCode = (email, endpoints) => {
  
  const {MUTATION_END_POINT} = endpoints

    return new Promise ((resolve, reject) => {
        //console.log(email)
        // const {email} = userData
        const requestFormat = {
            "@context": "http://semantro.com/",
            "@type": "SayakMutation",
            "actionName": "sendEmailVerificationCode",
            "data": {
                "@context": "http://semantro.com/",
                "@type": "User",
                "userName": ""
            }
        }

        const {data} = requestFormat

        const requestFormatWithUserData = {
            ...requestFormat,
            data : {
                ...data,
                userName : email
            }
    
        }
    
        //console.log(requestFormatWithUserData)
        const reqD = new FormData();
        reqD.append("data", JSON.stringify(requestFormatWithUserData));
    
    
        axios({
            method: "POST",
            url: MUTATION_END_POINT,
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: reqD,
          })
          .then((res) => {
            //console.log(res)
            resolve(res)
          })
          .catch((error) => {
            //console.log(error);
            reject(error)
          });
    })
}

export const r_RegisterUser = (userData,endpoints) => {
  const {MUTATION_END_POINT} = endpoints


    return new Promise((resolve, reject) => {

    //console.log(userData)
    const {name, email, password} = userData
    //create the request here 
    const requestFormat = {
        "@context": "http://semantro.com/",
        "@type": "SayakMutation",
        "actionName": "registerUser",
        "data": {
          "@context": "http://semantro.com/",
          "@type": "User",
          "userPassword": "",
          "name": "",
          "email": "",
          "hasRole": {
            "@context": "http://semantro.com/",
            "@type": "UserRole",
            "roleName": "USER"
          }
        }
      }
    const {data} = requestFormat

    const requestFormatWithUserData = {
        ...requestFormat,
        data : {
            ...data,
            name,
            email,
            userPassword: password
        }

    }

    //console.log(requestFormatWithUserData)
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(requestFormatWithUserData));


    axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: reqD,
      })
      .then((res) => {
        const { data } = res;
        if (res.status === 200 && data) {
          resolve(res);
      }
      })
      .catch((error) => {
        //console.log(error);
        reject(error)
      });

    })//end of promise return 
};


export const r_LoginUser = (userData, endpoints) => {
  const {LOGIN_END_POINT} = endpoints


  return new Promise((resolve, reject) => {
        
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(userData));

    axios({
      method: "POST",
      url: LOGIN_END_POINT,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: reqD,
    })
    .then((response) => {
      const { data } = response;
      if (response.status === 200 && data) {
          resolve(response);
      }
    })
    .catch((error) => {
      //console.log(error);
      reject("Login Error , Resolve this error : ", error);
      });
  });
};

export function r_RegisterUser_XXX(userData, endpoints) {
  const {LOGIN_END_POINT} = endpoints

  return new Promise((resolve, reject) => {
    const reqD = new FormData();
    reqD.append("data", JSON.stringify(userData));

    axios({
      method: "POST",
      url: LOGIN_END_POINT,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: reqD,
    })
      .then((response) => {
        const { data } = response;
        if (response.status === 200 && data) {
          resolve(response);
        }
      })
      .catch((error) => {
        //console.log(error);
        reject("user not found or some error check ");
      });
  });
}

