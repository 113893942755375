// import React, { Component } from "react";

// import "../style.css";

// import moment from "moment";

// import { DatePicker, Spin, Button } from "antd";

// import {
//   ResponsiveContainer,
//   LineChart,
//   CartesianGrid,
//   XAxis,
//   YAxis,
//   Legend,
//   Line,
//   Tooltip,
// } from "recharts";
// import { r_serviceUsedCountDateWise } from "../../../helpers/Requesthelper/RequestStatistics";

// const { RangePicker } = DatePicker;

// class UserProductStatistics extends Component {
//   state = {
//     chartLoading: true,
//     userUsageData: [],
//     chartDataRenderFor: "WEEKLY",
//     //startDate: moment().subtract(1, 'months').format("YYYY-MM-DD"),
//     endDate: moment().format("YYYY-MM-DD"),
//   };
//   componentDidMount() {
//     this.prepareChart();
//   }

//   prepareChart = () => {
//     this.setState({ chartLoading: true });

//     const userData = {
//       userName: this.props.userDetails.userName,
//       //startDate: this.state.startDate + "T00:00:00.000Z",
//       endDate: this.state.endDate + "T00:00:00.000Z",
//       description: this.state.chartDataRenderFor,
//     };
//     r_serviceUsedCountDateWise(
//       userData,
//       this.props.endpoints,
//       this.props.auth.userToken
//     ).then((res) => {
//       //console.log(res);
//       if (res.data.status === "fail") {
//         //console.log(res.data);
//       } else {
//         this.prepareStatDataForChart(res.data);
//       }
//     });
//   };
//   prepareStatDataForChart = (usageData) => {
//     this.setState({
//       chartLoading: false,
//       userUsageData: [],
//     });
//     let userUsageData = [];
//     Object.keys(usageData).forEach((key) => {
//       //console.log(usageData[key]);
//       userUsageData.push({
//         date: key,
//         FONTCONVERSION: usageData[key]["FONTCONVERSION"],
//         SPELLSUGGESTION: usageData[key]["SPELLSUGGESTION"],
//       });
//     });

//     //console.log(userUsageData);
//     this.setState({ userUsageData });
//   };

//   // handleCalendarChange = (e) => {
//   //     console.log(e)
//   //     if(e !== null){
//   //         if(e[0] !== null){
//   //             console.log(moment(e[0]._d).format('YYYY-MM-DD'))
//   //             const startDate = moment(e[0]._d).format('YYYY-MM-DD')
//   //             this.setState({startDate})

//   //             if(e[1] !== null){
//   //                 console.log(moment(e[1]._d).format('YYYY-MM-DD'))
//   //                 const endDate = moment(e[1]._d).format('YYYY-MM-DD')
//   //                 this.setState({endDate})

//   //                 this.prepareChart()
//   //             }
//   //         }

//   //     }

//   // }

//   handleRenderUsageChartForMonth = () => {
//     this.setState(
//       {
//         chartDataRenderFor: "MONTHLY",
//       },
//       () => {
//         this.prepareChart();
//       }
//     );
//   };

//   handleRenderUsageChartForWeek = () => {
//     this.setState(
//       {
//         chartDataRenderFor: "WEEKLY",
//       },
//       () => {
//         this.prepareChart();
//       }
//     );
//   };
//   render() {
//     //console.log(this.state);

//     return (
//       <div className="user-product-statistics">
//         <Button
//           dashed
//           style={{ margin: "0px 0px 20px 0" }}
//           onClick={this.handleRenderUsageChartForMonth}
//         >
//           This Month
//         </Button>

//         <Button
//           dashed
//           style={{ margin: "0px 0px 20px 0" }}
//           onClick={this.handleRenderUsageChartForWeek}
//         >
//           This Week
//         </Button>
//         {/* <RangePicker
//                     disabledTime
//                     onCalendarChange = {(e) => this.handleCalendarChange(e)}
//                     style={{margin:"0px 0px 20px 80px"}}

//                 /> */}
//         {this.state.chartLoading ? (
//           <Spin style={{ marginLeft: "20px" }} />
//         ) : (
//           <div>
//             <p style={{ margin: "0px 0px 20px 80px" }}>
//               {/* Usage of: */}
//               {this.state.chartDataRenderFor === "MONTHLY" ? (
//                 <b> {moment(this.state.endDate).format("MMMM YYYY")}</b>
//               ) : null}
//               {this.state.chartDataRenderFor === "WEEKLY" ? (
//                 <b>
//                   {" "}
//                   {moment(this.state.endDate)
//                     .subtract(7, "days")
//                     .format("MMMM Do YYYY")}{" "}
//                   to {moment(this.state.endDate).format("MMMM Do YYYY")}
//                 </b>
//               ) : null}
//             </p>
//             <ResponsiveContainer width="100%" height={200}>
//               <LineChart
//                 width={700}
//                 height={200}
//                 data={this.state.userUsageData}
//                 margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
//               >
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="date" />
//                 <YAxis />
//                 <Tooltip />
//                 <Legend />
//                 <Line
//                   type="monotone"
//                   dataKey="SPELLSUGGESTION"
//                   stroke="#8884d8"
//                 />
//                 <Line
//                   type="monotone"
//                   dataKey="FONTCONVERSION"
//                   stroke="#82ca9d"
//                 />
//               </LineChart>
//             </ResponsiveContainer>
//           </div>
//         )}
//       </div>
//     );
//   }
// }

// export default UserProductStatistics;

import React, { useEffect } from "react";
import { DatePicker, Space } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

const UserProductStatistics = () => {
  useEffect(() => {}, []);
  return (
    <>
      <RangePicker
        style={{
          width: "600px",
        }}
        placeholder={["start from", "end to"]}
        // defaultValue={[
        //   moment("2015/01/01", dateFormat),
        //   moment("2015/01/01", dateFormat),
        // ]}
        format={dateFormat}
      />
    </>
  );
};

export default UserProductStatistics;
