import React, { Component } from "react";
import { Row, Col } from "antd";


class LayoutPrivacyPolicy extends Component {
    render() {
        return (
            <div className="main-wrapper" >

                <Row
                    type="flex"
                    justify="space-around"
                    align="middle"
                    className="bg-blue-yellow-gradient"
                >
                    <Col span={20}>
                        <div
                            style={{
                                minHeight: "80vh",
                                display: "flex",
                                flexDirection: "column",
                                fontSize:"1.1em"
                            }}
                        >

                            <h1 style={{ marginBottom: "20px", textAlign: "center", fontSize: "2.2em", color: "#f3722c" }}>Saayak Privacy Policy</h1>
                            <p style={{ textDecoration: "italic" }}>Last Updated on January 16, 2017</p>

                            <p style={{ textAlign: "justify" }}>Integrated ICT Pvt. Ltd. values the privacy of your personal information and User Content. This Privacy Policy (this “Policy”) explains what information Integrated ICT Pvt. Ltd, a Kathmandu based company, and its subsidiaries and other affiliates (together, “I2CT” or “we” and sometimes “us”) collect about you and why, what we may do with that information and how we handle your personal information and User Content.</p>

                            <div style={{ padding: "0px 40px 0px 40px" }}>
                                <h3>What Is The Scope of This Privacy Policy?</h3>
                                <p style={{ textAlign: "justify" }}> This Policy is incorporated into I2CT’s Terms of Service and License Agreement located at integratedict.com.np (the “Terms of Service”), and applies to the information obtained by us through your use of the Site, the Services, the Software (together, “Products”) and other Integrated ICT’s products and services. Capitalized terms used in this Policy and not otherwise defined shall have the meanings provided for those terms in the Terms of Service.</p>
                            </div>


                            <div style={{ padding: "0px 40px 0px 40px" }}>
                                <h3>Will This Privacy Policy Ever Change?</h3>
                                <p style={{ textAlign: "justify" }}> As Integrated ICT evolves, we may need to update this Policy to keep pace with changes in our Products, our business and laws applicable to you; and us we will, however, always maintain our commitment to respect your privacy. We will email you (to your most recently provided email address) or post any revisions to this Policy, along with their effective date, in an easy to find area of the Site, so we recommend that you periodically check back here to stay informed of any changes. We will ask for your consent to such changes if required by applicable law; in other cases, please note that your continuation of your Integrated ICT’s any product/service account after any change means that you agree with, and consent to be bound by, the new Policy. If you disagree with any changes in this Policy and do not wish your information to be subject to the revised Policy, you will need to close your account in our site/system/product/service.</p>
                            </div>

                            <h2>I. INFORMATION COLLECTION AND USE</h2>

                            <div style={{ padding: "0px 40px 0px 40px" }}>
                                <h3>What Information Does Integrated ICT Collect About Me and Why?</h3>
                                <p style={{ textAlign: "justify" }}>
                                    <p> We collect very little personal information about our users. A list of the “Personal Information” Integrated ICT may collect, and why we collect it, follows:</p>
                                    <ul style={{ paddingLeft: "40px" }}>
                                        <li>Your username, email address and contact preferences. This lets us create your account, provide you with convenient and personalized access to your account and deliver and support our Products. This information also helps us communicate with you about your use of our Products, product announcements and software updates, as well as respond to your requests for assistance (including providing account verification support if you’re having difficultly accessing your account). In addition, we may use your Personal Information to send you direct marketing emails and special offers.</li>
                                        <li>For paying users, your payment information required to complete a commercial transaction on the Site. We use this information to enable and fulfill your transaction.</li>
                                        <li>The geographic area where you use your computer and mobile devices (as indicated by an IP address or similar identifier), and the language you select when interacting with our Products. We collect this information in order to help us deliver and support our Products, including by localizing your experience. This information also helps us improve our understanding of how our users use the Products.</li>
                                        <li>The Integrated ICT’s products and services you use. This information helps us deliver and support our Products and respond to your requests for assistance. We also collect this information to improve our understanding of how our users use our Products.</li>
                                        <li>Any User Content you add to your account. We don’t use this information for any purpose other than to provide the Services, make sure it is available to you when you use our Products and improve our algorithms underlying our Products.</li>

                                        <li>Unique device identifier for devices that are using Integrated ICT’s software. We use this information to
                                            <p>(i) calculate aggregate statistics on the number of unique devices using our Products, and</p>
                                            <p>(ii) detect and prevent misuse of our Products. Our third-party fraud detection partner also uses device identifiers for the detection and prevention of fraud and malicious use of our Products.</p>
                                        </li>
                                    </ul>
                                </p>
                                <p>The Site and the Software also collect and receive information from your computer or mobile device, including the activities you perform within your account, the type of hardware and software you are using (for example browser type) and information obtained from cookies. We use tawk.to messaging API to provide instant support to you. Finally, if you choose to use e-sewa/ipay and pay for your order, you will provide your credentials directly to e-sewa. The privacy policy of e-sewa® will apply to the information you provide on the e-sewa website.</p>
                            </div>

                            <div style={{ padding: "0px 40px 0px 40px" }}>
                                <h3>Will Integrated ICT Send Me Emails?</h3>
                                <p style={{ textAlign: "justify" }}> From time to time, we may want to contact you with information about product announcements, software updates and special offers. We consider your acceptance of this Policy as your acceptance of our offer to send you these emails. You may opt out of such communications at any time by clicking the “unsubscribe” link found within Integrated ICT email updates and changing your contact preferences.</p>
                            </div>

                            <div style={{ padding: "0px 40px 0px 40px" }}>
                                <h3>Does Integrated ICT Provide Any Information to Third-Party Analytics Providers?</h3>
                                <p style={{ textAlign: "justify" }}> Integrated ICT makes use of certain third-party system to facilitate our users. We use tawk.to to provide messaging facility for the support of our users.</p>
                            </div>

                            <h2>II. INFORMATION ACCESS AND DISCLOSURE</h2>

                            <div style={{ padding: "0px 40px 0px 40px" }}>
                                <h3>Does Integrated ICT Share My Personal Information or User Content?</h3>
                                <p style={{ textAlign: "justify" }}>
                                    <p> Integrated ICT is not in the business of selling or renting user information, and we only disclose information when:</p>
                                    <ul>
                                        <li>We have your explicit consent to share the information.</li>

                                        <li>We need to share your information with affiliated and unaffiliated service providers who process data on our behalf, including in connection with errors analysis (also called “error dumping”) and correction.</li>

                                        <li>We need to share your information with affiliated and unaffiliated service providers to fulfill your product or service requests.</li>

                                        <li>We believe it is necessary to investigate potential violations of the Terms of Service, to enforce those Terms of Service or where we believe it is necessary to investigate, prevent or take action regarding illegal activities, suspected fraud or potential threats against persons, property or the systems on which we operate our Products.</li>

                                        <li>We determine that the access, preservation or disclosure of information is required or permitted by law to protect the rights, property or personal safety of Integrated ICT and users of our Products, or is required to comply with applicable laws, including compliance with warrants, court orders or other legal process.</li>

                                        <li>We need to do so in connection with the sale or reorganization of all or part of our business, as permitted by applicable law. We do not share your information with third parties for the purpose of enabling them to deliver their advertisements to you.</li>
                                    </ul>
                                </p>
                            </div>

                            <div style={{ padding: "0px 40px 0px 40px" }}>
                                <h3>Does Integrated ICT Review User Content?</h3>
                                <p style={{ textAlign: "justify" }}> As a rule, Integrated CIT employees do not monitor or view your Personal Information or User Content stored in or transferred through our Products, but it may be viewed if we believe the Terms of Service have been violated and confirmation is required, if we need to do so in order to respond to your requests for user support, if we otherwise determine that we have an obligation to review it as described in the Terms of Service, or to improve our algorithms as described in the User Content section of our Terms of Service. In addition, our proofreaders may also read your User Content in connection with our provision of proofreading services. Finally, your Personal Information may be viewed where necessary to protect the rights, property or personal safety of Integrated ICT and its users, or in order to comply with our legal obligations, such as responding to warrants, court orders or other legal process.</p>
                            </div>

                            <div style={{ padding: "0px 40px 0px 40px" }}>
                                <h3>Does Integrated ICT Ever Make Any of My Personal Information or User Content Public?</h3>
                                <p style={{ textAlign: "justify" }}> No, except as otherwise described in this Policy.</p>
                            </div>


                            <h2>III. DATA STORAGE AND TRANSFER</h2>

                            <div style={{ padding: "0px 40px 0px 40px" }}>
                                <h3>Where Is My Data Stored?</h3>
                                <p style={{ textAlign: "justify" }}> When you use our Products, User Content you transmit will be stored on our servers, which are located inside Nepal. When you use the Software on your computing device, User Content you save will be stored locally on that device and sync with our servers. Please be aware that Personal Information and User Content submitted to Integrated ICT will be transferred to a data center inside Nepal. If you post or transfer any information to or through our Products, you are confirming your consent to such information, including Personal Information and User Content, being hosted and accessed in Nepal.</p>
                            </div>

                            <div style={{ padding: "0px 40px 0px 40px" }}>
                                <h3>How Secure Is My Data?</h3>
                                <p style={{ textAlign: "justify" }}> Integrated ICT is committed to protecting the security of your information and takes reasonable precautions to protect it. However, Internet data transmissions, whether wired or wireless, cannot be guaranteed to be 100% secure, and as a result, we cannot ensure the security of information you transmit to us, including Personal Information and User Content; accordingly, you acknowledge that you do so at your own risk. We use industry standard encryption to protect your data in transit. This is commonly referred to as transport layer security (“TLS”) or secure socket layer (“SSL”) technology. Once we receive your data, we protect it on our servers using a combination of administrative, physical and logical security safeguards. The security of the information stored locally in any of our Products installed on your computing device requires that you make use of the security features of your device. We recommend that you take the appropriate steps to secure all computing devices that you use with our applications and service. If Integrated ICT learns of a security system breach, we may attempt to notify you and provide information on protective steps, if available, through the email address that you have provided to us or by posting a notice on the Site. Depending on where you live, you may have a legal right to receive such notices in writing.</p>
                            </div>


                            <h2>IV. INFORMATION DELETION</h2>

                            <div style={{ padding: "0px 40px 0px 40px" }}>
                                <h3>What Happens If I Want to Stop Using products of Integrated ICT?</h3>
                                <p style={{ textAlign: "justify" }}> You can stop using our Products at any time. Residual copies of your Personal Information and deleted User Content may continue to exist on Integrated ICT’s back-up and archiving systems for up to one year due to the nature of those systems’ operations.</p>
                            </div>

                            <div style={{ padding: "0px 40px 0px 40px" }}>
                                <h3>What Happens If Integrated ICT Closes My Account?</h3>
                                <p style={{ textAlign: "justify" }}> If Integrated ICT closes your account due to your violation of the Terms of Service, then you may contact Integrated ICT to request deletion of your data, and Integrated ICT will evaluate such requests on a case by case basis, pursuant to our legal obligations.</p>
                            </div>

                            <h2>V. CONTACT US</h2>

                            <div style={{ padding: "0px 40px 0px 40px" }}>
                                <h3>How Can I Contact Integrated ICT?</h3>
                                <p style={{ textAlign: "justify" }}> You may contact us with any questions relating to this Privacy Policy by submitting a help desk request or by email, info@integratedict.com.np</p>
                            </div>




                        </div>
                    </Col>
                </Row>


            </div>
        );
    }

};


export default LayoutPrivacyPolicy