import React, { Component } from 'react'

import ContainerLoading from '../../layout/layoutUtils/ContainerLoading'

import { Table, Tag, Space, Spin, Modal, Button  } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import UserStatistics from './listAllUsersComponents/userStatistics'

import {
  r_listAllAdminUsers, 
  r_countTotalAdminUsers,
  r_deleteUser 
} from '../../helpers/Requesthelper/RequestAdminUserManagement'


const { confirm } = Modal;


class listAllAdminUsers extends Component {
    state = {
        //pageLoading: true,
        tableLoading : true,
        userList:[],
        pageStart: 0,
        pageEnd: 10,
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0,
        },
        selectedTableRow: {},
        userStatisticsDrawerVisible: false,

    }
    

    componentDidMount(){
      const {pagination} = this.state
      
      //this.countTotalUsers()
      this.countTotalAdminUsers().then(totalUserCount => {
        this.setState({pagination: {...pagination, total:totalUserCount}})
        console.log(this.state)
        this.renderUsers()
      })
      //this.renderUsers()
    }

    handleShowUserStatistics = (rowData) => {
      const {userName} = rowData
      console.log(rowData)
      this.setState({selectedTableRow: rowData}, ()=>{console.log(this.state)})
      this.setuserStatisticsDrawerVisible()
    }

    handlePagination = (tableStatus) => {
      console.log(tableStatus)
      const {pagination} = this.state

      const pageStartValue = (tableStatus.current * 10)-10
      //const pageEndValue = (tableStatus.current * 10) >  tableStatus.total? tableStatus.total : (tableStatus.current * 10) 
      const pageEndValue = (tableStatus.current * 10) 

      console.log(pageStartValue)
      console.log(pageEndValue)

      this.setState ({
        pageStart: pageStartValue,
        pageEnd : pageEndValue,
        pagination : {
          ...pagination,
          current: tableStatus.current,
        }
      }, ()=> {this.reRenderUsers()})
    }
    
    countTotalAdminUsers = () => {
      return new Promise ((resolve,reject)=> {
        r_countTotalAdminUsers (this.props.userDetails.userName, this.props.endpoints, this.props.auth.userToken).then(res => {
          console.log(res.data.value)
          resolve(res.data.value)
        })
      })
    }

    reRenderUsers = () => {
      this.setTableLoading()
      this.renderUsers()
    }
    renderUsers = () => {
      console.log("from list All users")
      console.log(this.state)
      //this.setPageLoading()
      

      r_listAllAdminUsers(this.props.userDetails.userName, this.props.auth.userToken, this.props.endpoints, this.state.pageStart, this.state.pageEnd).then(res => {
        console.log(res)
          const {itemListElement} = res.data
          console.log(itemListElement)
          this.setUserList(itemListElement)

          this.resetTableLoading()

        if(res.status === "success"){
          console.log(res)
          const {itemListElement} = res.data
          console.log(itemListElement)
          this.setUserList(itemListElement)
        }else if(res.status === "fail"){
          const {message,messageId} = res.data
          this.setState({errorMessage:message})
        }
          
      })
    }
    setuserStatisticsDrawerVisible = () => {
      this.setState({userStatisticsDrawerVisible: true})
    }
    resetuserStatisticsDrawerVisible = () => {
      this.setState({userStatisticsDrawerVisible: false})
    }
    setPageLoading = () => {
      this.setState({pageLoading:true})
    }
    resetPageLoading = () => {
      this.setState({pageLoading:false})
    }
    setTableLoading = () => {
      this.setState({tableLoading:true})
    }
    resetTableLoading = () => {
      this.setState({tableLoading:false})
    }

    setUserList = (users) => {
        let totalUserList = []
        if(users){
            users.map((item,index) => {
              const {approvedStatus, createdDate, description, email, name, userName} = item
              const descriptionList = description.split(',')
              const user = {
                  key: '1',
                  name: name,
                  email: email,
                  username: userName,
                  loggedinusing: descriptionList,
                  activestatus: approvedStatus? "VERIFIED" : "NOT VERIFIED"
              }
              
              console.log(user.username)
              totalUserList.push(user)
          })
        }
        

        console.log(totalUserList)
        this.setState({userList: totalUserList, pageLoading: false})
    }
    render() {
        return (
            <div>
                {/* {
                    this.state.tableLoading?
                    <ContainerLoading message="Generating User Table" />: */}
                    <RenderAdminUserList 
                      userList = {this.state.userList} 
                      {...this.props} 
                      renderUsers = {this.renderUsers} 
                      setPageLoading = {this.setPageLoading}
                      resetPageLoading = {this.resetPageLoading}

                      handlePagination = {this.handlePagination}
                      pagination = {this.state.pagination}

                      tableLoading = {this.state.tableLoading}
                      setTableLoading = {this.setTableLoading}
                      resetTableLoading = {this.resetTableLoading}

                      handleShowUserStatistics = {this.handleShowUserStatistics}
                    />
                        <UserStatistics 

                        {...this.props}

                        slectedUserRowDetails = {this.state.selectedTableRow}

                        userStatisticsDrawerVisible = {this.state.userStatisticsDrawerVisible}
                        setuserStatisticsDrawerVisible = {this.setuserStatisticsDrawerVisible}
                        resetuserStatisticsDrawerVisible = {this.resetuserStatisticsDrawerVisible}

                      />
                    
                {/* }  */}
            </div>
        )
    }
}


class RenderAdminUserList extends Component {

    state = {
    }

    handleTableChange = (e) => {
      this.props.handlePagination(e)
    }
    onDeleteUserConfirm = (rowData) => {
      //console.log("delete user initiated")

        //this.setState({tableLoading: true})
        this.props.setTableLoading()

        //console.log(rowData)
        //console.log("User Deletion ")
        const {username} = rowData

        r_deleteUser (username, this.props.userDetails.userName, this.props.endpoints, this.props.auth.userToken).then(
          resp => {
            //console.log("userDeleted")
            this.props.renderUsers()
            //this.setState({tableLoading: false})
            this.props.resetTableLoading()
          }
        )
    }
    handleUserDelete = (rowData) => {

      confirm({
        icon: <ExclamationCircleOutlined />,
        content: `Click on OK to delete the user.`,
        onOk: () => {this.onDeleteUserConfirm(rowData)},
        onCancel() {
          //console.log('Cancel');
          //return false
        },
      });

        // this.setState({tableLoading: true})

        // console.log(rowData)
        // console.log("User Deletion ")
        // const {username} = rowData

        // r_deleteUser (username, this.props.endpoints, this.props.auth.userToken).then(
        //   resp => {
        //     console.log("userDeleted")
        //     this.props.renderUsers()
        //     this.setState({tableLoading: false})
        //   }
        // )

    }
    
     columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: text => <a>{text}</a>,
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'User Name',
          dataIndex: 'username',
          key: 'username',
        },
        {
            title: 'Account Verified',
            dataIndex: 'activestatus',
            key: 'activestatus',
        },
        {
          title: 'Logged In Using',
          key: 'loggedinusing',
          dataIndex: 'loggedinusing',
          render: tags => (
            <>
              {tags.map(tag => {
                let color = 'green'
                if (tag === 'GOOGLE') {
                  color = 'blue';
                }
                return (
                  <Tag color={color} key={tag}>
                    {tag.toUpperCase()}
                  </Tag>
                );
              })}
            </>
          ),
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <Space size="middle">
              <a onClick={()=>this.handleUserDelete(record)}><Button type="dashed" size='small'>Delete</Button></a>
              <a onClick={()=>this.props.handleShowUserStatistics(record)}><Button type="dashed" size='small'>Statistics</Button></a>
              <a><Button type="dashed" size='small'>Edit</Button></a>
            </Space>
          ),
        },
      ];
      
      
    render(){
        return(
            <Table 
              columns={this.columns} 
              dataSource={this.props.userList} 
              loading = {this.props.tableLoading}
              pagination = {this.props.pagination}

              onChange={this.handleTableChange}

              
              />
        )
    }
}
export default listAllAdminUsers