import React, { useState, useEffect, useRef } from "react";
import "./FontConversionStyle.css";
import axios from "axios";
import {
  Input,
  Row,
  Col,
  Button,
  Select,
  Spin,
  Form,
  Alert,
  Space,
} from "antd";
import {
  CheckCircleTwoTone,
  SwapOutlined,
  CopyOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import copy from "copy-to-clipboard";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { r_userFontConversion } from "../../helpers/Requesthelper/RequestFontConversion";
import { r_addServiceUserLog } from "../../helpers/Requesthelper/RequestStatistics";
import { FaExchangeAlt, FaLongArrowAltRight } from "react-icons/fa";
import { updatePageHead } from "../common/UpdateMetaAndTitle";
import { Base64 } from "js-base64";

const { TextArea } = Input;
const { Option } = Select;

const FontConversion = (props) => {
  const [serviceName] = useState("FONTCONVERSION");
  const [initialFont, setInitialFont] = useState("");
  const [finalFont, setFinalFont] = useState("");
  const [callWord, setCallWord] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [wordCountLimit] = useState(100);
  const [conversionResult, setConversionResult] = useState("");
  const [conversionResultCopied, setConversionResultCopied] = useState(false);
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const textareaConvertedFont = useRef(null);

  useEffect(() => {
    // Uncomment if you want to use local storage
    // if (localStorage.getItem("fontConversionData")) {
    //   const { fromFont, toFont, sourceWord } = JSON.parse(localStorage.getItem("fontConversionData"));
    //   setInitialFont(fromFont);
    //   setFinalFont(toFont);
    //   setCallWord(sourceWord);
    // }
    updatePageHead(
      "Font Conversion",
      "Nepali Font,  Nepali Font Conversion, Preeti to Unicode, Preeti, Unicode, Kantiput font, Ganesh font, TTF font, Shangrila Font, Shrinath font, PCS font, Aalek font, नेपाली फन्ट, नेपाली फन्ट रुपान्तरण, कन्तिपुर फन्ट, प्रीति फन्ट, युनिकोड फन्ट",
      "Nepali Font,  Nepali Font Conversion, Preeti to Unicode, Preeti, Unicode, Kantiput font, Ganesh font, TTF font, Shangrila Font, Shrinath font, PCS font, Aalek font, नेपाली फन्ट, नेपाली फन्ट रुपान्तरण, कन्तिपुर फन्ट, प्रीति फन्ट, युनिकोड फन्ट"
    );
  }, []);

  const handleConversion = () => {
    setErrors("");
    setLoading(true);
    setConversionResult("");

    if (initialFont && finalFont && callWord) {
      const requestData = {
        fromFont: initialFont,
        toFont: finalFont,
        sourceWord: Base64.encode(callWord),
      };

      localStorage.setItem("fontConversionData", JSON.stringify(requestData));

      r_userFontConversion(
        requestData,
        props.endpoints,
        props.auth.userToken
      ).then((resp) => {
        console.log(resp);
        setConversionResult(resp.data.text);
        setLoading(false);

        // r_addServiceUserLog(props.userDetails.userName, serviceName, props.endpoints, props.auth.userToken);
      });
    } else if (!initialFont) {
      setErrors("हालको Font छानेर मात्र Font Convert थिच्नुहोस्।");
      setConversionResult("");
      setLoading(false);
    } else if (!finalFont) {
      setErrors("Convert गर्नुपर्ने Font छानेर मात्र Font Convert थिच्नुहोस्।");
      setConversionResult("");
      setLoading(false);
    } else {
      setErrors(
        "तलको बाकसमा केहि लेखेर वा Copy गरेर मात्र Font Convert थिच्नुहोस्।।"
      );
      setConversionResult("");
      setLoading(false);
    }
  };

  const handleCopyText = () => {
    copy(conversionResult, { format: "text/plain" });
    setConversionResultCopied(true);
    setTimeout(() => {
      setConversionResultCopied(false);
    }, 500);
  };

  const fontList = {
    preeti: "प्रीति",
    unicode: "युनिकोड",
    aakriti: "आकृति",
    aalekh: "आलेख",
    ganess: "गणेश",
    himalb: "हिमालबी",
    kantipur: "कान्तिपुर",
    navjeevan: "नवजीवन",
    PCSnepali: "पिसिएस",
    shangrila: "साङ्ग्रिला",
    shreenath: "श्रीनाथ",
    sumodacharya: "सुमुदाचार्य",
  };
  const fonts = [
    "preeti",
    "unicode",
    "aakriti",
    "aalekh",
    "ganess",
    "himalb",
    "kantipur",
    "navjeevan",
    "PCSnepali",
    "shangrila",
    "shreenath",
    "sumodacharya",
  ];

  //   const generateDoc = (textContent) => {
  //     // Define the DOC file content in a simple format
  //     const content = `MIME-Version: 1.0
  // Content-Type: multipart/related; boundary="----=_NextPart_01C3F3A4.6E0E62C0"

  // ------=_NextPart_01C3F3A4.6E0E62C0
  // Content-Type: text/html; charset="utf-8"
  // Content-Transfer-Encoding: 8bit

  // <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  // <html xmlns="http://www.w3.org/1999/xhtml">
  // <head>
  // <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  // <title>Document</title>
  // </head>
  // <body>
  // <p>${textContent}</p>
  // </body>
  // </html>

  // ------=_NextPart_01C3F3A4.6E0E62C0--`;

  //     // Create a Blob from the content
  //     const blob = new Blob([content], {
  //       type: "application/msword",
  //     });

  //     // Create a link element
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = "saayak.doc";

  //     // Append link to the body
  //     document.body.appendChild(link);

  //     // Programmatically click the link to trigger the download
  //     link.click();

  //     // Remove the link from the document
  //     document.body.removeChild(link);
  //   };

  const generateDoc = (textContent, fontName) => {
    // Define the DOC file content with inline CSS to preserve the font name
    const content = `MIME-Version: 1.0
Content-Type: multipart/related; boundary="----=_NextPart_01C3F3A4.6E0E62C0"

------=_NextPart_01C3F3A4.6E0E62C0
Content-Type: text/html; charset="utf-8"
Content-Transfer-Encoding: 8bit

<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
<title>Document</title>
<style>
  body {
    font-family: '${fontName}', sans-serif;
  }
</style>
</head>
<body>
<p>${textContent}</p>
</body>
</html>

------=_NextPart_01C3F3A4.6E0E62C0--`;

    // Create a Blob from the content
    const blob = new Blob([content], {
      type: "application/msword",
    });

    // Create a link element
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fontName}_converted.doc`;

    // Append link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <div>
      <div className="user-font-conversion__container">
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={20} xl={20} xxl={20}>
            <Form
              style={{
                display: "flex",
                // flexWrap: "wrap",
                padding: "10px",
                alignItems: "center",
                // justifyContent: "space-between",
              }}
            >
              <Space
                size="middle"
                style={{
                  width: "100%",
                  flexWrap: "wrap",
                  // justifyContent: "center",
                }}
              >
                <Form.Item style={{ flex: "1 1 auto", minWidth: "250px" }}>
                  <Select
                    showSearch
                    size="large"
                    style={{ width: "100%" }}
                    placeholder={
                      <div style={{ fontWeight: "bold" }}>
                        हालको Font छान्नुहोस्
                      </div>
                    }
                    allowClear
                    onChange={(e) => {
                      setInitialFont(e);
                      setConversionResult("");
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {fonts
                      .filter((font) => font !== finalFont)
                      .map((font, index) => (
                        <Option key={index} value={font}>
                          {fontList[font]}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaLongArrowAltRight style={{ fontSize: "20px" }} />
                </div>

                <Form.Item style={{ flex: "1 1 auto", minWidth: "250px" }}>
                  <Select
                    showSearch
                    disabled={!initialFont}
                    size="large"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={
                      <div style={{ fontWeight: "bold" }}>
                        Convert गर्नुपर्ने Font छान्नुहोस्
                      </div>
                    }
                    onChange={(e) => {
                      setFinalFont(e);
                      setConversionResult("");
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {fonts
                      .filter((font) => font !== initialFont)
                      .map((font, index) => (
                        <Option key={index} value={font}>
                          {fontList[font]}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item style={{ flex: "1 1 auto", minWidth: "250px" }}>
                  <Button
                    type="primary"
                    size="large"
                    style={{ width: "100%" }}
                    onClick={handleConversion}
                  >
                    Font Convert गर्न यहाँ थिच्नुहोस्
                  </Button>
                </Form.Item>

                <div style={{ flex: "1 1 100%" }}>
                  {errors ? (
                    <Alert
                      onClose={() => setErrors("")}
                      message={errors}
                      type="info"
                      closable
                    />
                  ) : null}
                </div>
              </Space>
            </Form>
          </Col>
        </Row>
        <Row className="height-100">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={conversionResult ? 12 : 24}
            xl={conversionResult ? 12 : 24}
            xxl={conversionResult ? 12 : 24}
            title="Original Text"
            // className="user-font-conversion__left-section"
            // style={{ borderRight: "2px solid #ccc" }}
          >
            <div className="user-font-conversion__body">
              {conversionResult ? (
                <p style={{ fontWeight: "bold" }}>Font Conversion अघि</p>
              ) : null}

              <Input.TextArea
                value={callWord}
                placeholder="यहाँ केहि लेख्नुहोस् वा कपी गर्नुहोस् अनि फन्ट कन्भर्ट गर्ने बटनमा क्लिक गर्नुहोस्।"
                className="font-conversion-textarea"
                allowClear
                style={{ height: "90%", fontFamily: `"${initialFont}"` }}
                onChange={(e) => setCallWord(e.target.value)}
              />
            </div>
          </Col>
          {conversionResult ? (
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <div className="user-font-conversion__body">
                {loading ? (
                  <Spin />
                ) : (
                  <>
                    <p style={{ fontWeight: "bold" }}>Font Conversion पछि</p>
                    <TextArea
                      placeholder="रूपान्तरित शब्दहरू यहाँ देखिनेछन् ।"
                      id="font-conversion-result-textArea"
                      className="font-conversion-textarea"
                      ref={textareaConvertedFont}
                      autoSize={false}
                      style={{ height: "90%", fontFamily: `"${finalFont}"` }}
                      value={conversionResult}
                    />
                  </>
                )}
              </div>
              <div className="user-font-conversion__bottom flex-row-justify-space-between-2">
                <Button type="primary" size="large" onClick={handleCopyText}>
                  <CopyOutlined />
                  Copy to Clipboard
                </Button>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    generateDoc(conversionResult, finalFont);
                  }}
                >
                  <DownloadOutlined />
                  Download as Doc
                </Button>
                {conversionResultCopied && (
                  <CheckCircleTwoTone
                    twoToneColor="#52c41a"
                    style={{ marginLeft: "5px", fontSize: "1.4em" }}
                  />
                )}
              </div>
            </Col>
          ) : null}
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  endpoints: state.apiConstants,
});

export default connect(mapStateToProps, {})(withRouter(FontConversion));
