import React, { Component } from "react";

import "./dashboard-style.css";
import SayakLogo from "../../images/sayak_logo.png";
import {
  UserOutlined,
  PhoneOutlined,
  BookOutlined,
  QuestionCircleOutlined,
  StarOutlined,
  FormOutlined,
} from "@ant-design/icons";

import { updatePageHead } from "../../components/common/UpdateMetaAndTitle";

import { Layout, Menu, Row, Col, Button, Alert } from "antd";
import NavBarUser from "./NavBarUser";
import UserNav from "./UserNav";
import { HashRouter as Router } from "react-router-dom";

import Footer from "./Footer";

import { UserWelcomePage } from "../../components/userComponents/UserWelcomePage";
import { SpellingCheck } from "../../components/userComponents/SpellingCheck";
import FontConversion from "../../components/userComponents/FontConversion";
import { DictSearch } from "../../components/userComponents/DictSearch/DictSearch";
import ContactForm from "../publicLayout/layoutComponents/ContactForm";
import FAQuser from "./layoutComponents/FAQuser";
import { UserProfile } from "../../components/userComponents/userProfile";
import { UserStatisticsMain } from "../../components/userComponents/userStatisticsMain";
import { LinkArchive } from "../../components/userComponents/LinkArchive";
import { TypeInNepali } from "../../components/userComponents/TypeInNepali";
import { TypeInNepaliTTF } from "../../components/userComponents/TypeInNepaliTTF";
import LayoutPrivacyPolicy from "./LayoutPrivacyPolicy";
import LayoutTermsOfUse from "./LayoutTermsOfUse";
import { NEW_LAYOUT_PATH, OLD_LAYOUT_PATH } from "../../helpers/Constants/path";

import {
  Route,
  Link,
  NavLink,
  Redirect,
  withRouter,
  Switch,
} from "react-router-dom";
import { Prompt } from "react-router";

import { connect } from "react-redux";
import { appBusyReset, appBusySet } from "../../store/actions/appStateAction";
import {
  resetUserAuth,
  logOutSayakUser,
  logOutNonSayakUser,
} from "../../store/actions/authAction";
import Loading from "../layoutUtils/Loading";

const { SubMenu } = Menu;
const { Content, Sider } = Layout;
const { Header } = Layout;

export class LayoutUserDashboard extends Component {
  state = {
    mainMenuSelectedItem: "0",
  };

  componentDidMount() {
    updatePageHead("DashBoard", "", "");
    this.updateMenuBasedOnRoute("0");

    //console.log(this.props)
    //check if logged in
    //if not then redirect to login

    if (!localStorage.getItem("sayakusertoken")) {
      // console.log("not logged in");
      this.props.history.push({ OLD_LAYOUT_PATH });
      //this.props.resetUserAuth()
    } else if (localStorage.getItem("sayakusertoken")) {
      //this is good
      //user logged in cha
    }
  }

  handleMenuItemClicked = (e) => {
    // console.log("i am event ", e)

    //this.setState({mainMenuSelectedItem : e.key})

    this.updateMenuBasedOnRoute(e.key);
  };

  updateMenuBasedOnRoute = (key) => {
    if (key > 0) {
      this.setState({ mainMenuSelectedItem: key });
    } else {
      let pathValues = {
        [OLD_LAYOUT_PATH]: "1",
        "/hijjeold/spelling": "2",
        "/hijjeold/typeInNepali": "3",
        // '/hijjeold/typeInNepaliTTF': '4',
        "/hijjeold/fontConversion": "4",
        "/hijjeold/dictSearch": "5",
        // '/hijjeold/linkArchive': '7',
        "/hijjeold/FAQ": "6",
        "/hijjeold/contact": "7",

        // [`${OLD_LAYOUT_PATH} /spelling`]: '2',
        // [`${OLD_LAYOUT_PATH} /typeInNepali`]: '3',
        // [`${OLD_LAYOUT_PATH} /typeInNepaliTTF`]: '4',
        // [`${OLD_LAYOUT_PATH} /fontConversion`]: '5',
        // [`${OLD_LAYOUT_PATH} /dictSearch`]: '6',
        // [`${OLD_LAYOUT_PATH} /linkArchive`]: '7',
        // [`${OLD_LAYOUT_PATH} /FAQ`]: '8',
        // [`${OLD_LAYOUT_PATH} /contact`]: '9',
      };

      let currentPath = this.props.location.pathname;
      // console.log("mah current path ho", currentPath)

      for (const [key, value] of Object.entries(pathValues)) {
        // console.log(currentPath, key)

        if (currentPath === key) {
          this.setState({ mainMenuSelectedItem: value });
        } else {
          // console.log("milena")
        }
      }
    }
  };

  openSurvey = () => {
    window.open("https://forms.gle/eYbM6bLaDjnzWhBD6", "_blank");
  };

  render() {
    //console.log(this.props)

    return (
      <div className="dashboard-main-wrapper" id="footer-scroll">
        <NavBarUser {...this.props} />

        {this.props.appState.busy ? (
          <Loading messageSpin="Logging In Please Wait" />
        ) : null}

        <Row type="flex" justify="space-around">
          <Col
            className="user-dashboard-layout-left"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 4 }}
            xl={{ span: 4 }}
            xxl={{ span: 4 }}
          >
            <div className="logo-section" style={{ textAlign: "center" }}>
              <Link to="/">
                <img src={SayakLogo} className="logo-desktop" />
              </Link>
            </div>

            <div className="dashboard-main-menu">
              <Menu
                theme=""
                mode="inline"
                //defaultSelectedKeys={this.state.mainMenuSelectedItem}
                selectedKeys={this.state.mainMenuSelectedItem}
                //defaultOpenKeys={["sub1"]}
                // style={{ height: "100%", borderRight: 0}}
                style={{ background: "none" }}
              >
                <Menu.Item
                  key="1"
                  icon={UserOutlined}
                  onClick={this.handleMenuItemClicked}
                >
                  <span>
                    <UserOutlined /> Dash Board
                  </span>
                  <Link to={OLD_LAYOUT_PATH} />
                </Menu.Item>

                {/* <SubMenu key="sub1" title={<span>Products Subscribed</span>}> */}
                <Menu.Item key="2" onClick={this.handleMenuItemClicked}>
                  <span>
                    <StarOutlined />
                    नेपाली हिज्जे परीक्षण
                  </span>
                  <Link to={OLD_LAYOUT_PATH + "/spelling"} />
                </Menu.Item>
                <Menu.Item key="3" onClick={this.handleMenuItemClicked}>
                  <span>
                    <StarOutlined />
                    रोमन नेपाली टाइपिङ{" "}
                  </span>
                  <Link to={OLD_LAYOUT_PATH + "/typeInNepali"} />
                </Menu.Item>
                {/* <Menu.Item key="4" onClick={this.handleMenuItemClicked} >
                  <span><StarOutlined />फन्ट टाइपिङ  </span>
                  <Link to={OLD_LAYOUT_PATH + "/typeInNepaliTTF"} />
                </Menu.Item> */}

                <Menu.Item key="4" onClick={this.handleMenuItemClicked}>
                  <span>
                    <StarOutlined />
                    फन्ट रूपान्तरण
                  </span>
                  <Link to={OLD_LAYOUT_PATH + "/fontConversion"} />
                </Menu.Item>
                <Menu.Item key="5" onClick={this.handleMenuItemClicked}>
                  <span>
                    <BookOutlined />
                    नेपाली शब्दकोश
                  </span>
                  <Link to={OLD_LAYOUT_PATH + "/dictSearch"} />
                </Menu.Item>

                {/* <Menu.Item key="7" onClick={this.handleMenuItemClicked} >
                  <span><BookOutlined />सङ्कलित सामग्रीहरू</span>
                  <Link to="/user/linkArchive" />
                </Menu.Item> */}

                {/* </SubMenu> */}

                <Menu.Item key="6" onClick={this.handleMenuItemClicked}>
                  <span>
                    <QuestionCircleOutlined />
                    सहयोगी प्रश्नोतर
                  </span>
                  <Link to={OLD_LAYOUT_PATH + "/FAQ"} />
                </Menu.Item>

                <Menu.Item key="7" onClick={this.handleMenuItemClicked}>
                  <span>
                    <PhoneOutlined />
                    सम्पर्क
                  </span>
                  <Link to={OLD_LAYOUT_PATH + "/contact"} />
                </Menu.Item>
                <Menu.Item key="8" onClick={this.openSurvey}>
                  <span>
                    <FormOutlined />
                    सायक सर्वेक्षण
                  </span>
                  {/* <Link to="/user/contact" /> */}
                </Menu.Item>
              </Menu>
            </div>
            {/* <div>
                <img src={adImage} alt="" width="100%"/>
              </div> */}
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 20 }}
            xl={{ span: 20 }}
            xxl={{ span: 20 }}
          >
            <div className="user-dashboard-layout-right">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Link to={NEW_LAYOUT_PATH}>
                  <Alert
                    message="नयाँ website मा जान यहाँ click गर्नुहोस् ।"
                    type="info"
                    closable
                    showIcon
                    style={{ fontSize: "1.2em" }}
                  />
                </Link>
              </div>
              <Router basename={OLD_LAYOUT_PATH}>
                <UserNav
                  {...this.props}
                  updateMainNav={this.updateMenuBasedOnRoute}
                />
                {/* <div className="user-header-layout-up">
                <Row>
                  <Header style={{ backgroundColor: "white", width: "100%", display: "flex", justifyContent: "space-around" }}>
                    <Menu theme="" mode="horizontal" selectedKeys={this.state.mainMenuSelectedItem} style={{ fontSize: "1.2em" }} className="usernav-header-menu" >
                      <Menu.Item key="1" onClick={this.handleMenuItemClicked} >
                        <Button><StarOutlined />नेपाली हिज्जे परीक्षण</Button>
                        <Link to="/user/spelling" />
                      </Menu.Item>
                      <Menu.Item key="2" onClick={this.handleMenuItemClicked} >
                        <Button><StarOutlined />नेपालीमा टाइप गर्नुहोस्।</Button>
                        <Link to="/user/typeInNepali" />
                      </Menu.Item>
                      <Menu.Item key="3" onClick={this.handleMenuItemClicked} >
                        <Button><StarOutlined />फन्ट रूपान्तरण</Button>
                        <Link to="/user/fontConversion" />
                      </Menu.Item>
                      <Menu.Item key="4" onClick={this.handleMenuItemClicked} >
                        <Button><BookOutlined />नेपाली शब्दकोश</Button>
                        <Link to="/user/dictSearch" />
                      </Menu.Item>
                    </Menu>
                  </Header>

                </Row>
              </div> */}
                <div className="container">
                  <div className="user-dashboard-main-section">
                    <Route
                      exact
                      path="/"
                      component={() => <UserWelcomePage {...this.props} />}
                      // component={() => <SpellingCheck {...this.props} />}
                    />

                    <Route
                      exact
                      path="/spelling"
                      component={() => <SpellingCheck {...this.props} />}
                    />

                    <Route
                      exact
                      path="/typeInNepali"
                      component={() => <TypeInNepali {...this.props} />}
                    />
                    <Route
                      exact
                      path="/typeInNepaliTTF"
                      component={() => <TypeInNepaliTTF {...this.props} />}
                    />

                    <Route
                      exact
                      path="/fontConversion"
                      component={() => <FontConversion {...this.props} />}
                    />

                    <Route
                      exact
                      path="/dictSearch"
                      component={() => <DictSearch {...this.props} />}
                    />

                    {/* <Route
                  exact
                  path="/user/linkArchive"
                  component={() => <LinkArchive {...this.props} />}
                /> */}

                    <Route exact path="/FAQ" component={() => <FAQuser />} />

                    <Route
                      exact
                      path="/contact"
                      component={() => <ContactForm {...this.props} />}
                    />

                    <Route
                      exact
                      path="/stat"
                      component={() => <UserStatisticsMain {...this.props} />}
                    />

                    <Route
                      exact
                      path="/profile"
                      component={() => <UserProfile {...this.props} />}
                    />
                    <Route
                      exact
                      path="/termsofuse"
                      component={() => <LayoutTermsOfUse />}
                    />
                    <Route
                      exact
                      path="/privacypolicy"
                      component={() => <LayoutPrivacyPolicy />}
                    />
                  </div>
                </div>
                <div>
                  <Footer />
                </div>
              </Router>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  endpoints: state.apiConstants,
  userDetails: state.userDetails,
  appState: state.appState,
});

export default connect(mapStateToProps, {
  logOutSayakUser,
  logOutNonSayakUser,
  appBusySet,
  appBusyReset,
})(withRouter(LayoutUserDashboard));
