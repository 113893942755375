import { APP_BUSY_SET, APP_BUSY_RESET } from "../actions/types";
const initialState = {
  busy: false,
  busyMessage: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case APP_BUSY_RESET:
      return {
        ...state,
        busy: false,
        busyMessage: "",
      };
    case APP_BUSY_SET:
      return {
        ...state,
        busy: true,
        busyMessage: action.payload,
      };
    default:
      return state;
  }
}
