import React, { Component } from "react";

import { UserOutlined, LockOutlined, MobileOutlined } from "@ant-design/icons";

import { updatePageHead } from "../../components/common/UpdateMetaAndTitle";

import NavBar from "./NavBar";
import Footer from "./Footer";

import { withRouter, Link } from "react-router-dom";

import isEmpty from "../../validation/is-empty";

import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Modal,
  Spin,
  Alert,
  Tabs,
} from "antd";

import Loading from "../layoutUtils/Loading";
import PopupWarning from "../layoutUtils/PopupWarning";

import LoginWithGoogleAndFacebook from "../layoutUtils/LoginWithGoogleAndFacebook";

import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  loginUser,
  loginUserWithFacebook,
  loginUserWithGoogle,
  resetUserAuth,
} from "../../store/actions/authAction";
import { appBusyReset, appBusySet } from "../../store/actions/appStateAction";
import { resetAuthErrors } from "../../store/actions/authErrorAction";
import MobileNumberLoginComponent from "./MobileNumberLoginComponent";
import SayakLogo from "../../images/sayak_logo.png";
import { FcGoogle } from "react-icons/fc";
import { FaMobileAlt } from "react-icons/fa";
import MobileLoginComponent from "./MobileLoginComponent";
import LoginComponent from "./LoginComponent";
import TypeInNepaliMessage from "./layoutComponents/TypeInNepaliMessage";
class LayoutLogin extends Component {
  state = {
    email: "",
    password: "",
    errors: {},
    open: false,
  };

  componentDidMount() {
    updatePageHead("Login", "", "");
    this.props.resetUserAuth();
    this.props.resetAuthErrors();
    this.props.appBusyReset();

    //reset the local storage here
    localStorage.removeItem("sayakuserregisterdetails");
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onFinish = (values) => {
    //make the call for action here
    const userData = {
      userName: this.state.email,
      userPassword: this.state.password,
    };

    console.log(this.state.password);

    const appStateData = { message: "Logging In" };
    this.props.appBusySet(appStateData);

    this.props.loginUser(userData, this.props.history, this.props.endpoints);

    //console.log("Received values of form: ", values);
  };

  componentClicked = (e) => {
    e.preventDefault();
    //console.log(e);
  };

  handleCancel = () => {
    console.log("Clicked cancel button");
    this.setState({ open: false });
  };

  render() {
    const layout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 24 },
    };

    return (
      <div className="main-wrapper">
        <NavBar />
        {this.props.appState.busy ? (
          <Loading messageSpin="Logging In Please Wait" />
        ) : null}

        <Row
          // className="main-bannerXXX"
          // className="wrapper-gray"
          type="flex"
          justify="space-around"
          align="middle"
          style={{ paddingTop: "10px" }}
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <div className="home-banner-left">
              <h1
                className="nepali-text"
                style={{
                  fontSize: "2.5em",
                  textAlign: "center",
                  color: "rgba(235, 122, 52)",
                }}
              >
                सजिलो नेपाली
              </h1>
              <p
                className="nepali-text"
                style={{ fontSize: "1.1em", textAlign: "center" }}
              >
                हिज्जे परीक्षण, फन्ट रूपान्तरण, शब्दकोश, युनिकोड टाइपिङ, अन्य
                सन्दर्भ सामग्री एकै ठाउँमा नि:शुल्क सेवा प्रयोग गर्न सक्नुहुनेछ।
              </p>
            </div>
          </Col>
        </Row>

        <div>
          <Row
            type="flex"
            justify="center"
            style={{ textAlign: "center", minHeight: "60vh" }}
            className="container home-products-tabs"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 20 }}
              xl={{ span: 20 }}
              xxl={{ span: 20 }}
            >
              <Row
                type="flex"
                justify="space-around"
                align="middle"
                // className="bg-blue-yellow-gradient"
              >
                <Col
                  xs={{ span: 22 }}
                  sm={{ span: 22 }}
                  md={{ span: 22 }}
                  lg={{ span: 16 }}
                  xl={{ span: 16 }}
                  xxl={{ span: 16 }}
                  // style={{ display: "flex", justifyContent: "center" }}
                >
                  <div style={{ minHeight: "80vh", margin: "auto" }}>
                    <div className="form-container">
                      <LoginComponent />
                    </div>
                  </div>
                  {/* <LoginComponent /> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {/* new section
         */}

        {/* <div className="wrapper-gray"> */}
        {/* <div>
          <Row type="flex" justify="center" className="container-heading">
            <Col span={20} style={{ textAlign: "center" }}>
              <TypeInNepaliMessage />
            </Col>
          </Row>
        </div> */}

        <Footer />

        <Modal
          title={
            <>
              <img src={SayakLogo} className="logo-desktop" />
            </>
          }
          visible={this.state.open}
          footer={null}
          destroyOnClose
          // onOk={handleOk}
          // confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        >
          <p>
            <MobileNumberLoginComponent />
          </p>
        </Modal>
      </div>
    );
  }
}

LayoutLogin.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  autherrors: state.authErrors,
  appState: state.appState,
  endpoints: state.apiConstants,
});

export default connect(mapStateToProps, {
  loginUser,
  resetAuthErrors,
  resetUserAuth,
  appBusySet,
  appBusyReset,
  loginUserWithFacebook,
  loginUserWithGoogle,
})(withRouter(LayoutLogin));
