import React, { Component } from 'react'
import ProductUsageStatistics from './ProductUsageStatistics'

export default class LoadAllStatistics extends Component {
    render() {
        return (
            <div>
               <ProductUsageStatistics {...this.props} /> 
            </div>
        )
    }
}
