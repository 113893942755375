import React, { Component } from "react";

import DummyImage from "./images/yoda.jpg";
import SayakIntroImage from "../../images/saayak.gif";
import fontConversionHomeImage from "../../images/fontConversionImageHome.png";
import NepaliSpellingCheckHome from "../../images/spell.gif";
import dictionarysearchHomeImage from "../../images/dictionarySearch.jpg";

import { updatePageHead } from "../../components/common/UpdateMetaAndTitle";

import TypeInNepaliMessage from "./layoutComponents/TypeInNepaliMessage";

import { SpellingCheck } from "../../components/publicComponents/SpellingCheck";
import FontConversion from "../../components/publicComponents/FontConversion";
import { DictSearch } from "../../components/publicComponents/DictSearch/DictSearch";
import { DocumentArchive } from "../../components/publicComponents/DocumentArchive";
import LinkArchive from "../../components/publicComponents/LinkArchive";
import DownloadMSWordPluginAffix from "../layoutUtils/DownloadMSWordPluginAffix";

import Footer from "./Footer";

import NavBar from "./NavBar";

import { connect } from "react-redux";

import { Row, Col, Menu, Icon, Tabs, Button, Card, Modal, Space } from "antd";
import {
  Link as ScrollLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import { Link, withRouter } from "react-router-dom";

const { TabPane } = Tabs;

class LayoutHome extends Component {
  state = {
    activeTabHome: "1",
    nepaliTypingHelpMessageShow: false,
  };

  howToUseSpellingCheck = () => {
    Modal.info({
      title: "नेपाली हिज्जे परिक्षण सफ्टवेयर प्रयोग गर्ने तरिका",
      content: <div></div>,
      onOk() {},
    });
  };

  howToUseFontConversion = () => {
    Modal.info({
      title: "फन्ट रुपान्तरण सफ्टवेयर प्रयोग गर्ने तरिका",
      content: <div></div>,
      onOk() {},
    });
  };

  howToUseDictionarySearch = () => {
    Modal.info({
      title: "नेपाली शब्दकोश प्रयोग गर्ने तरिका",
      content: <div></div>,
      onOk() {},
    });
  };

  componentDidMount() {
    //update the page meta and title
    updatePageHead("Home", "", "");
    window.scrollTo(0, 0);
  }

  // fetchQueryItems(){
  //     fetch("server_config.json")
  //         .then(data => data.json())
  //         .catch(error => console.log(error))
  //         .then(config => {
  //             this.setState({
  //                 config
  //             }, ()=> {
  //                     console.log("Config from main page: ",config)
  //                     }
  //             )})
  //         .then(
  //             fetch("QueryRequestPublic.json")
  //             .then(data => data.json())
  //             .catch(error => console.log(error))
  //             .then(query =>
  //                 this.setState({
  //                     query
  //                 }, ()=> {
  //                     console.log("Query from main page :",query)
  //                         }
  //                 ))
  //         )
  // }
  openPopUpFornepaliTypingHelpMessage = () => {
    //console.log("nepali typing help");
    this.setState({ nepaliTypingHelpMessageShow: true });
  };

  handleOk = (e) => {
    //console.log(e);
    this.setState({
      nepaliTypingHelpMessageShow: false,
    });
  };

  handleCancel = (e) => {
    //console.log(e);
    this.setState({
      nepaliTypingHelpMessageShow: false,
    });
  };

  render() {
    const showModalForNepaliTypingHelp = () => {
      return (
        <Modal
          title=""
          visible={this.state.nepaliTypingHelpMessageShow}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <TypeInNepaliMessage />
        </Modal>
      );
    };

    return (
      <div className="main-wrapper">
        {showModalForNepaliTypingHelp()}
        <NavBar />

        <Row
          // className="main-bannerXXX"
          // className="wrapper-gray"
          type="flex"
          justify="space-around"
          align="middle"
          style={{ paddingTop: "40px" }}
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <div className="home-banner-left">
              <h1
                className="nepali-text"
                style={{
                  fontSize: "3.4em",
                  textAlign: "center",
                  color: "rgba(235, 122, 52)",
                }}
              >
                सजिलो नेपाली
              </h1>
              <p
                className="nepali-text"
                style={{ fontSize: "1.5em", textAlign: "center" }}
              >
                हिज्जे परीक्षण, फन्ट रूपान्तरण, शब्दकोश, युनिकोड टाइपिङ, अन्य
                सन्दर्भ सामग्री एकै ठाउँमा नि:शुल्क सेवा प्रयोग गर्न सक्नुहुनेछ।
              </p>
            </div>
            {/* <div style={{ textAlign: "center" }}>
              <Button
                type="primary"
                shape="round"
                size="large"
                className="btn-yellow"
              >
                <Link to="/signup">नि:शुल्क खाता बनाउनुहोस्</Link>
              </Button>
              <br />
              <ScrollLink
                activeClass="active"
                to="test1"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  className="btn-orange"
                  style={{ marginTop: "10px" }}
                >
                  प्रयोग गरेर हेर्नुहोस् 
                </Button>
              </ScrollLink>
            </div> */}
          </Col>
          {/* <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              // REMOVE THIS IFRAME 
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/WEy_deihDfo"
                frameborder="0"
              ></iframe>
              <img
                src={SayakIntroImage}
                style={{ height: "auto", width: "80%", padding: "10px" }}
              />
            </div>
          </Col> */}
        </Row>

        {/* <div className="wrapper-gray"> */}
        <div>
          {/* <Row type="flex" justify="center" className="container-heading">
            <Col span={20} style={{ textAlign: "center" }}>
              <h1 className="nepali-text color-orange">
                प्रयोग गरेर हेर्नुहोस्
              </h1>
              <h3 className="nepali-text">
                {" "}
                नेपाली भाषा सँग सम्बन्धित विभिन्न सफ्टवेयर अनुसन्धान र विकासमा
                जुटेर केही सफ्टवेयर अनुप्रयोगहरू यहाँहरू समक्ष प्रस्तुत गरेका छौ
                । यस सायक प्रणालीमा लगिन गरि पूर्ण रुपमा निशुल्क सेवा प्रयोग
                गर्न सक्नुहुनेछ ।
              </h3>
            </Col>
          </Row> */}
          <Row
            type="flex"
            justify="center"
            style={{ textAlign: "center", minHeight: "60vh" }}
            className="container home-products-tabs"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 20 }}
              xl={{ span: 20 }}
              xxl={{ span: 20 }}
            >
              {/* <Tabs
                activeKey={this.state.activeTabHome}
                tabPosition="left"
                size="large"
                className="tabpane-home-custom"
                onTabClick={(e) => {
                  this.setState({ activeTabHome: e });
                }}
              >

                <TabPane tab="नेपाली हिज्जे परीक्षण" key="1" >
                  <div style={{ minHeight: "40vh" }}>
                    <SpellingCheck />
                  </div>
                </TabPane>
                <TabPane tab="फन्ट रूपान्तरण" key="2">
                  <div style={{ minHeight: "40vh" }}>
                    <FontConversion />
                  </div>
                </TabPane>

                <TabPane tab="नेपाली शब्दकोश" key="3">
                  <div style={{ minHeight: "40vh" }}>
                    <DictSearch />
                  </div>
                </TabPane>
              </Tabs> */}
            </Col>
          </Row>
        </div>

        {/* new section
         */}

        {/* <div className="wrapper-gray"> */}
        <div>
          <Row type="flex" justify="center" className="container-heading">
            <Col span={20} style={{ textAlign: "center" }}>
              <TypeInNepaliMessage />
            </Col>
          </Row>
        </div>

        {/* end of new section */}

        {/* <div
          style={{ background: "#fff", padding: "30px", textAlign: "center" }}
        >
          <Row type="flex" justify="center" className="container-heading">
            <Col span={20} style={{ textAlign: "center" }}>
              <h1 className="color-orange">अन्य सफ्टवेयरहरू</h1>
              <h3>
                इन्टिग्रेटेड आइसिटी प्रा लि द्वारा बिकसित अन्य सफ्टवेयर
                अनुप्रयोग तथा प्रणालीहरूको बारेमा जानकारी लिनुहोस् | नेपाली भाषा
                प्रशोधनसँग सम्वद्ध अनुसन्धान गरि बिकसित विभिन्न अनुप्रयोग र
                प्रणालीहरू यहाँहरू समक्ष प्रस्तुत गरेका छौँ |{" "}
              </h3>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Card title="नेपाली हिज्जे परिक्षण" bordered={true} hoverable={true}>
                <img
                  src={DummyImage}
                  style={{ height: "300px", width: "100%", padding: "10px" }}
                />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s
                </p>
                
                <ScrollLink activeClass="active" to="test1" spy={true} smooth={true} offset={50} duration={500} >
                    <Button
                    size="large"
                    className="btn-orange"
                    type="primary"
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                        this.setState({ activeTabHome: "1" });
                    }}
                    >
                    प्रयोग गरेर हेर्नुहोस्
                    </Button>
                </ScrollLink>

              </Card>
            </Col>
            <Col span={8}>
              <Card title="फन्ट रूपान्तरण" bordered={true} hoverable={true}>
                <img
                  src={DummyImage}
                  style={{ height: "300px", width: "100%", padding: "10px" }}
                />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s
                </p>

                <ScrollLink activeClass="active" to="test1" spy={true} smooth={true} offset={50} duration={500} >
                <Button
                  size="large"
                  className="btn-orange"
                  type="primary"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    this.setState({ activeTabHome: "2" });
                  }}
                >
                  प्रयोग गरेर हेर्नुहोस्
                </Button>
                </ScrollLink>
              </Card>
            </Col>
            <Col span={8}>
              <Card title="नेपाली शब्दकोश" bordered={true} hoverable={true}>
                <img
                  src={DummyImage}
                  style={{ height: "300px", width: "100%", padding: "10px" }}
                />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s
                </p>
                <ScrollLink activeClass="active" to="test1" spy={true} smooth={true} offset={50} duration={500} >
                <Button
                  size="large"
                  className="btn-orange"
                  type="primary"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    this.setState({ activeTabHome: "3" });
                  }}
                >
                  प्रयोग गरेर हेर्नुहोस्
                </Button>
                </ScrollLink>
              </Card>
            </Col>
          </Row>
        </div> */}

        {/* <Row
          type="flex"
          justify="center"
          style={{ textAlign: "center", padding: "0px", margin: "0px" }}
          className="container reader-archive-bg"
        >
          <Row type="flex" justify="center" className="">
            <Col span={24} style={{ textAlign: "center" }}>
              <h1
                className="color-orange"
                style={{ marginBottom: "20px", marginTop: "20px" }}
              >
                सङ्कलित सामग्रीहरू
              </h1>
              <p></p>
            </Col>
          </Row>

          {Object.keys(this.props.endpoints).length > 0 ? (
            <LinkArchive {...this.props} />
          ) : null}
        </Row> */}

        <Footer />
      </div>
    );
  } //end of render method
}

const mapStateToProps = (state) => ({
  endpoints: state.apiConstants,
});

export default connect(mapStateToProps)(withRouter(LayoutHome));
