import { notification } from "antd";

export const showSuccessNotification = (message, description) => {
  notification.success({
    message: message,
    description: description,
    duration: 5,
  });
};

export const showInfoNotification = (message, description) => {
  notification.info({
    message: message,
    description: description,
    duration: 5,
  });
};

export const showWarningNotification = (message, description) => {
  notification.warning({
    message: message,
    description: description,
    duration: 5,
  });
};

export const showErrorNotification = (message, description) => {
  notification.error({
    message: message,
    description: description,
    duration: 5,
  });
};
