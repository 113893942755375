import React from 'react'

import { Route, Redirect } from 'react-router-dom'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const AdminRoute = ({ component: Component, auth, userType, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            auth.isAuthenticated ? (
                userType === "ADMIN" ? <Component {...props} /> : <Redirect to="/user" />
            ) : (
                <Redirect to="/login" />
            )
        }
    />
)

AdminRoute.propTypes = {
    auth: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth,
    userType: state.userDetails.userType
})

export default connect(mapStateToProps)(AdminRoute)
