import React, { Component } from "react";

//load all css here
import "antd/dist/antd.css";
import "./styles/customantd.css";
import "./styles/index.css";
// import "./index.css";
// import "./tailwind.css";

//Layout based Imports

import LayoutHome from "./layout/publicLayout/LayoutHome";
import LayoutLogin from "./layout/publicLayout/LayoutLogin";
import LayoutSignUp from "./layout/publicLayout/LayoutSignUp";
import ActivateAfterSignUp from "./layout/publicLayout/ActivateAfterSignUp";

import ResetPassword from "./layout/publicLayout/ResetPassword";
import AddPassword from "./layout/publicLayout/AddPassword";

import { LayoutAbout } from "./layout/publicLayout/LayoutAbout";
import LayoutContact from "./layout/publicLayout/LayoutContact";
import { LayoutFAQ } from "./layout/publicLayout/LayoutFAQ";

//temporary imports of admin and user login panel for demonstration purpose
import LayoutAdminDashboard from "./layout/adminLayout/LayoutAdminDashboard";
import LayoutUserDashboard from "./layout/userLayout/LayoutUserDashboard";
import LayoutTermsOfUse from "./layout/publicLayout/LayoutTermsOfUse";
import LayoutPrivacyPolicy from "./layout/publicLayout/LayoutPrivacyPolicy";
import NavBarUser from "./layout/userLayout/NavBarUser";
import UserNav from "./layout/userLayout/UserNav";

import PrivateRouteUserRegister from "./components/common/PrivateRouteUserRegister";
import LoggedInRoute from "./components/common/LoggedInRoute";
import AdminRoute from "./components/common/AdminRoute";
import PublicRoute from "./components/common/PublicRoute";

import { prepReduxWithLocalStorage } from "./store/prepStore/prepStoreFromLocal";
import { prepReduxWithApiCallConstants } from "./store/prepStore/prepReduxWithApiCallConstants";
import { Route, Switch } from "react-router-dom";

import ChangeLayoutUserDash from "./layout/userLayout/ChangeLayoutUserDash";
import { NEW_LAYOUT_PATH, OLD_LAYOUT_PATH } from "./helpers/Constants/path";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import "./index.css";

import {
  initializeGA,
  logPageView,
} from "./utils/google_analytics_utils/GoogleAnalytics";

prepReduxWithLocalStorage();
prepReduxWithApiCallConstants();

class App extends Component {
  componentDidMount() {
    // Initialize Google Analytics

    initializeGA();
    // console.log("didMount");

    // Log initial page view
    const url = window.location.href;
    const segments = url.split("/");
    const lastSegment = segments[segments.length - 1];
    logPageView(lastSegment);

    // Log page views on route change using window.addEventListener
    window.addEventListener("popstate", this.handleRouteChange);
  }

  componentDidUpdate() {
    // Remove the event listener to prevent memory leaks
    window.removeEventListener("popstate", this.handleRouteChange);
    // console.log("didMount");
  }

  handleRouteChange = () => {
    // Log page view on route change
    const url = window.location.href;
    const segments = url.split("/");
    const lastSegment = segments[segments.length - 1];
    // console.log(lastSegment);
    logPageView(lastSegment);
  };
  render() {
    return (
      <GoogleOAuthProvider clientId="600793487202-i18ke3h80nprvi2cuffhss7ipdmr0bj0.apps.googleusercontent.com">
        <div>
          <Switch>
            {/* ADMIN ROUTES */}
            <AdminRoute path="/admin" component={LayoutAdminDashboard} />

            {/* PUBLIC ROUTES */}
            <PublicRoute exact path="/" component={LayoutLogin} />

            <PublicRoute path="/about" component={LayoutAbout} />

            <PublicRoute path="/contact" component={LayoutContact} />

            <PublicRoute path="/faq" component={LayoutFAQ} />

            <PublicRoute path="/termsofuse" component={LayoutTermsOfUse} />

            <PublicRoute
              path="/privacypolicy"
              component={LayoutPrivacyPolicy}
            />

            <PublicRoute exact path="/login" component={LayoutLogin} />

            <PublicRoute path="/signup" component={LayoutLogin} />

            <PublicRoute path="/resetPassword" component={ResetPassword} />

            <PublicRoute path="/addPassword" component={AddPassword} />

            {/* LOGGED IN USER ROUTES */}
            <LoggedInRoute
              path={OLD_LAYOUT_PATH}
              component={LayoutUserDashboard}
            />

            <LoggedInRoute
              path={NEW_LAYOUT_PATH}
              component={ChangeLayoutUserDash}
            />

            {/* OTHER SECURE ROUTES */}
            <PrivateRouteUserRegister
              exact
              path="/activateAccount"
              component={ActivateAfterSignUp}
            />
          </Switch>
          <ToastContainer style={{ width: "700px" }} />
        </div>
      </GoogleOAuthProvider>
    );
  }
}

export default App;
