import React, { Component } from "react";

import Aux from "../../components/Hoc/WrapperComponent";

import Sayakimage from "./images/yoda.png";
import { updatePageHead } from "../../components/common/UpdateMetaAndTitle";

import isEmpty from "../../validation/is-empty";
import NavBar from "./NavBar";
import Footer from "./Footer";

import LoginWithGoogleAndFacebook from "../layoutUtils/LoginWithGoogleAndFacebook";

import { Form, Input, Button, Row, Col, Alert } from "antd";

import { withRouter, Route, Link, Redirect } from "react-router-dom";

import PropTypes from "prop-types";
import Loading from "../layoutUtils/Loading";

import { connect } from "react-redux";

import {
  addPasswordCodeRequest,
  resetaddPasswordErrors,
  addPasswordCodeVerify,
  addPasswordSetNewPassword,
} from "../../store/actions/authAction";

import { appBusySet, appBusyReset } from "../../store/actions/appStateAction";

import { resetAuthErrors } from "../../store/actions/authErrorAction";

//import ResetPasswordEnterPin from 'resetPassword'

class AddPassword extends Component {
  state = {
    errors: {},
  };

  componentDidMount() {
    updatePageHead("Add Password", "", "");

    this.props.appBusyReset();
    this.props.resetaddPasswordErrors();
  }

  render() {
    return (
      <div className="main-wrapper">
        <NavBar />
        {this.props.appState.busy ? (
          <Loading messageSpin="Logging In Please Wait" />
        ) : null}

        <Row
          type="flex"
          justify="space-around"
          align="middle"
          className="bg-blue-yellow-gradient"
        >
          <Col
            xs={{ span: 22 }}
            sm={{ span: 22 }}
            md={{ span: 22 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            {/* <div
              className="container-layout-left-one"
              style={{
                minHeight: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <p>.</p>
            </div> */}
          </Col>
          <Col
            xs={{ span: 22 }}
            sm={{ span: 22 }}
            md={{ span: 22 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <div className="container-layout-right-one">
              <div
                style={{
                  padding: "40px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="form-container"
              >
                <Route
                  exact
                  path="/addPassword"
                  component={() => <AddPasswordEnterEmail {...this.props} />}
                />
                <Route
                  exact
                  path="/addPassword/pin"
                  component={() => <AddPasswordEnterPin {...this.props} />}
                />
                <Route
                  exact
                  path="/addPassword/newPassword"
                  component={() => (
                    <AddPasswordEnterNewPassword {...this.props} />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  addPassword: state.auth.addPassword,
  autherrors: state.authErrors,
  appState: state.appState,
  endpoints: state.apiConstants,
});

export default connect(mapStateToProps, {
  addPasswordCodeRequest,
  resetaddPasswordErrors,
  addPasswordCodeVerify,
  addPasswordSetNewPassword,

  appBusySet,
  appBusyReset,
})(withRouter(AddPassword));

export class AddPasswordEnterEmail extends Component {
  state = {
    email: "",
    errors: {},
  };
  componentDidMount() {}

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    //e.preventDefault()

    const UserData = {
      email: this.state.email,
    };

    const appStateData = { message: "Requesting Add Password Pin" };
    this.props.appBusySet(appStateData);

    this.props.addPasswordCodeRequest(
      UserData,
      this.props.history,
      this.props.endpoints
    );
  };
  render() {
    return (
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={this.onSubmit}
        style={{ width: "100%" }}
      >
        <h1>कृपया पासवर्ड राख्नुहोस्। </h1>
        <div>
          <p>
            तपाईंले यस अघि लगिन गर्दा जिमेल वा फेसबुक खाता प्रयोग गरि लगिन
            गर्नुभएको थियो।  सायक खाता प्रयोग गरि लगिन गर्नका लागि तलको बाकसमा
            आफ्नो इमेल ठेगाना राख्नुहोस्। 
          </p>
        </div>

        <Form.Item
          name="email"
          label="इमेल "
          rules={[{ required: true, message: "Please input your Email!" }]}
        >
          <Input name="email" placeholder="इमेल " onChange={this.onChange} />
        </Form.Item>

        {!isEmpty(this.props.addPassword.errors) ? (
          <Form.Item>
            <Alert
              message={this.props.addPassword.errors.message}
              type="error"
              showIcon
            />
          </Form.Item>
        ) : null}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className=""
            style={{ marginLeft: "10px" }}
          >
            पिन पठाउनुहोस्।
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export class AddPasswordEnterPin extends Component {
  state = {
    email: "",
    addpasswordpin: "",
    errors: {},
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    //e.preventDefault()

    const UserData = {
      email: this.props.addPassword.email,
      addpasswordpin: this.state.addpasswordpin,
    };

    const appStateData = { message: "Validating Password Reset Pin" };
    this.props.appBusySet(appStateData);

    //console.log(UserData);
    this.props.addPasswordCodeVerify(
      UserData,
      this.props.history,
      this.props.endpoints
    );

    // this.props.resetUserPassword(userData)
  };

  render() {
    return (
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={this.onSubmit}
        style={{ width: "100%" }}
      >
        <h1>पासवर्ड राख्नुहोस्। </h1>
        <div>
          <p>
            कृपया तपाईंको इमेल खाता {this.props.addPassword.email} मा आएको पिन
            कोड यहाँ बाकश मा राख्नुहोश
          </p>
        </div>

        {/* <Form.Item
          name="email"
          label="Your Email"
          rules={[{ required: false, message: "Please input your Email!" }]}
        >
          <Input
            name="email"
            placeholder={
              this.props.userForgotPassword.email
                ? this.props.userForgotPassword.email
                : "Your Email"
            }
            onChange={this.onChange}
            defaultValue={this.props.userForgotPassword.email}
            value={this.props.userForgotPassword.email}
          />
        </Form.Item> */}
        <Form.Item
          name="addpasswordpin"
          label="पिन: "
          rules={[{ required: true, message: "Please input your pin!" }]}
        >
          <Input
            name="addpasswordpin"
            placeholder="पिन राख्नुहोस्"
            onChange={this.onChange}
          />
        </Form.Item>

        {!isEmpty(this.props.addPassword.errors) ? (
          <Form.Item>
            <Alert
              message={this.props.addPassword.errors.message}
              type="error"
              showIcon
            />
          </Form.Item>
        ) : null}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className=""
            style={{ marginLeft: "10px" }}
          >
            पठाउनुहोस्।
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export class AddPasswordEnterNewPassword extends Component {
  state = {
    email: "",
    newpassword: "",
    newpassword2: "",
    resetPasswordSuccess: false,
    errors: {},
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    //e.preventDefault()
    //console.log("yaaaaaa");
    //console.log(this.state)
    //console.log(this.props.userForgotPassword)
    const userData = {
      email: this.props.addPassword.email,
      newPassword:
        this.state.newpassword === this.state.newpassword2
          ? this.state.newpassword
          : "",
      passwordrestpin: this.props.addPassword.setUpPasswordPin,
    };

    //console.log(userData);

    const appStateData = { message: "Setting New Password" };
    this.props.appBusySet(appStateData);

    this.props.addPasswordSetNewPassword(
      userData,
      this.props.history,
      this.props.endpoints
    );

    // this.props.resetUserPassword(userData)
  };

  render() {
    //console.log(this.props);
    return (
      <Aux>
        {this.props.addPassword.setUpPasswordSuccess ? (
          <div style={{ textAlign: "center" }}>
            <h1>नया पासवर्ड राखिएको छ ,  कृपया फेरी लगिन  गर्नुहोस्।</h1>
            <div style={{ margin: "10px 0px 10px 0px" }}>
              <Link to="/login">
                <Button size="large" className="btn-orange" type="primary">
                  लगिन गर्नुहोस् ।
                </Button>
              </Link>

              <LoginWithGoogleAndFacebook />
            </div>
          </div>
        ) : (
          <Form
            name="normal_login"
            className="login-form"
            onFinish={this.onSubmit}
            style={{ width: "100%" }}
          >
            <h1>पासवर्ड राख्नुहोश </h1>
            <div>
              {this.props.addPassword.setUpPasswordPin ? (
                <p>
                  तपाई को इमेल {this.props.addPassword.email} कृपया नया
                  पास्वोर्ड राख्नुहोश{" "}
                </p>
              ) : null}

              {/* // (
              //   <p>Enter your pin recieved for the email: {this.props.addPassword.email} </p>
              // )} */}
            </div>

            {/* <Form.Item
              name="email"
              label="Your Email"
              rules={[{ required: false, message: "Please input your Email!" }]}
            >
              <Input
                name="email"
                placeholder= { this.props.userForgotPassword.email ? this.props.userForgotPassword.email : "Your Email" }
                onChange={this.onChange}
                defaultValue = {this.props.userForgotPassword.email}
                value = {this.props.userForgotPassword.email}
              />
            </Form.Item> */}

            <Form.Item
              name="newpassword"
              label="नयाँ पासवर्ड:"
              rules={[
                { required: true, message: "Please input new Password!" },
              ]}
            >
              <Input
                name="newpassword"
                placeholder="नयाँ पासवर्ड"
                onChange={this.onChange}
              />
            </Form.Item>
            <Form.Item
              name="newpassword2"
              label="पुन: नयाँ पासवर्ड :"
              rules={[
                { required: true, message: "Please input new Password again!" },
              ]}
            >
              <Input
                name="newpassword2"
                placeholder="नयाँ पासवर्ड"
                onChange={this.onChange}
              />
            </Form.Item>

            {!isEmpty(this.props.addPassword.errors) ? (
              <Form.Item>
                <Alert
                  message={this.props.addPassword.errors.message}
                  type="error"
                  showIcon
                />
              </Form.Item>
            ) : null}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className=""
                style={{ marginLeft: "10px" }}
              >
                पासवर्ड राख्नुहोश
              </Button>
            </Form.Item>
          </Form>
        )}
      </Aux>
    );
  }
}
