import React, { Component } from "react";

import "./style.css";
import { Row, Col, Card, Button, Form, Input, Radio, message, Tag } from "antd";

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { r_addUserHtmlLink } from '../../../helpers/Requesthelper/RequestLinkArchive'

export class AddUserLinks extends Component {
  state = {
    addLinkOn: false,
    linkAdded: false,
    linkAddFail: false,
  };

  handleAddLink = () => {
    this.setState({ addLinkOn: true, linkAdded: false, linkAddFail: false });
  };

  cancelAddLink = () => {
    this.setState({ addLinkOn: false, linkAdded: false, linkAddFail: false });
  };

  onFinish = (e) => {
    console.log(e)
    const linkData = {
      // title: e.linkArchive.title,
      url: e.linkArchive.url,
      visibleTo: e.linkArchive.visibleTo,
      tags: e.tags.join(" "),
      documentType: "HTML"
    }
    const userData = {
      userName: this.props.userDetails.userName
    }
    const endpoints = this.props.endpoints
    const accessToken = this.props.auth.userToken

    console.log(linkData)

    r_addUserHtmlLink( linkData, userData, endpoints, accessToken ).then(res=>{
      console.log(res)
      if(res.data.status === "success"){
        this.setState({linkAdded: true , addLinkOn: false })
        message.success('Link Successfully Added');

      }else{
        this.setState({
          linkAddFail: true,
          addLinkOn: false,
        })
        message.error('Link Adding Failed');
      }
    })

  }

  render() {
    const layout  = {};

    return (
      <div style={{marginBottom: "20px"}}>
        <h1 style={{ textAlign: "center" }}> Reader's Archive </h1>

        <div style={{ marginBottom: "20px", textAlign: "center" }}>
          <Button primary onClick={(e) => this.handleAddLink(e)}>
            {" "}
            Add New Link{" "}
          </Button>
          <Button primary onClick={(e) => this.cancelAddLink(e)}>
            {" "}
            Cancel{" "}
          </Button>
        </div>
        {
          // this.state.linkAdded ? 
          // <div><h1>Link Successfully Added . Thankyou </h1></div>
          // : null 
        }
        {
          // this.state.linkAddFail ? 
          // <div><h1>Link Adding Failed.</h1></div>
          // : null 
        }
        {this.state.addLinkOn ? (
          <div>
            <Card title="Add Link">
              <Row gutter={20}>
                <Col span={24}>

                  <Form
                    {...layout}
                    name="nest-messages"
                    onFinish={this.onFinish}
                    //validateMessages={validateMessages}
                  >
                    {/* <Form.Item
                      name={["linkArchive", "title"]}
                      label="Title"
                      rules={[{ 
                        required: true,
                        message: "Please Input a title",
                        }]}
                    >
                      <Input />
                    </Form.Item> */}

                    <Form.Item
                      name={["linkArchive", "url"]}
                      label="URL"
                      rules={[{ 
                        required: true,
                        message: "Please Input a url",
                        }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={["linkArchive", "visibleTo"]}
                      label="Visible To"
                      rules={[{ 
                        required: true,
                        message: "Please select if you want this url for public or private",
                        }]}
                    >
                       <Radio.Group
                        // defaultValue = "PUBLIC"
                       >
                          <Radio value="PUBLIC">Public</Radio>
                          <Radio value="PRIVATE">Private</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {/* tags */}

                    <Form.List name="tags">
                      {(fields, { add, remove }) => {
                        return (
                          <div>
                            
                            <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              flexDirection: "row"
                            }}
                            >
                              
                            
                            {fields.map((field, index) => (

                              <Form.Item
                                {...(index === 0 ? layout : layout)}
                                label={index === 0 ? 'Tag' : ''}
                                required={false}
                                key={field.key}
                              >
                                <Form.Item

                                  {...field}
                                  validateTrigger={['onChange', 'onBlur']}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please Input a tag",
                                    },
                                  ]}
                                  style={{
                                    marginRight: "40px"
                                  }}
                                >
                                  <Input 
                                  placeholder="tag" 
                                  
                                  style={{ width: '100%', display:"inline"}} 
                                  
                                  />
                                </Form.Item>

                                {fields.length > 1 ? (
                                  <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    style={{ margin: '0 8px' }}
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                  />
                                ) : null}
                              </Form.Item>
                            ))}
                            </div>
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => {
                                  add();
                                }}
                                style={{ width: '20%' }}
                              >
                                <PlusOutlined /> Add Tag
                              </Button>
                              
                            </Form.Item>
                          </div>
                        );
                      }}
                    </Form.List>

                    {/* end of tags */}

                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>

                </Col>
              </Row>
            </Card>
          </div>
        ) : null}
      </div>
    );
  }
}
