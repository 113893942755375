
import { SET_LOGGED_IN_USER_DETAILS } from "../actions/types";

import isEmpty from "../../validation/is-empty";


export const setLoggedInUserDetails = (userData) => {
    return {
      type: SET_LOGGED_IN_USER_DETAILS,
      payload: userData,
    };
  };