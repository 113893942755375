import { APP_BUSY_RESET, APP_BUSY_SET } from "../actions/types";

export const appBusySet = (appStateData) => {
  return {
    type: APP_BUSY_SET,
    payload: appStateData.message,
  };
};

export const appBusyReset = () => {
  return {
    type: APP_BUSY_RESET,
  };
};
