import React, { Component } from "react";
import { Drawer, Statistic, Card, Row, Col, Spin } from "antd";

import {r_countGivenServiceUsedGlobal} from '../../../helpers/Requesthelper/RequestStatistics'


class ProductUsageStatistics extends Component {
  render() {
    return (
      <div>
        <Row
            type="flex"
            style={{ textAlign: "center"}}
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
              xl={{ span: 6 }}
              xxl={{ span: 6 }}
            >
            <RenderSpellingStatistics {...this.props} />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
              xl={{ span: 6 }}
              xxl={{ span: 6 }}
            >
            <RenderFontConversionStatistics {...this.props} />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
              xl={{ span: 6 }}
              xxl={{ span: 6 }}
            >
            <RenderDictionarySearchStatistics {...this.props} />
            </Col>
            </Row>
      </div>
    );
  }
}

export default ProductUsageStatistics;


class RenderSpellingStatistics extends Component {

  state = {
    serviceName : "SPELLSUGGESTION",
    loading: true,
    totalServiceCount: null
    
  }

  componentDidMount(){
    this.generateServiceStatisticsGlobal(this.props.slectedUserRowDetails)
  }

  generateServiceStatisticsGlobal = (userRow) =>{

    r_countGivenServiceUsedGlobal(this.state.serviceName, this.props.endpoints, this.props.auth.userToken).then(res=>{
      //console.log(res)
      const {data} = res
      this.setState({totalServiceCount: data.value, loading: false})
    })
  }

  render(){
    const {totalServiceCount} = this.state
    return (
      <div>
       <Card 
        className="card-with-shadow"
        >
        <p><b>Spelling Service</b></p>
        {
        this.state.loading ?
        <Spin />
        :
        <Card hoverable>
            <Statistic
            title="Total Hits"
            value={totalServiceCount}
            valueStyle={{ color: "#3f8600" }}
            />
        </Card>        
        }


        </Card>
      </div>
    )
  }
}

class RenderFontConversionStatistics extends Component {

  state = {
    serviceName : "FONTCONVERSION",
    loading: true,
    totalServiceCount: null
    
  }

  componentDidMount(){
    this.generateServiceStatisticsGlobal(this.props.slectedUserRowDetails)
  }

  generateServiceStatisticsGlobal = (userRow) =>{

    r_countGivenServiceUsedGlobal(this.state.serviceName, this.props.endpoints, this.props.auth.userToken).then(res=>{
      //console.log(res)
      const {data} = res
      this.setState({totalServiceCount: data.value, loading: false})
    })
  }

  render(){
    const {totalServiceCount} = this.state
    return (
      <div>
       <Card 
        className="card-with-shadow"
        >
        <p><b>Font Conversion Service</b></p>
        {
        this.state.loading ?
        <Spin />
        :
        <Card hoverable>
            <Statistic
            title="Total Hits"
            value={totalServiceCount}
            valueStyle={{ color: "#3f8600" }}
            />
        </Card> 
        
        }


        </Card>
      </div>
    )
  }
}

class RenderDictionarySearchStatistics extends Component {

  state = {
    serviceName : "DICTIONARY",
    loading: true,
    totalServiceCount: null
    
  }

  componentDidMount(){
    this.generateServiceStatisticsGlobal(this.props.slectedUserRowDetails)
  }

  generateServiceStatisticsGlobal = (userRow) =>{

    r_countGivenServiceUsedGlobal(this.state.serviceName, this.props.endpoints, this.props.auth.userToken).then(res=>{
      //console.log(res)
      const {data} = res
      this.setState({totalServiceCount: data.value, loading: false})
    })
  }

  render(){
    const {totalServiceCount} = this.state
    return (
      <div>
       <Card
        className="card-with-shadow"
        >
        <p><b>Dictionary Search Service</b></p>
        {
        this.state.loading ?
        <Spin />
        :
        <Card hoverable> 
            <Statistic
            title="Total Hits"
            value={totalServiceCount}
            valueStyle={{ color: "#3f8600" }}
            />
        </Card> 
        
        }


        </Card>
      </div>
    )
  }
}
