import React, { Component } from "react";

import SayakLogo from "../../images/sayak_logo.png";
import "./dashboard-style.css";

import { Row, Col, Menu, Button, Affix } from "antd";
import { Link, withRouter } from "react-router-dom";
import { NEW_LAYOUT_PATH, OLD_LAYOUT_PATH } from "../../helpers/Constants/path";
const { SubMenu } = Menu;

class ChangeNavBarUser extends Component {
  state = {};

  handleLogout = (e) => {
    e.preventDefault();

    if (this.props.userDetails.loginPlatform === "GOOGLE") {
      // console.log("proceed to logging Out with google");

      const appStateData = { message: "Logging Out Please Wait" };
      this.props.appBusySet(appStateData);
      this.props.logOutNonSayakUser(this.props.history);
    } else if (this.props.userDetails.loginPlatform === "FACEBOOK") {
      // console.log("proceed to logging Out with facebook");

      const appStateData = { message: "Logging Out Please Wait" };
      this.props.appBusySet(appStateData);
      this.props.logOutNonSayakUser(this.props.history);
    } else {
      const userData = this.props.userDetails.userName;
      const appStateData = { message: "Logging Out Please Wait" };
      this.props.appBusySet(appStateData);
      this.props.logOutSayakUser(
        userData,
        this.props.auth.userToken,
        this.props.history,
        this.props.endpoints
      );
    }
  };

  openSurvey = () => {
    window.open("https://forms.gle/eYbM6bLaDjnzWhBD6", "_blank");
  };

  render() {
    return (
      <Affix offsetTop={0}>
        <div id="mySidepanel" class="sidepanel">
          <a
            // href="javascript:void(0)"
            href="javascript:void(0)"
            className="closebtn"
            onClick={() => {
              window.closeNav();
            }}
          >
            &times;
          </a>
          <div className="logo-section">
            <Link to="/">
              <img src={SayakLogo} className="logo-desktop" />
            </Link>
          </div>

          <div>
            <Menu
              theme=""
              mode="inline"
              //defaultSelectedKeys={['1']}
              defaultOpenKeys={["sub1"]}
              className="sidepanel-antd-menu"
              style={{ borderRight: 0, background: "none" }}
            >
              <Menu.Item key="1">
                {/* <span>Dash Board</span> */}
                <Link to={NEW_LAYOUT_PATH} />
              </Menu.Item>

              <SubMenu
                key="sub1"
                title={<span>Products Subscribed</span>}
                style={{ background: "none", border: 0 }}
              >
                <Menu.Item key="2">
                  <span>रोमन नेपाली टाइपिङ </span>
                  <Link to={NEW_LAYOUT_PATH + "/typeInNepali"} />
                </Menu.Item>

                <Menu.Item key="3">
                  <span>नेपाली हिज्जे परीक्षण</span>
                  <Link to={NEW_LAYOUT_PATH + "/spelling"} />
                </Menu.Item>

                <Menu.Item key="4">
                  <span>फन्ट टाइपिङ </span>
                  <Link to={NEW_LAYOUT_PATH + "/typeInNepaliTTF"} />
                </Menu.Item>
                <Menu.Item key="5">
                  <span>फन्ट रूपान्तरण</span>
                  <Link to={NEW_LAYOUT_PATH + "/fontConversion"} />
                </Menu.Item>
                <Menu.Item key="6">
                  <span>नेपाली शब्दकोश</span>
                  <Link to={NEW_LAYOUT_PATH + "/dictSearch"} />
                </Menu.Item>
                {/* <Menu.Item key="7">
                                    <span>सङ्कलित सामग्रीहरू</span>
                                    <Link to="/hijjeworld/linkArchive" />
                                </Menu.Item> */}
              </SubMenu>

              {/* <Menu.Item key="8">
                                <span>सहयोगी प्रश्नोतर</span>
                                <Link to="/hijjeworld/FAQ" />
                            </Menu.Item>

                            <Menu.Item key="9">
                                <span>सम्पर्क</span>
                                <Link to="/hijjeworld/contact" />
                            </Menu.Item> */}
              <Menu.Item key="9" onClick={this.openSurvey}>
                <span>सायक सर्वेक्षण</span>
                {/* <Link to="/user/contact" /> */}
              </Menu.Item>
            </Menu>
          </div>
          <div class="button btn-yellow" onClick={this.handleLogout}>
            <Link to="">Log Out</Link>
          </div>
        </div>
        <button
          class="openbtn"
          onClick={() => {
            window.openNav();
          }}
        >
          &#9776; Menu{" "}
        </button>
      </Affix>
    );
  }
}

export default ChangeNavBarUser;
