import React, { Component } from 'react'

import { Card, Row, Col, Pagination, Button, Tag, Modal, message, Spin, Badge } from 'antd'

import './style.css'
import {ExclamationCircleOutlined} from '@ant-design/icons'

import { r_GetDataForDocumentPreview , 
        r_listUserHtmlLink, 
        r_countUserHtmlLink, 
        r_deleteUserHtmlLink 
} from '../../../helpers/Requesthelper/RequestLinkArchive'
const { confirm } = Modal;

export class ListLinksOfUser extends Component {

    state = {

        loadingLinks: true,
        articleInfo : {},
        
        linkList: [],
        
        pageStart: 0,
        pageEnd: 10,
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },

        tokenExpired : false,

    }

    updatePaginationTotal = (total) => {
        const { pagination } = this.state;
        this.setState({ pagination: { ...pagination, total: total } });
    };

    

    componentDidMount(){

        this.countAllLinks().then((totalCount) => {
            console.log(totalCount)
            this.updatePaginationTotal(totalCount);
            this.ListAllLinks()
        });


        //this.ListAllLinks()

    }


    countAllLinks = () => {

        const accessToken = this.props.auth.userToken
        const endpoints = this.props.endpoints
        const userData = {
            userName: this.props.userDetails.userName,
            visibleTo: "PRIVATE"
        }

        return new Promise((resolve, reject) => {
          r_countUserHtmlLink(
            userData,
            endpoints,
            accessToken
          ).then((res) => {
              console.log(res)
            resolve(res.data.value);
          });
        });
    };


    ListAllLinks = () => {
        this.setState({linkList: [], tokenExpired: false})
        const accessToken = this.props.auth.userToken
        const endpoints = this.props.endpoints
        const userData = {
            userName: this.props.userDetails.userName,
            visibleTo: "PRIVATE"
        }
        // const pagination = {...this.state.pagination}
        const pageStart = this.state.pageStart
        const pageEnd = this.state.pageEnd

        r_listUserHtmlLink(userData, pageStart, pageEnd, endpoints, accessToken).then(res=>{
            if(res.data){
                if(res.data.status === 'fail'){
                    this.setState({tokenExpired: true})
                }else{
                    this.setState({
                        linkList: res.data.itemListElement
                    })
                    console.log(res.data.itemListElement)
                }
                // this.setState({
                //     linkList: res.data.itemListElement
                // })
                
            }
        })
    }

    handlePaginationChange = (e) => {
        console.log(e)
        const { pagination } = this.state;

        const pageStartValue = e * 10 - 10;
        const pageEndValue = e * 10;

        this.setState(
            {
              pageStart: pageStartValue,
              pageEnd: pageEndValue,
              pagination: {
                ...pagination,
                current: e,
              },
            },
            () => {
                this.ListAllLinks()
            }
          );
    }

    

    render() {
        // if(
        //     this.props.refreshTrigger
        // ){
        //     this.reloadLinks()
        // }
        return (
            <div>
                <Row>
                    
                    {
                        this.state.tokenExpired ? 
                        <p>Login Session has expired. Please logout and login again to continue</p> :
                        <div>
                        {
                            !( this.state.linkList && this.state.linkList.length > 0) &&
                            <Spin />
                        }
                        </div>
                    }
                    {
                    this.state.linkList &&
                    this.state.linkList.map(linkItem => {
                        return ( 
                            <Col span={24}>
                                <PreviewLinksOfUser 
                                    endpoints = {this.props.endpoints}
                                    accessToken = {this.props.auth.userToken}
                                    url = {linkItem.url}
                                    dateCreated = {linkItem.dateCreated}
                                    identifier = {linkItem.identifier}
                                    documentType = {linkItem.documentType}
                                    tags = {linkItem.topic}
                                    visibleTo = {linkItem.description}

                                    ListAllLinks = {this.ListAllLinks}
                                />
                        </Col>)
                    })
                    }
                </Row>
                {
                    (this.state.linkList && this.state.linkList.length > 0) &&
                    <Pagination 
                        total={this.state.pagination.total} 
                        current={this.state.pagination.current}
                        onChange={this.handlePaginationChange} 
                    />
                }
                
            </div>
        )
    }
}

class PreviewLinksOfUser extends Component {

    state={
        loadingLink: true,
        articleInfo : {},

        deleteSuccessData: {},
        deleteFailData: {}
    }

    componentDidMount(){
        r_GetDataForDocumentPreview(
            this.props.url,
            this.props.endpoints,
            this.props.accessToken
            ).then(res => {
                console.log(res)
                const {data} = res
                console.log(data)
                const {description, image, topic, url} = data
                this.setState({
                    articleInfo: {
                        topic:topic,
                        image:image,
                        description: description,
                        url: url
                    },
                    loadingLink: false
                })
            })
    }

    
    handleDeleteUserLink = () => {
        console.log("delete action")
        const id = this.props.identifier  
        console.log(id)

        r_deleteUserHtmlLink(id, this.props.endpoints, this.props.accessToken).then(res=> {
            if(res.data.status ==='success'){
                this.props.ListAllLinks()
                this.setState({deleteSuccessData: res.data})
                message.success(res.data.message);
            }else{
                console.log("couldnot delete")
                this.setState({deleteFailData: res.data})
                message.error(res.data.message);
            }
        })
    }


    render(){

        const {url, dateCreated, identifier, documentType, tags, visibleTo} = this.props

        return(

            <div>
                <Card
                loading = {this.state.loadingLink}
                className="article-card"
                hoverable
                // title={this.state.articleInfo.topic}
                // extra={
                //     // <Button type="dashed" danger className="article-info-footer__right__button-two">
                //     //     Edit
                //     // </Button>
                // }
                >
                    <a href={url} target="_blank" className="article-link"><Row gutter={12}>
                        <Col span={3}>
                            <img src={this.state.articleInfo.image} className="article-image" />
                        </Col>
                        <Col span={21} className="padding-10px article-description">
                            <h2>{this.state.articleInfo.topic}</h2>
                            {this.state.articleInfo.description && 
                            this.state.articleInfo.description.slice(0,200)} 
                            <a href={url} target="_blank" style={{
                                                                marginLeft:"7px",
                                                                fontSize:".9em",
                                                                fontStyle:"italic"
                                                                }} 
                            >...Read more</a>
                        </Col>
                    </Row></a>
                    <Row>
                        <Col span={24} className="padding-10px" className="article-info-footer" >
                            <div className="article-info-footer__left">
                                Archived On: <span className="bold">{dateCreated}</span>
                                {/* Archived By: <span className="bold">"User name here"</span> */}
                                <div className="article-info-footer__tags-container">
                                    <span style={{marginRight: "10px"}}>Tags:</span>
                                    {
                                        tags && tags.split(' ').map(tag=>(
                                            <Tag>
                                                {tag}
                                            </Tag>
                                        ))
                                    }
                                    <span style={{marginLeft : "10px"}}>
                                    Visibility : {visibleTo === 'PUBLIC' ? <Tag color="volcano">{visibleTo}</Tag> : <Tag color="green">{visibleTo}</Tag> }
                                    </span>
                                </div>
                                
                            </div>
                            <div className="article-info-footer__right">
                                {/* <Button tyle="dashed" primary>
                                    <a href={url} target="_blank" >Read Complete Article</a>
                                </Button> */}
                                <Button type="dashed" danger className="article-info-footer__right__button-two" onClick={this.handleDeleteUserLink}>
                                    Delete
                                </Button>
                            </div>
                        </Col>
                    </Row>

                </Card>
            </div>

        )
    }
}
