import React, { Component } from "react";

import { Alert, Input, Card } from "antd";

import { HashRouter, Link } from "react-router-dom";

export default class TypeInNepaliMessage extends Component {
  render() {
    return (
      <div>
        <Card
          hoverable
          style={{ overflowWrap: "break-word" }}
          title="नेपालीमा टाइप गर्न आफ्नो कम्प्युटरमा उपकरण राख्नुहोस्। "
        >
          <h3>
            १. यो लिङ्कमा ({" "}
            {
              <a href="http://ltk.org.np/downloads.php" target="_blank">
                {" "}
                http://ltk.org.np/downloads.php{" "}
              </a>
            }
            ) पेजको पुछारमा Nepali Unicode Romanized वा Nepali Unicode
            Traditional डाउनलोड गर्नुहोस्।
          </h3>

          <h3>
            २.  क्रोम ब्राउजर मा राखेर नेपालीमा टाइप  गर्नुहोस्।  (
            {
              <a
                href="https://www.google.com/inputtools/chrome/"
                target="_blank"
              >
                https://www.google.com/inputtools/chrome/
              </a>
            }
            ) 
          </h3>
        </Card>
      </div>
    );
  }
}
