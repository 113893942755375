import React, { useContext, useEffect, useState } from "react";
import MobileLoginComponent from "../MobileLoginComponent";
import LoginWithGoogleAndFacebook from "../../layoutUtils/LoginWithGoogleAndFacebook";
import { Card, Col, Divider, Row, Spin } from "antd";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { showErrorNotification } from "../../../utils/Notification";
import { ToastContainer, toast } from "react-toastify";

const Login = (props) => {
  const { mobileNumber, loginCallBack, mobileNumberFromSignUp } = props;
  const [mobileNumberFormSignup, setMobileNumberFromSignup] = useState("");
  const [isMobileNumberComplete, setIsMobileNumberComplete] = useState(false);

  const otpCallBack = (value) => {
    setIsMobileNumberComplete(value);
  };

  const mobileNumberNotRegisterCallBack = (mobileNumber, errorMessage) => {
    loginCallBack(mobileNumber, errorMessage);
  };

  useEffect(() => {
    // console.log("HEllo");
    if (mobileNumberFromSignUp) {
      setMobileNumberFromSignup(mobileNumberFromSignUp);
    }
  }, [mobileNumberFromSignUp]);

  useEffect(() => {
    let expiredStatus = localStorage.getItem("expiredStatus");
    if (expiredStatus === "true") {
      toast(
        "उपलब्ध टोकनको म्याद समाप्त भइसकेको छ । हिज्जे प्रणाली प्रयोग गर्न पुनः लागिन गर्नुहोस् । ",
        {
          theme: "light",
          className: "custom-toast",
        }
      );
      localStorage.setItem("expiredStatus", false);
    }
  }, []);

  return (
    <>
      <Card>
        {/* <Spin spinning={!localStorage.getItem("sayakusertoken")}> */}
        <div style={{ minHeight: "30vh", margin: "auto" }}>
          {/* <div className="login-container"> */}

          <MobileLoginComponent
            otpCallBack={otpCallBack}
            mobileNumberNotRegisterCallBack={mobileNumberNotRegisterCallBack}
            mobileNumberFormSignup={mobileNumberFromSignUp}
          />

          {!isMobileNumberComplete ? (
            <>
              <br />
              {/* <h2 style={{ textAlign: "center", color: "#fa8c16" }}>-अथवा-</h2> */}
              <Divider
                style={{
                  padding: "0px",
                  margin: "0px",
                  marginBottom: "15px",
                  color: "#fa8c16",
                }}
              >
                -अथवा-
              </Divider>
              {/* <GoogleOAuthProvider clientId="600793487202-i18ke3h80nprvi2cuffhss7ipdmr0bj0.apps.googleusercontent.com"> */}
              <GoogleOAuthProvider clientId="93712308965-12kfjhu6h0hcelejdosqadsv2kfu1fik.apps.googleusercontent.com">
                <LoginWithGoogleAndFacebook />
              </GoogleOAuthProvider>
            </>
          ) : null}

          {/* </div> */}
        </div>
        {/* </Spin> */}
      </Card>
      <ToastContainer style={{ width: "700px" }} />
    </>
  );
};

export default Login;
