import React, { Component } from "react";
import { Drawer, Button, Select, Input, Spin } from "antd";

import copy from "copy-to-clipboard";
import { CheckCircleTwoTone, SwapOutlined } from "@ant-design/icons";

import { r_userFontConversion } from "../../../helpers/Requesthelper/RequestFontConversion";
import { r_addServiceUserLog } from "../../../helpers/Requesthelper/RequestStatistics";

const { Option } = Select;
const { TextArea } = Input;
class NepaliTypingDrawer extends Component {
  state = {
    childrenDrawer: false,

    serviceName: "FONTCONVERSION",
    initialFont: "preeti",
    finalFont: "unicode",
    callWord: "",
    wordCount: 0,
    wordCountLimit: 100,
    conversionResult: "",
    conversionResultcopied: false,
    errors: "",
    loading: false,
  };

  showChildrenDrawer = () => {
    this.setState({
      childrenDrawer: true,
    });
  };

  onChildrenDrawerClose = () => {
    this.setState({
      childrenDrawer: false,
    });
  };

  onChangeInput = ({ target: { value } }) => {
    console.log(value);
    this.setState({ callWord: value });
  };

  handleConversion = (e) => {
    this.setState({ errors: "", loading: true, conversionResult: "" });
    this.showChildrenDrawer();

    //if(!this.state.callWord) return
    //console.log("returning the state" , this.state)
    //console.log("returning the props", this.props)

    //code test for font conversion
    if (this.state.initialFont && this.state.finalFont && this.state.callWord) {
      const requestData = {
        fromFont: `${this.state.initialFont}`,
        toFont: `${this.state.finalFont}`,
        sourceWord: `${this.state.callWord}`,
      };

      //localStorage.setItem("fontConversionData", JSON.stringify(requestData));
      //call function from here and get the result
      r_userFontConversion(
        requestData,
        this.props.endpoints,
        this.props.auth.userToken
      ).then((resp) => {
        //console.log(resp.data.result)
        this.setState({ conversionResult: resp.data.text, loading: false });

        // r_addServiceUserLog(
        //   this.props.userDetails.userName,
        //   this.state.serviceName,
        //   this.props.endpoints,
        //   this.props.auth.userToken
        // ).then((res) => {
        //   //console.log("log successful")
        // });
      });
    } else {
      this.setState({
        errors: "फन्ट छानेर माथिको बाकसमा केहि लेख्नुहोस्।",
        conversionResult: "",
        loading: false,
      });
    }
  };

  showSuccessFullyCopied = () => {
    setTimeout(() => {
      this.setState({ conversionResultcopied: false });
    }, 500);

    return (
      <CheckCircleTwoTone
        twoToneColor="#52c41a"
        style={{ marginLeft: "5px", fontSize: "1.4em" }}
      />
    );
  };

  handleCopyText = () => {
    let a = this.state.conversionResult;
    //console.log(a)
    copy(a, {
      options: {
        format: "text/plain",
      },
    });
    this.setState({ conversionResultcopied: true });
  };

  render() {
    const fontList = {
      preeti: "Preeti",
    };
    const fonts = ["preeti"];

    return (
      <div>
        <Drawer
          title="Type In Nepali"
          width={520}
          closable={false}
          onClose={this.props.onClose}
          visible={this.props.drawerVisible}
        >
          <div className="flex-row-justify-space-between">
            <Select
              value={this.state.initialFont}
              showSearch
              size="large"
              style={{ width: 200 }}
              defaultValue="preeti"
              placeholder="फन्ट छन्नुहोस् ।"
              optionFilterProp="children"
              onChange={(e) => {
                this.setState({ initialFont: e, conversionResult: "" });
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {fonts.map((font, index) => {
                return (
                  <Option key={index} value={font}>
                    {fontList[font]}
                  </Option>
                );
              })}
            </Select>

            <Button
              type="primary"
              onClick={this.handleConversion}
              disabled={this.state.callWord.length < 1 ? true : false}
            >
              Convert To Unicode
            </Button>
          </div>

          <Input.TextArea
            value={this.state.callWord}
            placeholder="टाइप गर्नुहोस् वा अन्य ठाउँबाट कपी गरेर यस बाकसमा पेस्ट गर्नुहोस् अनि रुपान्तरण मा क्लिक गर्नुहोस्।"
            className="font-conversion-textarea"
            allowClear
            style={{
              height: "70%",
              fontFamily: `"${this.state.initialFont}"`,
            }}
            onChange={(e) => {
              this.onChangeInput(e);
            }}
          />

          <Drawer
            title="Conversion Result"
            width={500}
            closable={false}
            onClose={this.onChildrenDrawerClose}
            visible={this.state.childrenDrawer}
          >
            <div className="flex-row-justify-space-between-2">
              <Button onClick={this.handleCopyText}>Copy to Clipboard</Button>
              {this.state.conversionResultcopied
                ? this.showSuccessFullyCopied()
                : null}
            </div>
            {this.state.loading ? (
              <Spin />
            ) : (
              <TextArea
                placeholder="रुपान्तरित शब्दहरु यहाँ देखिने छन् "
                id="font-conversion-result-textArea"
                className="font-conversion-textarea"
                ref={(textareaConvertedFont) =>
                  (this.textareaConvertedFont = textareaConvertedFont)
                }
                autoSize={false}
                style={{
                  height: "70%",
                  fontFamily: `"${this.state.finalFont}"`,
                }}
                value={this.state.conversionResult}
              />
            )}
          </Drawer>
        </Drawer>
      </div>
    );
  }
}

export default NepaliTypingDrawer;
