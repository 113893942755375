import React, { Component } from "react";

import "./dashboard-style.css";
import SayakLogo from "../../images/sayak_logo.png";
import { BookOutlined, StarOutlined } from "@ant-design/icons";

import { updatePageHead } from "../../components/common/UpdateMetaAndTitle";

import { Layout, Menu, Row, Col, Button, Alert } from "antd";
import ChangeNavBarUser from "./ChangeNavBarUser";
import ChangeUserNav from "./ChangeUserNav";

import { HashRouter as Router } from "react-router-dom";

import { UserWelcomePage } from "../../components/userComponents/UserWelcomePage";
import { ChangeSpellingCheck } from "../../components/userComponents/ChangeSpellingCheck";
import FontConversion from "../../components/userComponents/FontConversion";
import { DictSearch } from "../../components/userComponents/DictSearch/DictSearch";
import ContactForm from "../publicLayout/layoutComponents/ContactForm";
import FAQuser from "./layoutComponents/FAQuser";
import { UserProfile } from "../../components/userComponents/userProfile";
import { UserStatisticsMain } from "../../components/userComponents/userStatisticsMain";
import { LinkArchive } from "../../components/userComponents/LinkArchive";
import { ChangeTypeInNepali } from "../../components/userComponents/ChangeTypeInNepali";
import LayoutTermsOfUse from "./LayoutTermsOfUse";
// import LayoutPrivacyPolicy from "./LayoutPrivacyPolicy";
import { NEW_LAYOUT_PATH, OLD_LAYOUT_PATH } from "../../helpers/Constants/path";
import { Route, Link, withRouter, Switch } from "react-router-dom";
import { Prompt } from "react-router";

import { connect } from "react-redux";
import { appBusyReset, appBusySet } from "../../store/actions/appStateAction";
import {
  resetUserAuth,
  logOutSayakUser,
  logOutNonSayakUser,
} from "../../store/actions/authAction";
import Loading from "../layoutUtils/Loading";
import { TypeInNepaliTTF } from "../../components/userComponents/TypeInNepaliTTF";
import Footer from "./Footer";
import LayoutPrivacyPolicy from "./LayoutPrivacyPolicy";

const { Header } = Layout;

export class ChangeLayoutUserDash extends Component {
  state = {
    mainMenuSelectedItem: "0",
    pasteCopiedText: "",
    spellCheckedText: "",
  };

  componentDidMount() {
    updatePageHead("Welcome", "", "");
    this.updateMenuBasedOnRoute("0");

    //console.log(this.props)
    //check if logged in
    //if not then redirect to login

    if (!localStorage.getItem("sayakusertoken")) {
      console.log("not logged in");
      this.props.history.push({ NEW_LAYOUT_PATH });
      //this.props.resetUserAuth()
    } else if (localStorage.getItem("sayakusertoken")) {
      //this is good
      //user logged in cha
    }
  }

  handleMenuItemClicked = (e) => {
    // console.log("i am event ", e)
    //this.setState({mainMenuSelectedItem : e.key})

    this.updateMenuBasedOnRoute(e.key);
  };

  handlePasteCopiedText = (value, callback) => {
    // console.log("parent maa value airaxa", value, callback);
    this.setState({ pasteCopiedText: value });

    // console.log("parent maa value aisakesi paste copied text maa value = ", this.state.pasteCopiedText);
  };

  handleSpellChecked = (value) => {
    this.setState({ spellCheckedText: value });
  };

  updateMenuBasedOnRoute = (key) => {
    // console.log(key);
    if (key > 0) {
      this.setState({ mainMenuSelectedItem: key });
    } else {
      let pathValues = {
        [NEW_LAYOUT_PATH]: "1",
        [`${NEW_LAYOUT_PATH} /typeInNepali`]: "1",
        [`${NEW_LAYOUT_PATH} /spelling`]: "2",
        [`${NEW_LAYOUT_PATH} /typeInNepaliTTF`]: "3",

        [`${NEW_LAYOUT_PATH} /fontConversion`]: "4",
        [`${NEW_LAYOUT_PATH} /dictSearch`]: "5",
        [`${NEW_LAYOUT_PATH} /FAQ`]: "6",
        [`${NEW_LAYOUT_PATH} /contact`]: "7",
      };
      let currentPath = this.props.location.pathname;
      // console.log("mah current path ho", currentPath)

      for (const [key, value] of Object.entries(pathValues)) {
        // console.log("currentpath and key", currentPath, key)

        if (currentPath === key) {
          this.setState({ mainMenuSelectedItem: value });
        } else {
          // console.log("milena")
        }
      }
    }
  };

  //

  render() {
    //console.log(this.props)
    // console.log(this.state.pasteCopiedText);

    return (
      <div className="dashboard-main-wrapper" id="footer-scroll">
        <ChangeNavBarUser {...this.props} />

        {this.props.appState.busy ? (
          <Loading messageSpin="Logging In Please Wait" />
        ) : null}

        <Row type="flex" justify="space-around">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
            xxl={{ span: 24 }}
          >
            <div className="user-dashboard-layout-right">
              {/* <div>
                <Link to={OLD_LAYOUT_PATH}>
                  <Alert
                    message="पुरानो website मा जान चाहनुहुन्छ ? यहाँ click गर्नुहोस् ।"
                    type="info"
                    closable
                    showIcon
                    style={{ fontSize: "1.2em" }}
                  />
                </Link>
              </div> */}

              <Router basename={NEW_LAYOUT_PATH}>
                <ChangeUserNav
                  {...this.props}
                  updateMainNav={this.updateMenuBasedOnRoute}
                  updateMenuNav={this.updateMenuBasedOnRoute}
                  selectedItem={this.state.mainMenuSelectedItem}
                />
                {/* <div className="user-header-layout-up">
                                    <Row>
                                        <Header style={{ backgroundColor: "transparent", width: "100%", display: "flex", justifyContent: "space-around", marginBottom: "10px" }}>
                                            <Menu theme="" mode="horizontal" selectedKeys={this.state.mainMenuSelectedItem} style={{ fontSize: "1.3em", backgroundColor: "transparent" }} className="usernav-header-menu" >

                                                <Menu.Item key="1" onClick={this.handleMenuItemClicked} >
                                                    <span>रोमन नेपाली टाइपिङ </span>
                                                    <Link to="/typeInNepali" />
                                                </Menu.Item>
                                                <Menu.Item key="2" onClick={this.handleMenuItemClicked} >
                                                    <span>नेपाली हिज्जे परीक्षण</span>
                                                    <Link to="/spelling" />
                                                </Menu.Item>
                                                <Menu.Item key="3" onClick={this.handleMenuItemClicked} >
                                                    <span>फन्ट टाइपिङ </span>
                                                    <Link to="/typeInNepaliTTF" />
                                                </Menu.Item>

                                                <Menu.Item key="4" onClick={this.handleMenuItemClicked} >
                                                    <span>फन्ट रूपान्तरण</span>
                                                    <Link to="/fontConversion" />
                                                </Menu.Item>
                                                <Menu.Item key="5" onClick={this.handleMenuItemClicked} >
                                                    <span>नेपाली शब्दकोश </span>
                                                    <Link to="/dictSearch" />
                                                </Menu.Item>
                                            </Menu>
                                        </Header>

                                    </Row>
                                </div> */}
                {/* <div className="container"> */}

                <div className="container">
                  <div className="user-dashboard-main-section">
                    {/* <Route index component={() => <SpellingCheck {...this.props} />} /> */}

                    <Route
                      exact
                      path="/"
                      // path="user/typeInNepali"
                      component={() => (
                        <ChangeTypeInNepali
                          pasteText={this.handlePasteCopiedText}
                          updateStyle={this.handleMenuItemClicked}
                          {...this.props}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/spelling"
                      // path="user/spelling"
                      component={() => (
                        <ChangeSpellingCheck
                          data={this.state.pasteCopiedText}
                          updateStyle={this.handleMenuItemClicked}
                          {...this.props}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/typeInNepali"
                      // path="user/typeInNepali"
                      component={() => (
                        <ChangeTypeInNepali
                          pasteText={this.handlePasteCopiedText}
                          updateStyle={this.handleMenuItemClicked}
                          {...this.props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/typeInNepaliTTF"
                      // path="user/typeInNepali"
                      component={() => {
                        // console.log("path reached");
                        return <TypeInNepaliTTF {...this.props} />;
                      }}
                    />

                    <Route
                      exact
                      path="/fontConversion"
                      component={() => <FontConversion {...this.props} />}
                    />

                    <Route
                      exact
                      path="/dictSearch"
                      component={() => <DictSearch {...this.props} />}
                    />

                    {/* <Route
                                        exact
                                        path="/linkArchive"
                                        component={() => <LinkArchive {...this.props} />}
                                    /> */}

                    <Route exact path="/FAQ" component={() => <FAQuser />} />

                    <Route
                      exact
                      path="/contact"
                      component={() => <ContactForm {...this.props} />}
                    />

                    <Route
                      exact
                      path="/stat"
                      component={() => <UserStatisticsMain {...this.props} />}
                    />

                    <Route
                      exact
                      path="/profile"
                      component={() => <UserProfile {...this.props} />}
                    />
                    <Route
                      exact
                      path="/termsofuse"
                      component={() => <LayoutTermsOfUse />}
                    />
                    <Route
                      exact
                      path="/privacypolicy"
                      component={() => <LayoutPrivacyPolicy />}
                    />
                  </div>
                </div>
                {/* </div> */}
                <div>
                  <Footer />
                </div>
              </Router>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  endpoints: state.apiConstants,
  userDetails: state.userDetails,
  appState: state.appState,
});

export default connect(mapStateToProps, {
  logOutSayakUser,
  logOutNonSayakUser,
  appBusySet,
  appBusyReset,
})(withRouter(ChangeLayoutUserDash));
