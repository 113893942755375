import React, { Component } from "react";

import isEmpty from "../../validation/is-empty";
import { updatePageHead } from "../../components/common/UpdateMetaAndTitle";

import NavBar from "./NavBar";
import Footer from "./Footer";

import LoginWithGoogleAndFacebook from "../layoutUtils/LoginWithGoogleAndFacebook";
import { Form, Icon, Input, Button, Row, Col, Alert } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined, UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";

import { withRouter, Link } from "react-router-dom";

import Loading from "../layoutUtils/Loading";

import "./styles/layoutstyle.css"

import { connect } from "react-redux";
import {
  registerUser,
  loginUserWithFacebook,
  loginUserWithGoogle,
  resetUserAuth,
} from "../../store/actions/authAction";
import { appBusyReset, appBusySet } from "../../store/actions/appStateAction";
import { resetAuthErrors } from "../../store/actions/authErrorAction";

class LayoutSignUp extends Component {
  state = {
    name: "",
    email: "",
    password: "",
    password2: "",
    errors: {},
  };

  componentDidMount() {
    updatePageHead("SignUp", "", "");

    this.props.resetUserAuth();
    this.props.resetAuthErrors();
    this.props.appBusyReset();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    this.setState({ errors: {} });
    //e.preventDefault()
    //console.log("submit hit")
    if (this.state.password !== this.state.password2) {
      this.setState({
        errors: {
          message: "passwords didnot match",
        },
      });
      // console.log("passwords dont match");
      return;
    }

    const userData = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
    };

    //console.log(userData);

    const appStateData = { message: "Creating Account" };

    this.props.appBusySet(appStateData);

    this.props.registerUser(userData, this.props.history, this.props.endpoints);

    //now take this content and make the api call
    //set the authentication reducer
    //setup the store

    //make the request here
  };

  render() {
    const layout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 24 },
    };

    //console.log(this.props)

    return (
      <div className="main-wrapper">
        <NavBar />
        {this.props.appState.busy ? (
          <Loading messageSpin="Logging In Please Wait" />
        ) : null}

        <Row
          type="flex"
          justify="space-around"
          align="middle"
          className="bg-blue-yellow-gradient"
        >
          {/* <Col
            xs={{ span: 22 }}
            sm={{ span: 22 }}
            md={{ span: 22 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <div className="container-layout-left-one">
              <LoginWithGoogleAndFacebook />
            </div>
          </Col> */}

          <Col
          // xs={{ span: 22 }}
          // sm={{ span: 22 }}
          // md={{ span: 22 }}
          // lg={{ span: 8 }}
          // xl={{ span: 8 }}
          // xxl={{ span: 8 }}
          >
            <div style={{ minHeight: "80vh", display: "flex", justifyContent: "center", flexDirection: "column" }}>
              <div className="form-container">
                <h1 style={{ color: "#fa8c16" }}>खाता खोल्नुहोस् ।</h1>
                <div className="container-layout-left-one">
                  <LoginWithGoogleAndFacebook />
                </div>
                <br />
                <h2 style={{ textAlign: "center", color: "#fa8c16" }}>-अथवा-</h2>
                <Form
                  {...layout}
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onFinish={this.onSubmit}
                  style={{ width: "100%" }}
                >

                  <Form.Item

                    name="name"
                    label="नाम : "
                    rules={[
                      { required: true, message: "Please input your Name!" },
                    ]}
                  >
                    <Input
                      name="name"
                      placeholder="नाम "
                      onChange={this.onChange}
                      prefix={<UserOutlined className="site-form-item-icon" />}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="इमेल: "
                    rules={[
                      { required: true, message: "Please input your Email!" },
                    ]}
                  >
                    <Input
                      name="email"
                      type="email"
                      placeholder="इमेल"
                      onChange={this.onChange}
                      prefix={<MailOutlined className="site-form-item-icon" />}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="पासवर्ड: "
                    rules={[
                      { required: true, message: "Please input your Password!" },
                    ]}
                  >
                    <Input.Password
                      name="password"
                      placeholder="पासवर्ड"
                      onChange={this.onChange}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      prefix={<LockOutlined className="site-form-item-icon" />}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password2"
                    label="पासवर्ड:"
                    rules={[
                      { required: true, message: "Please input your Password!" },
                    ]}
                  >
                    <Input.Password
                      name="password2"
                      placeholder="पासवर्ड"
                      onChange={this.onChange}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      prefix={<LockOutlined className="site-form-item-icon" />}
                    />
                  </Form.Item>

                  {!isEmpty(this.state.errors) ? (
                    <Form.Item
                      wrapperCol={{
                        sm: { span: 24, offset: 0 },
                        xl: { span: 19, offset: 5 },
                      }}
                    >
                      <Alert
                        message={this.state.errors.message}
                        type="error"
                        showIcon
                        style={{ widt: "100%" }}
                      />
                    </Form.Item>
                  ) : null}

                  {!isEmpty(this.props.autherrors) ? (
                    <Form.Item
                      wrapperCol={{
                        sm: { span: 24, offset: 0 },
                        xl: { span: 19, offset: 5 },
                      }}
                    >
                      <Alert
                        message={this.props.autherrors.message}
                        type="error"
                        showIcon
                        style={{ widt: "100%" }}
                      />
                    </Form.Item>
                  ) : null}

                  <Form.Item
                    wrapperCol={{
                      sm: { span: 24, offset: 0 },
                      xl: { span: 24, offset: 0 },
                    }}
                  >
                    <Button
                      style={{ marginTop: "10px" }}
                      htmlType="submit"
                      className="login-form-button"
                    >
                      नि:शुल्क खाता खोल्नुहोस्
                    </Button>
                    {/* <Button
                    type="primary"
                    className="login-form-button btn-orange"
                    style={{ marginLeft: "10px" }}
                  >
                    <Link to ="/login">लगिन</Link>
                  </Button> */}
                  </Form.Item>
                  {/* <Form.Item>
                  <span>-अथवा-</span>
                </Form.Item> */}

                </Form>
              </div>
            </div>
          </Col>
        </Row>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userRegister: state.auth.userRegister,
  appState: state.appState,
  autherrors: state.authErrors,
  endpoints: state.apiConstants,
});

export default connect(mapStateToProps, {
  registerUser,
  resetAuthErrors,
  resetUserAuth,
  appBusySet,
  appBusyReset,
  resetAuthErrors,
  loginUserWithFacebook,
  loginUserWithGoogle,
})(withRouter(LayoutSignUp));
