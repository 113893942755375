import React, { Component } from "react";

import UserProductStatistics from "./StatisticsComponents/UserProductStatistics";

import { Card, Row, Col, Button } from "antd";

import { Link } from "react-router-dom";

export class UserWelcomePage extends Component {
  render() {
    return (
      <div className="container">
        <Row
          type="flex"
          justify="center"
          style={{ textAlign: "center" }}
          className="container"
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
            xxl={{ span: 24 }}
          >
            <h2>Welcome!, {this.props.userDetails.name}</h2>

            <p style={{ marginTop: "20px" }}>
              We are glad to connect to you through Sayak. Sayak is a Nepali
              language based portal designed to help anyone in need of correct
              reading and writing Nepali. Sayak offers following services for
              free . Please select your desired service .
            </p>
          </Col>
        </Row>

        <Row type="flex" gutter={16} style={{ textAlign: "center" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Card hoverable title="Nepali Spelling Checker">
              <p>
                Check Spelling of Nepali words and correct them as you type .{" "}
              </p>
              <Link to="/user/spelling">
                <Button type="primary" size="large" className="btn-yellow">
                  Open Nepali Spelling Checker
                </Button>
              </Link>
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Card hoverable title="Nepali Font Conversion">
              <p>
                Convert unicode to .ttf font or vice-versa with a single click .
              </p>
              <Link to="/user/fontConversion">
                <Button type="primary" size="large" className="btn-yellow">
                  Open Nepali Font Conversion
                </Button>
              </Link>
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <Card hoverable title="Nepali Dictionary Search">
              <p>
                Find meaning of nepali words and make your vocabulary better.
              </p>
              <Link to="/user/dictSearch">
                <Button type="primary" size="large" className="btn-yellow">
                  Open Nepali Dictionary
                </Button>
              </Link>
            </Card>
          </Col>
        </Row>

        {/* <Row type="flex" justify="center" style={{textAlign:'center'}} className="container">   
                <Col
                xs={{ span: 24}} 
                sm={{ span: 24}}
                md={{ span: 24}} 
                lg={{ span: 12}}
                xl={{ span: 12}}
                xxl={{ span: 12}}
                > 
                    <Card 
                        className="card-with-shadow"
                        title="Your Usage Statistics"
                    >
                    <   UserProductStatistics {...this.props}/>
                    </Card>
                </Col>

            </Row> */}
      </div>
    );
  }
}
