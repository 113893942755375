import React, { Component } from "react";

import { Drawer, Button, Select, Input, Spin, Alert, Tabs } from "antd";
import copy from "copy-to-clipboard";
import {
  CheckCircleTwoTone,
  CopyOutlined,
  TranslationOutlined,
} from "@ant-design/icons";

import "./style.css";
import { updatePageHead } from "../common/UpdateMetaAndTitle";

const { Option } = Select;

export class TypeInNepaliTTF extends Component {
  state = {
    textBoxContent: localStorage.getItem("typeInNepaliTTF"),
    // textBoxContent:this.props.data,
    copied: false,
    selectedFont: "preeti",
  };

  componentDidMount() {
    updatePageHead(
      "Nepali Font Typing",
      "Nepali typing, Uncicode typing, Nepali Keyboard, नेपाली टाइप,  युनिकोड टाइपिङ",
      "Nepali typing, Uncicode typing, Nepali Keyboard, नेपाली टाइप,  युनिकोड टाइपिङ"
    );
  }
  onChangeInput = ({ target: { value } }) => {
    this.setState({ textBoxContent: value });

    localStorage.setItem("typeInNepaliTTF", value);
  };

  showSuccessFullyCopied = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 500);

    return (
      <CheckCircleTwoTone
        twoToneColor="rgb(243, 227, 2)"
        // style={{ marginLeft: "5px", fontSize: "1.4em" }}
      />
    );
  };

  handleCopyText = () => {
    let a = this.state.textBoxContent;
    copy(a, {
      options: {
        format: "text/plain",
      },
    });
    this.setState({ copied: true });
  };

  handleClearTextArea = () => {
    this.setState({ textBoxContent: "" });

    localStorage.removeItem("typeInNepaliTTF");
  };

  render() {
    const fontList = {
      preeti: "प्रीति",
      aakriti: "आकृति",
      aalekh: "आलेख",
      ganess: "गणेश",
      himalb: "हिमालबी",
      kantipur: "कान्तिपुर",
      navjeevan: "नवजीवन",
      PCSnepali: "पिसिएस",
      shangrila: "साङ्ग्रिला ",
      shreenath: "श्रीनाथ",
      sumodacharya: "सुमुदाचार्य",
    };
    const fonts = [
      "preeti",
      "aakriti",
      "aalekh",
      "ganess",
      "himalb",
      "kantipur",
      "navjeevan",
      "PCSnepali",
      "shangrila",
      "shreenath",
      "sumodacharya",
    ];

    return (
      <div className="type-in-nepali-ttf-container">
        {/* <h1 style={{ textAlign: "center", marginBottom: "20px" }}>फन्ट टाइपिङ ( प्रीति, कान्तिपुर आदि )</h1> */}
        <div
          className="flex-row-justify-space-between"
          style={{ marginBottom: "10px" }}
        >
          <div>
            <Select
              value={this.state.selectedFont}
              showSearch
              size="large"
              style={{ width: 200 }}
              defaultValue="preeti"
              placeholder="फन्ट छन्नुहोस् ।"
              optionFilterProp="children"
              onChange={(e) => {
                this.setState({ selectedFont: e });
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {fonts.map((font, index) => {
                return (
                  <Option key={index} value={font}>
                    {fontList[font]}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div>
            <Button
              type="primary"
              size="large"
              onClick={this.handleCopyText}
              icon={
                this.state.copied ? (
                  this.showSuccessFullyCopied()
                ) : (
                  <CopyOutlined />
                )
              }
            >
              Copy to Clipboard
            </Button>
            {/* {this.state.copied
              ? this.showSuccessFullyCopied()
              : null} */}

            <Button
              danger
              dashed
              size="large"
              onClick={this.handleClearTextArea}
              style={{ marginLeft: "10px" }}
            >
              Clear
            </Button>
          </div>
        </div>

        <div>
          <Input.TextArea
            value={this.state.textBoxContent}
            placeholder="माथि दिइएको फन्ट छान्नुहोस र बाकसमा टाइप गर्नुहोस्।"
            className="font-conversion-textarea"
            allowClear
            style={{
              height: "500px",
              fontFamily: `"${this.state.selectedFont}"`,
            }}
            onChange={(e) => {
              this.onChangeInput(e);
            }}
          />
        </div>
      </div>
    );
  }
}
