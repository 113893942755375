import React, { Component } from "react";
import {
  Button,
  Card,
  Input,
  Select,
  Form,
} from "antd";

const { Option } = Select;

class userFilter extends Component {
  state = {
    filterByName: false,
    filterByUsage: false,
    userNameToFilter: "",
    serviceUsageFilter: "",
  };

  filterUserFormRef = React.createRef()

  handleFilter = (e) => {
    console.log(e);
    this.setState({
      filterByName: false,
      filterByUsage: false,
    });
    if (e === "name") {
      this.setState({ filterByName: true });
      this.props.setTableLoading()
      this.filterUserFormRef.current.setFieldsValue({nameFilter: ""});

    }
    if (e === "usage") {
      this.setState({ filterByUsage: true });
      this.props.setTableLoading()
    }
  };

  handleServiceUsageFilter = (e) => {
    this.props.resetTableData()
    this.props.setServiceUsageFilter(e)
    this.setState({serviceUsageFilter : e})
  }

  handleFilterByNameChange = (e) => {
    this.props.resetTableData()
    this.props.setUserNameToFilter(e.target.value.trim())
    this.setState({userNameToFilter: e.target.value.trim()})
      
  }

  handleFilterUserFormClear = () => {
    this.filterUserFormRef.current.resetFields();
    this.setState({
        filterByName: false,
        filterByUsage: false,
      });

    this.props.resetTableFilterOn()
    //clear the form and reload the table with the complete data
    console.log("clear the filter form and reload the data of complete list of users")
  };

  handleFilterOfUsers = () => {
    
    this.props.setTableFilterOn()

    if(this.state.filterByName){
        if(this.state.userNameToFilter.length >= 1){
            this.props.renderUsersFilteredByName()
        }
    }
    if(this.state.filterByUsage){
        if(this.state.serviceUsageFilter.length >= 1){
          this.props.renderUsersFilterdByServiceUsage()
        }
    }

  }
  
  render() {
    return (
      <div>
        <Card>
          <Form
            ref={this.filterUserFormRef}
            name="filter-users-form"
            layout="inline"
            // onFinish={onFinish}
          >
            <Form.Item name="filterBy" label="Filter By">
              <Select
                placeholder="Select Filter Type"
                style={{ width: "200px" }}
                onChange={this.handleFilter}
              >
                <Option value="name">Name</Option>
                <Option value="usage">Usage</Option>
              </Select>
            </Form.Item>

            {this.state.filterByName ? (
              <Form.Item name="nameFilter" label="User Name">
                <Input placeholder="Input User Name"  onChange = {this.handleFilterByNameChange}/>
              </Form.Item>
            ) : null}

            {this.state.filterByUsage ? (
              <Form.Item name="serviceUsageFilter" label="Select Service">
                <Select placeholder="Select Service" style={{ width: "200px" }} onChange={this.handleServiceUsageFilter}>
                  <Option value="FONTCONVERSION">Font Conversion</Option>
                  <Option value="SPELLSUGGESTION">Spelling Check</Option>
                  <Option value="DICTIONARY">Dictionary Search</Option>
                </Select>
              </Form.Item>
            ) : null}

            <Form.Item>
              <Button type="primary" onClick={this.handleFilterOfUsers}>
                Filter
              </Button>
            </Form.Item>

            <Form.Item>
              <Button dashed onClick={this.handleFilterUserFormClear}>
                Reset Table
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

export default userFilter;
