import React , { Component } from 'react'

import { Input } from 'antd';

import SearchDictionary from './helper/SearchDictionary'

export class DictSearch extends Component{

    state = {
       
    }



    componentDidMount() {

    }

    render(){
    return(
        <div>

            <SearchDictionary {...this.props}/>
            
        </div>
    )
    }
}